import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import EmployRefInternalFroms from './EmployRefInternalFroms';
import EmployRefExternalFroms from './EmployRefExternalFroms';
import { useTranslation } from 'react-i18next';
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const { t } = useTranslation();
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function  EmployReferenceTabs() {
  const [value, setValue] = React.useState(0);
  const { t } = useTranslation();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{textAlign:"center" }}>
        <Tabs 
            sx={{
                textAlign:"center",
                display:"flex",
                flexDirection:"row",
                justifyContent:"center",
                alignItems:"center",
                width:{xs:260,sm:300,lg:370},
                mx:"auto",
                "& .MuiTabs-scroller":{
                    flexDirection:"row",
                    justifyContent:"center",
                    alignItems:"center", 
                    display:"flex",
                    backgroundColor:"#EFF1F8",
                    p:0.8,
                    borderRadius:"4px",
                },
                "& .Mui-selected": {
                    backgroundColor:"#4165F6 !important",
                    color:"#ffffff !important",
                    borderRadius:"4px",
                  
                },
                "& .MuiTab-textColorPrimary":{
                    px:{xs:4,sm:6,lg:8},
                    py:2,
                    textTransform:"capitalize"
                },
                "& .MuiTabs-indicator":{
                    display:"none"
                }
                 }} 
                 disableRipple={true}
                 value={value}
                  onChange={handleChange} 
                >
          <Tab label= {t("EmploymentReferences.optionsReferences")} {...a11yProps(0)} />
          <Tab label= {t("EmploymentReferences.optionsReferences1")}{...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <EmployRefInternalFroms/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <EmployRefExternalFroms/>
      </TabPanel>
    </Box>
  );
}