import { Grid, Typography, Box } from "@mui/material";
import React from "react";

import underLine from "../../assets/images/under-line.png";
import WithBureauQueryForm from "../WithBureau/WithBureauQueryForm/WithBureauQueryForm";
import { useTranslation } from "react-i18next";

function DashboardMain() {
  const { t } = useTranslation();
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} style={{
          paddingTop: 0,
        }}>
          <Box mt={2}>
            <Typography
              sx={{
                fontFamily: "Poppins-SemiBold",
                fontSize: "24px",
                lineHeight: "35px",
                textAlign: "center",
                color: "#1C212D",
              }}
            >
              {t("PageName.Query")}
            </Typography>
            <Box sx={{ textAlign: "center", mt: -1 }}>
              <img src={underLine} alt="under line" />
            </Box>
          </Box>
        </Grid>
        {/* <Grid item xs={12} sm={1}></Grid> */}
        <Grid item xs={12} sm={12}>
          {/* <GenerateQueryForm/> */}
          <WithBureauQueryForm />
        </Grid>
      </Grid>
    </>
  );
}

export default DashboardMain;
