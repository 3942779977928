import * as React from "react";

import Typography from "@mui/material/Typography";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import InnerTable from "./InnerTable";
import InnerTableThree from "./InnerTableThree";
import { useTranslation } from "react-i18next";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function Socioeconomic() {
  const { t } = useTranslation();
  return (
    <Box sx={{ height: "70vh", overflowY: "auto" }}>
      <TableContainer sx={{ borderRadius: 0 }} component={Paper}>
        <Table sx={{ borderRadius: 0 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: "#4165F6" }}>
            <TableRow sx={{ width: "100%" }}>
              <TableCell
                align="left"
                sx={{
                  width: "40%",
                  py: 2,
                  border: 0,
                  color: "#ffffff",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "2vw", sm: "0.875rem" },
                }}
              >
                {t("landingpage.leftContent.tabs.subtab.A")}
              </TableCell>
              <TableCell
                sx={{
                  width: "30%",
                  py: 2,
                  border: 0,
                  color: "#ffffff",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "2vw", sm: "0.875rem" },
                }}
                align="center"
              >
                {t("landingpage.leftContent.tabs.subheading.Report Format")}
              </TableCell>
              <TableCell
                sx={{
                  width: "15%",
                  py: 2,
                  border: 0,
                  color: "#ffffff",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "2vw", sm: "0.875rem" },
                }}
                align="center"
              >
                {t("landingpage.leftContent.tabs.subheading.Concept")}
              </TableCell>
              <TableCell
                sx={{
                  width: "15%",
                  py: 2,
                  border: 0,
                  color: "#ffffff",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "2vw", sm: "0.875rem" },
                }}
                align="center"
              >
                {t("landingpage.leftContent.tabs.subheading.Result")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ borderRadius: 0, mt: 2 }}>
            <TableRow
              sx={{
                borderRadius: 0,
                "&:last-child td, &:last-child th": {
                  border: 0,
                  borderRadius: 0,
                },
              }}
            >
              <TableCell
                // colSpan={2}
                sx={{
                  width: "40%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="left"
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    sx={{
                      color: "primary.main",
                      width: "100%",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    {t(
                      "landingpage.leftContent.tabs.A | Socioeconomic Study.A. Socioeconomic Study.I"
                    )}
                  </Typography>
                </Box>

                {/* <Box sx={{display:"flex",mt:4}}>
                          <InnerTable tableData={data[section.inner_table['data']]} dataType={section.inner_table['type']}/>
                        </Box> */}
              </TableCell>

              <TableCell
                sx={{
                  width: "30%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    sx={{
                      color: "#000000",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    {t(
                      "landingpage.leftContent.tabs.A | Socioeconomic Study.Report Format.medium"
                    )}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                {t(
                  "landingpage.leftContent.tabs.A | Socioeconomic Study.Concept.Colony"
                )}
              </TableCell>

              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  borderRadius: 0,
                  border: "5px solid #ffffff !important",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <ul>
                  {t(
                    "landingpage.leftContent.tabs.A | Socioeconomic Study.Result.Colonys"
                  )}
                  <br></br>
                  {t(
                    "landingpage.leftContent.tabs.A | Socioeconomic Study.Result.Colony"
                  )}
                </ul>
              </TableCell>
            </TableRow>
            <TableRow
              sx={{
                borderRadius: 0,
                "&:last-child td, &:last-child th": {
                  border: 0,
                  borderRadius: 0,
                },
              }}
            >
              <TableCell
                // colSpan={2}
                sx={{
                  width: "40%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  backgroundColor: "rgba(0, 127, 255, 0.1)",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="left"
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    sx={{
                      color: "primary.main",
                      width: "100%",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    {t(
                      "landingpage.leftContent.tabs.A | Socioeconomic Study.A. Socioeconomic Study.II"
                    )}
                  </Typography>
                </Box>
              </TableCell>

              <TableCell
                sx={{
                  width: "30%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  backgroundColor: "rgba(0, 127, 255, 0.1)",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "1.5vw", sm: 14 },
                    fontFamily: "Gilroy-Medium",
                  }}
                >
                  $35,000
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                {t(
                  "landingpage.leftContent.tabs.A | Socioeconomic Study.Concept.Estimated"
                )}
              </TableCell>

              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  borderRadius: 0,
                  border: "5px solid #ffffff !important",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <ul>
                  {t(
                    "landingpage.leftContent.tabs.A | Socioeconomic Study.Result.Amount MXN"
                  )}
                </ul>
              </TableCell>
            </TableRow>
            <TableRow
              sx={{
                borderRadius: 0,
                "&:last-child td, &:last-child th": {
                  border: 0,
                  borderRadius: 0,
                },
              }}
            >
              <TableCell
                colSpan={2}
                sx={{
                  width: "40%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="left"
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    sx={{
                      color: "primary.main",
                      width: "65%",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    {t(
                      "landingpage.leftContent.tabs.A | Socioeconomic Study.A. Socioeconomic Study.III"
                    )}
                  </Typography>
                  <Typography
                    sx={{
                      color: "primary.main",
                      width: "35%",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                      textAlign: "center",
                    }}
                  >
                    5
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", mt: { xs: 1, sm: 4 } }}>
                  <InnerTable />
                </Box>
              </TableCell>

              {/* <TableCell  sx={{width:"30%",border: "5px solid #ffffff !important",borderRadius:0,backgroundColor:"rgba(0, 127, 255, 0.1)"}} align="left">
                            <Box sx={{display:"flex",flexDirection:"row"}}>
                                <Typography sx={{color:"#000000",fontSize:14,fontFamily:"Gilroy-Medium"}}>$8,189 </Typography>
                          
                            </Box>
                        </TableCell> */}
              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                {" "}
                {t(
                  "landingpage.leftContent.tabs.A | Socioeconomic Study.Concept.People"
                )}
              </TableCell>

              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  borderRadius: 0,
                  border: "5px solid #ffffff !important",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <ul>
                  {t(
                    "landingpage.leftContent.tabs.A | Socioeconomic Study.Result.Amount, No / ◉ Relationship"
                  )}
                </ul>
              </TableCell>
            </TableRow>
            <TableRow
              sx={{
                borderRadius: 0,
                "&:last-child td, &:last-child th": {
                  border: 0,
                  borderRadius: 0,
                },
              }}
            >
              <TableCell
                colSpan={2}
                sx={{
                  width: "40%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  backgroundColor: "rgba(0, 127, 255, 0.1)",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="left"
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    sx={{
                      color: "primary.main",
                      width: "65%",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    {t(
                      "landingpage.leftContent.tabs.A | Socioeconomic Study.A. Socioeconomic Study.IV"
                    )}
                  </Typography>
                  <Typography
                    sx={{
                      color: "primary.main",
                      width: "35%",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                      textAlign: "center",
                    }}
                  >
                    4
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", mt: { xs: 1, sm: 4 } }}>
                  <InnerTableThree />
                </Box>
              </TableCell>

              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                {t(
                  "landingpage.leftContent.tabs.A | Socioeconomic Study.Concept.IMSS / ISSSTE"
                )}
              </TableCell>

              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  borderRadius: 0,
                  border: "5px solid #ffffff !important",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <ul>
                  {t(
                    "landingpage.leftContent.tabs.A | Socioeconomic Study.Result.Relationship Father"
                  )}
                </ul>
              </TableCell>
            </TableRow>

            <TableRow
              sx={{
                borderRadius: 0,
                "&:last-child td, &:last-child th": {
                  border: 0,
                  borderRadius: 0,
                },
              }}
            >
              <TableCell
                // colSpan={2}
                sx={{
                  width: "40%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="left"
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    sx={{
                      color: "primary.main",
                      width: "100%",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    {t(
                      "landingpage.leftContent.tabs.A | Socioeconomic Study.A. Socioeconomic Study.V"
                    )}
                  </Typography>
                </Box>

                {/* <Box sx={{display:"flex",mt:4}}>
                          <InnerTable tableData={data[section.inner_table['data']]} dataType={section.inner_table['type']}/>
                        </Box> */}
              </TableCell>

              <TableCell
                sx={{
                  width: "30%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "1.5vw", sm: 14 },
                    fontFamily: "Gilroy-Medium",
                  }}
                >
                  UVM / UNAM
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                {t(
                  "landingpage.leftContent.tabs.A | Socioeconomic Study.Concept.Institution"
                )}{" "}
              </TableCell>

              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  borderRadius: 0,
                  border: "5px solid #ffffff !important",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <ul>
                  {t(
                    "landingpage.leftContent.tabs.A | Socioeconomic Study.Result.Name of institution"
                  )}
                </ul>
              </TableCell>
            </TableRow>

            <TableRow
              sx={{
                borderRadius: 0,
                "&:last-child td, &:last-child th": {
                  border: 0,
                  borderRadius: 0,
                },
              }}
            >
              <TableCell
                // colSpan={2}
                sx={{
                  width: "40%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  backgroundColor: "rgba(0, 127, 255, 0.1)",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="left"
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    sx={{
                      color: "primary.main",
                      width: "100%",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    {t(
                      "landingpage.leftContent.tabs.A | Socioeconomic Study.A. Socioeconomic Study.VI"
                    )}
                  </Typography>
                </Box>
              </TableCell>

              <TableCell
                sx={{
                  width: "30%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  backgroundColor: "rgba(0, 127, 255, 0.1)",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    sx={{
                      color: "#000000",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    12345678 /{" "}
                    {t(
                      "landingpage.leftContent.tabs.A | Socioeconomic Study.Report Format.market"
                    )}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000000",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    12345688 /
                    {t(
                      "landingpage.leftContent.tabs.A | Socioeconomic Study.Report Format.numero"
                    )}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                {t(
                  "landingpage.leftContent.tabs.A | Socioeconomic Study.Concept.Accreditation"
                )}
              </TableCell>

              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  borderRadius: 0,
                  border: "5px solid #ffffff !important",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <ul>
                  {t(
                    "landingpage.leftContent.tabs.A | Socioeconomic Study.Result.ID/Title"
                  )}
                </ul>
              </TableCell>
            </TableRow>

            <TableRow
              sx={{
                borderRadius: 0,
                "&:last-child td, &:last-child th": {
                  border: 0,
                  borderRadius: 0,
                },
              }}
            >
              <TableCell
                // colSpan={2}
                sx={{
                  width: "40%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="left"
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    sx={{
                      color: "primary.main",
                      width: "100%",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    {t(
                      "landingpage.leftContent.tabs.A | Socioeconomic Study.A. Socioeconomic Study.VII"
                    )}
                  </Typography>
                </Box>

                {/* <Box sx={{display:"flex",mt:4}}>
                          <InnerTable tableData={data[section.inner_table['data']]} dataType={section.inner_table['type']}/>
                        </Box> */}
              </TableCell>

              <TableCell
                sx={{
                  width: "30%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "1.5vw", sm: 14 },
                    fontFamily: "Gilroy-Medium",
                  }}
                >
                  {t(
                    "landingpage.leftContent.tabs.A | Socioeconomic Study.Report Format.master's degree"
                  )}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                {t(
                  "landingpage.leftContent.tabs.A | Socioeconomic Study.Concept.Last"
                )}
              </TableCell>

              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  borderRadius: 0,
                  border: "5px solid #ffffff !important",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <ul>
                  {t(
                    "landingpage.leftContent.tabs.A | Socioeconomic Study.Result.Doctorate"
                  )}
                </ul>
              </TableCell>
            </TableRow>

            <TableRow
              sx={{
                borderRadius: 0,
                "&:last-child td, &:last-child th": {
                  border: 0,
                  borderRadius: 0,
                },
              }}
            >
              <TableCell
                // colSpan={2}
                sx={{
                  width: "40%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  backgroundColor: "rgba(0, 127, 255, 0.1)",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="left"
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    sx={{
                      color: "primary.main",
                      width: "100%",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    {t(
                      "landingpage.leftContent.tabs.A | Socioeconomic Study.A. Socioeconomic Study.VIII"
                    )}
                  </Typography>
                </Box>
              </TableCell>

              <TableCell
                sx={{
                  width: "30%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  backgroundColor: "rgba(0, 127, 255, 0.1)",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "1.5vw", sm: 14 },
                    fontFamily: "Gilroy-Medium",
                  }}
                >
                  {t(
                    "landingpage.leftContent.tabs.A | Socioeconomic Study.Report Format.GENERAL"
                  )}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                {t(
                  "landingpage.leftContent.tabs.A | Socioeconomic Study.Concept.National"
                )}
              </TableCell>

              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  borderRadius: 0,
                  border: "5px solid #ffffff !important",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <ul>
                  {t(
                    "landingpage.leftContent.tabs.A | Socioeconomic Study.Result.Certificate"
                  )}
                </ul>
              </TableCell>
            </TableRow>

            <TableRow
              sx={{
                borderRadius: 0,
                "&:last-child td, &:last-child th": {
                  border: 0,
                  borderRadius: 0,
                },
              }}
            >
              <TableCell
                // colSpan={2}
                sx={{
                  width: "40%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="left"
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    sx={{
                      color: "primary.main",
                      width: "100%",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    {t(
                      "landingpage.leftContent.tabs.A | Socioeconomic Study.A. Socioeconomic Study.IX"
                    )}
                  </Typography>
                </Box>

                {/* <Box sx={{display:"flex",mt:4}}>
                          <InnerTable tableData={data[section.inner_table['data']]} dataType={section.inner_table['type']}/>
                        </Box> */}
              </TableCell>

              <TableCell
                sx={{
                  width: "30%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "1.5vw", sm: 14 },
                    fontFamily: "Gilroy-Medium",
                  }}
                >
                  No
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                {t(
                  "landingpage.leftContent.tabs.A | Socioeconomic Study.Concept.Own"
                )}
              </TableCell>

              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  borderRadius: 0,
                  border: "5px solid #ffffff !important",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <ul>
                  {t(
                    "landingpage.leftContent.tabs.A | Socioeconomic Study.Result.Yes"
                  )}
                </ul>
              </TableCell>
            </TableRow>
            {/* Section start*/}
            <TableRow
              sx={{
                borderRadius: 0,
                "&:last-child td, &:last-child th": {
                  border: 0,
                  borderRadius: 0,
                },
              }}
            >
              <TableCell
                // colSpan={2}
                sx={{
                  width: "40%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  backgroundColor: "rgba(0, 127, 255, 0.1)",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="left"
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    sx={{
                      color: "primary.main",
                      width: "100%",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    {t(
                      "landingpage.leftContent.tabs.A | Socioeconomic Study.A. Socioeconomic Study.X"
                    )}
                  </Typography>
                </Box>
              </TableCell>

              <TableCell
                sx={{
                  width: "30%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  backgroundColor: "rgba(0, 127, 255, 0.1)",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "1.5vw", sm: 14 },
                    fontFamily: "Gilroy-Medium",
                  }}
                >
                  {t(
                    "landingpage.leftContent.tabs.A | Socioeconomic Study.Report Format.GENERAL"
                  )}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                {t(
                  "landingpage.leftContent.tabs.A | Socioeconomic Study.Concept.GENERAL"
                )}
              </TableCell>

              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  borderRadius: 0,
                  border: "5px solid #ffffff !important",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <ul>
                  {t(
                    "landingpage.leftContent.tabs.A | Socioeconomic Study.Result.Certificate"
                  )}
                </ul>
              </TableCell>
            </TableRow>

            <TableRow
              sx={{
                borderRadius: 0,
                "&:last-child td, &:last-child th": {
                  border: 0,
                  borderRadius: 0,
                },
              }}
            >
              <TableCell
                // colSpan={2}
                sx={{
                  width: "40%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="left"
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    sx={{
                      color: "primary.main",
                      width: "100%",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    {t(
                      "landingpage.leftContent.tabs.A | Socioeconomic Study.A. Socioeconomic Study.XI"
                    )}
                  </Typography>
                </Box>

                {/* <Box sx={{display:"flex",mt:4}}>
                          <InnerTable tableData={data[section.inner_table['data']]} dataType={section.inner_table['type']}/>
                        </Box> */}
              </TableCell>

              <TableCell
                sx={{
                  width: "30%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "1.5vw", sm: 14 },
                    fontFamily: "Gilroy-Medium",
                  }}
                >
                  No
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                {t(
                  "landingpage.leftContent.tabs.A | Socioeconomic Study.Concept.Own"
                )}
              </TableCell>

              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  borderRadius: 0,
                  border: "5px solid #ffffff !important",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <ul>
                  {t(
                    "landingpage.leftContent.tabs.A | Socioeconomic Study.Result.Yes"
                  )}
                </ul>
              </TableCell>
            </TableRow>
            {/* End */}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
