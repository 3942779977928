// with counter
import React,{useState, useEffect, useRef} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
// import logoImg from "../../../../assets/images/sologo.png"
import logoImg from "../../../assets/images/Loader.gif"

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 160,
  // maxWidth:"95%",
  bgcolor: 'background.paper',
  border: '0px solid #000',
  boxShadow: 0,
  // p: 4,
  backgroundColor:"transparent",
  border:0,
  "&:focus": {
    outline: "none",
  }
};

export default function CustomSpinner({countdownTo, isOpenLogoModal,visible}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [tiempoMostrado, setTiempoMostrado] = useState(countdownTo || 71);
  const stopTimerRef = useRef(false);
  const segunderoInverso = async () => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    for (var i = tiempoMostrado; i >= 0; i--) {
      if (stopTimerRef.current) {
        return;
      }
      await new Promise((resolve) =>
        setTimeout(() => {
          if (i > 0) {
            setTiempoMostrado(i);
          } else {
            // Reset the timer to 71 when it reaches 0
            setTiempoMostrado(71);
            i = 71;
          }
          resolve();
        }, 1000)
      );
    }
  };
  
  useEffect(() => {
    if (visible) {
      stopTimerRef.current = false;
      segunderoInverso();
    } else {
      stopTimerRef.current = true;
      setTiempoMostrado(71);
    }
    return () => {
      stopTimerRef.current = true;
    };
  }, [visible]);


  return (
    <div>
      <Modal
        open={visible}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{backgroundColor:"rgba(27, 32, 44, 0.6)"}}
      >
        <Box sx={style}>
            <div id="segundero" style={{position:"absolute",zIndex:99,left:50,top:80,width:35,display:"flex",justifyContent:"center"}}>
              <h1 style={{color: '#FF8D00',fontSize:40}}>{tiempoMostrado}</h1>
            </div>
          <Box sx={{display:"flex",flexDirection:"row", justifyContent:"center"}}>
            <img src={logoImg} height={260} alt='logo image' />
          </Box>
        </Box>
      </Modal>
    </div>
  );
}