import {
  IconCheck,
  IconCopy,
  IconEdit,
  IconRobot,
  IconTrash,
  IconUser,
} from '@tabler/icons-react';
import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CodeBlock } from '../Markdown/CodeBlock';
import { MemoizedReactMarkdown } from '../Markdown/MemoizedReactMarkdown';
import { ChatContext } from './ChatContext';
import { useTranslation } from 'react-i18next';

function ChatMessage({ message, messageIndex, onEdit }) {
  const { t } = useTranslation('chat');
  const { chatLog, setChatLog } = useContext(ChatContext);
  const selectedConversation = chatLog.selectedConversation;
  const conversations = chatLog.conversations;
  const currentMessage = chatLog.currentMessage;
  const messageIsStreaming = chatLog.messageIsStreaming;
  const updateChat = chatLog.updateChat;


  const [isEditing, setIsEditing] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [messageContent, setMessageContent] = useState(message.content);
  const [messagedCopied, setMessageCopied] = useState(false);

  const textareaRef = useRef(null);

  const updateConversation = (
    updatedConversation,
    allConversations,
  ) => {
    const updatedConversations = allConversations.map((c) => {
      if (c.id === updatedConversation.id) {
        return updatedConversation;
      }

      return c;
    });

    saveConversation(updatedConversation);
    saveConversations(updatedConversations);

    return {
      single: updatedConversation,
      all: updatedConversations,
    };
  };

  const saveConversation = (conversation) => {
    localStorage.setItem('selectedConversation', JSON.stringify(conversation));
  };

  const saveConversations = (conversations) => {
    localStorage.setItem('conversationHistory', JSON.stringify(conversations));
  };

  const toggleEditing = () => {
    setIsEditing(!isEditing);
  };

  const handleInputChange = (event) => {
    setMessageContent(event.target.value);
    if (textareaRef.current) {
      textareaRef.current.style.height = 'inherit';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const handleEditMessage = () => {
    if (message.content != messageContent) {
      if (selectedConversation && onEdit) {
        onEdit({ ...message, content: messageContent });
      }
    }
    setIsEditing(false);
  };

  const handleDeleteMessage = () => {
    if (!selectedConversation) return;

    const { messages } = selectedConversation;
    const findIndex = messages.findIndex((elm) => elm === message);

    if (findIndex < 0) return;

    if (
      findIndex < messages.length - 1 &&
      messages[findIndex + 1].role === 'assistant'
    ) {
      messages.splice(findIndex, 2);
    } else {
      messages.splice(findIndex, 1);
    }
    const updatedConversation = {
      ...selectedConversation,
      messages,
    };

    const { single, all } = updateConversation(
      updatedConversation,
      conversations,
    );
    updateChat({ field: 'selectedConversation', value: single });
    updateChat({ field: 'conversations', value: all });
  };

  const handlePressEnter = (e) => {
    if (e.key === 'Enter' && !isTyping && !e.shiftKey) {
      e.preventDefault();
      handleEditMessage();
    }
  };

  const copyOnClick = () => {
    if (!navigator.clipboard) return;

    navigator.clipboard.writeText(message.content).then(() => {
      setMessageCopied(true);
      setTimeout(() => {
        setMessageCopied(false);
      }, 2000);
    });
  };

  useEffect(() => {
    setMessageContent(message.content);
  }, [message.content]);


  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'inherit';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [isEditing]);

  // Define the style object for the 'assistant' role
  const assistantStyle = {
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    backgroundColor: "rgb(255, 230, 205)",
    color: "#4B5563",
    // Additional dark mode styles can be added here
  };

  // Define the style object for other roles
  const otherRoleStyle = {
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    backgroundColor: "rgb(205, 230, 255)",
    color: "#4B5563",
    // marginLeft: '10px',
    // Additional dark mode styles can be added here
  };

  // Define common styles
  const commonStyle = {
    paddingLeft: "1rem",  // Equivalent to md:px-4 for medium screens and up
    paddingRight: "1rem", // Equivalent to md:px-4 for medium screens and up
    // Additional common styles can be added here
  };

  // Combine styles based on role
  const finalStyle = {
    ...(message.role === 'assistant' ? assistantStyle : otherRoleStyle),
    ...commonStyle
  };

  return (
    <div
      style={{ overflowWrap: 'anywhere', ...finalStyle }}
    >
      <div style={{
        position: 'relative',
        margin: 'auto',
        display: 'flex',
        padding: '1rem',
        fontSize: '1rem', // assuming base size is 1rem
        // Responsive styles
        '@media (minWidth: 768px)': {
          maxWidth: '768px', // 2xl
          gap: '1.5rem',
          paddingTop: '1.5rem',
          paddingBottom: '1.5rem',
        },
        '@media (minWidth: 1024px)': {
          maxWidth: '1024px', // 3xl
        },
        '@media (minWidth: 1280px)': {
          paddingLeft: '0',
          paddingRight: '0',
        },
      }}>
        <div style={{
          minWidth: '40px',
          textAlign: 'left',
          fontWeight: 'bold',
        }}>
          {message.role === 'assistant' ? (
            <IconRobot size={30} />
          ) : (
            <IconUser size={30} />
          )}
        </div>

        <div style={{
          // Define prose styles or import them
          marginTop: '-2px',
          width: '100%',
          // Custom logic for dark mode inversion may be needed
        }}>
          {message.role === 'user' ? (
            <div style={{
              display: 'flex',
              width: '100%',
            }}>
              {isEditing ? (
                <div style={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'column',
                }}>
                  <textarea
                    ref={textareaRef}
                    value={messageContent}
                    onChange={handleInputChange}
                    onKeyDown={handlePressEnter}
                    onCompositionStart={() => setIsTyping(true)}
                    onCompositionEnd={() => setIsTyping(false)}
                    style={{
                      fontFamily: 'inherit',
                      fontSize: 'inherit',
                      lineHeight: 'inherit',
                      padding: '0',
                      margin: '0',
                      overflow: 'hidden',
                      width: '100%',
                      resize: 'none',
                      whiteSpace: 'pre-wrap',
                      border: 'none',
                    }}
                  />

                  <div style={{ marginTop: '2.5rem', display: 'flex', justifyContent: 'center', gap: '1rem' }}>
                    <button
                      style={{
                        height: '40px',
                        borderRadius: '0.375rem',
                        backgroundColor: '#3b82f6',
                        padding: '0.25rem 1rem',
                        fontSize: '0.875rem',
                        fontWeight: '500',
                        color: '#ffffff',
                      }}
                      onClick={handleEditMessage}
                      disabled={messageContent.trim().length <= 0}
                    >
                      Save & Submit
                    </button>
                    <button
                      style={{
                        height: '40px',
                        borderRadius: '0.375rem',
                        border: '1px solid #d1d5db',
                        padding: '0.25rem 1rem',
                        fontSize: '0.875rem',
                        fontWeight: '500',
                        color: '#4b5563',
                      }}
                      onClick={() => {
                        setMessageContent(message.content);
                        setIsEditing(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                <div style={{
                  whiteSpace: 'pre-wrap',
                  flex: '1',
                }}>
                  {message.content}
                </div>
              )}

              {!isEditing && (
                <div style={{
                  marginLeft: '0.25rem',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1rem',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}>
                  <button
                    style={{
                      visibility: 'hidden',
                      color: '#6b7280',
                    }}
                    onClick={toggleEditing}
                  >
                    <IconEdit size={20} />
                  </button>
                  <button
                    style={{
                      visibility: 'hidden',
                      color: '#6b7280',
                    }}
                    onClick={handleDeleteMessage}
                  >
                    <IconTrash size={20} />
                  </button>
                </div>
              )}
            </div>
          ) : (
            <div style={{
              display: 'flex',
              flexDirection: 'column',
            }}>
              <MemoizedReactMarkdown
                style={{
                  flex: '1',
                }}
                components={{
                  code({ node, inline, className, children, ...props }) {
                    if (children.length) {
                      if (children[0] == '▍') {
                        return <span style={{
                          cursor: 'default',
                          marginTop: '0.25rem',
                        }}>▍</span>
                      }

                      children[0] = (children[0]).replace("`▍`", "▍")
                    }

                    const match = /language-(\w+)/.exec(className || '');

                    return !inline ? (
                      <CodeBlock
                        key={Math.random()}
                        language={(match && match[1]) || ''}
                        value={String(children).replace(/\n$/, '')}
                        {...props}
                      />
                    ) : (
                      <code className={className} {...props}>
                        {children}
                      </code>
                    );
                  },
                  table({ children }) {
                    return (
                      <table style={{
                        borderCollapse: 'collapse',
                        border: '1px solid #000000',
                        padding: '0.25rem 0.75rem',
                      }}>
                        {children}
                      </table>
                    );
                  },
                  th({ children }) {
                    return (
                      <th style={{
                        wordBreak: 'break-word',
                        border: '1px solid #000000',
                        backgroundColor: '#6b7280',
                        padding: '0.25rem 0.75rem',
                        color: '#ffffff',
                      }}>
                        {children}
                      </th>
                    );
                  },
                  td({ children }) {
                    return (
                      <td style={{
                        wordBreak: 'break-word',
                        border: '1px solid #000000',
                        padding: '0.25rem 0.75rem',
                      }}>
                        {children}
                      </td>
                    );
                  },
                }}
              >
                {`${message.content}${messageIsStreaming && messageIndex == (selectedConversation?.messages.length ?? 0) - 1 ? '`▍`' : ''
                  }`}
              </MemoizedReactMarkdown>

              <div style={{
                marginLeft: '0.25rem',
                display: 'flex',
                flexDirection: 'row',
                gap: '1rem',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}>
                {messagedCopied ? (
                  <IconCheck
                    size={20}
                    style={{
                      color: '#10B981',
                    }}
                  />
                ) : (
                  <button
                    style={{
                      color: '#6b7280',
                      cursor: 'pointer',
                    }}
                    onClick={copyOnClick}
                  >
                    <IconCopy size={20} />
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatMessage;

