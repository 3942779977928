import Cookies from 'js-cookie';


export const getCookiesList = (cookieKey) => {
    const userInfoCookie = Cookies.get(cookieKey)
    if (userInfoCookie != null && typeof(userInfoCookie) != 'object') {
        return userInfoCookie.split(",")
    }
    return [];
};

export const removeCookie = (cookieValue) => {
    Cookies.remove(cookieValue)
};