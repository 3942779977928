import { Container, Grid, Typography, Box } from "@mui/material";
import React, { Fragment, useState } from "react";
import { List, ListItem, Link } from "@mui/material";
import Stack from "@mui/material/Stack";
import MailIcon from "@mui/icons-material/Mail";
import CallIcon from "@mui/icons-material/Call";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { useNavigate } from "react-router-dom";
import NoticePrivacy from "../components/NoticePrivacy";
import TermsConditions from "../components/TermsConditions";
import { useTranslation } from "react-i18next";
function GuestFooter(props) {
  const navigate = useNavigate();
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [openTermsModal, setOpenTermsModal] = useState(false);
  const { t } = useTranslation();

  const handleOpenUploadModal = () => {
    setOpenUploadModal(!openUploadModal);
  };
  const handleOpenTermsModal = () => {
    setOpenTermsModal(!openTermsModal);
  };
  return (
    // <Box
    //   sx={{
    //     background: "#1C212D",
    //     width: "100%",
    //     mt: { xs: 10, sm: 0 },
    //     py: 4,
    //   }}
    // >
    //   <Container>
    //     <Box>
    //       <Grid container spacing={2}>
    //         <Grid item xs={12} sm={12} md={2}></Grid>
    //         <Grid item xs={12} sm={12} md={8}>
    //           <Box sx={{ display: "flex", justifyContent: "center", pt: 1 }}>
    //             <Typography
    //               sx={{
    //                 color: "#fff",
    //                 fontSize: "22px",
    //                 fontFamily: "Poppins-SemiBold",
    //               }}
    //             >
    //               {t("landingpage.rightContent.footer.title")}
    //             </Typography>
    //           </Box>
    //           <Box sx={{ display: "flex", justifyContent: "center", pt: 1 }}>
    //             <List
    //               sx={{
    //                 display: "flex",
    //                 justifyContent: "center",
    //                 flexDirection: { xs: "column", sm: "row" },
    //                 p: 0,
    //               }}
    //             >
    //               <ListItem
    //                 sx={{
    //                   px: 1,
    //                   borderRight: {
    //                     xs: "solid 0px rgba(255, 255, 255, 0.8)",
    //                     sm: "solid 2px rgba(255, 255, 255, 0.8)",
    //                   },
    //                   py: 0,
    //                 }}
    //               >
    //                 <Box
    //                   sx={{
    //                     color: "rgba(255, 255, 255, 0.8)",
    //                     fontSize: "16px",
    //                     fontFamily: "Poppins-Medium",
    //                     textDecoration: "none",
    //                     width: { xs: "100%", sm: "150px" },
    //                   }}
    //                 >
    //                   {t("landingpage.rightContent.footer.subtitle.Searchlook")}
    //                 </Box>
    //               </ListItem>
    //               <ListItem
    //                 sx={{
    //                   px: 1,
    //                   borderRight: {
    //                     xs: "solid 0px rgba(255, 255, 255, 0.8)",
    //                     sm: "solid 2px rgba(255, 255, 255, 0.8)",
    //                   },
    //                   py: 0,
    //                 }}
    //               >
    //                 <Box
    //                   sx={{
    //                     color: "rgba(255, 255, 255, 0.8)",
    //                     fontSize: "16px",
    //                     fontFamily: "Poppins-Medium",
    //                     textDecoration: "none",
    //                     width: { xs: "100%", sm: "158px" },
    //                   }}
    //                 >
    //                   {/* {t(
    //                     "landingpage.rightContent.footer.subtitle.Mississippi River 49"
    //                   )} */}
    //                   Río Missisipi 49
    //                 </Box>
    //               </ListItem>
    //               <ListItem
    //                 sx={{
    //                   px: 1,
    //                   borderRight: {
    //                     xs: "solid 0px rgba(255, 255, 255, 0.8)",
    //                     sm: "solid 2px rgba(255, 255, 255, 0.8)",
    //                   },
    //                   py: 0,
    //                 }}
    //               >
    //                 <Link
    //                   onClick={() => handleOpenUploadModal()}
    //                   sx={{
    //                     color: "rgba(255, 255, 255, 0.8)",
    //                     fontSize: "16px",
    //                     fontFamily: "Poppins-Medium",
    //                     textDecoration: "none",
    //                     width: { xs: "100%", sm: "140px" },
    //                     cursor: "pointer",
    //                   }}
    //                 >
    //                   {t(
    //                     "landingpage.rightContent.footer.subtitle.Privacy Notice"
    //                   )}
    //                 </Link>
    //               </ListItem>
    //               <ListItem sx={{ px: 1, py: 0 }}>
    //                 <Link
    //                   onClick={() => handleOpenTermsModal()}
    //                   sx={{
    //                     color: "rgba(255, 255, 255, 0.8)",
    //                     fontSize: "16px",
    //                     fontFamily: "Poppins-Medium",
    //                     textDecoration: "none",
    //                     width: { xs: "100%", sm: "230px" },
    //                     cursor: "pointer",
    //                   }}
    //                 >
    //                   {t(
    //                     "landingpage.rightContent.footer.subtitle.Terms and Conditions"
    //                   )}
    //                 </Link>
    //               </ListItem>
    //             </List>
    //           </Box>
    //           <Box sx={{ display: "flex", justifyContent: "center", pt: 2 }}>
    //             <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
    //               <Link
    //                 sx={{
    //                   color: "rgba(255, 255, 255, 0.8)",
    //                   fontSize: "16px",
    //                   fontFamily: "Poppins-Medium",
    //                   textDecoration: "none",
    //                   background: "rgba(255, 255, 255, 0.1)",
    //                   borderRadius: 50,
    //                   px: 2,
    //                   py: 0.5,
    //                   display: "flex",
    //                   justifyContent: "center",
    //                 }}
    //                 href="tel:55 3332 6533"
    //               >
    //                 <CallIcon sx={{ mt: 0.5, mr: 1 }} fontSize="20px" />
    //                 {t("landingpage.rightContent.footer.contactData.tel")}
    //               </Link>
    //               <Link
    //                 sx={{
    //                   color: "rgba(255, 255, 255, 0.8)",
    //                   fontSize: "16px",
    //                   fontFamily: "Poppins-Medium",
    //                   textDecoration: "none",
    //                   background: "rgba(255, 255, 255, 0.1)",
    //                   borderRadius: 50,
    //                   px: 2,
    //                   py: 0.5,
    //                   display: "flex",
    //                   justifyContent: "center",
    //                 }}
    //                 href="mailto:contact@searchlook.mx"
    //               >
    //                 <MailIcon sx={{ mt: 0.5, mr: 1 }} fontSize="20px" />{" "}
    //                 {t("landingpage.rightContent.footer.contactData.email")}
    //               </Link>
    //             </Stack>
    //           </Box>
    //         </Grid>
    //         <Grid item xs={12} sm={12} md={2}></Grid>
    //       </Grid>
    //     </Box>
    //   </Container>
    //   <NoticePrivacy
    //     open={openUploadModal}
    //     handleClose={handleOpenUploadModal}
    //   />
    //   <TermsConditions
    //     openTerms={openTermsModal}
    //     handleTermsClose={handleOpenTermsModal}
    //   />
    // </Box>

    <Box
      sx={{
        background: "#2488FF",
        // position: { xs: "relative", sm: "absolute" },
        position: "relative",
        // position: "absolute",
        // zIndex: 2,
        // bottom: 0,
        // width: { xs: "100%", sm: "40%" },
        width: "100%",

        pb: 2,
        // right: 0,
        // paddingRight: { xs: "50px", lg: 0 },
        // paddingLeft: { xs: "50px", lg: 0 },
      }}
    >
      <Box sx={{ textAlign: "center", pt: 1 }}>
        <Typography
          sx={{
            color: "#fff",
            fontSize: "18px",
            fontFamily: "Poppins-SemiBold",
          }}
        >
          {t("landingpage.rightContent.footer.title")}
        </Typography>
      </Box>

      <Stack
        direction="row"
        mx={2}
        gap={1}
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        mt={1}
      >
        <Box
          sx={{
            color: "rgba(255, 255, 255, 0.8)",
            textAlign: "center !important",
            fontSize: "12px",
            fontFamily: "Poppins-Medium",
            whiteSpace: "nowrap",
            borderRight: "2px solid rgba(255, 255, 255, 0.8)",
            pr: 1,
          }}
        >
          {t("landingpage.rightContent.footer.subtitle.Searchlook")}
        </Box>
        <Box
          sx={{
            color: "rgba(255, 255, 255, 0.8)",
            textAlign: "center !important",
            fontSize: "12px",
            fontFamily: "Poppins-Medium",
            whiteSpace: "nowrap",
            borderRight: "2px solid rgba(255, 255, 255, 0.8)",
            pr: 1,
          }}
        >
          Río Missisipi 49
        </Box>
        <Box
          onClick={() => handleOpenUploadModal()}
          sx={{
            color: "rgba(255, 255, 255, 0.8)",
            textAlign: "center",
            fontSize: "12px",
            fontFamily: "Poppins-Medium",
            whiteSpace: "nowrap",
            cursor: "pointer",
          }}
        >
          {t("landingpage.rightContent.footer.subtitle.Privacy Notice")}
        </Box>
        <Box
          onClick={() => handleOpenTermsModal()}
          sx={{
            color: "rgba(255, 255, 255, 0.8)",
            textAlign: "center",
            fontSize: "12px",
            fontFamily: "Poppins-Medium",
            whiteSpace: "nowrap",
            borderLeft: "2px solid rgba(255, 255, 255, 0.8)",
            pl: 1,
            cursor: "pointer",
          }}
        >
          {t("landingpage.rightContent.footer.subtitle.Terms and Conditions")}
        </Box>
      </Stack>

      <Box sx={{ display: "flex", justifyContent: "center", pt: 2 }}>
        <Stack
          direction="row"
          flexWrap="wrap"
          gap={2}
          justifyContent="center"
          alignItems="center"
        >
          <Link
            sx={{
              color: "rgba(255, 255, 255, 0.8)",
              fontSize: "12px",
              fontFamily: "Poppins-Medium",
              textDecoration: "none",
              background: "rgba(255, 255, 255, 0.1)",
              borderRadius: 50,
              px: 1,
              py: 0.5,
              display: "flex",
              justifyContent: "center",
              whiteSpace: "nowrap",
              "&:hover": {
                color: "rgba(255, 255, 255, 0.8)",
              },
            }}
            href="tel:55 3332 6533"
          >
            <CallIcon sx={{ mt: 0.5, mr: 1 }} fontSize="20px" />
            {t("landingpage.rightContent.footer.contactData.tel")}
          </Link>
          <Link
            sx={{
              color: "rgba(255, 255, 255, 0.8)",
              fontSize: "12px",
              fontFamily: "Poppins-Medium",
              textDecoration: "none",
              background: "rgba(255, 255, 255, 0.1)",
              borderRadius: 50,
              px: 1,
              py: 0.5,
              display: "flex",
              justifyContent: "center",
              whiteSpace: "nowrap",
              "&:hover": {
                color: "rgba(255, 255, 255, 0.8)",
              },
            }}
            href="mailto:contact@searchlook.mx"
          >
            <MailIcon sx={{ mt: 0.5, mr: 1 }} fontSize="20px" />
            {t("landingpage.rightContent.footer.contactData.email")}
          </Link>
        </Stack>
      </Box>
      <NoticePrivacy
        open={openUploadModal}
        handleClose={handleOpenUploadModal}
      />
      <TermsConditions
        openTerms={openTermsModal}
        handleTermsClose={handleOpenTermsModal}
      />
    </Box>
  );
}

export default GuestFooter;
