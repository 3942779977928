import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Fragment } from "react";
import Divider from "@mui/material/Divider";
import { Link, useNavigate } from "react-router-dom";
import underLine from "../../../assets/images/under-line.png";
import LabelInputLeftIcon from "../../@common/Input/LabelInputLeftIcon";
import mailImage from "../../../assets/images/mailIcon.png";
import personImage from "../../../assets/images/person.svg";
import phoneImage from "../../../assets/images/phoneIcon.png";
import watsAppIcon from "../../../assets/images/watsappicon.svg";
import axios from "axios";
import { serverRequest } from "../../../utils/requestHelper";
import AlertModal from "../../@common/Modal/AlertModal";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { emailPattern } from "../../../utils/InputPatterns";
import { getCookiesList } from "../../../utils/cookiesHelper";
import {
  validateInput,
  validatePhoneNumber,
  validateName,
} from "../../../utils/inputValidation";
import { useTranslation } from "react-i18next";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 420,
  maxWidth: "95%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
};

const subjectList = ["The present subject is found"];

export default function ContactFormModal(props) {
  const { t } = useTranslation();
  const { text = "" } = props;
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setFormErrors({});
  };
  const [showAlert, setShowAlert] = useState(false); // Add a state to control the visibility of the alert
  const [showtext, setShowtext] = useState("");
  const [showSuccess, setSuccess] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile_number: "",
    coupon_type: props.couponType,
    coupon_count: props.numberOfCoupons,
    language: localStorage.getItem("selectedLanguage"),
  });

  const handleCloseAlert = () => {
    setShowAlert(false);
    setSuccess(false);
    setFormData({
      name: "",
      email: "",
      mobile_number: "",
      coupon_type: "",
      coupon_count: "",
    });
  };
  const [formErrors, setFormErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Clear the error when the user starts typing
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));

    let cleanedValue = value;

    if (name === "mobile_number") {
      // Remove any characters that are not 'e', numbers 0-9, or alphanumeric
      cleanedValue = value.replace(/[^0-9]/g, "");

      cleanedValue = cleanedValue.substring(0, 10);
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: cleanedValue,
    }));
  };

  const sendHandler = async () => {
    // Validate input before sending
    const errors = {};
    validateName(formData.name, "name", errors, true, t);
    validatePhoneNumber(formData.mobile_number, "mobile_number", errors, t);
    validateInput(formData.email, emailPattern, "email", errors, t);

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    try {
      const response = await serverRequest(
        "post",
        "send_email",
        formData,
        {},
        3000,
        false
      );
      if (response.status === 200) {
        const byteCharacters = atob(response.data.pdf_conent);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        const timeZone = "America/Mexico_City"; // Replace with the correct time zone identifier
        const currentDate = new Date();
        const formatter = new Intl.DateTimeFormat("en", {
          timeZone,
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        });
        const formattedDate = formatter.format(currentDate).replace(/:/g, "-");

        const filename = `${formattedDate}.pdf`;
        a.download = filename;

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        window.URL.revokeObjectURL(url);

        setSuccess(true);
        setShowAlert(true);
        setShowtext(t("thanks.generalPopUp"));
        handleClose();
      } else {
        setShowAlert(true);
        setShowtext("Email not sent successfully!");
      }
    } catch (error) {
      // Handle any errors
      console.error(error);
    }
  };

  const sendWhatsApp = () => {
    const URL = process.env.REACT_APP_WHATSAPP_BASE_URL;
    const number = process.env.REACT_APP_WHATSAPP_NUMBER;
    const message = "";
    let url = `${URL}/${number}`;
    url += `?text=${encodeURI(message)}`;
    window.open(url);
  };

  // const isValidEmail = (email) => {
  //   const emailRegex = /\S+@\S+\.\S+/;
  //   return emailRegex.test(email);
  // }

  useEffect(() => {
    const userCookiesList = getCookiesList("userInfo");
    const email = userCookiesList[3];
    const phone = userCookiesList[5];
    const name = userCookiesList[11];
    setFormData({
      ...formData,
      name: name,
      email: email,
      mobile_number: phone,
      coupon_type: props.couponType,
      coupon_count: props.numberOfCoupons,
      language: localStorage.getItem("selectedLanguage"),
    });
  }, [open]);

  return (
    <Fragment>
      {showAlert && (
        <AlertModal
          text={showtext}
          onClose={handleCloseAlert}
          thanks={showSuccess}
        />
      )}
      <Button
        sx={{
          backgroundColor: "#D9E0FD",
          textAlign: "center",
          borderWidth: 1,
          borderColor: "#D9E0FD",
          borderStyle: "solid",
          borderRadius: "6px",
          py: 0.5,
          px: 2,
          color: "primary.main",
          textDecoration: "none",
          fontSize: 12,
          fontFamily: "Gilroy-Medium",
          textTransform: "capitalize",
        }}
        onClick={handleOpen}
        variant="contained"
      >
        {" "}
        {t("buttons.Request")}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mb: "10px",
              mt: "-20px",
              mr: "-20px",
            }}
          >
            <IconButton
              sx={{
                color: "gray",
              }}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box>
            <Typography
              sx={{
                fontFamily: "Poppins-SemiBold",
                fontSize: "24px",
                lineHeight: "35px",
                textAlign: "center",
                color: "#1C212D",
              }}
            >
              {t("PageName.Contact Form")}
            </Typography>
            <Box sx={{ textAlign: "center", mt: -1 }}>
              <img src={underLine} alt="under line" />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <LabelInputLeftIcon
                mailIcon={personImage}
                type="text"
                label={t("labels.inputLabelName")}
                name="name"
                id="name"
                placeholder={t("placeholders.name")}
                inputValue={formData.name}
                onChange={handleInputChange}
                error={formErrors.name}
              />
              {formErrors.name && (
                <Typography variant="body2" color="error">
                  {formErrors.name}
                </Typography>
              )}
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <LabelInputLeftIcon
                mailIcon={phoneImage}
                type="text"
                label={t("labels.inputLabelNumber")}
                name="mobile_number"
                id="mobile_number"
                placeholder={t("placeholders.number")}
                inputValue={formData.mobile_number}
                onChange={handleInputChange}
                error={formErrors.mobile_number}
              />
              {formErrors.mobile_number && (
                <Typography variant="body2" color="error">
                  {formErrors.mobile_number}
                </Typography>
              )}
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <LabelInputLeftIcon
                mailIcon={mailImage}
                type="text"
                label={t("SuperAdminSignUp.inputs.inputLabelEmail")}
                name="email"
                id="email"
                placeholder={t("placeholders.email")}
                inputValue={formData.email}
                onChange={handleInputChange}
                error={formErrors.email}
              />
              {formErrors.email && (
                <Typography variant="body2" color="error">
                  {formErrors.email}
                </Typography>
              )}
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              mt: 4,
            }}
          >
            <Button
              onClick={sendHandler}
              sx={{
                backgroundColor: "primary.main",
                py: 1,
                px: { xs: 4, sm: 8 },
                fontSize: "18px",
                fontFamily: "Gilroy-Medium",
                textTransform: "capitalize",
              }}
              variant="contained"
            >
              {t("buttons.Send")}
            </Button>
          </Box>
          <Divider
            sx={{
              mt: 2,
              textAlign: "center",
              fontSize: "16px",
              color: "#000000",
              fontFamily: "Gilroy-SemiBold",
            }}
          >
            {t("buttons.continuewith")}
          </Divider>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              mt: 3,
            }}
          >
            <Button
              sx={{
                color: "#64B752",
                fontSize: 16,
                fontFamily: "Gilroy-SemiBold",
                backgroundColor: "rgba(101, 183, 82, 0.14)",
                border: "solid 1px #64B752",
              }}
              onClick={sendWhatsApp}
              variant="outlined"
              startIcon={<img src={watsAppIcon} alt="watsapp image" />}
            >
              55 3332 6533
            </Button>
          </Box>
        </Box>
      </Modal>
    </Fragment>
  );
}
