import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Fragment } from 'react';
import { textAlign } from '@mui/system';
import { Link, useNavigate } from 'react-router-dom';
import sucessImage from "../../assets/images/thankimage.png"
import { useTranslation } from 'react-i18next';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 420,
  maxWidth: "95%",
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 4
};

const subjectList = [
  "The present subject is found",
]

export default function ThankYouMessageModal(props) {
  const { text = "" } = props
  const { onupload ,showThankYouModal,handleclosed} = props;
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
    onupload();
  }
  const { t } = useTranslation();

  const sendHandler = () => {
    alert("sendHandler")
  };

 
  return (

    <Fragment>
      <Button
        sx={{
          backgroundColor: "primary.main",
          textAlign: "center",
          borderWidth: 1,
          borderColor: "primary.main",
          borderStyle: "solid",
          borderRadius: "6px",
          py: 1.5,
          px: 8,
          color: "#fff",
          textDecoration: "none",
          fontSize: 18,
          fontFamily: "Gilroy-Medium",
          textTransform: "capitalize"
        }}
        onClick={handleOpen} variant="contained">{t("buttons.Upload")}</Button>
      <Modal
        open={showThankYouModal}
        onClose={handleclosed}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ justifyContent: "center", display: "flex", alignItems: "center" }}>
            <img src={sucessImage} width="108px" height="108px" alt="email icon" />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 4, flexDirection: "column" }}>
            <Typography component={"h6"} sx={{ color: "neutral.900", fontSize: 20, fontFamily: "Gilroy-SemiBold", textAlign: "center" }}>{t("LaborReferencePage.Fileploaded")} <Box component={"span"} sx={{ color: "primary.main" }}> {t("LaborReferencePage.Successfully")} !</Box></Typography>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", mt: 4 }}>
            <Button onClick={handleclosed} style={{ color: "rgba(27, 32, 44, 0.6)", fontSize: 16, fontFamily: "Gilroy-Medium", textDecoration: "underline", textTransform: "capitalize" }} variant="text">{t("buttons.back")}</Button>
          </Box>
        </Box>
      </Modal>
    </Fragment>
  );

}
