import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import esTranslation from "./utils/locales/es.json"
import enTranslation from "./utils/locales/en.json"

i18n
  .use(initReactI18next)
  .init({   
    fallbackLng: "es",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: enTranslation
      },
      es: {
        translation: esTranslation
      },
    },
  });

export default i18n;