// ** React Imports

import { React, useEffect, useState, Fragment } from "react";
// ** MUI Imports
import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import MuiMenu from "@mui/material/Menu";
import MuiMenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import notificationIcon from "../../../assets/images/notification-icon.svg";
import topIcon from "../../../assets/images/top-icon.svg";
import { useTranslation, Trans } from "react-i18next";


import { call_api_function } from "../../../utils/verifyAPICalls";
import Cookies from "js-cookie";
// ** Icon Imports
import NotificationsIcon from "@mui/icons-material/Notifications";
import GlobalNotification from "./NotificationModal/GlobalNotification";
import { translationHelper } from "../../../utils/bureauHelper";
import { Tooltip } from "@mui/material";

// ** Custom Components Imports

// ** Util Import

const notifications = [
  {
    active: true,
    meta: "11:25 AM",
    imgAlt: "image notification",
    title: "Meeting",
    image: { notificationIcon },
    subtitle: "Lorem Ipsum is simply dummy text",
  },
  {
    active: false,
    meta: "11 Aug",
    imgAlt: "image notification",
    title: "Calendar",
    image: "../../../assets/images/notification-icon.svg",
    subtitle:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry",
  },
  {
    active: false,
    meta: "11:15 AM",
    title: "Lorem Ipsum",
    imgAlt: "image notification",
    subtitle: "Lorem Ipsum is simply dummy text",
    image: "../../../assets/images/notification-icon.svg",
  },
];

// ** Styled Menu component
const Menu = styled(MuiMenu)(({ theme }) => ({
  "& .MuiMenu-paper": {
    width: 380,
    overflow: "hidden",
    marginTop: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  "& .MuiMenu-list": {
    padding: 0,
  },
}));

// ** Styled MenuItem component
const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  // p:0,
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  width: "90%",
  margin: "0px auto",
  "&:not(:last-of-type)": {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

// ** Styled component for the title in MenuItems
const MenuItemTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  color: "#1C212D",
  flex: "1 1 100%",
  overflow: "hidden",
  fontSize: "0.875rem",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  marginBottom: theme.spacing(0.75),
}));

// ** Styled component for the subtitle in MenuItems
const MenuItemSubtitle = styled(Typography)({
  flex: "1 1 100%",
  color: "rgba(27, 32, 44, 0.6)",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
});

const NotificationDropdown = (props) => {
  // **
  const storedLanguage = localStorage.getItem("selectedLanguage");
  const userInfo = Cookies.get("userInfo");
  const user_id = userInfo.split(",")[6];
  const { } = props;
  const { t, i18n } = useTranslation();
  const [Notifications, setNotifications] = useState([]);
  const fetchInitialNotifications = async () => {
    const formdata = new FormData();
    formdata.append("user_id", user_id);
    try {
      const response = await call_api_function(
        process.env.REACT_APP_BASE_URL2 + "get_user_notifications",
        formdata
      );
      const formdataa = new FormData();

      formdataa.append("input_dict", JSON.stringify(response.data));

      formdataa.append(
        "source_language",
        storedLanguage === "en" ? "es" : "en"
      );

      formdataa.append(
        "target_language",
        storedLanguage === "en" ? "en" : "es"
      );
      const res = await call_api_function(
        process.env.REACT_APP_BASE_URL2 + "translate_report_data",
        formdataa
      );

      // Reverse the order of notifications before updating the state
      setNotifications([...res.data].reverse());
    } catch (error) {
      console.error("Error fetching initial notifications:", error);
    }
  };

  useEffect(() => {
    fetchInitialNotifications();
  }, [storedLanguage]); // Empty dependency array to run the effect only once
  // ** States
  const [anchorEl, setAnchorEl] = useState(null);

  // ** Hook
  const hidden = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const handleDropdownOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };
  const SeenStatusUpdateNotifications = async (id) => {
    const formdata = new FormData();
    formdata.append("_id", id);
    try {
      const response = await call_api_function(
        process.env.REACT_APP_BASE_URL2 + "update_seen_status",
        formdata
      );

      fetchInitialNotifications();
    } catch (error) {
      console.error("Error fetching initial notifications:", error);
    }
  };

  const handleMenuItemClick = (event, _id) => {
    SeenStatusUpdateNotifications(_id);
    handleDropdownClose(); // Close the dropdown after clicking on a menu item
  };
  function formatTimestamp(date, language) {
    const dateObject = new Date(date);

    if (language === "en") {
      const formattedDate = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: false,
      }).format(dateObject);

      return formattedDate;
    } else if (language === "es") {
      const formattedDate = new Intl.DateTimeFormat("es-ES", {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      }).format(dateObject);

      // Remove the "de" before the month
      return formattedDate.replace(/ de /g, " ");
    } else {
      // Default to English if the language is not specified or invalid
      return new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      }).format(dateObject);
    }
  }

  return (
    <Fragment>
      <Tooltip title={t("notification.bell")} arrow placement="top">
        <IconButton
          color="inherit"
          aria-haspopup="true"
          onClick={handleDropdownOpen}
          aria-controls="customized-menu"
        >
          <Badge
            color="error"
            variant="dot"
            invisible={Notifications.every((notification) => notification.active)}
            sx={{
              "& .MuiBadge-badge": {
                top: 10,
                right: 10,
                boxShadow: (theme) =>
                  `0 0 0 2px ${theme.palette.background.paper}`,
              },
            }}
          >
            <NotificationsIcon sx={{ color: "#1C212D", fontSize: 40 }} />
          </Badge>
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleDropdownClose}
      // anchorOrigin={{ vertical: 'top', horizontal: 'right'}}
      >
        <Box sx={{ maxHeight: 349, overflowY: "auto", overflowX: "hidden" }}>
          {Notifications.map((notification, index) => (
            <MenuItem
              sx={{
                padding: 0,
              }}
              key={index}
              onClick={(event) => handleMenuItemClick(event, notification._id)}
            >
              {!notification.active && (
                <Box
                  sx={{
                    height: "50px",
                    width: 6,
                    backgroundColor: "#4165F6",
                    display: "flex",
                    alignItems: "center",
                    borderTopRightRadius: 20,
                    borderBottomRightRadius: 20,
                    position: "absolute",
                    left: -20,
                  }}
                ></Box>
              )}

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  height: 100,
                  padding: 4,
                }}
              >
                <Box sx={{ width: 28 }}>
                  <img
                    src={notificationIcon}
                    height={24}
                    alt={notification.imgAlt}
                  />
                </Box>
                <Box sx={{ width: "100%", pl: 4 }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                    }}
                  >
                    <MenuItemTitle>{notification.title}</MenuItemTitle>
                    <Typography
                      variant="caption"
                      sx={{ color: "rgba(27, 32, 44, 0.6)" }}
                    >
                      {formatTimestamp(notification.meta, storedLanguage)}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <MenuItemSubtitle variant="body2">
                      {notification.subtitle}
                    </MenuItemSubtitle>
                  </Box>
                </Box>
              </Box>
            </MenuItem>
          ))}
        </Box>
      </Menu>
    </Fragment>
  );
};

export default NotificationDropdown;
