import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import LabelInputLeftIcon from '../@common/Input/LabelInputLeftIcon';
import personImage from "../../assets/images/person.svg"
import companyIcon from "../../assets/images/company-icon.png"
import phoneIcon from "../../assets/images/phoneIcon.png"
import { Link, useNavigate } from 'react-router-dom';
import LabelInput from '../@common/Input/LabelInput';
import mailIcon from "../../assets/images/mailIcon.png"
import DropDownInputSimple from '../@common/Input/DropDownInputSimple/DropDownInputSimple';
import SimpleDropDown from '../@common/Input/DropDownInputSimple/SimpleDropDown';
import EmployRefEmailSuccessSent from '../@common/Modal/EmployRefEmailSuccessSent';
import DropDown from "../@common/Input/DropDownInputSimple/DropDown";
import { encryptData } from "../../utils/encryptionHelper";
import { getCookiesList, removeCookie } from "../../utils/cookiesHelper"
import { serverRequest } from "../../utils/requestHelper"
import { validateInput, validatePhoneNumber, validateName } from '../../utils/inputValidation';
import { emailPattern } from "../../utils/InputPatterns"
function EmployRefInternalFroms(props) {
  const { t } = useTranslation();
  const [isEmailSuccessSent, setIsEmailSuccessSent] = useState(false)
  const [cookieList, setCookieList] = useState([])
  const [documents, setDocuments] = useState("")
  useEffect(() => {
    const userCookiesList = getCookiesList('userInfo'); // Replace 'userInfo' with the actual cookie key
    if (userCookiesList.length > 0) {
      setCookieList(userCookiesList);
    }
  }, []);

  useEffect(() => {

  }, [cookieList]);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    last_name: '',
    mother_last_name: '',
    cname: '',
    referencetype: '',
    position: '',
    number: '',
    email: '',
    Relationshiptype: '',
    document: [],
    nombre_sujeto: '',
  });
  const [formErrors, setFormErrors] = useState({})
  const handleChange = (name, value) => {

    // Clear the error when user starts typing
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const sendHandler = async () => {
    const errors = {};
    validatePhoneNumber(formData.number, 'number', errors, t);
    validateInput(formData.email, emailPattern, 'email', errors, t);
    validateName(formData.name, 'name', errors, true, t);
    validateName(formData.last_name, 'last_name', errors, true, t);
    validateName(formData.mother_last_name, 'mother_last_name', errors, true, t);
    // Add validation for 'referencetype' and 'referencesrequired'
    if (!formData.referencetype) {
      errors.referencetype = t("placeholders.ReferenceType");
    }

    if (!formData.Relationshiptype) {
      errors.Relationshiptype = t("placeholders.relation");
    }
    if (!formData.document) {
      errors.document = t("placeholders.Document");
    }
    if (!formData.cname) {
      errors.cname = t("placeholders.companyname");
    }
    if (!formData.position) {
      errors.position = t("placeholders.Position")
    }
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }
    setIsEmailSuccessSent(true);
    const data_enr = encryptData(formData.name, 500);
    const data_eap = encryptData(formData.last_name, 500);
    const data_eam = encryptData(formData.mother_last_name, 500);
    const data_eem = encryptData(formData.cname, 500);
    const data_etr = encryptData(formData.referencetype, 500);
    const data_epo = encryptData(formData.position, 500);
    const data_eto = encryptData(formData.number, 500);
    const data_eco = encryptData(formData.email, 500);
    const data_erf = encryptData(formData.Relationshiptype, 500);
    const data_end = encryptData(formData.document, 500);
    const data_enc = encryptData(formData.nombre_sujeto, 500);
    const data_eue = encryptData(cookieList[3], 500);

    let dataPost = {
      "data_enr": {
        "data_enri": data_enr["iv"],
        "data_enrs": data_enr["salt"],
        "data_enrc": data_enr["ct"]
      },
      "data_eap": {
        "data_eapi": data_eap["iv"],
        "data_eaps": data_eap["salt"],
        "data_eapc": data_eap["ct"]
      },
      "data_eam": {
        "data_eami": data_eam["iv"],
        "data_eams": data_eam["salt"],
        "data_eamc": data_eam["ct"]
      },
      "data_eem": {
        "data_eemi": data_eem["iv"],
        "data_eems": data_eem["salt"],
        "data_eemc": data_eem["ct"]
      },
      "data_etr": {
        "data_etri": data_etr["iv"],
        "data_etrs": data_etr["salt"],
        "data_etrc": data_etr["ct"]
      },
      "data_epo": {
        "data_epoi": data_epo["iv"],
        "data_epos": data_epo["salt"],
        "data_epoc": data_epo["ct"]
      },
      "data_eto": {
        "data_etoi": data_eto["iv"],
        "data_etos": data_eto["salt"],
        "data_etoc": data_eto["ct"]
      },
      "data_eco": {
        "data_ecoi": data_eco["iv"],
        "data_ecos": data_eco["salt"],
        "data_ecoc": data_eco["ct"]
      },
      "data_erf": {
        "data_erfi": data_erf["iv"],
        "data_erfs": data_erf["salt"],
        "data_erfc": data_erf["ct"]
      },
      "data_end": {
        "data_endi": data_end["iv"],
        "data_ends": data_end["salt"],
        "data_endc": data_end["ct"]
      },
      "data_enc": {
        "data_enci": data_enc["iv"],
        "data_encs": data_enc["salt"],
        "data_encc": data_enc["ct"]
      },
      "data_eue": {
        "data_euei": data_eue["iv"],
        "data_eues": data_eue["salt"],
        "data_euec": data_eue["ct"]
      }
    }
    try {
      const response = await serverRequest('post', 'rl/rl_interna', dataPost);
      if (response.data === 'ok') {
        navigate("/ReferencesRemaining");
      } else if (response.data === 'error servidor correo') {
        alert('error servidor correo');
      } else if (response.data === 'error bd') {
        alert('error bd');
      }
    } catch (e) {
      console.log(e);
    }
  };
  const getreferencedata = async () => {
    if (cookieList.length > 0 && cookieList[13] !== undefined && cookieList[13]) {
      const data_ene = encryptData(cookieList[13], 500);
      const dataForm_ = {
        data_ene: {
          data_enei: data_ene.iv,
          data_enes: data_ene.salt,
          data_enec: data_ene.ct,
        },
        token: cookieList[13],
      };
      const response = await serverRequest('post', 'rl/get_rl_docs', dataForm_)

      if (formData.Relationshiptype === "Letter") {
        const documentNames = response.data.cartas.map((carta) => carta.nombre);
        setFormData((prevData) => ({
          ...prevData,
          document: documentNames,
        }));
      }
      else if (formData.Relationshiptype === "Questionnaire") {
        const documentNames = response.data.cuestionarios.map((cuestionarios) => cuestionarios.nombre);
        setFormData((prevData) => ({
          ...prevData,
          document: documentNames,
        }));
      }
      else {
        setFormData((prevData) => ({
          ...prevData,
          document: [],
        }));
      }

    };
  };
  useEffect(() => {
    getreferencedata();
  }, [formData.Relationshiptype]);
  return (
    <Box sx={{ backgroundColor: "#ffffff", p: 4, borderRadius: 1 }}>
      <Grid container spacing={{ xs: 2, sm: 2, lg: 4 }}>
        <Grid item xs={12} sm={12} lg={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center"
            }}>
            <Typography
              sx={{
                color: "text.opacity",
                fontSize: 16,
                fontFamily: "Gilroy-Medium",
                textAlign: "center",
                mb: 2
              }}
              component={"h2"}
            >
              {t("EmploymentReferences.internalTittle")}
            </Typography>
            <Typography sx={{
              color: "neutral.900",
              fontSize: 22,
              fontFamily: "Gilroy-SemiBold",
              textAlign: "center",
            }} component={"h2"}>
              {t("EmploymentReferences.internalSubtittle")}: 1
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} lg={6}>
          <LabelInputLeftIcon
            mailIcon={personImage}
            type='text'
            label={t("labels.inputLabelName")}
            name='name'
            id="name"
            placeholder={t("placeholders.name")}
            value={formData.name}
            onChange={(e) => handleChange('name', e.target.value)}
            error={formErrors.name}
            maxLength={10}
          />
          {formErrors.name && (
            <Typography variant="body2" color="error">
              {formErrors.name}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={12} lg={6}>
          <LabelInputLeftIcon
            mailIcon={personImage}
            type='text'
            label={t("labels.inputLabelLastName")}
            name='last_name'
            id="last_name"
            placeholder={t("placeholders.lastname")}
            value={formData.last_name}
            onChange={(e) => handleChange('last_name', e.target.value)}
            error={formErrors.last_name}
            maxLength={10}
          />
          {formErrors.last_name && (
            <Typography variant="body2" color="error">
              {formErrors.last_name}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={12} lg={6}>
          <LabelInputLeftIcon
            mailIcon={personImage}
            type='text'
            label={t("labels.inputLabelMotherName")}
            name='mother_last_name'
            id="mother_last_name"
            placeholder={t("placeholders.mothername")}
            value={formData.mother_last_name}
            onChange={(e) => handleChange('mother_last_name', e.target.value)}
            error={formErrors.mother_last_name}
            maxLength={10}
          />
          {formErrors.mother_last_name && (
            <Typography variant="body2" color="error">
              {formErrors.mother_last_name}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={12} lg={6}>
          <LabelInputLeftIcon
            mailIcon={companyIcon}
            type='text'
            label={t("labels.labelcompanyname")}
            name='cname'
            id="cname"
            placeholder={t("placeholders.companyname")}
            value={formData.cname}
            onChange={(e) => handleChange('cname', e.target.value)}
            error={formErrors.cname}
            maxLength={10}
          />
          {formErrors.cname && (
            <Typography variant="body2" color="error">
              {formErrors.cname}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={12} lg={6}>
          <SimpleDropDown
            firstName={t("firstName.referencename")}
            mailIcon={personImage}
            label={t("labels.labelReferenceType")}
            name='referencetype'
            id="referencetype"
            placeholder={t("placeholders.ReferenceType")}
            value={formData.referencetype}
            onChange={(value) => handleChange('referencetype', value)}
            error={formErrors.referencetype}
            maxLength={10}
          />
          {formErrors.referencetype && (
            <Typography variant="body2" color="error">
              {formErrors.referencetype}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={12} lg={6}>
          <LabelInput
            type='text'
            label={t("labels.labelPosition")}
            name='position'
            id="position"
            placeholder={t("placeholders.Position")}
            value={formData.name}
            onChange={(e) => handleChange('position', e.target.value)}
            error={formErrors.position}
            maxLength={10}
          />
          {formErrors.position && (
            <Typography variant="body2" color="error">
              {formErrors.position}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={12} lg={6}>
          <LabelInputLeftIcon
            mailIcon={phoneIcon}
            type='text'
            label={t("labels.inputLabelNumber")}
            name='number'
            id="number"
            placeholder={t("placeholders.number")}
            value={formData.number}
            onChange={(e) => handleChange('number', e.target.value)}
            error={formErrors.number}
            maxLength={10}
          />
          {formErrors.number && (
            <Typography variant="body2" color="error">
              {formErrors.number}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={12} lg={6}>
          <LabelInputLeftIcon
            mailIcon={mailIcon}
            type='text'
            label={t("SuperAdminSignUp.inputs.inputLabelEmail")}
            name='email'
            id="email"
            placeholder={t("placeholders.lastname")}
            value={formData.email}
            onChange={(e) => handleChange('email', e.target.value)}
            error={formErrors.email}
          />
          {formErrors.email && (
            <Typography variant="body2" color="error">
              {formErrors.email}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={12} lg={6}>
          <SimpleDropDown
            firstName={t("firstName.relationname")}
            mailIcon={personImage}
            type='text'
            label={t("labels.labelrelation")}
            name='Relationshiptype'
            id="Relationshiptype"
            placeholder={t("placeholders.relation")}
            value={formData.Relationshiptype}
            onChange={(value) => handleChange('Relationshiptype', value)}
            error={formErrors.Relationshiptype}
            maxLength={10}
          />
          {formErrors.Relationshiptype && (
            <Typography variant="body2" color="error">
              {formErrors.Relationshiptype}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={12} lg={6}>
          <DropDown
            options_data={formData.document}
            type="text"
            subLabel=""
            name='document'
            id="document"
            label={t("labels.labelDocument")}
            placeholder={t("placeholders.Document")}
            inputValue={documents}
            value="" // Set to an empty string initially
            onChange={(e) => setDocuments(e.target.value)}
          />

          {formErrors.document && (
            <Typography variant="body2" color="error">
              {formErrors.document}
            </Typography>
          )}
        </Grid>

        {/************************ Modal Email successfully **************************/}

        <Grid item xs={12} sm={12} lg={12}>
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
            <EmployRefEmailSuccessSent
              buttonText="OK"
              onClick={sendHandler}
              isEmailSuccessSent={isEmailSuccessSent}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default EmployRefInternalFroms;