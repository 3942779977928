import { Box, Typography } from "@mui/material";
import { fontSize } from "@mui/system";
import React, { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import lineImg from "../../assets/images/line-header-sec.png";
import { DataGrid } from "@mui/x-data-grid";
import StarIcon from "@mui/icons-material/Star";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import RenderCellExpander from "../@common/RenderCellExpander";
function AssignedCoupons(props) {
  const [rowsdata, setRows] = useState([]);
  const { t } = useTranslation(); // Updated: Initialize rows state
  const { data } = props;
  const getAssignedCoupons = async () => {
    const transformedRows = data.map((item, index) => {
      const CBDisponible = item.CB?.disponible;
      const CBTotal = item.CB?.total;
      const SBDisponible = item.SB?.disponible;
      const SBTotal = item.SB?.total;

      return {
        id: index + 1,
        email: item.email || "",
        withbureau: `${CBDisponible}/${CBTotal}`,
        withoutbureau: `${SBDisponible}/${SBTotal}`,
      };
    });

    setRows(transformedRows);
  };
  useEffect(() => {
    getAssignedCoupons();
  }, [data]);
  useEffect(() => {}, [rowsdata]);

  const columns = [
    {
      field: "email",
      headerName: t("AdministratorUsers.rightContentTablesHeaders"),
      flex: 1,
      editable: true,
      renderCell: RenderCellExpander,
    },
    {
      field: "withbureau",
      headerName: t("CouponsManager.leftContentAdd"),
      flex: 1,
      renderCell: (params) => {
        const numbersArr = params.value.split("/");
        return (
          <Box>
            <Box
              sx={{
                color: "#4165F6",
                fontSize: 18,
                fontFamily: "Gilroy-Medium",
              }}
              component={"span"}
            >
              {numbersArr[0]}
            </Box>
            <Box
              sx={{
                color: "text.opacity",
                fontSize: 18,
                fontFamily: "Gilroy-Medium",
              }}
              component={"span"}
            >
              /
            </Box>
            <Box
              sx={{
                color: "text.opacity",
                fontSize: 18,
                fontFamily: "Gilroy-Medium",
              }}
              component={"span"}
            >
              {numbersArr[1]}
            </Box>
          </Box>
        );
      },
    },
    {
      field: "withoutbureau",
      headerName: t("CouponsManager.leftContentAddW"),
      flex: 1,
      editable: true,
      renderCell: (params) => {
        const numbersArr = params.value.split("/");
        return (
          <Box>
            <Box
              sx={{
                color: "#4165F6",
                fontSize: 18,
                fontFamily: "Gilroy-Medium",
              }}
              component={"span"}
            >
              {numbersArr[0]}
            </Box>
            <Box
              sx={{
                color: "text.opacity",
                fontSize: 18,
                fontFamily: "Gilroy-Medium",
              }}
              component={"span"}
            >
              /
            </Box>
            <Box
              sx={{
                color: "text.opacity",
                fontSize: 18,
                fontFamily: "Gilroy-Medium",
              }}
              component={"span"}
            >
              {numbersArr[1]}
            </Box>
          </Box>
        );
      },
    },
  ];

  const rows = [
    {
      id: 1,
      email: "erendoncc@gmail.com",
      withbureau: "100/200",
      withoutbureau: "90/200",
    },
    {
      id: 2,
      email: "morkfen@gmail.com",
      withbureau: "100/200",
      withoutbureau: "90/200",
    },
    {
      id: 3,
      email: "erendoncc@gmail.co",
      withbureau: "50/200",
      withoutbureau: "6/200",
    },
    {
      id: 4,
      email: "morkfen@gmail.com",
      withbureau: "80/200",
      withoutbureau: "120/200",
    },
    {
      id: 5,
      email: "erendoncc@gmail.co",
      withbureau: "90/200",
      withoutbureau: "190/200",
    },
  ];

  const handleButtonClick = (id) => {
    // handle button click
    console.log(`Button clicked for row ${id}`);
  };

  const removeHandler = () => {
    alert("working fine");
  };
  const continueHandler = () => {
    alert("working fine");
  };

  const addHandler = () => {
    alert("working fine");
  };
  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#ffffff",
          borderRadius: 2,
          px: 4,
          py: 3,
        }}
      >
        <Typography
          sx={{
            color: "neutral.900",
            fontFamily: "Gilroy-SemiBold",
            fontSize: 22,
            textAlign: "center",
          }}
        >
          {t("CouponsManager.rightContentTittle")}
        </Typography>
        <Box sx={{ width: "100%", mt: "12px" }}>
          <DataGrid
            rows={rowsdata}
            columns={columns}
            isCellEditable={() => false}
            autoHeight // Disable field editing
            sx={{
              "& .css-ubja00-MuiDataGrid-root .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted):hover .MuiDataGrid-sortIcon ": {
                opacity :"1"
              },

              "& svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall.MuiDataGrid-sortIcon.css-ptiqhd-MuiSvgIcon-root": {
                color: "#fff"
              },
              " & .css-yrdy0g-MuiDataGrid-columnHeaderRow :nth-child(1 of svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root)": {
                fill: "#fff"
              },
              "& .MuiDataGrid-columnSeparator.MuiDataGrid-columnSeparator--sideRight  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root": {
                display: "none"
              },
              border: "solid 1px #f4f4f4",
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#ff8d28",
                borderTopRightRadius: 4,
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                fontSize: 18,
                color: "#fff",
                fontFamily: "Gilroy-SemiBold"
              },
              "& .MuiDataGrid-cellContent": {
                fontSize: 18,
                color: "#fff",
                fontFamily: "Gilroy-Medium"
              },
              "& .css-wop1k0-MuiDataGrid-footerContainer": {
                display: "none",
              },
              "& .MuiDataGrid-withBorderColor": {
                borderColor: "#f4f4f4 !important",
              },
              "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus": {
                outline: "none", // Remove the outline
              },
            }}
            initialState={{
              pagination: false, // Disable pagination
            }}
            checkboxSelection={false}
            disableRowSelectionOnClick
            hideFooterPagination={true}
            disableColumnMenu={true} // disable the filter functionality
          />
        </Box>
      </Box>
    </>
  );
}

export default AssignedCoupons;
