import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Fragment } from 'react';
import { textAlign } from '@mui/system';
import { Link, useNavigate } from 'react-router-dom';
import sucessImage from "../../../assets/images/authorize-user.svg"
import changeRole from "../../../assets/images/changeRole.svg"
import LabelInput from "../Input/LabelInput"
import Cookies from 'js-cookie';
import Divider from '@mui/material/Divider';
import FormGroup from '@mui/material/FormGroup'
import Switch from '@mui/material/Switch'
import FormControlLabel from "@mui/material/FormControlLabel"
import { getCookiesList } from '../../../utils/cookiesHelper'
import { getPersonalization, checkCupons, getReport, validateCupons, verReport, userCupons, imprimir } from '../../../utils/bureauHelper'
import AlertModal from "../../@common/Modal/AlertModal"
import CustomSpinner from '../../@common/CustomSpinner/customSpinner'
import { processCompanyData } from "../../../utils/getColorHelper";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    maxWidth: "95%",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 4
};

export default function HomonymsModal(props) {
    const { handleclosed, nssData, nss1Data, curpData, estadoData, nbData, subsections_dicts, dataCurpZero, cname, edad, guestUser } = props;
    // State for the toggle buttons
    const [toggle1, setToggle1] = useState(false);
    const [toggle2, setToggle2] = useState(false);
    const [continueButtonDisabled, setContinueButtonDisabled] = useState(true);
    const [cookieList, setCookieList] = useState([])
    const [showAlert, setShowAlert] = useState(false); // Add a state to control the visibility of the alert
    const [showtext, setShowtext] = useState("");
    const [spinnerVisibility, setSpinnerVisibility] = useState(false)
    const [closedModal, setclosedModal] = useState(false)
    const navigate = useNavigate();
    const handleCloseAlert = () => {
        setShowAlert(false);
    };
    // Side Effects
    useEffect(() => {
        const cookieKey = guestUser ? 'guestUserInfo' : 'userInfo'
        const userCookiesList = getCookiesList(cookieKey)
        if (userCookiesList.length > 0) {
            setCookieList(userCookiesList)
        }
    }, []);
    // Function to handle toggle button state changes
    const handleToggle1Change = () => {
        setToggle1(true);
        setToggle2(false); // Set the other toggle2 to false
    };

    const handleToggle2Change = () => {
        setToggle2(true);
        setToggle1(false); // Set the other toggle1 to false
    };
    // Event handler for the "continue" button click
    const handleContinueClick = async () => {
        setSpinnerVisibility(true);
        try {
            const cupon = guestUser === true ? null : await checkCupons('SB', cookieList[3]);
            if (cupon === 'error') {
                setSpinnerVisibility(false);
                setShowAlert(true);
                setShowtext('Tus cupones SB son insuficientes');
            } else {
                let resultados = {};
                if (guestUser) {
                    resultados = await validateCupons(cookieList[3], cookieList[4], nbData, dataCurpZero['vl_id']);
                    if (resultados === "Coupon attempts have been exhausted, please try another") {
                        setSpinnerVisibility(false);
                        setShowAlert(true);
                        setShowtext("Coupon attempts have been exhausted, please try another");
                        setclosedModal(true)
                        return;
                    } else if (resultados === "Connection Error") {
                        setSpinnerVisibility(false);
                        setShowAlert(true);
                        setShowtext('Connection Error');
                        setclosedModal(true)
                        return;
                    }
                }
                else {
                    // await userCupons(cupon, cookieList[3]);
                    resultados = await getReport('SB', cupon, nbData, guestUser, undefined, dataCurpZero['vl_id']);
                    checkCupons('SB', nbData.email);
                    // await imprimir(resultados, nbData.email, subsections_dicts, cupon, guestUser);
                    const reportPage = guestUser ? '/GuestReport' : '/report';
                    const personalization = await getPersonalization(
                        cupon == 'SB' || cupon == 'CB'? cupon : 'SB', guestUser? 'guestUserInfo' : 'userInfo'
                    );
                    await verReport(dataCurpZero['vl_id'])
                    setSpinnerVisibility(false);
                    navigate(reportPage, { state: { 
                        personalization: personalization,
                        espData: processCompanyData(cookieList[13], resultados), 
                        sub_sections_data: subsections_dicts,
                        vlid: dataCurpZero['vl_id'],
                        curp: resultados['CURP'],
                        usuarioCupon: 'SB'
                     } });
                }
            }
        } catch (e) {
            setSpinnerVisibility(false);
        }
    };


    useEffect(() => {
        if (toggle1 || toggle2) {
            setContinueButtonDisabled(false);
        } else {
            setContinueButtonDisabled(true);
        }
    }, [toggle1, toggle2]);
    return (
        <Fragment>
            {showAlert && <AlertModal closedModal={closedModal} closedModalFunction={handleclosed} text={showtext} onClose={handleCloseAlert} />}
            <CustomSpinner visible={spinnerVisibility} />
            <Modal
                open={true} // Open the modal whenever it is rendered
                onClose={handleclosed}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ justifyContent: "center", display: "flex", alignItems: "center" }}>
                        <Typography component={"h6"} sx={{ color: "neutral.900", fontSize: 20, fontFamily: "Gilroy-SemiBold", textAlign: "center" }}>
                            Select the correct  <Box component={"span"} sx={{ color: "#8ca1f9" }}>homonym</Box>
                        </Typography>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", mt: 4, flexDirection: "column", }}>
                        <Typography component={"h6"} sx={{ color: "white", fontSize: 20, fontFamily: "Gilroy-SemiBold", borderRadius: 1, textAlign: "center", backgroundColor: "#4165f6", display: 'flex', justifyContent: "center", alignItems: 'center', height: '40px', width: 750 }}>
                            {cname}
                            <Box component={"span"} sx={{ color: "white", ml: 22 }}>{edad} Years</Box>
                        </Typography>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", mt: 2, flexDirection: "column", marginLeft: '20px' }}>
                        <Typography component={"h6"} sx={{ color: "#8ca1f9", fontSize: 16, fontFamily: "Gilroy-SemiBold", display: 'flex', }}>
                            <Divider sx={{ backgroundColor: '#4c6ef7', height: 50, mr: 1, borderWidth: '0.5px', borderColor: 'primary.main' }} />
                            <Box sx={{ flexDirection: "row" }}>
                                <Box sx={{ display: 'flex' }}>
                                    CURP <Box component={"span"} sx={{ color: "#acacac", ml: 1 }}>{curpData}</Box>
                                    <Box sx={{ ml: 18, width: '180px' }} >
                                        NSS IMSS <Box component={"span"} sx={{ color: "#acacac", ml: 1 }}>{nssData}</Box>
                                    </Box>
                                </Box>
                                <Box>
                                    ESTADO  <Box component={"span"} sx={{ color: "#acacac", ml: 1 }}>{estadoData}</Box>
                                </Box>
                            </Box>
                            <Box>
                                <FormGroup row>
                                    <FormControlLabel sx={{ flexDirection: "row-reverse", ml: 8 }} control={<Switch color='success' checked={toggle1}
                                        onChange={handleToggle1Change} />} />
                                </FormGroup>
                            </Box>
                        </Typography>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", mt: 2, flexDirection: "column", marginLeft: '20px' }}>
                        <Typography component={"h6"} sx={{ color: "#8ca1f9", fontSize: 16, fontFamily: "Gilroy-SemiBold", display: 'flex', }}>
                            <Divider sx={{ backgroundColor: '#4c6ef7', height: 50, mr: 1, borderWidth: '0.5px', borderColor: 'primary.main' }} />
                            <Box sx={{ flexDirection: "row" }}>
                                <Box sx={{ display: 'flex' }}>
                                    CURP <Box component={"span"} sx={{ color: "#acacac", ml: 1 }}>{curpData}</Box>
                                    <Box sx={{ ml: 18, width: '180px' }} >
                                        NSS IMSS <Box component={"span"} sx={{ color: "#acacac", ml: 1 }}>{nss1Data}</Box>
                                    </Box>
                                </Box>
                                <Box>
                                    ESTADO <Box component={"span"} sx={{ color: "#acacac", ml: 1 }}>{estadoData}</Box>
                                </Box>
                            </Box>
                            <Box>
                                <FormGroup row>
                                    <FormControlLabel sx={{ flexDirection: "row-reverse", ml: 8 }} control={<Switch color='success' checked={toggle2}
                                        onChange={handleToggle2Change} />} />
                                </FormGroup>
                            </Box>
                        </Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", mt: 4 }}>
                        <Button
                            id="continue-button"
                            sx={{
                                backgroundColor: "primary.main",
                                py: 1,
                                mt: 2,
                                px: { xs: 4, sm: 8 },
                                fontSize: "18px",
                                fontFamily: "Gilroy-Medium",
                                textTransform: "capitalize"
                            }}
                            variant="contained"
                            disabled={continueButtonDisabled}
                            onClick={handleContinueClick}
                        >
                            Continue
                        </Button>

                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", mt: 4 }}>
                        <Button onClick={handleclosed} style={{ color: "rgba(27, 32, 44, 0.6)", fontSize: 16, fontFamily: "Gilroy-Medium", textDecoration: "underline", textTransform: "capitalize" }} variant="text">Go Back</Button>
                    </Box>
                </Box>
            </Modal>
        </Fragment>
    );

}
