import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import EmployReferenceTabs from './EmployReferenceTabs'
import { useTranslation } from 'react-i18next';
function EmployReferenceContent() {
    const { t } = useTranslation();
  return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={1}>
            </Grid>
            <Grid item xs={12} sm={10}>
                <Box>
                    <Typography 
                    sx={{
                        color:"text.opacity",
                        fontSize:18,
                        fontFamily: "Gilroy-Medium",
                        textAlign:"center",
                        mb:4,
                    }} 
                    component={"h2"}
                    >
                      {t("EmploymentReferences.subtitle")}
                    </Typography>
                    <Box>
                        <EmployReferenceTabs/>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} sm={1}>
            </Grid>
        </Grid>
  )
}

export default EmployReferenceContent