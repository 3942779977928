import { useTranslation } from "react-i18next";
// ** React Imports
import { Fragment, useState, useEffect } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
// ** Third Party Imports
import toast from "react-hot-toast";
import AnimationLoginScreen from "../@common/AnimationLoginScreen/AnimationLoginScreen";
import bgImage from "../../assets/images/coverphoto.png";
import Footer from "../../Footer/Footer";
import logo from "../../assets/images/logo.png";
import underLine from "../../assets/images/under-line.png";
import LabelInputLeftIcon from "../@common/Input/LabelInputLeftIcon";
import PasswordShowHideIcon from "../@common/Input/PasswordShowHideIcon/PasswordShowHideIcon";
import mailImage from "../../assets/images/mailIcon.png";
import profileIcon from "../../assets/images/profileIcon.png";
import phoneIcon from "../../assets/images/phoneIcon.png";
import { Link } from "react-router-dom";
import companyImage from "../../assets/images/company-icon.png";
import DropDownInput from "../@common/Input/DropDownInput/DropDownInput";
import NoticePrivacy from "../NoticePrivacy/NoticePrivacy";
import TermsConditionModal from "../TermsConditions/TermsCondition";
import { Stack } from "@mui/material";
import sjcl from "sjcl";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { serverRequest } from "../../utils/requestHelper";
import AlertModal from "../@common/Modal/AlertModal";
import { emailPattern } from "../../utils/InputPatterns";
import {
  validateInput,
  validatePassword,
  validatePhoneNumber,
  validateName,
  validateConfirmPassword,
} from "../../utils/inputValidation";
import CustomSpinner from "../@common/CustomSpinner/customSpinner";
import EnterEmailModal from "../@common/Modal/EnterEmailModal";
import AuthLeftScreen from "../@common/AuthLeftScreen";
import LoginImage from "../../assets/images/loginRounded.png";

const steps = [
  {
    title: "User Step 1",
  },
  {
    title: "User Step 2",
  },
];

const SuperAdminSignUp = () => {
  const { t } = useTranslation();
  const [showAlert, setShowAlert] = useState(false); // Add a state to control the visibility of the alert
  const [showtext, setShowtext] = useState("");
  const [name, setName] = useState("");
  const [last_name, setLastName] = useState("");
  const [mother_last_name, setMotherLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile_number, setMobileNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [belongs_company, setBelongsCompany] = useState("");
  const [company_zone, setCompanyZone] = useState("");
  const [company_branch, setCompanyBranch] = useState("");
  const [company_address, setCompanyAddress] = useState("");
  const [company_email, setCompanyEmail] = useState("");
  const [company_phone, setCompanyPhone] = useState("");
  const [isNoticeCheckboxChecked, setIsNoticeCheckboxChecked] = useState(false);
  const [isTermsCheckboxChecked, setIsTermsCheckboxChecked] = useState(false);
  let [companies, setListOfCompanies] = useState([]);
  let [listData, setListData] = useState([]);
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState({
    selectedCompany: "",
    company_address: "",
    company_email: "",
    company_phone: "",
    name: "",
    last_name: "",
    mother_last_name: "",
    mobile_number: "",
    email: "",
    password: "",
    confirm_password: "",
  });
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [openTermsModal, setOpenTermsModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [spinnerVisibility, setSpinnerVisibility] = useState(false);
  // const [showSuccesstext, setShowSuccesstext] = useState("")

  var arrayData = [];
  arrayData.push(name);
  arrayData.push(last_name);
  arrayData.push(mother_last_name);
  arrayData.push(email);
  arrayData.push(mobile_number);
  arrayData.push(password);
  arrayData.push(confirm_password);
  arrayData.push(selectedCompany);
  arrayData.push(belongs_company);
  arrayData.push(company_zone);
  arrayData.push(company_branch);
  arrayData.push(company_address);
  arrayData.push(company_email);
  arrayData.push(company_phone);

  const list_of_companies = async () => {
    const response = await serverRequest("get", "listado");
    var decrypted = sjcl.decrypt(
      process.env.REACT_APP_MIX_COOK_KEY,
      JSON.stringify(response.data)
    );
    companies = JSON.parse(decrypted);
    let newListData = [];

    for (var i = 0; i < companies.length; i++) {
      let newData = {};
      if (companies[i]["super_admin"] == "0") {
        newData.name = companies[i].nombre_fiscal_empresa;
        newData.value = companies[i].nombre_fiscal_empresa;
        newListData.push(newData);
        setListData(newListData);
      }
    }
  };
  useEffect(() => {
    setBelongsCompany("no");
    list_of_companies();
  }, []);
  const handleCloseAlert = () => {
    setShowAlert(false);
  };
  // checkbox changes
  const handleCheckboxChange = (e) => {
    setIsNoticeCheckboxChecked(e.target.checked);
  };
  const handleTermsCheckboxChange = (e) => {
    setIsTermsCheckboxChecked(e.target.checked);
  };
  // close privacy and open terms modal
  const handleClosePrivacyModalAndOpenTermsModal = () => {
    setIsNoticeCheckboxChecked(false);
    setOpenUploadModal(false);
    setOpenTermsModal(true);
  };
  // simple close
  const handleJustCloseNoticeModal = () => {
    setIsNoticeCheckboxChecked(false);
    setOpenUploadModal(false);
  };
  const handleJustClosePrivacyModal = () => {
    setIsTermsCheckboxChecked(false);
    setOpenTermsModal(false);
  };
  // close and submit
  const handleCloseTermsModalAndSubmit = () => {
    setIsTermsCheckboxChecked(false);
    setOpenTermsModal(false);
    SignUpSuperAdmin(arrayData);
  };
  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    navigate("/Dashboard");
  };
  const handleNext = () => {
    const errors = {};
    if (activeStep === 0) {
      if (selectedCompany === "" || selectedCompany.trim() === "") {
        errors.selectedCompany = t(
          "SuperAdminSignUp.inputs.inputCompanyRedMessageWrongFormat"
        );
      }
      validateInput(company_address, null, "company_address", errors, t);
      validateInput(company_email, emailPattern, "company_email", errors, t);
      validatePhoneNumber(company_phone, "company_phone", errors, t);
    } else if (activeStep === 1) {
      validateName(name, "name", errors, true, t); // Requires capital letters in the name
      validateName(last_name, "last_name", errors, true, t); // Requires capital letters in the last name
      validateName(mother_last_name, "mother_last_name", errors, true, t); // Requires capital letters in the mother's last name
      validatePhoneNumber(mobile_number, "mobile_number", errors, t);
      validateInput(email, emailPattern, "email", errors, t);
      const passwordErrors = validatePassword(password, t);
      if (Object.keys(passwordErrors).length > 0) {
        errors.password = passwordErrors.password;
      }
      const confirmPasswordValidationResult = validateConfirmPassword(
        password,
        confirm_password,
        t
      );

      if (confirmPasswordValidationResult.error) {
        errors.confirm_password = confirmPasswordValidationResult.message;
      } else {
        delete errors.confirm_password;
      }
    }

    setFormErrors(errors);

    // If there are no errors, proceed to the next step or submit the form.
    if (Object.keys(errors).length === 0) {
      if (activeStep === steps.length - 1) {
        setOpenUploadModal(true);
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  const SignUpSuperAdmin = async (data) => {
    try {
      var dataToRegister = {
        data_ene: sjcl.encrypt(process.env.REACT_APP_MIX_COOK_KEY, data[0], {
          iter: 500,
        }), // Nombre
        data_eap: sjcl.encrypt(process.env.REACT_APP_MIX_COOK_KEY, data[1], {
          iter: 500,
        }), // Apellido Paterno
        data_eam: sjcl.encrypt(process.env.REACT_APP_MIX_COOK_KEY, data[2], {
          iter: 500,
        }), // Apellido Materno
        data_eel: sjcl.encrypt(process.env.REACT_APP_MIX_COOK_KEY, data[3], {
          iter: 500,
        }), // Email
        data_eto: sjcl.encrypt(process.env.REACT_APP_MIX_COOK_KEY, data[4], {
          iter: 500,
        }), // Teléfono
        data_epd: sjcl.encrypt(process.env.REACT_APP_MIX_COOK_KEY, data[5], {
          iter: 500,
        }), // Contraseña
        data_eea: sjcl.encrypt(process.env.REACT_APP_MIX_COOK_KEY, data[7], {
          iter: 500,
        }), // Nombre empresa
        data_epe: sjcl.encrypt(process.env.REACT_APP_MIX_COOK_KEY, data[8], {
          iter: 500,
        }), // Pertenece empresa
        data_eze: sjcl.encrypt(process.env.REACT_APP_MIX_COOK_KEY, data[9], {
          iter: 500,
        }), // Zona empresa
        data_ese: sjcl.encrypt(process.env.REACT_APP_MIX_COOK_KEY, data[10], {
          iter: 500,
        }), // Sucursal empresa
        data_ede: sjcl.encrypt(process.env.REACT_APP_MIX_COOK_KEY, data[11], {
          iter: 500,
        }), // Dirección empresa
        data_ece: sjcl.encrypt(process.env.REACT_APP_MIX_COOK_KEY, data[12], {
          iter: 500,
        }), // Correo contacto empresa
        data_ete: sjcl.encrypt(process.env.REACT_APP_MIX_COOK_KEY, data[13], {
          iter: 500,
        }), // Teléfono contacto empresa
      };
    } catch (e) {
      alert(e);
    }
    var dataFormJsonEne = JSON.parse(dataToRegister["data_ene"]);
    var dataFormJsonEap = JSON.parse(dataToRegister["data_eap"]);
    var dataFormJsonEam = JSON.parse(dataToRegister["data_eam"]);
    var dataFormJsonEel = JSON.parse(dataToRegister["data_eel"]);
    var dataFormJsonEto = JSON.parse(dataToRegister["data_eto"]);
    var dataFormJsonEpd = JSON.parse(dataToRegister["data_epd"]);
    var dataFormJsonEea = JSON.parse(dataToRegister["data_eea"]);
    var dataFormJsonEpe = JSON.parse(dataToRegister["data_epe"]);
    var dataFormJsonEze = JSON.parse(dataToRegister["data_eze"]);
    var dataFormJsonEse = JSON.parse(dataToRegister["data_ese"]);
    var dataFormJsonEde = JSON.parse(dataToRegister["data_ede"]);
    var dataFormJsonEce = JSON.parse(dataToRegister["data_ece"]);
    var dataFormJsonEte = JSON.parse(dataToRegister["data_ete"]);

    dataToRegister = {
      data_ene: {
        data_enei: dataFormJsonEne["iv"],
        data_enes: dataFormJsonEne["salt"],
        data_enec: dataFormJsonEne["ct"],
      },
      data_eap: {
        data_eapi: dataFormJsonEap["iv"],
        data_eaps: dataFormJsonEap["salt"],
        data_eapc: dataFormJsonEap["ct"],
      },
      data_eam: {
        data_eami: dataFormJsonEam["iv"],
        data_eams: dataFormJsonEam["salt"],
        data_eamc: dataFormJsonEam["ct"],
      },
      data_eel: {
        data_eeli: dataFormJsonEel["iv"],
        data_eels: dataFormJsonEel["salt"],
        data_eelc: dataFormJsonEel["ct"],
      },
      data_eto: {
        data_etoi: dataFormJsonEto["iv"],
        data_etos: dataFormJsonEto["salt"],
        data_etoc: dataFormJsonEto["ct"],
      },
      data_epd: {
        data_epdi: dataFormJsonEpd["iv"],
        data_epds: dataFormJsonEpd["salt"],
        data_epdc: dataFormJsonEpd["ct"],
      },
      data_eea: {
        data_eeai: dataFormJsonEea["iv"],
        data_eeas: dataFormJsonEea["salt"],
        data_eeac: dataFormJsonEea["ct"],
      },
      data_epe: {
        data_epei: dataFormJsonEpe["iv"],
        data_epes: dataFormJsonEpe["salt"],
        data_epec: dataFormJsonEpe["ct"],
      },
      data_eze: {
        data_ezei: dataFormJsonEze["iv"],
        data_ezes: dataFormJsonEze["salt"],
        data_ezec: dataFormJsonEze["ct"],
      },
      data_ese: {
        data_esei: dataFormJsonEse["iv"],
        data_eses: dataFormJsonEse["salt"],
        data_esec: dataFormJsonEse["ct"],
      },
      data_ede: {
        data_edei: dataFormJsonEde["iv"],
        data_edes: dataFormJsonEde["salt"],
        data_edec: dataFormJsonEde["ct"],
      },
      data_ece: {
        data_ecei: dataFormJsonEce["iv"],
        data_eces: dataFormJsonEce["salt"],
        data_ecec: dataFormJsonEce["ct"],
      },
      data_ete: {
        data_etei: dataFormJsonEte["iv"],
        data_etes: dataFormJsonEte["salt"],
        data_etec: dataFormJsonEte["ct"],
      },
    };
    setSpinnerVisibility(true);
    const response = await serverRequest(
      "post",
      "existe_usuario",
      dataToRegister
    );
    if (response.data == "usuario existe") {
      setSpinnerVisibility(false);
      setShowAlert(true);
      setShowtext(t("thanks.alreadyexit"));
    } else if (response.data == "empresa existe") {
      setSpinnerVisibility(false);
      setShowAlert(true);
      setShowtext(t("thanks.register"));
    } else if (response.data == "empresa no existe") {
      setSpinnerVisibility(false);
      setShowAlert(true);
      setShowtext("Verify your company or go with your manager");
    } else if (response.data == "error bd") {
      setTimeout(() => {
        setSpinnerVisibility(false);
        setShowAlert(true);
        setShowtext("Reconnection! No connection accept");
      }, 10);
    } else if (response.data == "usuario no existe") {
      registerAPI(dataToRegister);
    }
  };
  const registerAPI = async (dataToRegister) => {
    const response = await serverRequest("post", "registrar", dataToRegister);
    if (response.data == "registrado exitosamente") {
      setSpinnerVisibility(false);
      setShowSuccessModal(true);
    } else if (response.data == "existe") {
      setSpinnerVisibility(false);
      setShowAlert(true);
      setShowtext("The email entered is already registered");
    } else if (response.data == "error bd") {
      setSpinnerVisibility(false);
      setShowAlert(true);
      setShowtext("An unexpected error occurred Please report it");
    } else if (response.data == "empresa existe") {
      setSpinnerVisibility(false);
      setShowAlert(true);
      setShowtext(t("thanks.register"));
    } else if (response.data == "empresa no existe") {
      setSpinnerVisibility(false);
      setShowAlert(true);
      setShowtext(t("thanks.register"));
    } else {
      setSpinnerVisibility(false);
      setShowAlert(true);
      setShowtext("Something went wrong");
    }
  };
  // ** States

  // Handle Stepper
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // const handleNext = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  //   if (activeStep === steps.length - 1) {
  //     handleSubmit();
  //   }
  // };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Fragment key={step}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <DropDownInput
                data={listData}
                mailIcon={companyImage}
                type="text"
                name="companyname"
                id="companyname"
                label={t("labels.labelcompanyname")}
                firstName={t("labels.labelcompanyname")}
                onChange={(e) => setSelectedCompany(e.target.value)}
                error={formErrors.selectedCompany}
              />
              {formErrors.selectedCompany && (
                <Typography variant="body2" color="error">
                  {formErrors.selectedCompany}
                </Typography>
              )}
            </Box>
            <Box sx={{ mt: 1 }}>
              <LabelInputLeftIcon
                mailIcon={mailImage}
                type="text"
                label={t("SuperAdminSignUp.inputs.inputLabelCompanyAddress")}
                name="companyaddress"
                id="companyaddress"
                placeholder={t(
                  "SuperAdminSignUp.inputs.inputTextCompanyAddress"
                )}
                onChange={(e) => setCompanyAddress(e.target.value)}
                error={formErrors.company_address}
              />
              {formErrors.company_address && (
                <Typography variant="body2" color="error">
                  {formErrors.company_address}
                </Typography>
              )}
            </Box>
            <Stack
              sx={{ mt: 1 }}
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 0, sm: 2 }}
            >
              <Box sx={{ width: { xs: "100%", sm: "50%" } }}>
                <LabelInputLeftIcon
                  mailIcon={mailImage}
                  type="email"
                  label={t("SuperAdminSignUp.inputs.inputLabelCompanyEmail")}
                  name="emailAddress"
                  id="emailAddress"
                  placeholder={t(
                    "SuperAdminSignUp.inputs.inputTextCompanyEmail"
                  )}
                  onChange={(e) => setCompanyEmail(e.target.value)}
                  error={formErrors.company_email}
                />
                {formErrors.company_email && (
                  <Typography variant="body2" color="error">
                    {formErrors.company_email}
                  </Typography>
                )}
              </Box>
              <Box sx={{ width: { xs: "100%", sm: "50%" } }}>
                <LabelInputLeftIcon
                  mailIcon={phoneIcon}
                  type="number"
                  label={t("labels.inputLabelNumber")}
                  name="mnumber"
                  id="mnumber"
                  placeholder={t("placeholders.number")}
                  value={company_phone}
                  maxLength={10}
                  onChange={(e) => setCompanyPhone(e.target.value)}
                  error={formErrors.company_phone}
                />
                {formErrors.company_phone && (
                  <Typography variant="body2" color="error">
                    {formErrors.company_phone}
                  </Typography>
                )}
              </Box>
            </Stack>
          </Fragment>
        );
      case 1:
        return (
          <Fragment key={step}>
            <NoticePrivacy
              open={openUploadModal}
              isCheckboxChecked={isNoticeCheckboxChecked}
              handleClose={handleClosePrivacyModalAndOpenTermsModal}
              handleJustClose={handleJustCloseNoticeModal}
              handleCheckboxChange={handleCheckboxChange}
            />
            <TermsConditionModal
              open={openTermsModal}
              isCheckboxChecked={isTermsCheckboxChecked}
              handleClose={handleCloseTermsModalAndSubmit}
              handleJustClose={handleJustClosePrivacyModal}
              handleCheckboxChange={handleTermsCheckboxChange}
            />
            <CustomSpinner visible={spinnerVisibility} />
            {showSuccessModal && (
              <EnterEmailModal onClose={handleCloseSuccessModal} />
            )}

            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 0, sm: 2 }}
            >
              <Box sx={{ width: { xs: "100%", sm: "50%" } }}>
                <LabelInputLeftIcon
                  mailIcon={profileIcon}
                  type="text"
                  label={t("SuperAdminSignUp.inputs.inputLabelName")}
                  name="Name"
                  id="name"
                  placeholder={t("placeholders.name")}
                  onChange={(e) => setName(e.target.value)}
                  error={formErrors.name}
                />
                {formErrors.name && (
                  <Typography variant="body2" color="error">
                    {formErrors.name}
                  </Typography>
                )}
              </Box>
              <Box sx={{ width: { xs: "100%", sm: "50%" } }}>
                <LabelInputLeftIcon
                  mailIcon={profileIcon}
                  type="text"
                  label={t("SuperAdminSignUp.inputs.inputLabelLastName")}
                  name="lname"
                  id="lname"
                  placeholder={t("placeholders.lastname")}
                  onChange={(e) => setLastName(e.target.value)}
                  error={formErrors.last_name}
                />
                {formErrors.last_name && (
                  <Typography variant="body2" color="error">
                    {formErrors.last_name}
                  </Typography>
                )}
              </Box>
            </Stack>
            <Box sx={{ mt: 1 }}>
              <LabelInputLeftIcon
                mailIcon={profileIcon}
                type="text"
                label={t("SuperAdminSignUp.inputs.inputLabelMotherName")}
                name="mname"
                id="mname"
                placeholder={t("placeholders.mothername")}
                onChange={(e) => setMotherLastName(e.target.value)}
                error={formErrors.mother_last_name}
              />
              {formErrors.mother_last_name && (
                <Typography variant="body2" color="error">
                  {formErrors.mother_last_name}
                </Typography>
              )}
            </Box>
            <Stack
              sx={{ mt: 1 }}
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 0, sm: 2 }}
            >
              <Box sx={{ width: { xs: "100%", sm: "50%" } }}>
                <LabelInputLeftIcon
                  mailIcon={phoneIcon}
                  type="number"
                  label={t("SuperAdminSignUp.inputs.inputLabelCompanyTel")}
                  name="number"
                  id="number"
                  maxLength={10}
                  placeholder={t("SuperAdminSignUp.inputs.inputTextCompanyTel")}
                  onChange={(e) => setMobileNumber(e.target.value)}
                  error={formErrors.mobile_number}
                />
                {formErrors.mobile_number && (
                  <Typography variant="body2" color="error">
                    {formErrors.mobile_number}
                  </Typography>
                )}
              </Box>
              <Box sx={{ width: { xs: "100%", sm: "50%" } }}>
                <LabelInputLeftIcon
                  mailIcon={mailImage}
                  type="email"
                  label={t("SuperAdminSignUp.inputs.inputLabelEmail")}
                  name="emailAddress"
                  id="emailAddress"
                  placeholder={t("SuperAdminSignUp.inputs.inputTextEmail")}
                  onChange={(e) => setEmail(e.target.value)}
                  error={formErrors.email}
                />
                {formErrors.email && (
                  <Typography variant="body2" color="error">
                    {formErrors.email}
                  </Typography>
                )}
              </Box>
            </Stack>
            <Stack
              sx={{ mt: 1 }}
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 0, sm: 2 }}
            >
              <Box sx={{ width: { xs: "100%", sm: "50%" } }}>
                <PasswordShowHideIcon
                  type="text"
                  label={t("SuperAdminSignUp.inputs.inputLabelNewPass")}
                  name="Password"
                  id="Password"
                  placeholder={t("SuperAdminSignUp.inputs.inputTextNewPass")}
                  onChange={(e) => setPassword(e.target.value)}
                  error={formErrors.password}
                />
                {formErrors.password && (
                  <Typography variant="body2" color="error">
                    {formErrors.password}
                  </Typography>
                )}
              </Box>
              <Box sx={{ width: { xs: "100%", sm: "50%" } }}>
                <PasswordShowHideIcon
                  type="text"
                  label={t("SuperAdminSignUp.inputs.inputLabelConfirmPass")}
                  name="confirmpassword"
                  id="confirmpassword"
                  placeholder={t(
                    "SuperAdminSignUp.inputs.inputTextConfirmPass"
                  )}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  error={formErrors.confirm_password}
                />
                {formErrors.confirm_password && (
                  <Typography variant="body2" color="error">
                    {formErrors.confirm_password}
                  </Typography>
                )}
              </Box>
            </Stack>
          </Fragment>
        );
    }
  };

  const renderContent = () => {
    return (
      <form>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
            margin: "auto",
          }}
        >
          <Box
            sx={{
              background: "#ffffff",
              borderRadius: 1,
              p: { xs: 2, sm: 4 },
              my: 2,
              width: { xs: "90%", sm: "500px" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <img src={logo} alt="logo" />
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography
                sx={{
                  fontFamily: "Poppins-SemiBold",
                  fontSize: "24px",
                  lineHeight: "35px",
                  textAlign: "center",
                  color: "#1C212D",
                }}
              >
                {t("SuperAdminSignUp.header.title")}
              </Typography>
              <Box sx={{ textAlign: "center", mt: -1 }}>
                <img src={underLine} alt="under line" />
              </Box>
              <Box sx={{ width: { xs: "100%", sm: "40%" }, mx: "auto" }}>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontFamily: "Poppins-Medium",
                    fontSize: 16,
                    lineHeight: "25px",
                  }}
                >
                  {t("SuperAdminSignUp.header.subtitle")}
                </Typography>
              </Box>
            </Box>
            {getStepContent(activeStep)}
            <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
              <Button
                size="large"
                variant="contained"
                onClick={handleNext}
                sx={{
                  backgroundColor: "primary.main",
                  py: 1,
                  px: { xs: 4, sm: 5 },
                  fontSize: "18px",
                  fontFamily: "Gilroy-Medium",
                }}
              >
                {activeStep === steps.length - 1
                  ? t("Signup.header.title")
                  : t("SuperAdminSignUp.buttons.buttonTextContinue")}
              </Button>
            </Box>
            {activeStep === steps.length - 1 ? null : (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flex: "row",
                    mt: 2,
                  }}
                >
                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      color: "#000000",
                      fontFamily: "Gilroy-Medium",
                      fontSize: "16px",
                    }}
                  >
                    {t("SuperAdminSignUp.buttons.textBelowContinueButton")}{" "}
                    <Link
                      to="/Login"
                      style={{
                        color: "#4165F6",
                        textDecoration: "none",
                        marginLeft: "5px",
                      }}
                    >
                      {t("SuperAdminSignUp.buttons.LoginLink")}
                    </Link>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flex: "row",
                    mt: 2,
                  }}
                >
                  <Link
                    to="/GuestUser"
                    style={{
                      color: "rgba(33, 33, 33, 0.5)",
                      fontFamily: "Gilroy-Medium",
                      fontSize: "16px",
                      display: "flex",
                      justifyContent: "center",
                      textDecoration: "underline",
                    }}
                  >
                    {t("SuperAdminSignUp.buttons.guestLoginLink")}
                  </Link>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </form>
    );
  };

  return (
    <>
      <Box
        sx={{
          background: "#fff",
        }}
        id="particles-js"
      >
        <Grid container>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                width: "100%",
              }}
            >
              {renderContent()}
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              backgroundImage: `url(${LoginImage})`,
            }}
          >
            <AuthLeftScreen text={t("Signup.buttons.Login")} url="/Login" />
          </Grid>
        </Grid>
      </Box>
      {showAlert && <AlertModal text={showtext} onClose={handleCloseAlert} />}
    </>
  );
};

export default SuperAdminSignUp;
