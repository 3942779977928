import { Grid, Typography, Box, Button } from "@mui/material";
import React from "react";
import underLine from "../../assets/images/under-line.png";
import downloadIcon from "../../assets/images/downloadIcon.svg";
import fileUpload from "../../assets/images/fileuploadIcon.svg";
import DownloadTemplateModal from "../@common/Modal/DownloadTemplateModal";
import FileUploadModal from "../@common/Modal/FileUploadModal";
import AlertModal from "../@common/Modal/AlertModal";
import UploadErrorModal from "../@common/Modal/UploadErrorModal";
import axios from "axios";
import { useState, useEffect } from "react";
import { getCookiesList } from "../../utils/cookiesHelper";
import { useTranslation } from "react-i18next";
import { call_api_function } from "../../utils/verifyAPICalls";
function BulkUpload() {
  const { t } = useTranslation();
  const [cookieList, setCookieList] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showAlert, setShowAlert] = useState(false); // Add a state to control the visibility of the alert
  const [showtext, setShowtext] = useState("");
  const [hideContent, sethideContent] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState("");

  // Hooks
  useEffect(() => {
    const userCookiesList = getCookiesList("userInfo");
    if (userCookiesList.length > 0) {
      setCookieList(userCookiesList);
    }
  }, []);

  useEffect(() => {
    if (selectedFiles.length > 0) {
      submitFile();
    }
  }, [selectedFiles]);

  // Alert open/close
  const openAlert = (flag, errorMsg) => {
    setShowAlert(flag);
    setShowtext(errorMsg);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  // show/hide lower part of upload files modal box
  const hideContentHandler = (isHide) => {
    sethideContent(isHide);
  };

  // show/hide error modal
  const handleErrorModalOpen = (flag) => {
    setOpenErrorModal(flag);
  };

  const onFileSelection = (files) => {
    // validate and then call submit
    const isValidFiles = Array.from(files).every((file) =>
      file.name.endsWith(".xlsx")
    );
    if (isValidFiles) {
      setSelectedFiles(files);
    } else {
      setSelectedFiles([]);
      openAlert(
        true,
        "Please select an excel file with the extension .xlsx"
      );
    }
  };

  const submitFile = async () => {
    let formData = new FormData();
    formData.append("file", selectedFiles[0], selectedFiles[0].name);
    formData.append("userName", cookieList[0]);
    formData.append("userLastname", cookieList[1]);
    formData.append("userSurlastname", cookieList[2]);
    formData.append("userEmail", cookieList[3]);
    formData.append("userPhone", cookieList[5]);
    formData.append("userId", cookieList[6]);

    try {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL2 + "upload_csv",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data === "Successfully upload") {
        setSelectedFiles([]);
        sethideContent(true);
      } else if (
        response.data.indexOf("CB") >= 0 ||
        response.data.indexOf("SB") >= 0
      ) {
        openAlert(true, t("thanks.dearuser"));
      } else if (response.data.indexOf("[") >= 0) {
        setErrorModalMessage(response.data);
        handleErrorModalOpen(true);
      }
    } catch (error) {
      setSelectedFiles([]);
      console.log(error);
    }
  };

  const downloadDocument = async () => {
    try {
      const fileUrl =
        "https://searchlook-new.s3.us-east-1.amazonaws.com/compartir/plantilla_carga_masiva_2.xlsx";
      const response = await axios.get(fileUrl, { responseType: "blob" });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "plantilla_carga_masiva_2.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {showAlert && <AlertModal text={showtext} onClose={handleCloseAlert} />}
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12}>
          <Box sx={{ mt: 2 }}>
            <Typography
              sx={{
                fontFamily: "Poppins-SemiBold",
                fontSize: "24px",
                lineHeight: "35px",
                textAlign: "center",
                color: "#1C212D",
              }}
            >
              {t("PageName.Bulk")}
            </Typography>
            <Box sx={{ textAlign: "center", mt: -1 }}>
              <img src={underLine} alt="under line" />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: 4,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={1}></Grid>
          <Grid item xs={12} sm={10}>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "column", lg: "row" },
                backgroundColor: "#ffffff",
                py: 8,
                px: 4,
                borderRadius: 1,
              }}
            >
              <Box
                sx={{
                  width: { xs: "100%", sm: "100%", lg: "40%" },
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: 22,
                    fontFamily: "Gilroy-SemiBold",
                    color: "neutral.900",
                    mb: 4,
                  }}
                  component={"h4"}
                >
                  {t("BulkUpload.subtitles")}
                </Typography>

                {/* <Button
                            sx={{
                                backgroundColor:"#4165F6",
                                color:"#ffffff",
                                borderRadius:"10px",
                                borderWidth:1,
                                borderStyle:"solid",
                                borderColor:"#4165F6",
                                width:{xs:"100%",sm:"228px"},
                                "&:hover":{
                                    backgroundColor:"rgba(65, 101, 246, 0.5)"
                                }
                            }}
                             variant="text">
                                <Box sx={{mx:"auto",width:"90%",py:2}}>
                                    <Box sx={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                        <img width={72} height={72} src={downloadIcon} alt="download icon" />
                                    </Box>
                                    <Typography sx={{fontSize:18, fontFamily:"Gilroy-Medium",color:"#ffffff",textAlign:"center",mt:3}}>
                                        Download Template
                                    </Typography>
                                </Box>
                            </Button> */}

                {/****************************** DownloadTemplateModal Shwo ************************/}
                <DownloadTemplateModal downloadFile={downloadDocument} />

                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: 16,
                    fontFamily: "Gilroy-SemiBold",
                    color: "neutral.900",
                    mt: 4,
                    minHeight: 100,
                  }}
                  component={"h4"}
                >
                  {t("BulkUpload.textBelowDownloadButton")}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "10%",
                  display: { xs: "none", sm: "none", lg: "flex" },
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <hr
                  style={{
                    borderLeft: "dashed 1px rgba(27, 32, 44, 0.2)",
                    margin: "6px auto",
                    height: "300px",
                    width: "1px",
                  }}
                />
              </Box>
              <Box
                sx={{
                  width: { xs: "100%", sm: "100%", lg: "40%" },
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: 22,
                    fontFamily: "Gilroy-SemiBold",
                    color: "neutral.900",
                    mb: 4,
                  }}
                  component={"h4"}
                >
                  {t("BulkUpload.Step 2")}
                </Typography>

                {/* <Button
                            sx={{
                                backgroundColor:"#fff",
                                color:"#ffffff",
                                borderRadius:"10px",
                                borderWidth:1,
                                borderStyle:"solid",
                                borderColor:"#4165F6",
                                width:{xs:"100%",sm:"228px"}
                            }}
                             variant="text">
                                <Box sx={{mx:"auto",width:"90%",py:2}}>
                                    <Box sx={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                        <img width={72} height={72} src={fileUpload} alt="file upload icon" />
                                    </Box>
                                    <Typography sx={{fontSize:18, fontFamily:"Gilroy-Medium",color:"neutral.900",textAlign:"center",mt:3}}>
                                    Upload File <br />For Mass Query
                                    </Typography>
                                </Box>
                            </Button> */}

                {/****************************** DownloadTemplateModal Shwo ************************/}
                <FileUploadModal
                  onFileSelection={onFileSelection}
                  onSubmit={submitFile}
                  hideContent={hideContent}
                  hideContentHandler={hideContentHandler}
                  openAlert={openAlert}
                />
                <UploadErrorModal
                  isOpen={openErrorModal}
                  handleOpen={handleErrorModalOpen}
                  errorMessage={errorModalMessage}
                />

                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: 16,
                    fontFamily: "Gilroy-SemiBold",
                    color: "neutral.900",
                    mt: 4,
                    minHeight: 100,
                  }}
                  component={"h4"}
                >
                  {t("BulkUpload.textBelowUploadButton")}
                </Typography>
              </Box>
              {/****************************** Upload Error File Modal Show ************************/}{" "}
            </Box>

            {/* <UploadErrorModal/> */}

            {/****************************** Upload Error File Modal Show ************************/}
          </Grid>
          <Grid item xs={12} sm={1}></Grid>
        </Grid>
      </Box>
    </>
  );
}

export default BulkUpload;
