import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { Fragment, useState, useEffect } from "react";
import logo from "../../assets/images/logo.png";
import Button from "@mui/material/Button";
import Footer from "../../Footer/FooterTwo";
import bgImage from "../../assets/images/coverphoto.png";
import imgEng from "../../assets/images/Eng.svg";
import imgspa from "../../assets/images/Spanish.svg";
import AnimationLoginScreen from "../@common/AnimationLoginScreen/AnimationLoginScreen";
import ScrollableTabs from "../ScrollableTabs/ScrollableTabs";
import { Link, useNavigate } from "react-router-dom";
import EmptyScreen from "../LoginScreen/EmptyScreen";
import { useTranslation } from "react-i18next";
import LoginBgImage from "../../assets/images/login.jpeg";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

function LoginScreen() {
  const { t, i18n } = useTranslation();
  const [showEmptyScreen, setShowEmptyScreen] = useState(false);
  const storedLanguage = localStorage.getItem("selectedLanguage");
  const [language, setLanguage] = useState(storedLanguage);

  const lngs = {
    en: { nativeName: t("name.English"), code: "en" },
    es: { nativeName: t("name.Spanish"), code: "es" },
  };

  const navigate = useNavigate();
  const loginHanler = () => {
    navigate("/Login");
  };
  const signUpHanler = () => {
    navigate("/Signup");
  };
  const GuestUserHandler = () => {
    navigate("/GuestUser");
  };
  useEffect(() => {}, [showEmptyScreen]);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setShowEmptyScreen(true);
    setValue(newValue);
  };
  // Function to handle language change
  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
    localStorage.setItem("selectedLanguage", newLanguage);
  };
  return (
    <Fragment>
      <Stack direction={{ xs: "column", md: "row" }}>
        <Box
          sx={{
            width: { xs: "100%", md: "60%" },
            backgroundColor: "#FAFCFF",
            zIndex: 2,
            p: 2,
          }}
        >
          <Stack
            direction={{ xs: "column-reverse", sm: "row" }}
            alignItems="center"
            justifyContent="space-between"
            rowGap={1}
          >
            <Typography
              sx={{
                color: "neutral.900",
                fontFamily: "Poppins-Bold",
                fontSize: { xs: 18, sm: 28 },
                lineHeight: { xs: "28px", sm: "42px" },
              }}
            >
              {t("landingpage.header.title1")}
              <span style={{ color: "#4165F6" }}>
                {" "}
                {t("landingpage.header.title2")}
              </span>{" "}
              {t("landingpage.header.title3")}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                textAlign: "right",
              }}
            >
              <FormControl sx={{ mt: 0, minWidth: 80 }} size="small">
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={language}
                  displayEmpty
                  onChange={handleLanguageChange}
                  sx={{
                    padding: "7px 10px",
                    display: "flex",
                    alignItems: "center",
                    "& .MuiSelect-select": {
                      display: "flex !important",
                      alignItems: "center !important",
                    },
                    "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputSizeSmall.css-17ayv1u-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                      {
                        display: "flex",
                        alignItems: "center !important",
                      },
                  }}
                >
                  {Object.keys(lngs).map((lng) => (
                    <MenuItem key={lngs[lng].code} value={lngs[lng].code}>
                      <img
                        src={lng === "en" ? imgEng : imgspa}
                        alt={lngs[lng].nativeName}
                        width="30"
                        height="25"
                        style={{ marginRight: "10px" }}
                      />
                      {lng === "en" ? lngs[lng].nativeName : "Español"}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Stack>
          <Typography
            sx={{
              color: "rgba(33, 33, 33, 0.6)",
              fontFamily: "Gilroy-SemiBold",
              fontSize: 16,
            }}
          >
            {t("landingpage.header.subtitle")}
          </Typography>

          <>
            <ScrollableTabs value={value} handleChange={handleChange} />
            {/* {showEmptyScreen ? null: <EmptyScreen />} */}
          </>
        </Box>
        <Box
          sx={{
            width: { xs: "100%", md: "40%" },
            maxWidth: "100%",
            position: "relative",
            minHeight: "100vh",
            backgroundImage: `url(${LoginBgImage})`,
            bgcolor: "#006BFF",
            backgroundRepeat: "repeat",
          }}
        >
          <Box
            sx={{
              minHeight: "100vh",
              background: "transparent",
            }}
            id="particles-js"
          >
            <Box
              sx={{
                width: "100%",
                minHeight: "100vh",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "100vh",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    background: "#ffffff",
                    position: "relative",
                    // zIndex: 1,
                    borderRadius: 1,
                    p: { xs: 2, sm: 4 },
                    width: 360,
                    maxWidth: "80%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <img src={logo} alt="logo" />
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "row", mt: 6 }}>
                    <Button
                      onClick={loginHanler}
                      sx={{
                        backgroundColor: "primary.main",
                        fontSize: "18px",
                        width: "100%",
                        fontFamily: "Gilroy-Medium",
                        textTransform: "capitalize !important",
                      }}
                      variant="contained"
                    >
                      {t("landingpage.rightContent.buttons.Login")}
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      mt: 3,
                    }}
                  >
                    <Button
                      onClick={signUpHanler}
                      sx={{
                        borderColor: "primary.main",
                        color: "primary.main",
                        width: "100%",
                        fontSize: "18px",
                        fontFamily: "Gilroy-Medium",
                        textTransform: "capitalize !important",
                      }}
                      variant="outlined"
                    >
                      {t("landingpage.rightContent.buttons.Create Account")}
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      mt: 3,
                    }}
                  >
                    <Link
                      onClick={GuestUserHandler}
                      to="/GuestUser"
                      style={{
                        color: "#4165F6",
                        fontSize: 18,
                        textTransform: "capitalize",
                        fontFamily: "Gilroy-Medium",
                      }}
                    >
                      {t("landingpage.rightContent.buttons.Login as Guest")}
                    </Link>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  // display: "flex",
                  // flexDirection: "row",
                  // justifyContent: "flex-end",
                  position: "absolute",

                  bottom: "22%",
                  "@media (max-width: 500px)": {
                    bottom: "25%",
                  },
                  zIndex: 9,
                  right: "10%",
                }}
              >
                <Link
                  to="/aidVideo"
                  style={{
                    backgroundColor: "#fff",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    height: 42,
                    width: 42,
                    borderRadius: 50,
                    alignItems: "center",
                    boxShadow: 10,
                  }}
                >
                  <QuestionMarkIcon sx={{ color: "primary.light" }} />
                </Link>
              </Box>
            </Box>
          </Box>
          <Box sx={{ minWidth: "100%" }}>
            <Footer />
          </Box>
        </Box>
      </Stack>
    </Fragment>
  );
}

export default LoginScreen;
