// src/msalConfig.js
export const msalConfig = {
    auth: {
        clientId: 'e26ffc74-bbae-4d01-acd7-dc34389f8fa5',
        authority:
            'https://login.microsoftonline.com/consumers',
        redirectUri: "/login",
        postLogoutRedirectUri: "/login",
        navigateToLoginRequestUrl: true,
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
    },
    windowHashTimeout: 60000,
    iframeHashTimeout: 6000,
    loadFrameTimeout: 0,
};
