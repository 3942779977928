import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Fragment } from "react";
import { textAlign } from "@mui/system";
import { Link, useNavigate } from "react-router-dom";
import sucessImage from "../../../assets/images/successfull-email.png";
import downloadIcon from "../../../assets/images/downloadIcon.svg";
import successIcon from "../../../assets/images/downloadSucessIcon.svg";
import { useTranslation } from "react-i18next";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 420,
  maxWidth: "95%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
};

const subjectList = ["The present subject is found"];

export default function DownloadTemplateModal(props) {
  const { text = "" } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const { downloadFile } = props;
  const sendHandler = () => {
    alert("sendHandler");
  };
  const handleOpen = () => {
    downloadFile();
    setOpen(true);
  };

  return (
    <Fragment>
      <Button
        onClick={handleOpen}
        sx={{
          backgroundColor: "#4165F6",
          color: "#ffffff",
          borderRadius: "10px",
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: "#4165F6",
          width: { xs: "100%", sm: "228px", height: "202px" },
          "&:hover": {
            backgroundColor: "rgba(65, 101, 246, 0.5)",
          },
        }}
        variant="text"
      >
        <Box sx={{ mx: "auto", width: "90%", py: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              width={72}
              height={72}
              src={downloadIcon}
              alt="download icon"
            />
          </Box>
          <Typography
            sx={{
              fontSize: 18,
              fontFamily: "Gilroy-Medium",
              color: "#ffffff",
              textAlign: "center",
              mt: 3,
            }}
          >
            {t("BulkUpload.textDownloadButton")}
          </Typography>
        </Box>
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={successIcon}
              width="100px"
              height="92px"
              alt="email icon"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 4,
              flexDirection: "column",
            }}
          >
            <Typography
              component={"h6"}
              sx={{
                color: "neutral.900",
                fontSize: 20,
                fontFamily: "Gilroy-SemiBold",
                textAlign: "center",
              }}
            >
              {t("BulkUpload.popUpTextDownloadButton")}
              <Box component={"span"} sx={{ color: "primary.main" }}>
                {" "}
                {t("BulkUpload.successfully")}!
              </Box>
            </Typography>

            <Typography
              sx={{
                fontSize: 16,
                fontFamily: "Gilroy-Medium",
                color: "rgba(27, 32, 44, 0.6)",
                textAlign: "center",
                mt: 2,
              }}
            >
              {text}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              mt: 1,
            }}
          >
            <Button
              onClick={handleClose}
              style={{
                color: "rgba(27, 32, 44, 0.6)",
                fontSize: 16,
                fontFamily: "Gilroy-Medium",
                textDecoration: "underline",
                textTransform: "capitalize",
              }}
              variant="text"
            >
              {t("buttons.back")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Fragment>
  );
}
