import { phoneNumberPattern, namePattern, strongPasswordPattern } from './InputPatterns';

export function validateInput(inputValue, pattern, errorMessage, errors,t) {
    if (inputValue === null || inputValue === "" || inputValue.trim() === "") {
      errors[errorMessage] = errorMessage==="company_address"? t("SuperAdminSignUp.inputs.inputTextCompanyAddress")   :t("Login.popup.inputEmailRedMessageEnterTheEmail");
    } else if (pattern && !pattern.test(inputValue)) {
      errors[errorMessage] =
        errorMessage === "email" ? t("Login.popup.inputEmailRedMessageWrongFormat") :  t("Login.popup.inputEmailRedMessageWrongFormat");
    }  
    else {
      delete errors[errorMessage];
    }
  }
  export function validateInputCoupon(inputValue, pattern, errorMessage, errors,t) {
    if (inputValue === null || inputValue === "" || inputValue.trim() === "") {
      errors[errorMessage] =  t("GuestUser.popup.inputCouponRedMessageEnterTheCoupon");
    } 
  }
  
  export function validatePhoneNumber(inputValue, errorMessage, errors, t) {
    if (inputValue === "" || inputValue.trim() === "") {
      errors[errorMessage] = t("SuperAdminSignUp.inputs.inputphoneRedMessageEnterThephone");
    } else if (!phoneNumberPattern.test(inputValue)) {
      errors[errorMessage] = t("SuperAdminSignUp.inputs.contactInputTelRedText");
    } else {
      delete errors[errorMessage];
    }
  }
  
  export function validateName(inputValue, errorMessage, errors, requireCapital = false,t) {
    if (inputValue === "" || inputValue.trim() === "") {
      errors[errorMessage] = errorMessage === "name" ? t("UserSignup.inputNameRedMessageEnterTheName") : errorMessage === "last_name" ? t("UserSignup.inputNameRedMessageEnterTheLastName"):errorMessage === "mother_last_name" ? t("UserSignup.inputNameRedMessageEnterTheMotherLastName"):null;
    } else if (!namePattern.test(inputValue)) {
    } else if (!namePattern.test(inputValue)) {
      errors[errorMessage] = requireCapital
        ? errorMessage === "name"
          ? t("UserSignup.inputNameRedMessageEnterTheName")
          : errorMessage === "last_name"
          ? t("UserSignup.inputNameRedMessageEnterTheLastName")
          : errorMessage === "mother_last_name"
          ? t("UserSignup.inputNameRedMessageEnterTheMotherLastName")
          : null
        : null;
    }
     else {
      delete errors[errorMessage];
    }
  }
  export function validateConfirmPassword(password, confirm_password,t) {
    if (confirm_password === "" || confirm_password.trim() === "") {
      return { error: true, message:t("UserSignup.inputPasswordRedMessageEnterThePass")};
    }
  
    if (password !== confirm_password) {
      return { error: true, message:t("UserSignup.inputPasswordRedMessageEnterTheConfirmPass")};
    }
  
    return { error: false, message: null };
  }
  export function validatePassword(password,t) {
    const errors = {};
  
    if (password === "" || password.trim() === "") {
      errors.password = t("UserSignup.inputPasswordRedMessageEnterThePass")
    } else if (password.length < 8) {
      errors.password =t("UserSignup.passwordInputRedText")
    } else if (!strongPasswordPattern.test(password)) {
      errors.password =
      t("UserSignup.inputPasswordRedMessageWrongFormat")
    }
  
    return errors;
  }

  export function isValidOTP(otp) {
    return otp.length === 6 && /^\d+$/.test(otp);
  }
    