import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import videoIcon from "../../assets/images/profile_Icon.svg"
import emailIcon from "../../assets/images/profile-emailIcon.svg"
import phoneIcon from "../../assets/images/profile-phoneIcon.svg"
import Button from '@mui/material/Button';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import img from "../../assets/images/avatar.svg"
import { useTranslation } from 'react-i18next';
function CompanyInformationCard(props) {
  const { t } = useTranslation();
    const { titleMain="",line=true,titleMainTwo="",subTitle="",emailText="",phone="",onClick,mainImage ,isAdminButtonDisabled} = props
   
  return (
    <Box>
    <Grid container spacing={2}>
       <Grid item xs={12} sm={1}></Grid>
       <Grid item xs={12} sm={10}>
        <Box
        sx={{
            display:"flex",
            backgroundColor:"#ffffff",
            flexDirection:{xs:"column",sm:"column",lg:"row"},
            p:2,
            borderRadius:1,
            width:"100%",
            justifyContent:"space-between",
            boxShadow:4
        }}
        >
        <Box sx={{
            display:"flex",
            flexDirection:{xs:"column",sm:"column",lg:"row"},
            justifyContent:{xs:"center",sm:"center",lg:"flex-start"},

            alignItems:{xs:"center",sm:"center",lg:"flex-start"}
            // backgroundColor:"red"
            }}>
       <Box>
      {/* Conditional rendering for the mainImage */}
      {mainImage ? (
        <img width={"143px"} height={"143px"} src={mainImage} alt='Main Image' />
      ) : (
        <img width={"143px"} height={"143px"} src={img} alt='Placeholder Image' />
      )}
    </Box>
        <Box 
        sx={{
            display:"flex",
            flexDirection:"column",
            ml:{xs:0,sm:0,lg:3}
        }}>
            <Box sx={{display:"flex",flexDirection:{xs:"column",sm:"column",lg:"row"},justifyContent:{xs:"center",sm:"center",lg:"flex-start"},}}>
                <Typography sx={{fontFamily:"Gilroy-Bold",fontSize:22,color:"neutral.900",mr:2}} component={"h4"}>{titleMain}</Typography>
                {/* {line ? (
              <hr
              style={{
                  border:"solid 1px #FF8D00",
                  
              }}
               />
            ) : null} */}
                {/* <Typography sx={{fontFamily:"Gilroy-Bold",fontSize:{xs:16,sm:18,lg:22},color:"neutral.900",ml:2}} component={"h4"}>{titleMainTwo}</Typography> */}
            </Box>
            <Typography sx={{fontFamily:"Gilroy-Medium",fontSize:{xs:14,sm:16,lg:20},color:"neutral.900"}}>{subTitle}</Typography>
            <Box sx={{display:"flex",flexDirection:"row",mt:2}}>
            <img width={"30px"} height={"30px"} src={emailIcon} alt='image not found' />
            <Typography sx={{fontFamily:"Gilroy-Medium",fontSize:{xs:14,sm:16,lg:20},color:"neutral.900",ml:2}}>{emailText}</Typography>
            </Box>
            <Box sx={{display:"flex",flexDirection:"row",mt:1}}>
            <img width={"30px"} height={"30px"} src={phoneIcon} alt='image not found' />
            <Typography sx={{fontFamily:"Gilroy-Medium",fontSize:{xs:14,sm:16,lg:20},color:"neutral.900",ml:2}}>{phone}</Typography>
            </Box>
        </Box>
        </Box>
        <Box sx={{textAlign:"right",justifyContent:"end"}}>
        <Button disabled={isAdminButtonDisabled}  onClick={onClick} variant="contained" sx={{backgroundColor:"rgba(65, 101, 246, 0.15)", color:"#4165F6", fontSize:13,fontFamily:"Gilroy-Medium",textTransform:"capitalize"}} startIcon={<BorderColorIcon />}>
           {t("BulkUpload.Edit")}
        </Button>

        </Box>
        </Box>
       </Grid>
       <Grid item xs={12} sm={1}></Grid>
    </Grid>
    </Box>
  )
}

export default CompanyInformationCard