import { Grid, Typography, Box } from "@mui/material";
import React from "react";
import underLine from "../../assets/images/under-line.png";
import ReportHeader from "../@common/ReportHeader/ReportHeader";
import AccordainTable from "../@common/AccordainTable/AccordainTable";
import LaborReferencesForm from "./LaborReferencesForm";
import { useTranslation } from "react-i18next";

function LaborReferences() {
  const { t } = useTranslation();
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Box sx={{ mt: 2 }}>
            <Typography
              sx={{
                fontFamily: "Poppins-SemiBold",
                fontSize: "24px",
                lineHeight: "35px",
                textAlign: "center",
                color: "#1C212D",
              }}
            >
              {t("PageName.Labor References")}
            </Typography>
            <Box sx={{ textAlign: "center", mt: -1 }}>
              <img src={underLine} alt="under line" />
            </Box>
          </Box>
        </Grid>
        {/* <Grid item xs={12} sm={12}>
            <ReportHeader/>
          </Grid> */}
      </Grid>
      <Box
        sx={{
          // backgroundColor:"red",
          // width:{sx:"100%",sm:"1145px"},
          // maxWidth:"100%",
          display: "flex",
          flexDirection: "column",
          mt: 4,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={2}></Grid>
          <Grid item xs={12} sm={8}>
            <LaborReferencesForm />
          </Grid>
          <Grid item xs={12} sm={2}></Grid>
        </Grid>
      </Box>
    </>
  );
}

export default LaborReferences;
