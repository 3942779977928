import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Checkbox,Typography } from '@mui/material';

export default function RadioButtonsGroup(props) {

    const { topLabelName="Gender",reasonsGrouping = [], topLabel = false, sx_radio = {}, onChange , disabled = false, reasonSelect } = props
    
    return (

        <FormControl>
            {topLabel ? <FormLabel
              sx={{
                fontSize:16,
                fontFamily: "Gilroy-Medium",
                color: "rgba(33, 33, 33, 0.6) !important",
                pb:"10px"
              }} 
             id="demo-radio-buttons-group-label">{topLabelName}</FormLabel> : null}
                {reasonsGrouping.map((item, index) => (
                    <FormControlLabel disabled={disabled} key={index} value={item.value} checked={reasonSelect == item.value  ? true : false} control={<Checkbox sx={sx_radio} />} label={item.label}
                        onChange={onChange}
                    />
                ))}
        </FormControl>
    );
}
