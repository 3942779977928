import { Box, Typography } from '@mui/material'
import React from 'react'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { python2Content, python3Content } from '../../utils/optionsEdo'
import { useTranslation } from 'react-i18next';
function APIeftSide() {
    const { t } = useTranslation();
  return (
    <>
   <Box>
    <Box 
    sx={{
        backgroundColor:"rgba(65, 101, 246, 0.1)",
        p:1,
        display:"flex",
        flexDirection:"row",
        justifyContent:"start",
        alignItems:"center",
        borderRadius:"4px"
    }}>
        <Box sx={{fontSize:22,fontFamily:"Gilroy-SemiBold",color:"primary.main"}} component={"span"}>Python2</Box>
        <NavigateNextIcon color="primary.main" sx={{color:"primary.main"}} />
        <Box sx={{fontSize:22,fontFamily:"Gilroy-SemiBold",color:"neutral.900"}} component={"span"}>{t("api.Tittle1")}</Box>
    </Box>
        <Box
       sx={{
        mt:2,
        mb:3,
        px:6,
        height:"50vh",
        overflow:"auto"
       }}
        >
            <Typography
            sx={{
                fontSize:16,
                fontFamily:"Gilroy-Medium",
                color:"text.opacity",
            }}
            >
                <pre>{python2Content}</pre>
            </Typography>
        
        </Box>
   </Box>
   <Box>
    <Box 
    sx={{
        backgroundColor:"rgba(65, 101, 246, 0.1)",
        p:1,
        display:"flex",
        flexDirection:"row",
        justifyContent:"start",
        alignItems:"center",
        borderRadius:"4px"
    }}>
        <Box sx={{fontSize:22,fontFamily:"Gilroy-SemiBold",color:"primary.main"}} component={"span"}>Python3</Box>
        <NavigateNextIcon color="primary.main" sx={{color:"primary.main"}} />
        <Box sx={{fontSize:22,fontFamily:"Gilroy-SemiBold",color:"neutral.900"}} component={"span"}>{t("api.Subittle1")}</Box>
    </Box>
        <Box
       sx={{
        mt:2,
        mb:3,
        px:6,
        height:"50vh",
        overflow:"auto"
       }}
        >
            <Typography
            // key={index}
            sx={{
                fontSize:16,
                fontFamily:"Gilroy-Medium",
                color:"text.opacity",
            }}
            >
                <pre>{python3Content}</pre>
            </Typography>
    
        </Box>
   </Box>
   </>
  )
}

export default APIeftSide