import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { getCookiesList } from '../../../utils/cookiesHelper';

export const GuestProtectedRoute = ({ children }) => {
    const location = useLocation();
    const guestCookiesList = getCookiesList('guestUserInfo');
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const checkGuestIsAuthenticated = async () => {
        if(typeof guestCookiesList != 'object' || guestCookiesList.length != 0 || guestCookiesList[3] != undefined || guestCookiesList [4] != undefined){
            return true
        }
        return false
    }

    useEffect(() => {
        const fetchData = async () => {
            const isAuthenticated = await checkGuestIsAuthenticated();
            setIsAuthenticated(isAuthenticated);
            setIsLoading(false);
        };

        fetchData();
    }, [location]);

    if (isLoading) {
        return null;
    }
    if (isAuthenticated) {
        return <Outlet />;
    } else {
        return <Navigate to="/Login" replace />;
    }
};
