export const oktaConfig = {
    clientId: '0oakcb59w1zXkKPVJ2p7',
    issuer: 'https://jabil.okta.com',
    redirectUri: 'https://jabil.searchlook.mx/login/callback',
    scopes: ['openid', 'profile', 'email','address','phone','offline_access','groups'],
    pkce: true,
    testing: {
      disableHttpsCheck: ''
    },
    tokenManager: {
      storage: 'localStorage'
    }
};