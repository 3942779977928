import React from "react";
import "./InputRightIcon.css";
import mailImage from "../../../../assets/images/mailIcon.png";
function InputRightIcon(props) {
  const {
    
    mailIcon = { mailImage },
    label,
    type = "text",
    inputValue,
    placeholder,
    name = "name",
    onChange,
    errorMsg = "errorMsg",
    flag = false,
    containerStyle,
    readOnly = false,
    maxLength,
    disabled,
    inputStyle,
    inputContainerStyle,
    id = "input",
    star,
    onBlur,
    onFocus,
    mandatory,
    FilledOut,
    isValid=true
  } = props;

  return (
    <div className="li_container" style={containerStyle}>
      {label && (
        <label htmlFor="" className="li_label">
          {label}
          <span style={{ color: "red" }}>{star}</span>
        </label>
      )}
      <div className="li_input_container" style={inputContainerStyle}>
        <div
          className="container_box"
          style={{
            backgroundColor: disabled ? 
              "rgba(100,100,100,0.5)": FilledOut ? 
              null : (!isValid ? 
              "rgba(255,140, 0, 0.1)" :
              inputValue ? 
              null : mandatory ? 
              "rgba(255,140, 0, 0.1)" : (inputValue === "" && "rgba(65,101,246,.06)")),
          }}
        >
          <input
            id={id}
            value={inputValue}
            className="li_input"
            type={type}
            onBlur={onBlur}
            onFocus={onFocus}
            placeholder={placeholder}
            name={name}
            onChange={onChange}
            readOnly={readOnly}
            maxLength={maxLength}
            disabled={disabled}
            style={{ cursor: disabled ? "not-allowed" : "text" }}
          />
          { mailIcon &&  <div className="icon_box_1">
            <img src={mailIcon} alt="mail icon" width={18} />
          </div>
          }
        </div>
        <p
          className="li_error_text"
          style={{ display: flag ? "block" : "none" }}
        >
          {errorMsg}
        </p>
      </div>
    </div>
  );
}

export default InputRightIcon;
