import { Grid, Typography, Box, Button } from "@mui/material";
import React from "react";
import underLine from "../../assets/images/under-line.png";
import downloadIcon from "../../assets/images/downloadIcon.svg";
import fileUpload from "../../assets/images/fileuploadIcon.svg";
import DownloadTemplateModal from "../@common/Modal/DownloadTemplateModal";
import FileUploadModal from "../@common/Modal/FileUploadModal";
import UploadErrorModal from "../@common/Modal/UploadErrorModal";
import MenuCard from "../@common/Cards/MenuCard";
import superAdmin from "../../assets/images/super-admin.svg";
import userAdmin from "../../assets/images/user-admin.svg";
import authorizeUser from "../../assets/images/authorize-user.svg";
import editAccount from "../../assets/images/editAccount.svg";
import consult from "../../assets/images/consult.svg";
import adminChange from "../../assets/images/adminChange.svg";
import changeRole from "../../assets/images/changeRole.svg";
import coupon from "../../assets/images/assignCoupon.svg";
import deleteUser from "../../assets/images/deleteUser.svg";
import { useTranslation } from "react-i18next";

function AidPage() {
  const { t } = useTranslation();
  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12}>
          <Box sx={{ mt: 2 }}>
            <Typography
              sx={{
                fontFamily: "Poppins-SemiBold",
                fontSize: "24px",
                lineHeight: "35px",
                textAlign: "center",
                color: "#1C212D",
              }}
            >
              {t("PageName.AID")}
            </Typography>
            <Box sx={{ textAlign: "center", mt: -1 }}>
              <img src={underLine} alt="under line" />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: 4,
        }}
      >
        <Grid container>
          <Grid item xs={12} sm={1}></Grid>
          <Grid item xs={12} sm={10}>
            <Box
              sx={{
                p: 4,
                backgroundColor: "#ffffff",
                borderRadius: 1,
                mb: 4,
              }}
            >
              <Grid container spacing={6}>
                <Grid item xs={12} sm={6} lg={4}>
                  <MenuCard
                    img={superAdmin}
                    link="/SuperAdminSignUp"
                    title={t("AIDPage.SuperAdmin")}
                    Subtitle={t("AIDPage.Registration")}
                    videoId="V6HDVrgYxxE"
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <MenuCard
                    img={userAdmin}
                    link="/UserSignup"
                    title={t("AIDPage.User")}
                    Subtitle={t("AIDPage.Registration")}
                    videoId="P1g2Id2ysNg"
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <MenuCard
                    img={authorizeUser}
                    link="/Signup"
                    title={t("AIDPage.Authorise")}
                    Subtitle={t("AIDPage.User")}
                    videoId="NDXN5Xamzu0"
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <MenuCard
                    img={editAccount}
                    link="/Signup"
                    title={t("AIDPage.Edit")}
                    Subtitle={t("AIDPage.Account")}
                    videoId="bFTxtY3GwZc"
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <MenuCard
                    img={consult}
                    link="/dashboard"
                    title={t("AIDPage.Consult")}
                    Subtitle={t("AIDPage.Subject")}
                    videoId="Bnx-H_972QE"
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <MenuCard
                    img={adminChange}
                    link="/dashboard"
                    title={t("AIDPage.Change")}
                    Subtitle={t("AIDPage.AdminRole")}
                    videoId="KHRobQONXY4"
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <MenuCard
                    img={changeRole}
                    link="/dashboard"
                    title={t("AIDPage.Change")}
                    Subtitle={t("AIDPage.UserRole")}
                    videoId="wrDPJQ2MLH0"
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <MenuCard
                    img={coupon}
                    link="/dashboard"
                    title={t("AIDPage.Assign")}
                    Subtitle={t("AIDPage.Coupons")}
                    videoId="7PK5lXRCffg"
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <MenuCard
                    img={deleteUser}
                    link="/dashboard"
                    title={t("AIDPage.Delete")}
                    Subtitle={t("AIDPage.User")}
                    videoId="ao407Tj3owI"
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={1}></Grid>
        </Grid>
      </Box>
    </>
  );
}

export default AidPage;
