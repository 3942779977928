import { useTranslation } from "react-i18next";
// ** React Imports
import { Fragment, useEffect, useState } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
// ** Third Party Imports
import toast from "react-hot-toast";
import AnimationLoginScreen from "../@common/AnimationLoginScreen/AnimationLoginScreen";
import bgImage from "../../assets/images/coverphoto.png";
import Footer from "../../Footer/Footer";
import logo from "../../assets/images/logo.png";
import underLine from "../../assets/images/under-line.png";
import LabelInputLeftIcon from "../@common/Input/LabelInputLeftIcon";
import PasswordShowHideIcon from "../@common/Input/PasswordShowHideIcon/PasswordShowHideIcon";
import mailImage from "../../assets/images/mailIcon.png";
import profileIcon from "../../assets/images/profileIcon.png";
import phoneIcon from "../../assets/images/phoneIcon.png";
import { Link, Navigate } from "react-router-dom";
import companyImage from "../../assets/images/company-icon.png";
import DropDownInput from "../@common/Input/DropDownInput/DropDownInput";
import { Stack } from "@mui/material";
import sjcl from "sjcl";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { serverRequest } from "../../utils/requestHelper";
import AlertModal from "../@common/Modal/AlertModal";
import NoticePrivacy from "../NoticePrivacy/NoticePrivacy";
import TermsConditionModal from "../TermsConditions/TermsCondition";
import CustomSpinner from "../@common/CustomSpinner/customSpinner";
import EnterEmailModal from "../@common/Modal/EnterEmailModal";
import { emailPattern } from "../../utils/InputPatterns";
import {
  validateInput,
  validatePassword,
  validatePhoneNumber,
  validateName,
  validateConfirmPassword,
} from "../../utils/inputValidation";
import AuthLeftScreen from "../@common/AuthLeftScreen";
import DropDownInputSimple from "../@common/Input/DropDownInputSimple/DropDownInputSimple";
const steps = [
  {
    title: "User Step 1",
  },
  {
    title: "User Step 2",
  },
];

const UserRegistration = () => {
  const { t } = useTranslation();
  // ** States
  const [showAlert, setShowAlert] = useState(false); // Add a state to control the visibility of the alert
  const [showtext, setShowtext] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [last_name, setLastName] = useState("");
  const [mother_last_name, setMotherLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile_number, setMobileNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [belongs_company, setBelongsCompany] = useState("");
  const registration_type = "";
  const [company_zone, setCompanyZone] = useState("");
  const [company_branch, setCompanyBranch] = useState("");
  const [company_address, setCompanyAddress] = useState("");
  const [company_email, setCompanyEmail] = useState("");
  const [company_phone, setCompanyPhone] = useState("");
  let [companies, setListOfCompanies] = useState([]);
  let [data, setListData] = useState([]);
  const [isNoticeCheckboxChecked, setIsNoticeCheckboxChecked] = useState(false);
  const [isTermsCheckboxChecked, setIsTermsCheckboxChecked] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [openTermsModal, setOpenTermsModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [spinnerVisibility, setSpinnerVisibility] = useState(false);
  const [showField, setShowFields] = useState(false);
  const [Zona, setZona] = useState(false);

  const [sucursalData, setSucursal] = useState(false);



  const [formErrors, setFormErrors] = useState({
    selectedCompany: "",
    company_address: "",
    company_email: "",
    company_phone: "",
    name: "",
    last_name: "",
    mother_last_name: "",
    mobile_number: "",
    email: "",
    password: "",
    confirm_password: "",
  });
  var arrayData = [];
  arrayData.push(name);
  arrayData.push(last_name);
  arrayData.push(mother_last_name);
  arrayData.push(email);
  arrayData.push(mobile_number);
  arrayData.push(password);
  arrayData.push(confirm_password);
  arrayData.push(selectedCompany);
  arrayData.push(belongs_company);
  arrayData.push(company_zone);
  arrayData.push(company_branch);
  arrayData.push(company_address);
  arrayData.push(company_email);
  arrayData.push(company_phone);
  if(Zona)
  {
    arrayData.push(Zona)

  }
  if(sucursalData){
    arrayData.push(sucursalData)

  }
  // Handle Stepper
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleCloseAlert = () => {
    setShowAlert(false);
  };
  const list_of_companies = async () => {
    const response = await serverRequest("get", "listado");
    response.data ? setShowFields(true) : setShowFields(false)
    var decrypted = sjcl.decrypt(
      process.env.REACT_APP_MIX_COOK_KEY,
      JSON.stringify(response.data)
    );
    companies = JSON.parse(decrypted);
    console.log(companies, "companies data");
    for (var i = 0; i < companies.length; i++) {
      if (companies[i]["super_admin"] == "1") {
        data[i] = {};
        data[i].name = companies[i].nombre_fiscal_empresa;
        data[i].value = companies[i].nombre_fiscal_empresa;
        data[i].zonas = companies[i].zonas
      }
      setListData(data);
    }
  };
  useEffect(() => {
    list_of_companies();
  }, []);
  // checkbox changes
  const handleCheckboxChange = (e) => {
    setIsNoticeCheckboxChecked(e.target.checked);
  };
  const handleTermsCheckboxChange = (e) => {
    setIsTermsCheckboxChecked(e.target.checked);
  };
  // close privacy and open terms modal
  const handleClosePrivacyModalAndOpenTermsModal = () => {
    setIsNoticeCheckboxChecked(false);
    setOpenUploadModal(false);
    setOpenTermsModal(true);
  };
  // simple close
  const handleJustCloseNoticeModal = () => {
    setIsNoticeCheckboxChecked(false);
    setOpenUploadModal(false);
  };
  const handleJustClosePrivacyModal = () => {
    setIsTermsCheckboxChecked(false);
    setOpenTermsModal(false);
  };
  // close and submit
  const handleCloseTermsModalAndSubmit = () => {
    setIsTermsCheckboxChecked(false);
    setOpenTermsModal(false);
    signUpUser(arrayData);
  };
  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    navigate("/Dashboard");
  };
  const handleNext = () => {
    const errors = {};
    let newError = false
    let newError2 = false

    if (activeStep === 0) {
      validateInput(email, emailPattern, "email", errors, t);
      const passwordErrors = validatePassword(password, t);
      if (Object.keys(passwordErrors).length > 0) {
        errors.password = passwordErrors.password;
      }
      const confirmPasswordValidationResult = validateConfirmPassword(
        password,
        confirm_password,
        t
      );

      if (confirmPasswordValidationResult.error) {
        errors.confirm_password = confirmPasswordValidationResult.message;
      } else {
        delete errors.confirm_password;
      }
    } else if (activeStep === 1) {
      if (selectedCompany === "" || selectedCompany.trim() === "") {
        errors.selectedCompany = t(
          "SuperAdminSignUp.inputs.inputCompanyRedMessageWrongFormat"
        );
      }
      validateName(name, "name", errors, true, t);
      validateName(last_name, "last_name", errors, true, t);
      validateName(mother_last_name, "mother_last_name", errors, true, t);
      validatePhoneNumber(mobile_number, "mobile_number", errors, t);
      if (Object.keys(filteredObject?.zonas || {}).length !== 0) {
        if (!Zona) {
          setZonaError(true);
          newError=true
        } 
      }
      if (branchOption.length>0) {
        if (!sucursalData) {
          setSucursalError(true);
          newError2 = true
        }
      }
    }

    // Set the formErrors state with the new errors
    setFormErrors(errors);
    // If there are no errors, proceed to the next step or submit the form.
    if (!newError&&!newError2 && Object.keys(errors).length === 0) {
      if (activeStep === steps.length - 1) {
        setOpenUploadModal(true);
        // signUpUser(arrayData);
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };
  const signUpUser = async (data) => {
    try {
      
      var dataToRegister = {
        data_ene: sjcl.encrypt(process.env.REACT_APP_MIX_COOK_KEY, data[0], {
          iter: 500,
        }),
        data_eap: sjcl.encrypt(process.env.REACT_APP_MIX_COOK_KEY, data[1], {
          iter: 500,
        }),
        data_eam: sjcl.encrypt(process.env.REACT_APP_MIX_COOK_KEY, data[2], {
          iter: 500,
        }),
        data_eel: sjcl.encrypt(process.env.REACT_APP_MIX_COOK_KEY, data[3], {
          iter: 500,
        }),
        data_eto: sjcl.encrypt(process.env.REACT_APP_MIX_COOK_KEY, data[4], {
          iter: 500,
        }),
        data_epd: sjcl.encrypt(process.env.REACT_APP_MIX_COOK_KEY, data[5], {
          iter: 500,
        }),
        data_eea: sjcl.encrypt(process.env.REACT_APP_MIX_COOK_KEY, data[7], {
          iter: 500,
        }),
        data_epe: sjcl.encrypt(process.env.REACT_APP_MIX_COOK_KEY, data[8], {
          iter: 500,
        }),
        data_eze: sjcl.encrypt(process.env.REACT_APP_MIX_COOK_KEY, data[9], {
          iter: 500,
        }),
        data_ese: sjcl.encrypt(process.env.REACT_APP_MIX_COOK_KEY, data[10], {
          iter: 500,
        }),
        data_ede: sjcl.encrypt(process.env.REACT_APP_MIX_COOK_KEY, data[11], {
          iter: 500,
        }),
        data_ece: sjcl.encrypt(process.env.REACT_APP_MIX_COOK_KEY, data[12], {
          iter: 500,
        }),
        data_ete: sjcl.encrypt(process.env.REACT_APP_MIX_COOK_KEY, data[13], {
          iter: 500,
        }),
        data_zona: data[14]?(sjcl.encrypt(process.env.REACT_APP_MIX_COOK_KEY, data[14], {
          iter: 500,
        })):"",
        data_securusal: data[15]?(sjcl.encrypt(process.env.REACT_APP_MIX_COOK_KEY, data[15], {
          iter: 500,
        })):"",
      };
    } catch (e) {
      alert(e);
    }
    var dataFormJsonEne = JSON.parse(dataToRegister["data_ene"]);
    var dataFormJsonEap = JSON.parse(dataToRegister["data_eap"]);
    var dataFormJsonEam = JSON.parse(dataToRegister["data_eam"]);
    var dataFormJsonEel = JSON.parse(dataToRegister["data_eel"]);
    var dataFormJsonEto = JSON.parse(dataToRegister["data_eto"]);
    var dataFormJsonEpd = JSON.parse(dataToRegister["data_epd"]);
    var dataFormJsonEea = JSON.parse(dataToRegister["data_eea"]);
    var dataFormJsonEpe = JSON.parse(dataToRegister["data_epe"]);
    var dataFormJsonEze = JSON.parse(dataToRegister["data_eze"]);
    var dataFormJsonEse = JSON.parse(dataToRegister["data_ese"]);
    var dataFormJsonEde = JSON.parse(dataToRegister["data_ede"]);
    var dataFormJsonEce = JSON.parse(dataToRegister["data_ece"]);
    var dataFormJsonEte = JSON.parse(dataToRegister["data_ete"]);
    var dataFormJsonZona = dataToRegister["data_zona"]?JSON.parse(dataToRegister["data_zona"]):"";
    var dataFormJsonSucursal = dataToRegister["data_securusal"]?JSON.parse(dataToRegister["data_securusal"]):"";

    dataToRegister = {
      data_ene: {
        data_enei: dataFormJsonEne["iv"],
        data_enes: dataFormJsonEne["salt"],
        data_enec: dataFormJsonEne["ct"],
      },
      data_eap: {
        data_eapi: dataFormJsonEap["iv"],
        data_eaps: dataFormJsonEap["salt"],
        data_eapc: dataFormJsonEap["ct"],
      },
      data_eam: {
        data_eami: dataFormJsonEam["iv"],
        data_eams: dataFormJsonEam["salt"],
        data_eamc: dataFormJsonEam["ct"],
      },
      data_eel: {
        data_eeli: dataFormJsonEel["iv"],
        data_eels: dataFormJsonEel["salt"],
        data_eelc: dataFormJsonEel["ct"],
      },
      data_eto: {
        data_etoi: dataFormJsonEto["iv"],
        data_etos: dataFormJsonEto["salt"],
        data_etoc: dataFormJsonEto["ct"],
      },
      data_epd: {
        data_epdi: dataFormJsonEpd["iv"],
        data_epds: dataFormJsonEpd["salt"],
        data_epdc: dataFormJsonEpd["ct"],
      },
      data_eea: {
        data_eeai: dataFormJsonEea["iv"],
        data_eeas: dataFormJsonEea["salt"],
        data_eeac: dataFormJsonEea["ct"],
      },
      data_epe: {
        data_epei: dataFormJsonEpe["iv"],
        data_epes: dataFormJsonEpe["salt"],
        data_epec: dataFormJsonEpe["ct"],
      },
      data_eze: {
        data_ezei: dataFormJsonEze["iv"],
        data_ezes: dataFormJsonEze["salt"],
        data_ezec: dataFormJsonEze["ct"],
      },
      data_ese: {
        data_esei: dataFormJsonEse["iv"],
        data_eses: dataFormJsonEse["salt"],
        data_esec: dataFormJsonEse["ct"],
      },
      data_ede: {
        data_edei: dataFormJsonEde["iv"],
        data_edes: dataFormJsonEde["salt"],
        data_edec: dataFormJsonEde["ct"],
      },
      data_ece: {
        data_ecei: dataFormJsonEce["iv"],
        data_eces: dataFormJsonEce["salt"],
        data_ecec: dataFormJsonEce["ct"],
      },
      data_ete: {
        data_etei: dataFormJsonEte["iv"],
        data_etes: dataFormJsonEte["salt"],
        data_etec: dataFormJsonEte["ct"],
      },
      data_zonaAndSucursal: {
        zona: dataFormJsonZona?dataFormJsonZona:"",
        sucursal: dataFormJsonSucursal?dataFormJsonSucursal:"",

      },
    };
    setSpinnerVisibility(true);
    const response = await serverRequest(
      "post",
      "existe_usuario",
      dataToRegister
    );
    if (response.data == "usuario existe") {
      setSpinnerVisibility(false);
      setShowAlert(true);
      setShowtext(t("thanks.alreadyexit"));
    } else if (response.data == "empresa existe") {
      setSpinnerVisibility(false);
      setShowAlert(true);
      setShowtext(t("thanks.register"));
    } else if (response.data == "empresa no existe") {
      setSpinnerVisibility(false);
      setShowAlert(true);
      setShowtext("Verify your company or go with your manager");
    } else if (response.data == "error bd") {
      setSpinnerVisibility(false);
      setTimeout(() => {
        setShowAlert(true);
        setShowtext("Reconnection! No connection accept");
      }, 10);
    } else setSpinnerVisibility(false);
    if (response.data === "usuario no existe") {
      registerAPI(dataToRegister);
    }
  };
  const registerAPI = async (dataToRegister) => {
    const response = await serverRequest("post", "registrar", dataToRegister);

    if (response.data == "registrado exitosamente") {
      setSpinnerVisibility(false);
      setShowSuccessModal(true);
    } else if (response.data == "existe") {
      setSpinnerVisibility(false);
      setShowAlert(true);
      setShowtext("The email entered is already registered");
    } else if (response.data == "error bd") {
      setSpinnerVisibility(false);
      setShowAlert(true);
      setShowtext("An unexpected error occurred Please report it");
    } else if (response.data == "empresa existe") {
      setSpinnerVisibility(false);
      setShowAlert(true);
      setShowtext(t("thanks.register"));
    } else if (response.data == "empresa no existe") {
      setSpinnerVisibility(false);
      setShowAlert(true);
      setShowtext(t("thanks.register"));
    } else {
      setSpinnerVisibility(false);
      setShowAlert(true);
      setShowtext("Something went wrong");
    }
  };
  const [filteredObject, setFilteredObject] = useState({ zonas: "" });
  const [zonaoption, setZonaOption] = useState()
  const [branchOption, setbranchOption] = useState([])
  const [ZonaError, setZonaError] = useState(false)
  const [SucursalError, setSucursalError] = useState(false)

  // Function to filter array of objects based on parameter
  const getObjectFromValue = (params) => {
    const filteredData = data.filter(obj => obj.name === params);
    setFilteredObject(filteredData[0]);

    if (filteredData.length > 0) {
      const obj = filteredData[0].zonas;
      let tempArray = Object.keys(obj)
      let newArray = tempArray.map(key => ({ name: key }));
      setZonaOption(newArray)
    }
  };
  const changeZona = (key) => {
    const selectedZona = filteredObject?.zonas[key];
    if (selectedZona?.length > 0) {

      let newArray = selectedZona.map(key => ({ name: key }));
      setbranchOption(newArray)
    }
  }
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Fragment key={step}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <LabelInputLeftIcon
                mailIcon={mailImage}
                type="email"
                label={t("Login.inputs.inputLabelEmail")}
                name="emailAddress"
                id="emailAddress"
                placeholder={t("Login.inputs.inputTextEmail")}
                onChange={(e) => setEmail(e.target.value)}
                error={formErrors.email}
              />
              {formErrors.email && (
                <Typography variant="body2" color="error">
                  {formErrors.email}
                </Typography>
              )}
            </Box>
            <Box sx={{ mt: 1 }}>
              <PasswordShowHideIcon
                type="text"
                label={t("Login.inputs.inputLabelPassword")}
                name="Password"
                id="Password"
                placeholder={t("Login.inputs.inputTextPassword")}
                onChange={(e) => setPassword(e.target.value)}
                error={formErrors.password}
              />
              {formErrors.password && (
                <Typography variant="body2" color="error">
                  {formErrors.password}
                </Typography>
              )}
            </Box>
            <Box sx={{ mt: 1 }}>
              <PasswordShowHideIcon
                type="text"
                label={t(
                  "ResetPassword?token={token}.inputs.inputLabelConfirmPass"
                )}
                name="confirmpassword"
                id="confirmpassword"
                placeholder={t(
                  "ResetPassword?token={token}.inputs.inputTextConfirmPass"
                )}
                onChange={(e) => setConfirmPassword(e.target.value)}
                error={formErrors.confirm_password}
              />
              {formErrors.confirm_password && (
                <Typography variant="body2" color="error">
                  {formErrors.confirm_password}
                </Typography>
              )}
            </Box>
          </Fragment>
        );
      case 1:
        return (
          <Fragment key={step}>
            <NoticePrivacy
              open={openUploadModal}
              isCheckboxChecked={isNoticeCheckboxChecked}
              handleClose={handleClosePrivacyModalAndOpenTermsModal}
              handleJustClose={handleJustCloseNoticeModal}
              handleCheckboxChange={handleCheckboxChange}
            />
            <TermsConditionModal
              open={openTermsModal}
              isCheckboxChecked={isTermsCheckboxChecked}
              handleClose={handleCloseTermsModalAndSubmit}
              handleJustClose={handleJustClosePrivacyModal}
              handleCheckboxChange={handleTermsCheckboxChange}
            />
            <CustomSpinner visible={spinnerVisibility} />
            {showSuccessModal && (
              <EnterEmailModal onClose={handleCloseSuccessModal} />
            )}
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <DropDownInput
                data={data}
                mailIcon={companyImage}
                type="text"
                name="companyname"
                id="companyname"
                label={t("labels.labelcompanyname")}
                firstName={t("labels.labelcompanyname")}
                onChange={(e) => {
                  setSelectedCompany(e.target.value)
                  getObjectFromValue(e.target.value)
                  setbranchOption([])
                }}
                error={formErrors.selectedCompany}
              />
              {formErrors.selectedCompany && (
                <Typography variant="body2" color="error">
                  {formErrors.selectedCompany}
                </Typography>
              )}
            </Box>

            <Stack
              sx={{ mt: 1 }}
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 0, sm: 2 }}
            >
              <>
                {Object.keys(filteredObject?.zonas || {}).length !== 0 && (
                  <Box sx={{ width: { xs: "50%", sm: "50%" } }}>
                    <DropDownInputSimple
                      options_data={zonaoption}
                      type="text"
                      name="companyname"
                      id="companyname"
                      label={t("labels.zona")}
                      // inputValue={birthState}
                      // onChange={(event) => {
                      //   setBirthState(event.target.value);
                      // }}
                      // FilledOut={curp}
                      showDefaultOption={true}
                      onChange={(e) => {
                        setZona(e.target.value)
                        changeZona(e.target.value)
                        setZonaError(false)
                        setSucursalError(false)
                      }
                      }
                    />
                    {ZonaError && (
                      <Typography variant="body2" color="error">
                        {t("labels.zonaError")}
                      </Typography>
                    )}
                  </Box>
                )}
                {branchOption?.length > 0 &&
                  <Box sx={{ width: { xs: "100%", sm: "50%" } }}>
                    <DropDownInputSimple
                      options_data={branchOption}
                      type="text"
                      name="companyname"
                      id="companyname"
                      label={t("labels.sucursal")}
                      // inputValue={birthState}
                      // onChange={(event) => {
                      //   setBirthState(event.target.value);
                      // }}
                      // FilledOut={curp}
                      showDefaultOption={true}

                      onChange={(e) => {
                        setSucursal(e.target.value)
                        setSucursalError(false)
                      }}
                    />
                    {SucursalError && (
                      <Typography variant="body2" color="error">
                       {t("labels.sucursalError")}
                      </Typography>
                    )}
                  </Box>
                }

              </>

            </Stack>
            <Stack
              sx={{ mt: 1 }}
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 0, sm: 2 }}
            >
              <Box sx={{ width: { xs: "100%", sm: "50%" } }}>
                <LabelInputLeftIcon
                  mailIcon={profileIcon}
                  type="text"
                  label={t("SuperAdminSignUp.inputs.inputLabelName")}
                  name="Name"
                  id="name"
                  placeholder={t("placeholders.name")}
                  onChange={(e) => setName(e.target.value)}
                  error={formErrors.name}
                />
                {formErrors.name && (
                  <Typography variant="body2" color="error">
                    {formErrors.name}
                  </Typography>
                )}
              </Box>
              <Box sx={{ width: { xs: "100%", sm: "50%" } }}>
                <LabelInputLeftIcon
                  mailIcon={profileIcon}
                  type="text"
                  label={t("SuperAdminSignUp.inputs.inputLabelLastName")}
                  name="lname"
                  id="lname"
                  placeholder={t("placeholders.lastname")}
                  onChange={(e) => setLastName(e.target.value)}
                  error={formErrors.last_name}
                />
                {formErrors.last_name && (
                  <Typography variant="body2" color="error">
                    {formErrors.last_name}
                  </Typography>
                )}
              </Box>
            </Stack>
            <Stack
              sx={{ mt: 1 }}
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 0, sm: 2 }}
            >
              <Box sx={{ width: { xs: "100%", sm: "50%" } }}>
                <LabelInputLeftIcon
                  mailIcon={profileIcon}
                  type="text"
                  label={t("SuperAdminSignUp.inputs.inputLabelMotherName")}
                  name="mname"
                  id="mname"
                  placeholder={t("placeholders.mothername")}
                  onChange={(e) => setMotherLastName(e.target.value)}
                  error={formErrors.mother_last_name}
                />
                {formErrors.mother_last_name && (
                  <Typography variant="body2" color="error">
                    {formErrors.mother_last_name}
                  </Typography>
                )}
              </Box>
              <Box sx={{ width: { xs: "100%", sm: "50%" } }}>
                <LabelInputLeftIcon
                  mailIcon={phoneIcon}
                  type="number"
                  label={t("labels.inputLabelNumber")}
                  name="mnumber"
                  id="mnumber"
                  placeholder={t("placeholders.number")}
                  onChange={(e) => setMobileNumber(e.target.value)}
                  error={formErrors.mobile_number}
                  maxLength={10}
                />
                {formErrors.mobile_number && (
                  <Typography variant="body2" color="error">
                    {formErrors.mobile_number}
                  </Typography>
                )}
              </Box>
            </Stack>


          </Fragment>
        );
    }
  };

  const renderContent = () => {
    return (
      <form onSubmit={(e) => e.preventDefault()}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
            margin: "auto",
          }}
        >
          <Box
            sx={{
              background: "#ffffff",
              position: "relative",
              zIndex: 1,
              borderRadius: 1,
              p: { xs: 2, sm: 4 },

              width: { xs: "90%", sm: "500px" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <img src={logo} alt="logo" />
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography
                sx={{
                  fontFamily: "Poppins-SemiBold",
                  fontSize: "24px",
                  lineHeight: "35px",
                  textAlign: "center",
                  color: "#1C212D",
                }}
              >
                {t("UserSignup.header")}
              </Typography>
              <Box sx={{ textAlign: "center", mt: -1 }}>
                <img src={underLine} alt="under line" />
              </Box>
            </Box>
            {getStepContent(activeStep)}
            <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
              <Button
                size="large"
                variant="contained"
                onClick={() => {
                  if (activeStep === steps.length - 1) {
                    if (Object.keys(filteredObject?.zonas || {}).length !== 0) {
                      if (Zona && Zona.trim() !== "") {
                        handleNext();
                      } else {
                        // If zona is empty, set the error state to true
                        setZonaError(true);
                      }
                    } else {
                      // If zonas object is empty, directly call handleNext
                      handleNext();
                    }
                    if (!branchOption || branchOption.length === 0) {
                      // If branchOption is empty, call handleNext
                      handleNext();
                    } else if (sucursalData) {
                      // If sucursalData is true, call handleNext
                      handleNext();
                    } else {
                      // If sucursalData is false, set the sucursalError state to true
                      setSucursalError(true);
                    }
                  }
                  else {
                    handleNext()

                  }
                }} sx={{
                  backgroundColor: "primary.main",
                  py: 1,
                  px: { xs: 4, sm: 5 },
                  fontSize: "18px",
                  fontFamily: "Gilroy-Medium",
                }}
              >
                {activeStep === steps.length - 1
                  ? t("Signup.header.title")
                  : t("Signup.buttons.buttonTextContinue")}
              </Button>
            </Box>
            {activeStep === steps.length - 1 ? null : (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flex: "row",
                    mt: 2,
                  }}
                >
                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      color: "#000000",
                      fontFamily: "Gilroy-Medium",
                      fontSize: "16px",
                    }}
                  >
                    {t("SuperAdminSignUp.buttons.textBelowContinueButton")}{" "}
                    <Link
                      to="/Login"
                      style={{
                        color: "#4165F6",
                        textDecoration: "none",
                        marginLeft: "5px",
                      }}
                    >
                      {t("SuperAdminSignUp.buttons.LoginLink")}
                    </Link>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flex: "row",
                    mt: 2,
                    mb: 2,
                  }}
                >
                  <Link
                    to="/GuestUser"
                    style={{
                      color: "rgba(33, 33, 33, 0.5)",
                      fontFamily: "Gilroy-Medium",
                      fontSize: "16px",
                      display: "flex",
                      justifyContent: "center",
                      textDecoration: "underline",
                    }}
                  >
                    {t("SuperAdminSignUp.buttons.guestLoginLink")}
                  </Link>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </form>
    );
  };

  return (
    <>
      <Box
        sx={{
          background: "#fff",
        }}
        id="particles-js"
      >
        <Grid container>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                width: "100%",
              }}
            >
              {renderContent()}
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <AuthLeftScreen text={t("Signup.buttons.Login")} url="/Login" />
          </Grid>
        </Grid>
      </Box>
      {showAlert && <AlertModal text={showtext} onClose={handleCloseAlert} />}
    </>
  );
};

export default UserRegistration;
