import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Fragment } from 'react';
import { textAlign } from '@mui/system';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getEspera } from '../../../utils/bureauHelper';

import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  maxWidth: "95%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
};

export default function ModalUpdatereport(props) {
  const { handleclosed, vistosReporte, resendReport, updateReport, email, curp, itemId, itemIndex, handleResendCandidateClosed, showReport, downloadReport, forwardEmail, isModelReport, isModelEmail, isModelDownload } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleReport = async (is_update) => {
    if (isModelReport) {
      await showReport(itemId, curp, email, is_update);
      await handleclosed();
    } else if (isModelDownload) {
      await downloadReport(itemId, curp, email, is_update);
      await handleclosed();
    } else if (isModelEmail) {
      await resendReport(itemId, curp, email, is_update);
      await handleclosed();
    }
  }
  return (
    <Fragment>
      <Modal
        open={true} // Open the modal whenever it is rendered
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClick={handleclosed}
      >
        <Box sx={style} onClick={(e) => e.stopPropagation()} >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mb: "10px",
              mt: "-20px",
              mr: "-20px",
            }}
          >
            <IconButton
              sx={{
                color: "gray",
              }}
              onClick={handleclosed}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography component={"h6"} sx={{ color: "neutral.900", fontSize: 20, fontFamily: "Gilroy-SemiBold", textAlign: "center" }}>
            {t("authorizationpanel.rightContent.Report.View")}
            {isModelReport && <Box component={"span"} sx={{ color: "primary.main" }}> {t("authorizationpanel.rightContent.buttons.View")}</Box>}
            {isModelEmail && <Box component={"span"} sx={{ color: "primary.main" }}> {t("authorizationpanel.rightContent.buttons.Send")}</Box>}
            {isModelDownload && <Box component={"span"} sx={{ color: "primary.main" }}> {t("authorizationpanel.rightContent.buttons.Download")}</Box>}
            {t("authorizationpanel.rightContent.Report.cost")}
          </Typography>
          <Box sx={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            marginTop: '20px',
          }}>
            <Button
              sx={{
                backgroundColor: "primary.main",
                py: 1,
                px: { xs: 4, sm: 8 },
                fontSize: "18px",
                fontFamily: "Gilroy-Medium",
                textTransform: "capitalize",
                justifyContent: "center",
                display: "flex",
                alignItems: "center"
              }}
              variant="contained"
              onClick={async () => {
                try {
                  const checkUpdateCondition = await updateReport(itemId);
                  if (checkUpdateCondition.final_response?.status === '1') {
                    // Only execute these if conditions if checkUpdateCondition is not true
                    await getEspera(checkUpdateCondition.final_response.curps._id);
                    await handleReport(true);
                  }
                } catch (error) {
                  // Handle errors if any
                  console.error('Error updating report:', error);
                }
              }}
            >
              {t("authorizationpanel.rightContent.buttons.inputLabelYes")}
            </Button>
            <Button
              disabled={vistosReporte === 0}
              sx={{
                backgroundColor: "primary.main",
                py: 1,
                px: { xs: 4, sm: 8 },
                fontSize: "18px",
                fontFamily: "Gilroy-Medium",
                textTransform: "capitalize",
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                marginLeft: "20px"
              }}
              variant="contained"
              onClick={async () => {
                await handleReport(false);
              }}
              >
              {t("authorizationpanel.rightContent.buttons.inputLabelNo")}
            </Button>
          </Box>

        </Box>
      </Modal>
    </Fragment>
  );
}
