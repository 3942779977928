import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";
import sucessImage from "../../../assets/images/successfull-email.png";
import { useTranslation } from "react-i18next";
import DoneIcon from "@mui/icons-material/Done";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 520,
  maxWidth: "95%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
  outline: "none",
  border: 0,
};

const ReportEmailSendModal = ({ open, onClose }) => {
  const { t } = useTranslation();
  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ textAlign: "end" }}>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              backgroundColor: "#4165F6",
              color: "#fff",
              width: 50,
              height: 50,
              borderRadius: "50%",
              margin: "auto",
            }}
          >
            <DoneIcon />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 2,
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                fontSize: 22,
                fontFamily: "Gilroy-Medium",
                color: "neutral.900",
                textAlign: "center",
                mb: 4,
              }}
            >
              {t("thanks.SENT_EMAIL")}
            </Typography>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ReportEmailSendModal;
