import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import img from '../../assets/images/status-green.svg';

function CardPanelReport(props) {
  const {
    title = 'Reports per day',
    count = '25',
    countPercentage = '+8.5%',
    color = '#35A826',
    statusIcon = img, // Corrected this line
  } = props;

  return (
  
        <Card sx={{mt:0,borderRadius:"6px"}}>
          <CardContent sx={{ p: '0px !important' }}>
            <Box sx={{ p: 2 }}>
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    backgroundColor: '#FF8D28',
                    width: 6,
                    borderRadius: 20,
                    mr: 2,
                    height: 70,
                    mt: -1,
                  }}
                ></Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Typography sx={{fontSize:16,width:"100%",color:"rgba(27, 32, 44, 0.6)"}}>{title}</Typography>
                  <Typography sx={{ color: '#1C212D', fontSize: 35, mt: 1 }} component={'h1'}>
                    {count}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  mt: 1.5,
                }}
              >
                <Box sx={{ backgroundColor: 'transparent', width: 6, height: 10, mr: 2 }}></Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <img src={statusIcon} height={22} width={22} alt="status" />
                  <Typography sx={{ fontSize: 16, color: color, ml: 1 }}>
                    {countPercentage}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
  );
}

export default CardPanelReport;
