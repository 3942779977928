import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Fragment } from 'react';
import { textAlign } from '@mui/system';
import { Link, useNavigate } from 'react-router-dom';
import sucessImage from "../../../assets/images/authorize-user.svg"
import changeRole from "../../../assets/images/changeRole.svg"
import LabelInput from "../Input/LabelInput"
import Cookies from 'js-cookie';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  '@media (max-width: 600px)': {
    width: 370
  },
  '@media (max-width: 425px)': {
    width: 300
  },
  maxWidth: "95%",
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 4
};

export default function TrashModalpanel(props) {
  const { handleclosed,deleteCandidate,itemToDeleteIndex} = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (

    <Fragment>
      <Modal
        open={true} // Open the modal whenever it is rendered
        onClose={handleclosed}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ justifyContent: "center", display: "flex", alignItems: "center" }}>
          <DeleteIcon   sx={{
        backgroundColor: '#FE5A3E', // Background color set to red
        color: 'white', // Text color set to white
        borderRadius: '50%', // Circular shape
        width: '60px', // Width of the circle
        height: '60px', // Height of the circle
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }} />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 4, flexDirection: "column" }}>
    <Typography component={"h6"} sx={{ color: "neutral.900", fontSize: 20, fontFamily: "Gilroy-SemiBold", textAlign: "center" }}>
      {t("thanks.Areyou")} <Box component={"span"} sx={{ color: "primary.main" }}> {t("thanks.sure")} </Box>
    </Typography>
</Box>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2, flexDirection: "column" }}>
    <Typography component={"h6"} sx={{ color: "neutral.900", fontSize: 16, fontFamily: "Gilroy-SemiBold", textAlign: "center" }}>
    {t("thanks.do")} <Box component={"span"} sx={{ color: "#FE5A3E" }}> {t("thanks.delete")} </Box>  {t("thanks.records")}
    </Typography>
</Box>

          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", mt: 4 }}>
          <Button
          onClick={() => {deleteCandidate(itemToDeleteIndex)}}
  sx={{
    backgroundColor: "primary.main",
    py: 1,
    px: { xs: 4, sm: 8 },
    fontSize: "18px",
    fontFamily: "Gilroy-Medium",
    textTransform: "capitalize"
  }}
  variant="contained"
>
{t("buttons.Yes")}
</Button>

          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", mt: 4 }}>
            <Button onClick={handleclosed} style={{ color: "rgba(27, 32, 44, 0.6)", fontSize: 16, fontFamily: "Gilroy-Medium", textDecoration: "underline", textTransform: "capitalize" }} variant="text">{t("buttons.back")}</Button>
          </Box>
        </Box>
      </Modal>
    </Fragment>
  );

}
