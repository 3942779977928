import axios from 'axios';

export const getIP = async () => {
    try {
        const response = await axios.get('https://api.ipify.org?format=json');
        const { ip } = response.data;
        return ip;
    } catch (error) {
        return 'xx.xx.xx.xx';
    }
}