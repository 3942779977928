import { Grid, Typography, Box } from '@mui/material'
import { useLocation } from 'react-router-dom';
import {useState, useEffect} from 'react'
import Paper from "@mui/material/Paper";
import Accordion from "@mui/material/Accordion";
import imgReport from "../../assets/images/avatar.svg"
import underLine from "../../assets/images/under-line.png"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { useTranslation } from 'react-i18next';
import axios from 'axios';


function Summary() {
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState(["Basic", "FAD", "Labor", "Legal", "Homónimos", "Ratings"]);
    const handleChange = (panel) => (event, isExpanded) => {
        if (isExpanded) {
            setExpanded([...expanded, panel]);
        } else {
            setExpanded(expanded.filter((item) => item !== panel));
        }
    };
    const { data, item_id } = useLocation().state;
    const formattedDate = new Intl.DateTimeFormat("en-US", {
        weekday: "short",
        day: "2-digit",
        month: "short",
        year: "numeric",
    });
    useEffect(() => {
        let formdata = new FormData();
        formdata.append("vlid", item_id);
        formdata.append("type", "vistos_summary");
        axios.post(process.env.REACT_APP_BASE_URL2 + 'ver_revision', formdata, {"headers": {"Content-Type": "multipart/form-data"}});
    }, []);
    // const language = localStorage.getItem('selectedLanguage');
    return (
        <>
            <Grid container spacing={2} sx={{ whiteSpace: 'pre-wrap' }}>
                <Grid item xs={12} sm={12}>
                    <Box sx={{ mt: 2 }}>
                        <Typography
                            sx={{
                                fontFamily: "Poppins-SemiBold",
                                fontSize: "24px",
                                lineHeight: "35px",
                                textAlign: "center",
                                color: "#1C212D"
                            }}>
                            {t("thanks.Summary")}
                        </Typography>
                        <Box sx={{ textAlign: "center", mt: -1 }}>
                            <img src={underLine} alt="under line" />
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Box sx={{ backgroundColor: "#ffffff", px: 2, py: 2, display: "flex", flexDirection: { xs: "column", sm: "row" }, justifyContent: "space-between" }}>
                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <img src={imgReport} width={70} height={70} alt="report" />
                            <Box sx={{ ml: 2 }}>
                                <Typography sx={{ fontSize: 18, fontFamily: "Gilroy-SemiBold", color: "neutral.900" }}>{data["Basic"]["Nombre"]}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <div>
                        <Accordion
                            key="Basic"
                            expanded={expanded.includes("Basic")}
                            onChange={handleChange("Basic")}
                            sx={{
                                mb: 2,
                                "& .MuiAccordionSummary-root.Mui-expanded": {
                                    minHeight: "45px !important",
                                },
                            }}
                        >
                            <TableContainer sx={{ backgroundColor: "primary.main", m: 0 }} component={Paper}>
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            sx={{
                                                color: "primary.main",
                                                backgroundColor: "#ffffff",
                                                borderRadius: 50,
                                            }}
                                        />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    sx={{
                                        my: "0px !important",
                                        "& .MuiAccordionSummary-content": {
                                            m: "0px !important",
                                            minHeight: "45px !important",
                                        },
                                    }}
                                >
                                    <Table sx={{  }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ width: "40px", py: 0, border: 0, color: "#ffffff" }}>
                                                    A.
                                                </TableCell>
                                                <TableCell align="left" sx={{ width: null, py: 0, border: 0, color: "#ffffff" }}>
                                                    Información Básica
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                    </Table>
                                </AccordionSummary>
                            </TableContainer>
                            <AccordionDetails sx={{ display: "flex", flexDirection: "row" }}>
                                <Box sx={{ width: "40px" }}></Box>
                                <TableContainer sx={{ borderRadius: 0 }} component={Paper}>
                                    <Table sx={{  borderRadius: 0 }} aria-label="simple table">
                                        <TableBody sx={{ borderRadius: 0 }}>
                                            <p><b>CURP:</b> {data["Basic"]["Curp"]}</p>
                                            <p><b>Nombre:</b> {data["Basic"]["Nombre"]}</p>
                                            <p><b>Fecha de nacimiento:</b> {(new Date(data["Basic"]["Fecha_de_nacimiento"])).toISOString().split("T")[0]}</p>
                                            <p><b>Estado de nacimiento:</b> {data["Basic"]["Estado_de_nacimiento"]}</p>
                                            <p><b>Cédula profesional:</b> {data["Basic"]["Cédula_profesional"]}</p>
                                            <p><b>Nivel educación:</b> {data["Basic"]["Nivel_educación"]}</p>
                                            <p><b>Universidad:</b> {data["Basic"]["Universidad"].replace('<br><br>', '\n\t')}</p>
                                            <p><b>Situacion laboral actual:</b> {data["Basic"]["Situacion_laboral_actual"]}</p>
                                            <p><b>Salario Cotizado:</b> {data["Basic"]["Salario_Cotizado"]}</p>
                                            <p><b>Fecha Consulta:</b> {data["Basic"]["Fecha_Consulta"]}</p>
                                            {data["Basic"]["Dirección"][0] ? <p style={{ whiteSpace: "pre-wrap" }}>
                                                <b>Dirección:</b>
                                                <br></br>
                                                <b>    Calle:</b> {data["Basic"]["Dirección"][0]["Calle"]}
                                                <br></br>
                                                <b>    Colonia Población:</b> {data["Basic"]["Dirección"][0]["ColoniaPoblación"]}
                                                <br></br>
                                                <b>    Delegación Municipio:</b> {data["Basic"]["Dirección"][0]["DelegaciónMunicipio"]}
                                                <br></br>
                                                <b>    Ciudad:</b> {data["Basic"]["Dirección"][0]["Ciudad"]}
                                                <br></br>
                                                <b>    Estado:</b> {data["Basic"]["Dirección"][0]["Estado"]}
                                                <br></br>
                                                <b>    CP:</b> {data["Basic"]["Dirección"][0]["CP"]}
                                            </p> : ''}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <div>
                        <Accordion
                            key="FAD"
                            expanded={expanded.includes("FAD")}
                            onChange={handleChange("FAD")}
                            sx={{
                                mb: 2,
                                "& .MuiAccordionSummary-root.Mui-expanded": {
                                    minHeight: "45px !important",
                                },
                            }}
                        >
                            <TableContainer sx={{ backgroundColor: "primary.main", m: 0 }} component={Paper}>
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            sx={{
                                                color: "primary.main",
                                                backgroundColor: "#ffffff",
                                                borderRadius: 50,
                                            }}
                                        />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    sx={{
                                        my: "0px !important",
                                        "& .MuiAccordionSummary-content": {
                                            m: "0px !important",
                                            minHeight: "45px !important",
                                        },
                                    }}
                                >
                                    <Table sx={{  }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ width: "40px", py: 0, border: 0, color: "#ffffff" }}>
                                                    B.
                                                </TableCell>
                                                <TableCell align="left" sx={{ width: null, py: 0, border: 0, color: "#ffffff" }}>
                                                    Familiar/Conocidos
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                    </Table>
                                </AccordionSummary>
                            </TableContainer>
                            <AccordionDetails sx={{ display: "flex", flexDirection: "row" }}>
                                <Box sx={{ width: "40px" }}></Box>
                                <TableContainer sx={{ borderRadius: 0 }} component={Paper}>
                                    <Table sx={{  borderRadius: 0 }} aria-label="simple table">
                                        <TableBody sx={{ borderRadius: 0 }}>
                                            <ul>
                                                {data["FAD"].map((item) => (
                                                    <li>
                                                        <p><b>Nombre:</b> {item["Nombre"] || ""}</p>
                                                        <p><b>Parentesco:</b> {item["Parentesco"] || ""}</p>
                                                        <p><b>Fecha de nacimiento:</b> {item["Fecha_de_nacimiento"] ? (new Date(item["Fecha_de_nacimiento"])).toISOString().split("T")[0] : ""}</p>
                                                    </li>
                                                ))}
                                            </ul>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <div>
                        <Accordion
                            key="Labor"
                            expanded={expanded.includes("Labor")}
                            onChange={handleChange("Labor")}
                            sx={{
                                mb: 2,
                                "& .MuiAccordionSummary-root.Mui-expanded": {
                                    minHeight: "45px !important",
                                },
                            }}
                        >
                            <TableContainer sx={{ backgroundColor: "primary.main", m: 0 }} component={Paper}>
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            sx={{
                                                color: "primary.main",
                                                backgroundColor: "#ffffff",
                                                borderRadius: 50,
                                            }}
                                        />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    sx={{
                                        my: "0px !important",
                                        "& .MuiAccordionSummary-content": {
                                            m: "0px !important",
                                            minHeight: "45px !important",
                                        },
                                    }}
                                >
                                    <Table sx={{  }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ width: "40px", py: 0, border: 0, color: "#ffffff" }}>
                                                    C.
                                                </TableCell>
                                                <TableCell align="left" sx={{ width: null, py: 0, border: 0, color: "#ffffff" }}>
                                                    Laboral
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                    </Table>
                                </AccordionSummary>
                            </TableContainer>
                            <AccordionDetails sx={{ display: "flex", flexDirection: "row" }}>
                                <Box sx={{ width: "40px" }}></Box>
                                <TableContainer sx={{ borderRadius: 0 }} component={Paper}>
                                    <Table sx={{  borderRadius: 0 }} aria-label="simple table">
                                        <TableBody sx={{ borderRadius: 0 }}>
                                            <ul>
                                                {data["Labor"]["Detalles_de_empleos"].map((item) => (
                                                    <li>
                                                        <p><b>Empleador:</b> {item["Empleador"] || ""}</p>
                                                        <p><b>Duracion:</b> {item["Duracion"] || ""}</p>
                                                        <p><b>Alta Baja:</b> {item["Alta_Baja"] || ""}</p>
                                                        <p><b>Salario:</b> {item["Salario"] || ""}</p>
                                                        <p><b>Estado empleo:</b> {item["Estado_empleo"] || ""}</p>
                                                    </li>
                                                ))}
                                            </ul>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <div>
                        <Accordion
                            key="Legal"
                            expanded={expanded.includes("Legal")}
                            onChange={handleChange("Legal")}
                            sx={{
                                mb: 2,
                                "& .MuiAccordionSummary-root.Mui-expanded": {
                                    minHeight: "45px !important",
                                },
                            }}
                        >
                            <TableContainer sx={{ backgroundColor: "primary.main", m: 0 }} component={Paper}>
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            sx={{
                                                color: "primary.main",
                                                backgroundColor: "#ffffff",
                                                borderRadius: 50,
                                            }}
                                        />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    sx={{
                                        my: "0px !important",
                                        "& .MuiAccordionSummary-content": {
                                            m: "0px !important",
                                            minHeight: "45px !important",
                                        },
                                    }}
                                >
                                    <Table sx={{  }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ width: "40px", py: 0, border: 0, color: "#ffffff" }}>
                                                    D.
                                                </TableCell>
                                                <TableCell align="left" sx={{ width: null, py: 0, border: 0, color: "#ffffff" }}>
                                                    Legal
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                    </Table>
                                </AccordionSummary>
                            </TableContainer>
                            <AccordionDetails sx={{ display: "flex", flexDirection: "row" }}>
                                <Box sx={{ width: "40px" }}></Box>
                                <TableContainer sx={{ borderRadius: 0 }} component={Paper}>
                                    <Table sx={{  borderRadius: 0 }} aria-label="simple table">
                                        <TableBody sx={{ borderRadius: 0 }}>
                                            <p><b>Sexual Agresor:</b> {data["Legal"]["Agresor_Sexual"] || ""}</p>
                                            <p><b>Alertas internacionales:</b> {data["Legal"]["Tiene_alertas_internacionales"] || ""}</p>
                                            <p><b>Legal Resumen:</b><br></br><span style={{ whiteSpace: "pre-wrap" }}>{data["Legal"]["Demandas_detalles"] || ""}</span></p>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <div>
                        <Accordion
                            key="Homónimos"
                            expanded={expanded.includes("Homónimos")}
                            onChange={handleChange("Homónimos")}
                            sx={{
                                mb: 2,
                                "& .MuiAccordionSummary-root.Mui-expanded": {
                                    minHeight: "45px !important",
                                },
                            }}
                        >
                            <TableContainer sx={{ backgroundColor: "primary.main", m: 0 }} component={Paper}>
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            sx={{
                                                color: "primary.main",
                                                backgroundColor: "#ffffff",
                                                borderRadius: 50,
                                            }}
                                        />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    sx={{
                                        my: "0px !important",
                                        "& .MuiAccordionSummary-content": {
                                            m: "0px !important",
                                            minHeight: "45px !important",
                                        },
                                    }}
                                >
                                    <Table sx={{  }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ width: "40px", py: 0, border: 0, color: "#ffffff" }}>
                                                    E.
                                                </TableCell>
                                                <TableCell align="left" sx={{ width: null, py: 0, border: 0, color: "#ffffff" }}>
                                                    Homónimos
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                    </Table>
                                </AccordionSummary>
                            </TableContainer>
                            <AccordionDetails sx={{ display: "flex", flexDirection: "row" }}>
                                <Box sx={{ width: "40px" }}></Box>
                                <TableContainer sx={{ borderRadius: 0 }} component={Paper}>
                                    <Table sx={{  borderRadius: 0 }} aria-label="simple table">
                                        <TableBody sx={{ borderRadius: 0 }}>
                                            <ul>
                                                {data["Homónimos"].map((item) => (
                                                    <li>
                                                        <p><b>Nombre:</b> {item["Nombre"] || ""}</p>
                                                        <p><b>Estado de nacimiento:</b> {item["Estado_de_nacimiento"] || ""}</p>
                                                        <p><b>Fecha de nacimiento:</b> {item["Fecha_de_nacimiento"] || ""}</p>
                                                    </li>
                                                ))}
                                            </ul>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <div>
                        <Accordion
                            key="Ratings"
                            expanded={expanded.includes("Ratings")}
                            onChange={handleChange("Ratings")}
                            sx={{
                                mb: 2,
                                "& .MuiAccordionSummary-root.Mui-expanded": {
                                    minHeight: "45px !important",
                                },
                            }}
                        >
                            <TableContainer sx={{ backgroundColor: "primary.main", m: 0 }} component={Paper}>
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            sx={{
                                                color: "primary.main",
                                                backgroundColor: "#ffffff",
                                                borderRadius: 50,
                                            }}
                                        />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    sx={{
                                        my: "0px !important",
                                        "& .MuiAccordionSummary-content": {
                                            m: "0px !important",
                                            minHeight: "45px !important",
                                        },
                                    }}
                                >
                                    <Table sx={{  }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ width: "40px", py: 0, border: 0, color: "#ffffff" }}>
                                                    F.
                                                </TableCell>
                                                <TableCell align="left" sx={{ width: null, py: 0, border: 0, color: "#ffffff" }}>
                                                    Ratings
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                    </Table>
                                </AccordionSummary>
                            </TableContainer>
                            <AccordionDetails sx={{ display: "flex", flexDirection: "row" }}>
                                <Box sx={{ width: "40px" }}></Box>
                                <TableContainer sx={{ borderRadius: 0 }} component={Paper}>
                                    <Table sx={{  borderRadius: 0 }} aria-label="simple table">
                                        <TableBody sx={{ borderRadius: 0 }}>
                                            <p><b>Comportamiento Personal porcentaje:</b> {data["Ratings"]["Comportamiento_Personal_porcentaje"] || ""}</p>
                                            <p><b>Comportamiento Personal porcentaje 1:</b> {data["Ratings"]["Comportamiento_Personal_porcentaje_1"] || ""}</p>
                                            <p><b>Evolución personal:</b> {data["Ratings"]["Evolución_personal"] || ""}</p>
                                            <p><b>Grado Demandas:</b> {data["Ratings"]["Grado_Demandas"] || ""}</p>
                                            <p><b>Historial Legal:</b> {data["Ratings"]["Historial_Legal"] || ""}</p>
                                            <p><b>Historial Legal Mensaje:</b> {data["Ratings"]["Historial_Legal_Mensaje"] || ""}</p>
                                            <p><b>Historial Legal Porcentaje:</b> {data["Ratings"]["Historial_Legal_Porcentaje"] || ""}</p>
                                            <p><b>Historial laboral final:</b> {data["Ratings"]["Historial_laboral_final"] || ""}</p>
                                            <p><b>Historial laboral porcentaje:</b> {data["Ratings"]["Historial_laboral_porcentaje"] || ""}</p>
                                            <p><b>Nivel Socieconomico:</b> {data["Ratings"]["Nivel_Socieconomico"] || ""}</p>
                                            <p><b>Nivel Socieconomico Porcentaje:</b> {data["Ratings"]["Nivel_Socieconomico_Porcentaje"] || ""}</p>
                                            <p><b>Periodos de inactivad de más de 3 meses:</b> {data["Ratings"]["Número_de_despidos"] || ""}</p>
                                            <p><b>Periodos de inactivad calificación:</b> {data["Ratings"]["Número_de_despidos_calificación"] || ""}</p>
                                            <p><b>Número total de empleos:</b> {data["Ratings"]["Número_total_de_empleos"] || ""}</p>
                                            <p><b>Porcentaje Laborado:</b> {data["Ratings"]["Porcentaje_Laborado"] || ""}</p>
                                            <p><b>Problemas Empleador:</b> {data["Ratings"]["Problemas_Empleador"] || ""}</p>
                                            <p><b>Progresion Salarial:</b> {data["Ratings"]["Progresion_Salarial"] || ""}</p>
                                            <p><b>Progresion Salarial calf:</b> {data["Ratings"]["Progresion_Salarial_calf"] || ""}</p>
                                            <p><b>SL Score:</b> {data["Ratings"]["SL_Score"] || ""}</p>
                                            <p><b>Salario Cotizado calf:</b> {data["Ratings"]["Salario_Cotizado_calf"] || ""}</p>
                                            <p><b>Tiempo Laborando desde los 22 años:</b> {data["Ratings"]["Tiempo_Laborando_desde_los_22_años"] || ""}</p>
                                            <p><b>Tiempo Laborando desde los 22 años calificación:</b> {data["Ratings"]["Tiempo_Laborando_desde_los_22_años_calificación"] || ""}</p>
                                            <p><b>Tiempo Paro Acumulado:</b> {data["Ratings"]["Tiempo_Paro_Acumulado"] || ""}</p>
                                            <p><b>Tiempo en su situación laboral actual:</b> {data["Ratings"]["Tiempo_en_su_situación_laboral_actual"] || ""}</p>
                                            <p><b>Tiempo promedio duracion empleos:</b> {data["Ratings"]["Tiempo_promedio_duracion_empleos"] || ""}</p>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </Grid>
            </Grid>
        </>
    )
}

export default Summary