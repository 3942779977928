import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
// ** Third Party Imports
import InputMask from "react-input-mask";
import "./DatePicker.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import calenderIcon from "../../../../assets/images/calender-icon.svg";

function DatePickerInput({ disabled, selectedDate, onDateChange, mandatory,FilledOut }) {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography
        sx={{
          fontSize: 16,
          fontFamily: "Gilroy-Medium",
          pb: "10px",
          pt: "10px",
          color: "rgba(33, 33, 33, 0.6)",
        }}
      >
        {t("labels.inputLabelBirthDate")}
      </Typography>
      <Box sx={{ position: "relative" }}>
        <InputMask
          disabled={disabled}
          fullwidth="true"
          type="text"
          id="dateInput"
          mask="99/99/9999"
          placeholder={t("placeholders.Date")}
          value={selectedDate}
          onChange={(event) => onDateChange(event.target.value)}
          className="input_mask_box"
          style={{
            cursor: disabled ? "not-allowed" : "text",
            backgroundColor: disabled ? 
              "rgba(100,100,100,0.5)" : FilledOut ? 
              null : (selectedDate ? 
              null : mandatory ? 
              "rgba(255,140, 0, 0.1)" : (selectedDate === "" && "#DFFAFE")),
          }}

        />
        <Box
          sx={{ 
            position: "absolute", 
            right: 11.5, 
            top: 15, 
            color: "#4165F6" 
          }}
        >
          <img src={calenderIcon} height={20} alt="" calender-icon="true" />
        </Box>
      </Box>
    </Box>
  );
}

export default DatePickerInput;
