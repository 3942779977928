import * as React from "react";

import Typography from "@mui/material/Typography";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import InnerTable from "./InnerTable";
import InnerTableThree from "./InnerTableThree";
import InnerTableFourth from "./InnerTableFourth";
import InnerTableSecond from "./InnerTableSecond";
import InnerTableFifth from "./InnerTableFifth";
import { useTranslation } from "react-i18next";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function HomonymousLegalHistory() {
  const { t } = useTranslation();
  return (
    <Box sx={{ height: "70vh", overflowY: "auto" }}>
      <TableContainer sx={{ borderRadius: 0 }} component={Paper}>
        <Table sx={{ borderRadius: 0 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: "#4165F6" }}>
            <TableRow sx={{ width: "100%" }}>
              <TableCell
                align="left"
                sx={{
                  width: "40%",
                  py: 2,
                  border: 0,
                  color: "#ffffff",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "2vw", sm: "0.875rem" },
                }}
              >
                {t("landingpage.leftContent.tabs.subtab.C")}
              </TableCell>
              <TableCell
                sx={{
                  width: "30%",
                  py: 2,
                  border: 0,
                  color: "#ffffff",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "2vw", sm: "0.875rem" },
                }}
                align="center"
              >
                {t("landingpage.leftContent.tabs.subheading.Report Format")}
              </TableCell>
              <TableCell
                sx={{
                  width: "15%",
                  py: 2,
                  border: 0,
                  color: "#ffffff",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "2vw", sm: "0.875rem" },
                }}
                align="center"
              >
                {t("landingpage.leftContent.tabs.subheading.Concept")}
              </TableCell>
              <TableCell
                sx={{
                  width: "15%",
                  py: 2,
                  border: 0,
                  color: "#ffffff",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "2vw", sm: "0.875rem" },
                }}
                align="center"
              >
                {t("landingpage.leftContent.tabs.subheading.Result")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ borderRadius: 0, mt: 2 }}>
            <TableRow
              sx={{
                borderRadius: 0,
                "&:last-child td, &:last-child th": {
                  border: 0,
                  borderRadius: 0,
                },
              }}
            >
              <TableCell
                colSpan={2}
                sx={{
                  width: "40%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="left"
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    sx={{
                      color: "primary.main",
                      width: "65%",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    {t(
                      "landingpage.leftContent.tabs.C | Historial Legal Homónimo.C. Historial Legal Homónimo.I"
                    )}
                  </Typography>
                  <Typography
                    sx={{
                      color: "primary.main",
                      width: "35%",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                      textAlign: "center",
                    }}
                  >
                    3
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", mt: { xs: 1, sm: 4 } }}>
                  <InnerTable />
                </Box>
              </TableCell>

              {/* <TableCell  sx={{width:"30%",border: "5px solid #ffffff !important",borderRadius:0,backgroundColor:"rgba(0, 127, 255, 0.1)"}} align="left">
                            <Box sx={{display:"flex",flexDirection:"row"}}>
                                <Typography sx={{color:"#000000",fontSize:14,fontFamily:"Gilroy-Medium"}}>$8,189 </Typography>
                          
                            </Box>
                        </TableCell> */}
              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                {t(
                  "landingpage.leftContent.tabs.C | Historial Legal Homónimo.Concept.Demandas Laborales"
                )}
              </TableCell>

              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  borderRadius: 0,
                  border: "5px solid #ffffff !important",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <ul>
                  {t(
                    "landingpage.leftContent.tabs.C | Historial Legal Homónimo.Result.Demandas Laborales"
                  )}
                </ul>
              </TableCell>
            </TableRow>

            <TableRow
              sx={{
                borderRadius: 0,
                "&:last-child td, &:last-child th": {
                  border: 0,
                  borderRadius: 0,
                },
              }}
            >
              <TableCell
                colSpan={2}
                sx={{
                  width: "40%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  backgroundColor: "rgba(0, 127, 255, 0.1)",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="left"
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    sx={{
                      color: "primary.main",
                      width: "65%",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    {t(
                      "landingpage.leftContent.tabs.C | Historial Legal Homónimo.C. Historial Legal Homónimo.II"
                    )}
                  </Typography>
                  <Typography
                    sx={{
                      color: "primary.main",
                      width: "35%",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                      textAlign: "center",
                    }}
                  >
                    1
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", mt: { xs: 1, sm: 4 } }}>
                  <InnerTableSecond />
                </Box>
              </TableCell>

              {/* <TableCell  sx={{width:"30%",border: "5px solid #ffffff !important",borderRadius:0,backgroundColor:"rgba(0, 127, 255, 0.1)"}} align="left">
                            <Box sx={{display:"flex",flexDirection:"row"}}>
                                <Typography sx={{color:"#000000",fontSize:14,fontFamily:"Gilroy-Medium"}}>$8,189 </Typography>
                          
                            </Box>
                        </TableCell> */}
              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                {t(
                  "landingpage.leftContent.tabs.C | Historial Legal Homónimo.Concept.Demandas Civiles"
                )}
              </TableCell>

              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  borderRadius: 0,
                  border: "5px solid #ffffff !important",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <ul>
                  {t(
                    "landingpage.leftContent.tabs.C | Historial Legal Homónimo.Result.Demandas Civiles"
                  )}
                </ul>
              </TableCell>
            </TableRow>

            <TableRow
              sx={{
                borderRadius: 0,
                "&:last-child td, &:last-child th": {
                  border: 0,
                  borderRadius: 0,
                },
              }}
            >
              <TableCell
                colSpan={2}
                sx={{
                  width: "40%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="left"
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    sx={{
                      color: "primary.main",
                      width: "65%",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    {t(
                      "landingpage.leftContent.tabs.C | Historial Legal Homónimo.C. Historial Legal Homónimo.III"
                    )}
                  </Typography>
                  <Typography
                    sx={{
                      color: "primary.main",
                      width: "35%",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                      textAlign: "center",
                    }}
                  >
                    1
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", mt: { xs: 1, sm: 4 } }}>
                  <InnerTableThree />
                </Box>
              </TableCell>

              {/* <TableCell  sx={{width:"30%",border: "5px solid #ffffff !important",borderRadius:0,backgroundColor:"rgba(0, 127, 255, 0.1)"}} align="left">
                            <Box sx={{display:"flex",flexDirection:"row"}}>
                                <Typography sx={{color:"#000000",fontSize:14,fontFamily:"Gilroy-Medium"}}>$8,189 </Typography>
                          
                            </Box>
                        </TableCell> */}
              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                {t(
                  "landingpage.leftContent.tabs.C | Historial Legal Homónimo.Concept.Demandas Familiares"
                )}
              </TableCell>

              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  borderRadius: 0,
                  border: "5px solid #ffffff !important",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <ul>
                  {t(
                    "landingpage.leftContent.tabs.C | Historial Legal Homónimo.Result.Demandas Familiares"
                  )}
                </ul>
              </TableCell>
            </TableRow>

            <TableRow
              sx={{
                borderRadius: 0,
                "&:last-child td, &:last-child th": {
                  border: 0,
                  borderRadius: 0,
                },
              }}
            >
              <TableCell
                colSpan={2}
                sx={{
                  width: "40%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  backgroundColor: "rgba(0, 127, 255, 0.1)",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="left"
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    sx={{
                      color: "primary.main",
                      width: "65%",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    {t(
                      "landingpage.leftContent.tabs.C | Historial Legal Homónimo.C. Historial Legal Homónimo.IV"
                    )}
                  </Typography>
                  <Typography
                    sx={{
                      color: "primary.main",
                      width: "35%",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                      textAlign: "center",
                    }}
                  >
                    2
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", mt: { xs: 1, sm: 4 } }}>
                  <InnerTableFourth />
                </Box>
              </TableCell>

              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                {t(
                  "landingpage.leftContent.tabs.C | Historial Legal Homónimo.Concept.Procesos Penales"
                )}
              </TableCell>

              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  borderRadius: 0,
                  border: "5px solid #ffffff !important",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <ul>
                  {t(
                    "landingpage.leftContent.tabs.C | Historial Legal Homónimo.Result.Procesos Penales"
                  )}
                </ul>
              </TableCell>
            </TableRow>

            <TableRow
              sx={{
                borderRadius: 0,
                "&:last-child td, &:last-child th": {
                  border: 0,
                  borderRadius: 0,
                },
              }}
            >
              <TableCell
                colSpan={2}
                sx={{
                  width: "40%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="left"
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    sx={{
                      color: "primary.main",
                      width: "65%",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    {t(
                      "landingpage.leftContent.tabs.C | Historial Legal Homónimo.C. Historial Legal Homónimo.V"
                    )}
                  </Typography>
                  <Typography
                    sx={{
                      color: "primary.main",
                      width: "35%",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                      textAlign: "center",
                    }}
                  >
                    1
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", mt: { xs: 1, sm: 4 } }}>
                  <InnerTableFifth />
                </Box>
              </TableCell>

              {/* <TableCell  sx={{width:"30%",border: "5px solid #ffffff !important",borderRadius:0,backgroundColor:"rgba(0, 127, 255, 0.1)"}} align="left">
                            <Box sx={{display:"flex",flexDirection:"row"}}>
                                <Typography sx={{color:"#000000",fontSize:14,fontFamily:"Gilroy-Medium"}}>$8,189 </Typography>
                          
                            </Box>
                        </TableCell> */}
              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                {t(
                  "landingpage.leftContent.tabs.C | Historial Legal Homónimo.Concept.Demandas Administrativas"
                )}
              </TableCell>

              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  borderRadius: 0,
                  border: "5px solid #ffffff !important",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <ul>
                  {t(
                    "landingpage.leftContent.tabs.C | Historial Legal Homónimo.Result.Demandas Administrativas"
                  )}
                </ul>
              </TableCell>
            </TableRow>

            <TableRow
              sx={{
                borderRadius: 0,
                "&:last-child td, &:last-child th": {
                  border: 0,
                  borderRadius: 0,
                },
              }}
            >
              <TableCell
                // colSpan={2}
                sx={{
                  width: "40%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  backgroundColor: "rgba(0, 127, 255, 0.1)",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="left"
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    sx={{
                      color: "primary.main",
                      width: "100%",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    {t(
                      "landingpage.leftContent.tabs.C | Historial Legal Homónimo.C. Historial Legal Homónimo.VI"
                    )}
                  </Typography>
                </Box>
              </TableCell>

              <TableCell
                sx={{
                  width: "30%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  backgroundColor: "rgba(0, 127, 255, 0.1)",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#000000",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    {t(
                      "landingpage.leftContent.tabs.C | Historial Legal Homónimo.Report Format.Yes"
                    )}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000000",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    (DEA, INTERPOL){" "}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                {t(
                  "landingpage.leftContent.tabs.C | Historial Legal Homónimo.Concept.Agresores Sexuales"
                )}
              </TableCell>

              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  borderRadius: 0,
                  border: "5px solid #ffffff !important",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <ul>
                  {t(
                    "landingpage.leftContent.tabs.C | Historial Legal Homónimo.Result.Agresores Sexuales"
                  )}
                </ul>
              </TableCell>
            </TableRow>

            <TableRow
              sx={{
                borderRadius: 0,
                "&:last-child td, &:last-child th": {
                  border: 0,
                  borderRadius: 0,
                },
              }}
            >
              <TableCell
                sx={{
                  width: "40%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="left"
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    sx={{
                      color: "primary.main",
                      width: "100%",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    {t(
                      "landingpage.leftContent.tabs.C | Historial Legal Homónimo.C. Historial Legal Homónimo.VII"
                    )}
                  </Typography>
                </Box>
              </TableCell>

              <TableCell
                sx={{
                  width: "30%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "1.5vw", sm: 14 },
                    fontFamily: "Gilroy-Medium",
                  }}
                >
                  {t(
                    "landingpage.leftContent.tabs.C | Historial Legal Homónimo.Report Format.No"
                  )}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                {t(
                  "landingpage.leftContent.tabs.C | Historial Legal Homónimo.Concept.Alertas Internacionales"
                )}
              </TableCell>

              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  borderRadius: 0,
                  border: "5px solid #ffffff !important",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <ul>
                  {t(
                    "landingpage.leftContent.tabs.C | Historial Legal Homónimo.Result.Alertas Internacionales"
                  )}
                </ul>
              </TableCell>
            </TableRow>

            <TableRow
              sx={{
                borderRadius: 0,
                "&:last-child td, &:last-child th": {
                  border: 0,
                  borderRadius: 0,
                },
              }}
            >
              <TableCell
                // colSpan={2}
                sx={{
                  width: "40%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  backgroundColor: "rgba(0, 127, 255, 0.1)",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="left"
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    sx={{
                      color: "primary.main",
                      width: "100%",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    {t(
                      "landingpage.leftContent.tabs.C | Historial Legal Homónimo.C. Historial Legal Homónimo.VIII"
                    )}
                  </Typography>
                </Box>
              </TableCell>

              <TableCell
                sx={{
                  width: "30%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  backgroundColor: "rgba(0, 127, 255, 0.1)",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "1.5vw", sm: 14 },
                    fontFamily: "Gilroy-Medium",
                  }}
                >
                  {t(
                    "landingpage.leftContent.tabs.C | Historial Legal Homónimo.Report Format.No"
                  )}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                {t(
                  "landingpage.leftContent.tabs.C | Historial Legal Homónimo.Concept.SAT Contribuyente"
                )}
              </TableCell>

              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  borderRadius: 0,
                  border: "5px solid #ffffff !important",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <ul>
                  {t(
                    "landingpage.leftContent.tabs.C | Historial Legal Homónimo.Result.SAT Contribuyente"
                  )}
                </ul>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
