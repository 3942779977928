import * as React from "react";
import Box from "@mui/material/Box";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Icon, IconButton, Stack, Typography } from "@mui/material";
import EmptyScreen from "../LoginScreen/EmptyScreen";
import reportImage from "../../assets/images/ReportImage.png";
import SocioeconomicMain from "../@common/SocioeconomicStudy/SocioeconomicMain";
import { padding } from "@mui/system";
import EmploymentHistory from "../@common/EmploymentHistory/EmploymentHistory";
import HomonymousLegalHistory from "../@common/HomonymousLegalHistory/HomonymousLegalHistory";
import DebtAnalysis from "../@common/DebtAnalysis/DebtAnalysis";
import MortgageCredit from "../@common/MortgageCredit/MortgageCredit";
import RelevantInformation from "../@common/RelevantInformation/RelevantInformation";
import FinalRecommendation from "../@common/FinalRecommendation/FinalRecommendation";
import { useTranslation } from "react-i18next";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: { xs: 0, md: 3 } }}>
          ,<Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function ScrollableTabs(props) {
  const { value, handleChange } = props;
  const { t } = useTranslation();

  const handleScrollButtonClick = (direction) => {
    const tabList = document.querySelector(".MuiTabs-scroller");
    const scrollAmount = 200; // Set the width you want to scroll

    if (tabList) {
      if (direction === "left") {
        tabList.scrollLeft -= scrollAmount;
      } else if (direction === "right") {
        tabList.scrollLeft += scrollAmount;
      }
    }
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        maxWidth: "100%",
        backgroundColor: "transparent",
        borderRadius: 1,
        mt: 4,
      }}
    >
      <Box
        sx={{
          bgcolor: "#fff",
          borderRadius: "12px",
          boxShadow: "0px 0px 60px #00000008",
          p: 1,
          position: "relative",
          fontSize: { xs: "10" },
        }}
      >
        <Stack direction="row" alignItems="center" gap={1}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons={false}
            aria-label="visible arrows tabs example"
            sx={{
              "& .Mui-selected": {
                backgroundColor: "primary.main",
                color: "#ffffff !important",
                border: 0,
              },
              "& .MuiTabs-indicator": {
                display: "none",
              },
              "& .MuiTabs-scroller": {
                scrollBehavior: "smooth",
              },
              "& .MuiTabScrollButton-root": {
                backgroundColor: "primary.opacity",
                color: "primary.main",
                height: 30,
                width: 30,
                borderRadius: 50,
                // mt: 1,
                mx: 2,
              },
              "& .MuiTabScrollButton-root svg": {
                fontSize: 30,
              },
            }}
          >
            <Tab
              sx={{
                backgroundColor: "transparent",
                color: "neutral.400",
                textTransform: "uppercase",
                borderWidth: 1,
                borderColor: "neutral.400",
                borderStyle: "solid",
                borderRadius: 1,
                px: 2,
                mx: 1,
                fontFamily: "Gilroy-SemiBold",
                fontSize: 16,
                display: "none",
              }}
              label=""
            />
            <Tab
              sx={{
                backgroundColor: "transparent",
                color: "neutral.400",
                textTransform: "uppercase",
                borderWidth: 1,
                borderColor: "neutral.400",
                borderStyle: "solid",
                borderRadius: 1,
                px: 2,
                mx: 1,
                fontFamily: "Gilroy-SemiBold",
                fontSize: 16,
              }}
              label={t("landingpage.leftContent.tabs.tab.A")}
            />
            <Tab
              sx={{
                backgroundColor: "transparent",
                color: "neutral.400",
                textTransform: "uppercase",
                borderWidth: 1,
                borderColor: "neutral.400",
                borderStyle: "solid",
                borderRadius: 1,
                px: 2,
                mx: 1,
                fontFamily: "Gilroy-SemiBold",
                fontSize: 16,
              }}
              label={t("landingpage.leftContent.tabs.tab.B")}
            />
            <Tab
              sx={{
                backgroundColor: "transparent",
                color: "neutral.400",
                textTransform: "uppercase",
                borderWidth: 1,
                borderColor: "neutral.400",
                borderStyle: "solid",
                borderRadius: 1,
                px: 2,
                mx: 1,
                fontFamily: "Gilroy-SemiBold",
                fontSize: 16,
              }}
              label={t("landingpage.leftContent.tabs.tab.C")}
            />
            <Tab
              sx={{
                backgroundColor: "transparent",
                color: "neutral.400",
                textTransform: "uppercase",
                borderWidth: 1,
                borderColor: "neutral.400",
                borderStyle: "solid",
                borderRadius: 1,
                px: 2,
                mx: 1,
                fontFamily: "Gilroy-SemiBold",
                fontSize: 16,
              }}
              label={t("landingpage.leftContent.tabs.tab.D")}
            />
            <Tab
              sx={{
                backgroundColor: "transparent",
                color: "neutral.400",
                textTransform: "uppercase",
                borderWidth: 1,
                borderColor: "neutral.400",
                borderStyle: "solid",
                borderRadius: 1,
                px: 2,
                mx: 1,
                fontFamily: "Gilroy-SemiBold",
                fontSize: 16,
              }}
              label={t("landingpage.leftContent.tabs.tab.E")}
            />
            <Tab
              sx={{
                backgroundColor: "transparent",
                color: "neutral.400",
                textTransform: "uppercase",
                borderWidth: 1,
                borderColor: "neutral.400",
                borderStyle: "solid",
                borderRadius: 1,
                px: 2,
                mx: 1,
                fontFamily: "Gilroy-SemiBold",
                fontSize: 16,
              }}
              label={t("landingpage.leftContent.tabs.tab.F")}
            />
            <Tab
              sx={{
                backgroundColor: "transparent",
                color: "neutral.400",
                textTransform: "uppercase",
                borderWidth: 1,
                borderColor: "neutral.400",
                borderStyle: "solid",
                borderRadius: 1,
                px: 2,
                mx: 1,
                fontFamily: "Gilroy-SemiBold",
                fontSize: 16,
              }}
              label={t("landingpage.leftContent.tabs.tab.G")}
            />
          </Tabs>

          <Stack direction="row" alignItems="center" gap={1}>
            <IconButton
              sx={{
                bgcolor: "#4165F633",
                border: "1px solid #4165F6",
                color: "#4165F6",
                width: 30,
                height: 30,
              }}
              onClick={() => handleScrollButtonClick("left")}
            >
              <NavigateBeforeIcon />
            </IconButton>
            <IconButton
              sx={{
                bgcolor: "#4165F633",
                border: "1px solid #4165F6",
                color: "#4165F6",
                width: 30,
                height: 30,
              }}
              onClick={() => handleScrollButtonClick("right")}
            >
              <NavigateNextIcon />
            </IconButton>
          </Stack>
        </Stack>
      </Box>
      <TabPanel value={value} index={0}>
        <EmptyScreen />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box>
          <SocioeconomicMain />
        </Box>
        {/* <EmptyScreen /> */}
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Box>
          <EmploymentHistory />
        </Box>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Box>
          <HomonymousLegalHistory />
        </Box>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Box>
          <DebtAnalysis />
        </Box>
      </TabPanel>
      <TabPanel value={value} index={5}>
        <Box>
          <MortgageCredit />
          {/* <img style={{ width: "100%",height:"100%" }} src={reportImage} alt="report image" /> */}
        </Box>
      </TabPanel>
      <TabPanel value={value} index={6}>
        <Box>
          <RelevantInformation />
        </Box>
      </TabPanel>
      <TabPanel value={value} index={7}>
        <Box>
          <FinalRecommendation />
        </Box>
      </TabPanel>
    </Box>
  );
}
