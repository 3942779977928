import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { Fragment, useState, useEffect } from "react";
import logo from "../../assets/images/logo.png";
import underLine from "../../assets/images/under-line.png";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import LabelInputLeftIcon from "../@common/Input/LabelInputLeftIcon";
import PasswordShowHideIcon from "../@common/Input/PasswordShowHideIcon/PasswordShowHideIcon";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";
import google from "../../assets/images/google.png";
import microSoft from "../../assets/images/microsoft.png";
import Footer from "../../Footer/Footer";
import bgImage from "../../assets/images/coverphoto.png";
import AnimationLoginScreen from "../@common/AnimationLoginScreen/AnimationLoginScreen";
import mailImage from "../../assets/images/mailIcon.png";
import sjcl from "sjcl";
import axios from "axios";
import AlertModal from "../@common/Modal/AlertModal";
import { encryptData } from "../../utils/encryptionHelper";
import { serverRequest2 } from "../../utils/requestHelper";
import {
  validateInput,
  validatePassword,
  validateConfirmPassword,
} from "../../utils/inputValidation";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AuthLeftScreen from "../@common/AuthLeftScreen";
function ResetPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [showtext, setShowtext] = useState("");
  const [formErrors, setFormErrors] = useState({
    password: "",
    confirmPassword: "",
  });

  useEffect(() => {
    if (showtext != "") {
      setShowAlert(true);
    }
  }, [showtext]);

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const updateHandler = async (e) => {
    // validation
    const errors = {};
    const passwordErrors = validatePassword(password, t);
    if (Object.keys(passwordErrors).length > 0) {
      errors.password = passwordErrors.password;
    }
    const confirmPasswordValidationResult = validateConfirmPassword(
      password,
      confirmPassword,
      t
    );
    if (confirmPasswordValidationResult.error) {
      errors.confirmPassword = confirmPasswordValidationResult.message;
    } else {
      delete errors.confirmPassword;
    }
    setFormErrors(errors);
    // if no errors call api
    if (Object.keys(errors).length === 0) {
      await changePassword();
    }
  };
  const changePassword = async () => {
    var data = [];
    data.push(password);

    var getToken = new URLSearchParams(window.location.search).get("token");
    if (getToken != null) {
      const data_epd = encryptData(data[0], 500);
      var dataForm_ = {
        data_epd: {
          data_epdi: data_epd["iv"],
          data_epds: data_epd["salt"],
          data_epdc: data_epd["ct"],
        },
        token: getToken,
      };
      const response = await serverRequest2("post", "change_pass", dataForm_);
      if (!response.data) {
        setShowtext("Link is Invalid Or Expired");
      } else if (response.data == "error bd") {
        setShowtext(t("thanks.check"));
      } else if (response.data == true) {
        navigate("/Login");
      } else {
        setShowtext("Check your internet connection");
      }
    }
  };

  return (
    <>
      {showAlert && <AlertModal text={showtext} onClose={handleCloseAlert} />}

      <Box
        sx={{
          background: "#fff",
        }}
        id="particles-js"
      >
        <Grid container>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
                minHeight: "100vh",
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Box
                  sx={{
                    background: "#ffffff",
                    position: "relative",
                    zIndex: 1,
                    borderRadius: 1,
                    p: { xs: 2, sm: 4 },
                    width: { xs: "90%", sm: "500px" },
                    margin: "auto",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <img src={logo} alt="logo" />
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: "Poppins-SemiBold",
                        fontSize: "24px",
                        lineHeight: "35px",
                        textAlign: "center",
                        color: "#1C212D",
                      }}
                    >
                      {t("ResetPassword?token={token}.header.title")}
                    </Typography>
                    <Box sx={{ textAlign: "center", mt: -1 }}>
                      <img src={underLine} alt="under line" />
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", mt: 4 }}>
                    <PasswordShowHideIcon
                      type="text"
                      label={t(
                        "ResetPassword?token={token}.inputs.inputLabelNewPass"
                      )}
                      name="newpassword"
                      id="newpassword"
                      placeholder={t(
                        "ResetPassword?token={token}.inputs.inputTextNewPass"
                      )}
                      onChange={(e) => setPassword(e.target.value)}
                      error={formErrors.password}
                    />
                  </Box>
                  {formErrors.password && (
                    <Typography variant="body2" color="error">
                      {formErrors.password}
                    </Typography>
                  )}
                  <Box sx={{ mt: 1 }}>
                    <PasswordShowHideIcon
                      type="text"
                      label={t(
                        "ResetPassword?token={token}.inputs.inputLabelConfirmPass"
                      )}
                      name="confirmpassword"
                      id="confirmpassword"
                      placeholder={t(
                        "ResetPassword?token={token}.inputs.inputTextConfirmPass"
                      )}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      error={formErrors.confirmPassword}
                    />
                    {formErrors.confirmPassword && (
                      <Typography variant="body2" color="error">
                        {formErrors.confirmPassword}
                      </Typography>
                    )}
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      mt: 3,
                    }}
                  >
                    <Button
                      onClick={updateHandler}
                      sx={{
                        backgroundColor: "primary.main",
                        py: 1,
                        px: { xs: 4, sm: 10 },
                        fontSize: "18px",
                        fontFamily: "Gilroy-Medium",
                        textTransform: "capitalize !important",
                      }}
                      variant="contained"
                    >
                      {t(
                        "ResetPassword?token={token}.buttons.buttonTextResetPass"
                      )}
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flex: "row",
                      mt: 2,
                    }}
                  >
                    <Link
                      to="/"
                      style={{
                        color: "rgba(33, 33, 33, 0.5)",
                        fontFamily: "Gilroy-Medium",
                        fontSize: "16px",
                        display: "flex",
                        justifyContent: "center",
                        textDecoration: "underline",
                      }}
                    >
                      {t(
                        "ResetPassword?token={token}.buttons.returnMainPageLink"
                      )}
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <AuthLeftScreen
              text={t("Login.buttons.signUpLink")}
              url="/Signup"
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default ResetPassword;
