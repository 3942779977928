import React, { useState, useCallback, useEffect } from "react";
import { Grid, Box } from "@mui/material";
import RadioButtonsGroup from "../@common/Input/radios";
import Button from "@mui/material/Button";
import CustomizedReportTabs from "./CustomizedReportTabs";
import MultiReportWithBureau from "./MultiReportWithBureau";
import { getPersonalization, savePersonalization } from "../../utils/bureauHelper";
import { getCookiesList } from "../../utils/cookiesHelper";
import { useTranslation } from "react-i18next";

function MultiReport() {
  const { t } = useTranslation();
  const Tipo = [
    { label: t("labels.radioLabelWithBureau"), value: "With Bureau" },
    { label: t("labels.radioLabelWithoutBureau"), value: "Without Bureau" },
  ];
  // Load the TipoType value from local storage or use 'with Bureau' as the default value
  const initialTipoType = localStorage.getItem("TipoType") || "Without Bureau";
  const [TipoType, setTipoType] = useState(initialTipoType);
  const [showCustomizedReportTabs, setShowCustomizedReportTabs] =
    useState(false);
  const [showMultiReportWithBureau, setMultiReportWithBureau] = useState(false);
  const [personalization, setPersonalization] = useState({});
  const handleTipoType = useCallback((e) => {
    console.log('handleTipoType', e.target.value);
    const newValue = e.target.value;
    setTipoType(newValue);
    // Store the new value in local storage
    localStorage.setItem("TipoType", newValue);
  }, []);
  const toggleMultiReportWithBureau = () => {
    setMultiReportWithBureau(true);
    setShowCustomizedReportTabs(false);
  };
  const toggleCustomizedReportTabs = () => {
    setShowCustomizedReportTabs(!showCustomizedReportTabs);
  };
  useEffect(() => async () => {
    // Set the initial value in local storage when the component mounts
    const storedTipoType = localStorage.getItem("TipoType") || "Without Bureau";
    localStorage.setItem("TipoType", storedTipoType);
    setTipoType(storedTipoType);
  }, []);

  const savePersonalizationAndUpdate = async (email, TipoType, switchValues) => {
    try {
      const response = await savePersonalization(email, TipoType, switchValues);
      setPersonalization(await getPersonalization(TipoType == "Without Bureau" ? "SB" : "CB"));
      return response;
    } catch (error) {
      console.error("Error saving personalization:", error);
    }
  };

  useEffect(() => {
    const get_personalization = async () => {
      setPersonalization(await getPersonalization(TipoType == "Without Bureau" ? "SB" : "CB"));
    }
    get_personalization();
  }, [TipoType]);

  return (
    <Box>
      {!showCustomizedReportTabs && !showMultiReportWithBureau && (
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={12}>
            <Box
              sx={{
                backgroundColor: "#ffffff",
                p: 4,
                borderRadius: 2,
                width: "100%",
                maxWidth: { xs: "100%", sm: 420 },
                height: "100%",
                m: "auto",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <RadioButtonsGroup
                  topLabelName={t("CustomizedReport.subtitle")}
                  topLabel={true}
                  GroupingList={Tipo}
                  value={TipoType}
                  onChange={handleTipoType}
                  smGrid={6}
                  xs={6}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  mt: 4,
                }}
              >
                <Button
                  onClick={() => {
                    toggleCustomizedReportTabs();
                  }}
                  sx={{
                    backgroundColor: "primary.main",
                    py: 1,
                    px: { xs: 2, sm: 6 },
                    fontSize: "18px",
                    fontFamily: "Gilroy-Medium",
                    textTransform: "capitalize",
                  }}
                  variant="contained"
                >
                  {t("buttons.buttonTextContinue")}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
      {showCustomizedReportTabs && <CustomizedReportTabs 
        personalization={personalization}
        savePersonalization={savePersonalizationAndUpdate}
        TipoType={TipoType} 
      />}
      {showMultiReportWithBureau && <MultiReportWithBureau personalization={personalization} savePersonalization={savePersonalizationAndUpdate} />}
    </Box>
  );
}

export default MultiReport;
