import React,{useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Fragment } from 'react';
import { textAlign } from '@mui/system';
import {Link,useNavigate }from 'react-router-dom';
import sucessImage from "../../../assets/images/errorIcon.svg"

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 520,
  maxWidth:"95%",
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius:4
};

const subjectList = [
    "The present subject is found",
  ]

export default function UploadErrorModal(props) {
  const { text="" } = props
  const { handleOpen } = props
  const { isOpen } = props
  const {errorMessage} = props
  const navigate = useNavigate();
  const handleClose = () => handleOpen(false);

  const sendHandler = () => {
   alert("sendHandler")
  };


  return (
    <Fragment>
      {/* <Button
      sx={{
        backgroundColor:"primary.main",
        textAlign:"center",
        borderWidth:1,
        borderColor:"primary.main",
        borderStyle:"solid",
        borderRadius:"6px",
        py:1.5,
        px:8,
        color:"#fff",
        textDecoration:"none",
        fontSize:18,
        fontFamily:"Gilroy-Medium",
        textTransform:"capitalize"
     }}
       onClick={handleOpen} variant="contained">Send</Button> */}
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <Box sx={{justifyContent:"center",display:"flex", alignItems:"center"}}>
                <img src={sucessImage} width="68px" height="68px" alt="email icon" />
            </Box>
            <Box sx={{display:"flex",justifyContent:"center", mt:2,flexDirection:"column"}}>
          <Typography
          sx={{
            fontSize:22,
            fontFamily: "Gilroy-Medium",
            color:"neutral.900",
            textAlign:"center",
          }}
          >
         Dear user, your mass upload file presents the missing data in the following columns:
          </Typography>
          <Typography
          sx={{
            fontSize:16,
            fontFamily: "Gilroy-Medium",
            color:"rgba(27, 32, 44, 0.6)",
            textAlign:"center",
            mt:2
          }}
          >
            {errorMessage}
          </Typography>
          <Typography
          sx={{
            fontSize:16,
            fontFamily: "Gilroy-Medium",
            color:"neutral.900",
            textAlign:"center",
            mt:2
          }}
          >
         Please download the file, enter the pending data and upload it again, Thank you
          </Typography>
          </Box>
         
          <Box sx={{display:"flex",flexDirection:"row",justifyContent:"center",mt:4}}>
          <Button onClick={handleClose} sx={{backgroundColor:"primary.main",py:1,px:{xs:4,sm:8},fontSize:"18px",  fontFamily: "Gilroy-Medium",textTransform:"capitalize" }} variant="contained">Continue</Button>
          </Box>
        </Box>
      </Modal>
    </Fragment>
  );
}