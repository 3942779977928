
export const options_edo = [
    {"name": "State", "value": "State"},
    {"name": "Aguascalientes", "value": "Aguascalientes"},
    {"name": "Baja California", "value": "Baja California"},
    {"name": "Baja California Sur", "value": "Baja California Sur"},
    {"name": "Campeche", "value": "Campeche"},
    {"name": "Coahuila de Zaragoza", "value": "Coahuila de Zaragoza"},
    {"name": "Colima", "value": "Colima"},
    {"name": "Chiapas", "value": "Chiapas"},
    {"name": "Chihuahua", "value": "Chihuahua"},
    {"name": "Ciudad de México", "value": "Ciudad de México"},
    {"name": "Durango", "value": "Durango"},
    {"name": "Estado de México", "value": "Estado de México"},
    {"name": "Guanajuato", "value": "Guanajuato"},
    {"name": "Guerrero", "value": "Guerrero"},
    {"name": "Hidalgo", "value": "Hidalgo"},
    {"name": "Jalisco", "value": "Jalisco"},
    {"name": "Michoacán de Ocampo", "value": "Michoacán de Ocampo"},
    {"name": "Morelos", "value": "Morelos"},
    {"name": "Nayarit", "value": "Nayarit"},
    {"name": "Nuevo León", "value": "Nuevo León"},
    {"name": "Oaxaca", "value": "Oaxaca"},
    {"name": "Puebla", "value": "Puebla"},
    {"name": "Querétaro", "value": "Querétaro"},
    {"name": "Quintana Roo", "value": "Quintana Roo"},
    {"name": "San Luis Potosí", "value": "San Luis Potosí"},
    {"name": "Sinaloa", "value": "Sinaloa"},
    {"name": "Sonora", "value": "Sonora"},
    {"name": "Tabasco", "value": "Tabasco"},
    {"name": "Tamaulipas", "value": "Tamaulipas"},
    {"name": "Tlaxcala", "value": "Tlaxcala"},
    {"name": "Veracruz de Ignacio de la Llave", "value": "Veracruz de Ignacio de la Llave"},
    {"name": "Yucatán", "value": "Yucatán"},
    {"name": "Zacatecas", "value": "Zacatecas"},
    {"name": "Nacido en el Extranjero", "value": "Nacido en el Extranjero"},
  ]

export const options_phone = [
    {
      "code": "+52",
      "name": "Mexico",
      "flag": "https://flagcdn.com/mx.svg"
    },
    {
      "code": "+1",
      "name": "United States",
      "flag": "https://flagcdn.com/us.svg"
    },
    {
      "code": "+93",
      "name": "Afghanistan",
      "flag": "https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_the_Taliban.svg"
    },
    {
      "code": "+355",
      "name": "Albania",
      "flag": "https://flagcdn.com/al.svg"
    },
    {
      "code": "+213",
      "name": "Algeria",
      "flag": "https://flagcdn.com/dz.svg"
    },
    {
      "code": "+1684",
      "name": "American Samoa",
      "flag": "https://flagcdn.com/as.svg"
    },
    {
      "code": "+376",
      "name": "Andorra",
      "flag": "https://flagcdn.com/ad.svg"
    },
    {
      "code": "+244",
      "name": "Angola",
      "flag": "https://flagcdn.com/ao.svg"
    },
    {
      "code": "+1264",
      "name": "Anguilla",
      "flag": "https://flagcdn.com/ai.svg"
    },
    {
      "code": "",
      "name": "Antarctica",
      "flag": "https://flagcdn.com/aq.svg"
    },
    {
      "code": "+1268",
      "name": "Antigua and Barbuda",
      "flag": "https://flagcdn.com/ag.svg"
    },
    {
      "code": "+54",
      "name": "Argentina",
      "flag": "https://flagcdn.com/ar.svg"
    },
    {
      "code": "+374",
      "name": "Armenia",
      "flag": "https://flagcdn.com/am.svg"
    },
    {
      "code": "+297",
      "name": "Aruba",
      "flag": "https://flagcdn.com/aw.svg"
    },
    {
      "code": "+61",
      "name": "Australia",
      "flag": "https://flagcdn.com/au.svg"
    },
    {
      "code": "+43",
      "name": "Austria",
      "flag": "https://flagcdn.com/at.svg"
    },
    {
      "code": "+994",
      "name": "Azerbaijan",
      "flag": "https://flagcdn.com/az.svg"
    },
    {
      "code": "+1242",
      "name": "Bahamas",
      "flag": "https://flagcdn.com/bs.svg"
    },
    {
      "code": "+973",
      "name": "Bahrain",
      "flag": "https://flagcdn.com/bh.svg"
    },
    {
      "code": "+880",
      "name": "Bangladesh",
      "flag": "https://flagcdn.com/bd.svg"
    },
    {
      "code": "+1246",
      "name": "Barbados",
      "flag": "https://flagcdn.com/bb.svg"
    },
    {
      "code": "+375",
      "name": "Belarus",
      "flag": "https://flagcdn.com/by.svg"
    },
    {
      "code": "+32",
      "name": "Belgium",
      "flag": "https://flagcdn.com/be.svg"
    },
    {
      "code": "+501",
      "name": "Belize",
      "flag": "https://flagcdn.com/bz.svg"
    },
    {
      "code": "+229",
      "name": "Benin",
      "flag": "https://flagcdn.com/bj.svg"
    },
    {
      "code": "+1441",
      "name": "Bermuda",
      "flag": "https://flagcdn.com/bm.svg"
    },
    {
      "code": "+975",
      "name": "Bhutan",
      "flag": "https://flagcdn.com/bt.svg"
    },
    {
      "code": "+591",
      "name": "Bolivia",
      "flag": "https://flagcdn.com/bo.svg"
    },
    {
      "code": "+387",
      "name": "Bosnia and Herzegovina",
      "flag": "https://flagcdn.com/ba.svg"
    },
    {
      "code": "+267",
      "name": "Botswana",
      "flag": "https://flagcdn.com/bw.svg"
    },
    {
      "code": "+47",
      "name": "Bouvet Island",
      "flag": "https://flagcdn.com/bv.svg"
    },
    {
      "code": "+55",
      "name": "Brazil",
      "flag": "https://flagcdn.com/br.svg"
    },
    {
      "code": "+246",
      "name": "British Indian Ocean Territory",
      "flag": "https://flagcdn.com/io.svg"
    },
    {
      "code": "+1284",
      "name": "British Virgin Islands",
      "flag": "https://flagcdn.com/vg.svg"
    },
    {
      "code": "+673",
      "name": "Brunei",
      "flag": "https://flagcdn.com/bn.svg"
    },
    {
      "code": "+359",
      "name": "Bulgaria",
      "flag": "https://flagcdn.com/bg.svg"
    },
    {
      "code": "+226",
      "name": "Burkina Faso",
      "flag": "https://flagcdn.com/bf.svg"
    },
    {
      "code": "+257",
      "name": "Burundi",
      "flag": "https://flagcdn.com/bi.svg"
    },
    {
      "code": "+855",
      "name": "Cambodia",
      "flag": "https://flagcdn.com/kh.svg"
    },
    {
      "code": "+237",
      "name": "Cameroon",
      "flag": "https://flagcdn.com/cm.svg"
    },
    {
      "code": "+1",
      "name": "Canada",
      "flag": "https://flagcdn.com/ca.svg"
    },
    {
      "code": "+238",
      "name": "Cape Verde",
      "flag": "https://flagcdn.com/cv.svg"
    },
    {
      "code": "+599",
      "name": "Caribbean Netherlands",
      "flag": "https://flagcdn.com/bq.svg"
    },
    {
      "code": "+1345",
      "name": "Cayman Islands",
      "flag": "https://flagcdn.com/ky.svg"
    },
    {
      "code": "+236",
      "name": "Central African Republic",
      "flag": "https://flagcdn.com/cf.svg"
    },
    {
      "code": "+235",
      "name": "Chad",
      "flag": "https://flagcdn.com/td.svg"
    },
    {
      "code": "+56",
      "name": "Chile",
      "flag": "https://flagcdn.com/cl.svg"
    },
    {
      "code": "+86",
      "name": "China",
      "flag": "https://flagcdn.com/cn.svg"
    },
    {
      "code": "+61",
      "name": "Christmas Island",
      "flag": "https://flagcdn.com/cx.svg"
    },
    {
      "code": "+61",
      "name": "Cocos (Keeling) Islands",
      "flag": "https://flagcdn.com/cc.svg"
    },
    {
      "code": "+57",
      "name": "Colombia",
      "flag": "https://flagcdn.com/co.svg"
    },
    {
      "code": "+269",
      "name": "Comoros",
      "flag": "https://flagcdn.com/km.svg"
    },
    {
      "code": "+682",
      "name": "Cook Islands",
      "flag": "https://flagcdn.com/ck.svg"
    },
    {
      "code": "+506",
      "name": "Costa Rica",
      "flag": "https://flagcdn.com/cr.svg"
    },
    {
      "code": "+385",
      "name": "Croatia",
      "flag": "https://flagcdn.com/hr.svg"
    },
    {
      "code": "+53",
      "name": "Cuba",
      "flag": "https://flagcdn.com/cu.svg"
    },
    {
      "code": "+599",
      "name": "Curaçao",
      "flag": "https://flagcdn.com/cw.svg"
    },
    {
      "code": "+357",
      "name": "Cyprus",
      "flag": "https://flagcdn.com/cy.svg"
    },
    {
      "code": "+420",
      "name": "Czechia",
      "flag": "https://flagcdn.com/cz.svg"
    },
    {
      "code": "+243",
      "name": "DR Congo",
      "flag": "https://flagcdn.com/cd.svg"
    },
    {
      "code": "+45",
      "name": "Denmark",
      "flag": "https://flagcdn.com/dk.svg"
    },
    {
      "code": "+253",
      "name": "Djibouti",
      "flag": "https://flagcdn.com/dj.svg"
    },
    {
      "code": "+1767",
      "name": "Dominica",
      "flag": "https://flagcdn.com/dm.svg"
    },
    {
      "code": "+1809",
      "name": "Dominican Republic",
      "flag": "https://flagcdn.com/do.svg"
    },
    {
      "code": "+593",
      "name": "Ecuador",
      "flag": "https://flagcdn.com/ec.svg"
    },
    {
      "code": "+20",
      "name": "Egypt",
      "flag": "https://flagcdn.com/eg.svg"
    },
    {
      "code": "+503",
      "name": "El Salvador",
      "flag": "https://flagcdn.com/sv.svg"
    },
    {
      "code": "+240",
      "name": "Equatorial Guinea",
      "flag": "https://flagcdn.com/gq.svg"
    },
    {
      "code": "+291",
      "name": "Eritrea",
      "flag": "https://flagcdn.com/er.svg"
    },
    {
      "code": "+372",
      "name": "Estonia",
      "flag": "https://flagcdn.com/ee.svg"
    },
    {
      "code": "+268",
      "name": "Eswatini",
      "flag": "https://flagcdn.com/sz.svg"
    },
    {
      "code": "+251",
      "name": "Ethiopia",
      "flag": "https://flagcdn.com/et.svg"
    },
    {
      "code": "+500",
      "name": "Falkland Islands",
      "flag": "https://flagcdn.com/fk.svg"
    },
    {
      "code": "+298",
      "name": "Faroe Islands",
      "flag": "https://flagcdn.com/fo.svg"
    },
    {
      "code": "+679",
      "name": "Fiji",
      "flag": "https://flagcdn.com/fj.svg"
    },
    {
      "code": "+358",
      "name": "Finland",
      "flag": "https://flagcdn.com/fi.svg"
    },
    {
      "code": "+33",
      "name": "France",
      "flag": "https://flagcdn.com/fr.svg"
    },
    {
      "code": "+594",
      "name": "French Guiana",
      "flag": "https://flagcdn.com/gf.svg"
    },
    {
      "code": "+689",
      "name": "French Polynesia",
      "flag": "https://flagcdn.com/pf.svg"
    },
    {
      "code": "+262",
      "name": "French Southern and Antarctic Lands",
      "flag": "https://flagcdn.com/tf.svg"
    },
    {
      "code": "+241",
      "name": "Gabon",
      "flag": "https://flagcdn.com/ga.svg"
    },
    {
      "code": "+220",
      "name": "Gambia",
      "flag": "https://flagcdn.com/gm.svg"
    },
    {
      "code": "+995",
      "name": "Georgia",
      "flag": "https://flagcdn.com/ge.svg"
    },
    {
      "code": "+49",
      "name": "Germany",
      "flag": "https://flagcdn.com/de.svg"
    },
    {
      "code": "+233",
      "name": "Ghana",
      "flag": "https://flagcdn.com/gh.svg"
    },
    {
      "code": "+350",
      "name": "Gibraltar",
      "flag": "https://flagcdn.com/gi.svg"
    },
    {
      "code": "+30",
      "name": "Greece",
      "flag": "https://flagcdn.com/gr.svg"
    },
    {
      "code": "+299",
      "name": "Greenland",
      "flag": "https://flagcdn.com/gl.svg"
    },
    {
      "code": "+1473",
      "name": "Grenada",
      "flag": "https://flagcdn.com/gd.svg"
    },
    {
      "code": "+590",
      "name": "Guadeloupe",
      "flag": "https://flagcdn.com/gp.svg"
    },
    {
      "code": "+1671",
      "name": "Guam",
      "flag": "https://flagcdn.com/gu.svg"
    },
    {
      "code": "+502",
      "name": "Guatemala",
      "flag": "https://flagcdn.com/gt.svg"
    },
    {
      "code": "+44",
      "name": "Guernsey",
      "flag": "https://flagcdn.com/gg.svg"
    },
    {
      "code": "+224",
      "name": "Guinea",
      "flag": "https://flagcdn.com/gn.svg"
    },
    {
      "code": "+245",
      "name": "Guinea-Bissau",
      "flag": "https://flagcdn.com/gw.svg"
    },
    {
      "code": "+592",
      "name": "Guyana",
      "flag": "https://flagcdn.com/gy.svg"
    },
    {
      "code": "+509",
      "name": "Haiti",
      "flag": "https://flagcdn.com/ht.svg"
    },
    {
      "code": "",
      "name": "Heard Island and McDonald Islands",
      "flag": "https://flagcdn.com/hm.svg"
    },
    {
      "code": "+504",
      "name": "Honduras",
      "flag": "https://flagcdn.com/hn.svg"
    },
    {
      "code": "+852",
      "name": "Hong Kong",
      "flag": "https://flagcdn.com/hk.svg"
    },
    {
      "code": "+36",
      "name": "Hungary",
      "flag": "https://flagcdn.com/hu.svg"
    },
    {
      "code": "+354",
      "name": "Iceland",
      "flag": "https://flagcdn.com/is.svg"
    },
    {
      "code": "+91",
      "name": "India",
      "flag": "https://flagcdn.com/in.svg"
    },
    {
      "code": "+62",
      "name": "Indonesia",
      "flag": "https://flagcdn.com/id.svg"
    },
    {
      "code": "+98",
      "name": "Iran",
      "flag": "https://flagcdn.com/ir.svg"
    },
    {
      "code": "+964",
      "name": "Iraq",
      "flag": "https://flagcdn.com/iq.svg"
    },
    {
      "code": "+353",
      "name": "Ireland",
      "flag": "https://flagcdn.com/ie.svg"
    },
    {
      "code": "+44",
      "name": "Isle of Man",
      "flag": "https://flagcdn.com/im.svg"
    },
    {
      "code": "+972",
      "name": "Israel",
      "flag": "https://flagcdn.com/il.svg"
    },
    {
      "code": "+39",
      "name": "Italy",
      "flag": "https://flagcdn.com/it.svg"
    },
    {
      "code": "+225",
      "name": "Ivory Coast",
      "flag": "https://flagcdn.com/ci.svg"
    },
    {
      "code": "+1876",
      "name": "Jamaica",
      "flag": "https://flagcdn.com/jm.svg"
    },
    {
      "code": "+81",
      "name": "Japan",
      "flag": "https://flagcdn.com/jp.svg"
    },
    {
      "code": "+44",
      "name": "Jersey",
      "flag": "https://flagcdn.com/je.svg"
    },
    {
      "code": "+962",
      "name": "Jordan",
      "flag": "https://flagcdn.com/jo.svg"
    },
    {
      "code": "+76",
      "name": "Kazakhstan",
      "flag": "https://flagcdn.com/kz.svg"
    },
    {
      "code": "+254",
      "name": "Kenya",
      "flag": "https://flagcdn.com/ke.svg"
    },
    {
      "code": "+686",
      "name": "Kiribati",
      "flag": "https://flagcdn.com/ki.svg"
    },
    {
      "code": "+383",
      "name": "Kosovo",
      "flag": "https://flagcdn.com/xk.svg"
    },
    {
      "code": "+965",
      "name": "Kuwait",
      "flag": "https://flagcdn.com/kw.svg"
    },
    {
      "code": "+996",
      "name": "Kyrgyzstan",
      "flag": "https://flagcdn.com/kg.svg"
    },
    {
      "code": "+856",
      "name": "Laos",
      "flag": "https://flagcdn.com/la.svg"
    },
    {
      "code": "+371",
      "name": "Latvia",
      "flag": "https://flagcdn.com/lv.svg"
    },
    {
      "code": "+961",
      "name": "Lebanon",
      "flag": "https://flagcdn.com/lb.svg"
    },
    {
      "code": "+266",
      "name": "Lesotho",
      "flag": "https://flagcdn.com/ls.svg"
    },
    {
      "code": "+231",
      "name": "Liberia",
      "flag": "https://flagcdn.com/lr.svg"
    },
    {
      "code": "+218",
      "name": "Libya",
      "flag": "https://flagcdn.com/ly.svg"
    },
    {
      "code": "+423",
      "name": "Liechtenstein",
      "flag": "https://flagcdn.com/li.svg"
    },
    {
      "code": "+370",
      "name": "Lithuania",
      "flag": "https://flagcdn.com/lt.svg"
    },
    {
      "code": "+352",
      "name": "Luxembourg",
      "flag": "https://flagcdn.com/lu.svg"
    },
    {
      "code": "+853",
      "name": "Macau",
      "flag": "https://flagcdn.com/mo.svg"
    },
    {
      "code": "+261",
      "name": "Madagascar",
      "flag": "https://flagcdn.com/mg.svg"
    },
    {
      "code": "+265",
      "name": "Malawi",
      "flag": "https://flagcdn.com/mw.svg"
    },
    {
      "code": "+60",
      "name": "Malaysia",
      "flag": "https://flagcdn.com/my.svg"
    },
    {
      "code": "+960",
      "name": "Maldives",
      "flag": "https://flagcdn.com/mv.svg"
    },
    {
      "code": "+223",
      "name": "Mali",
      "flag": "https://flagcdn.com/ml.svg"
    },
    {
      "code": "+356",
      "name": "Malta",
      "flag": "https://flagcdn.com/mt.svg"
    },
    {
      "code": "+692",
      "name": "Marshall Islands",
      "flag": "https://flagcdn.com/mh.svg"
    },
    {
      "code": "+596",
      "name": "Martinique",
      "flag": "https://flagcdn.com/mq.svg"
    },
    {
      "code": "+222",
      "name": "Mauritania",
      "flag": "https://flagcdn.com/mr.svg"
    },
    {
      "code": "+230",
      "name": "Mauritius",
      "flag": "https://flagcdn.com/mu.svg"
    },
    {
      "code": "+262",
      "name": "Mayotte",
      "flag": "https://flagcdn.com/yt.svg"
    },
    {
      "code": "+691",
      "name": "Micronesia",
      "flag": "https://flagcdn.com/fm.svg"
    },
    {
      "code": "+373",
      "name": "Moldova",
      "flag": "https://flagcdn.com/md.svg"
    },
    {
      "code": "+377",
      "name": "Monaco",
      "flag": "https://flagcdn.com/mc.svg"
    },
    {
      "code": "+976",
      "name": "Mongolia",
      "flag": "https://flagcdn.com/mn.svg"
    },
    {
      "code": "+382",
      "name": "Montenegro",
      "flag": "https://flagcdn.com/me.svg"
    },
    {
      "code": "+1664",
      "name": "Montserrat",
      "flag": "https://flagcdn.com/ms.svg"
    },
    {
      "code": "+212",
      "name": "Morocco",
      "flag": "https://flagcdn.com/ma.svg"
    },
    {
      "code": "+258",
      "name": "Mozambique",
      "flag": "https://flagcdn.com/mz.svg"
    },
    {
      "code": "+95",
      "name": "Myanmar",
      "flag": "https://flagcdn.com/mm.svg"
    },
    {
      "code": "+264",
      "name": "Namibia",
      "flag": "https://flagcdn.com/na.svg"
    },
    {
      "code": "+674",
      "name": "Nauru",
      "flag": "https://flagcdn.com/nr.svg"
    },
    {
      "code": "+977",
      "name": "Nepal",
      "flag": "https://flagcdn.com/np.svg"
    },
    {
      "code": "+31",
      "name": "Netherlands",
      "flag": "https://flagcdn.com/nl.svg"
    },
    {
      "code": "+687",
      "name": "New Caledonia",
      "flag": "https://flagcdn.com/nc.svg"
    },
    {
      "code": "+64",
      "name": "New Zealand",
      "flag": "https://flagcdn.com/nz.svg"
    },
    {
      "code": "+505",
      "name": "Nicaragua",
      "flag": "https://flagcdn.com/ni.svg"
    },
    {
      "code": "+227",
      "name": "Niger",
      "flag": "https://flagcdn.com/ne.svg"
    },
    {
      "code": "+234",
      "name": "Nigeria",
      "flag": "https://flagcdn.com/ng.svg"
    },
    {
      "code": "+683",
      "name": "Niue",
      "flag": "https://flagcdn.com/nu.svg"
    },
    {
      "code": "+672",
      "name": "Norfolk Island",
      "flag": "https://flagcdn.com/nf.svg"
    },
    {
      "code": "+850",
      "name": "North Korea",
      "flag": "https://flagcdn.com/kp.svg"
    },
    {
      "code": "+389",
      "name": "North Macedonia",
      "flag": "https://flagcdn.com/mk.svg"
    },
    {
      "code": "+1670",
      "name": "Northern Mariana Islands",
      "flag": "https://flagcdn.com/mp.svg"
    },
    {
      "code": "+47",
      "name": "Norway",
      "flag": "https://flagcdn.com/no.svg"
    },
    {
      "code": "+968",
      "name": "Oman",
      "flag": "https://flagcdn.com/om.svg"
    },
    {
      "code": "+92",
      "name": "Pakistan",
      "flag": "https://flagcdn.com/pk.svg"
    },
    {
      "code": "+680",
      "name": "Palau",
      "flag": "https://flagcdn.com/pw.svg"
    },
    {
      "code": "+970",
      "name": "Palestine",
      "flag": "https://flagcdn.com/ps.svg"
    },
    {
      "code": "+507",
      "name": "Panama",
      "flag": "https://flagcdn.com/pa.svg"
    },
    {
      "code": "+675",
      "name": "Papua New Guinea",
      "flag": "https://flagcdn.com/pg.svg"
    },
    {
      "code": "+595",
      "name": "Paraguay",
      "flag": "https://flagcdn.com/py.svg"
    },
    {
      "code": "+51",
      "name": "Peru",
      "flag": "https://flagcdn.com/pe.svg"
    },
    {
      "code": "+63",
      "name": "Philippines",
      "flag": "https://flagcdn.com/ph.svg"
    },
    {
      "code": "+64",
      "name": "Pitcairn Islands",
      "flag": "https://flagcdn.com/pn.svg"
    },
    {
      "code": "+48",
      "name": "Poland",
      "flag": "https://flagcdn.com/pl.svg"
    },
    {
      "code": "+351",
      "name": "Portugal",
      "flag": "https://flagcdn.com/pt.svg"
    },
    {
      "code": "+1787",
      "name": "Puerto Rico",
      "flag": "https://flagcdn.com/pr.svg"
    },
    {
      "code": "+974",
      "name": "Qatar",
      "flag": "https://flagcdn.com/qa.svg"
    },
    {
      "code": "+242",
      "name": "Republic of the Congo",
      "flag": "https://flagcdn.com/cg.svg"
    },
    {
      "code": "+40",
      "name": "Romania",
      "flag": "https://flagcdn.com/ro.svg"
    },
    {
      "code": "+73",
      "name": "Russia",
      "flag": "https://flagcdn.com/ru.svg"
    },
    {
      "code": "+250",
      "name": "Rwanda",
      "flag": "https://flagcdn.com/rw.svg"
    },
    {
      "code": "+262",
      "name": "Réunion",
      "flag": "https://flagcdn.com/re.svg"
    },
    {
      "code": "+590",
      "name": "Saint Barthélemy",
      "flag": "https://flagcdn.com/bl.svg"
    },
    {
      "code": "+290",
      "name": "Saint Helena, Ascension and Tristan da Cunha",
      "flag": "https://flagcdn.com/sh.svg"
    },
    {
      "code": "+1869",
      "name": "Saint Kitts and Nevis",
      "flag": "https://flagcdn.com/kn.svg"
    },
    {
      "code": "+1758",
      "name": "Saint Lucia",
      "flag": "https://flagcdn.com/lc.svg"
    },
    {
      "code": "+590",
      "name": "Saint Martin",
      "flag": "https://flagcdn.com/mf.svg"
    },
    {
      "code": "+508",
      "name": "Saint Pierre and Miquelon",
      "flag": "https://flagcdn.com/pm.svg"
    },
    {
      "code": "+1784",
      "name": "Saint Vincent and the Grenadines",
      "flag": "https://flagcdn.com/vc.svg"
    },
    {
      "code": "+685",
      "name": "Samoa",
      "flag": "https://flagcdn.com/ws.svg"
    },
    {
      "code": "+378",
      "name": "San Marino",
      "flag": "https://flagcdn.com/sm.svg"
    },
    {
      "code": "+966",
      "name": "Saudi Arabia",
      "flag": "https://flagcdn.com/sa.svg"
    },
    {
      "code": "+221",
      "name": "Senegal",
      "flag": "https://flagcdn.com/sn.svg"
    },
    {
      "code": "+381",
      "name": "Serbia",
      "flag": "https://flagcdn.com/rs.svg"
    },
    {
      "code": "+248",
      "name": "Seychelles",
      "flag": "https://flagcdn.com/sc.svg"
    },
    {
      "code": "+232",
      "name": "Sierra Leone",
      "flag": "https://flagcdn.com/sl.svg"
    },
    {
      "code": "+65",
      "name": "Singapore",
      "flag": "https://flagcdn.com/sg.svg"
    },
    {
      "code": "+1721",
      "name": "Sint Maarten",
      "flag": "https://flagcdn.com/sx.svg"
    },
    {
      "code": "+421",
      "name": "Slovakia",
      "flag": "https://flagcdn.com/sk.svg"
    },
    {
      "code": "+386",
      "name": "Slovenia",
      "flag": "https://flagcdn.com/si.svg"
    },
    {
      "code": "+677",
      "name": "Solomon Islands",
      "flag": "https://flagcdn.com/sb.svg"
    },
    {
      "code": "+252",
      "name": "Somalia",
      "flag": "https://flagcdn.com/so.svg"
    },
    {
      "code": "+27",
      "name": "South Africa",
      "flag": "https://flagcdn.com/za.svg"
    },
    {
      "code": "+500",
      "name": "South Georgia",
      "flag": "https://flagcdn.com/gs.svg"
    },
    {
      "code": "+82",
      "name": "South Korea",
      "flag": "https://flagcdn.com/kr.svg"
    },
    {
      "code": "+211",
      "name": "South Sudan",
      "flag": "https://flagcdn.com/ss.svg"
    },
    {
      "code": "+34",
      "name": "Spain",
      "flag": "https://flagcdn.com/es.svg"
    },
    {
      "code": "+94",
      "name": "Sri Lanka",
      "flag": "https://flagcdn.com/lk.svg"
    },
    {
      "code": "+249",
      "name": "Sudan",
      "flag": "https://flagcdn.com/sd.svg"
    },
    {
      "code": "+597",
      "name": "Suriname",
      "flag": "https://flagcdn.com/sr.svg"
    },
    {
      "code": "+4779",
      "name": "Svalbard and Jan Mayen",
      "flag": "https://flagcdn.com/sj.svg"
    },
    {
      "code": "+46",
      "name": "Sweden",
      "flag": "https://flagcdn.com/se.svg"
    },
    {
      "code": "+41",
      "name": "Switzerland",
      "flag": "https://flagcdn.com/ch.svg"
    },
    {
      "code": "+963",
      "name": "Syria",
      "flag": "https://flagcdn.com/sy.svg"
    },
    {
      "code": "+239",
      "name": "São Tomé and Príncipe",
      "flag": "https://flagcdn.com/st.svg"
    },
    {
      "code": "+886",
      "name": "Taiwan",
      "flag": "https://flagcdn.com/tw.svg"
    },
    {
      "code": "+992",
      "name": "Tajikistan",
      "flag": "https://flagcdn.com/tj.svg"
    },
    {
      "code": "+255",
      "name": "Tanzania",
      "flag": "https://flagcdn.com/tz.svg"
    },
    {
      "code": "+66",
      "name": "Thailand",
      "flag": "https://flagcdn.com/th.svg"
    },
    {
      "code": "+670",
      "name": "Timor-Leste",
      "flag": "https://flagcdn.com/tl.svg"
    },
    {
      "code": "+228",
      "name": "Togo",
      "flag": "https://flagcdn.com/tg.svg"
    },
    {
      "code": "+690",
      "name": "Tokelau",
      "flag": "https://flagcdn.com/tk.svg"
    },
    {
      "code": "+676",
      "name": "Tonga",
      "flag": "https://flagcdn.com/to.svg"
    },
    {
      "code": "+1868",
      "name": "Trinidad and Tobago",
      "flag": "https://flagcdn.com/tt.svg"
    },
    {
      "code": "+216",
      "name": "Tunisia",
      "flag": "https://flagcdn.com/tn.svg"
    },
    {
      "code": "+90",
      "name": "Turkey",
      "flag": "https://flagcdn.com/tr.svg"
    },
    {
      "code": "+993",
      "name": "Turkmenistan",
      "flag": "https://flagcdn.com/tm.svg"
    },
    {
      "code": "+1649",
      "name": "Turks and Caicos Islands",
      "flag": "https://flagcdn.com/tc.svg"
    },
    {
      "code": "+688",
      "name": "Tuvalu",
      "flag": "https://flagcdn.com/tv.svg"
    },
    {
      "code": "+256",
      "name": "Uganda",
      "flag": "https://flagcdn.com/ug.svg"
    },
    {
      "code": "+380",
      "name": "Ukraine",
      "flag": "https://flagcdn.com/ua.svg"
    },
    {
      "code": "+971",
      "name": "United Arab Emirates",
      "flag": "https://flagcdn.com/ae.svg"
    },
    {
      "code": "+44",
      "name": "United Kingdom",
      "flag": "https://flagcdn.com/gb.svg"
    },
    {
      "code": "+268",
      "name": "United States Minor Outlying Islands",
      "flag": "https://flagcdn.com/um.svg"
    },
    {
      "code": "+1340",
      "name": "United States Virgin Islands",
      "flag": "https://flagcdn.com/vi.svg"
    },
    {
      "code": "+598",
      "name": "Uruguay",
      "flag": "https://flagcdn.com/uy.svg"
    },
    {
      "code": "+998",
      "name": "Uzbekistan",
      "flag": "https://flagcdn.com/uz.svg"
    },
    {
      "code": "+678",
      "name": "Vanuatu",
      "flag": "https://flagcdn.com/vu.svg"
    },
    {
      "code": "+3906698",
      "name": "Vatican City",
      "flag": "https://flagcdn.com/va.svg"
    },
    {
      "code": "+58",
      "name": "Venezuela",
      "flag": "https://flagcdn.com/ve.svg"
    },
    {
      "code": "+84",
      "name": "Vietnam",
      "flag": "https://flagcdn.com/vn.svg"
    },
    {
      "code": "+681",
      "name": "Wallis and Futuna",
      "flag": "https://flagcdn.com/wf.svg"
    },
    {
      "code": "+2125288",
      "name": "Western Sahara",
      "flag": "https://flagcdn.com/eh.svg"
    },
    {
      "code": "+967",
      "name": "Yemen",
      "flag": "https://flagcdn.com/ye.svg"
    },
    {
      "code": "+260",
      "name": "Zambia",
      "flag": "https://flagcdn.com/zm.svg"
    },
    {
      "code": "+263",
      "name": "Zimbabwe",
      "flag": "https://flagcdn.com/zw.svg"
    },
    {
      "code": "+35818",
      "name": "Åland Islands",
      "flag": "https://flagcdn.com/ax.svg"
    }
  ]

export const python2Content=`
def getcurpDetails():
    url = f"{host}/api/v2/getDetails"
    header = {"x-access-email": email, #email de usuario
            "x-access-password": password} #contraseña de usuario
    data = {
        "sujeto_nombre": "Mario", #obligatorio
        "sujeto_apellido_p": "Marquez", #obligatorio
        "sujeto_apellido_m": "Malagon", #obligatorio
        "sujeto_dia_nacimiento": "24", #obligatorio
        "sujeto_mes_nacimiento": "08", #obligatorio
        "sujeto_year_nacimiento": "1983", #obligatorio
        "sujeto_edo_nacimiento": '', #opcional
        "sujeto_sexo": "H", #obligatorio
        "sujeto_calle": 'NA', #Manténlo así porque es SB
        "sujeto_colonia": 'NA', #Manténlo así porque es SB
        "sujeto_municipio": 'NA', #Manténlo así porque es SB
        "sujeto_estado": 'NA', #Manténlo así porque es SB
        "sujeto_codigo_postal": 'NA', #Manténlo así porque es SB
        "sujeto_nss":'', #opcional
        "sujeto_cedula":'' #opcional
    } 
return requests.post(url, data=data, headers=header)

def getconsultwob(data):
    header = {"x-access-email": email, #email de usuario
        "x-access-password": password, #contraseña de usuario
        "sujeto-email": "example@example.mx", #email candidato
        "sujeto-tel": "123456789", #número candidato
        "PP": 'true'
    }
    url = f"{host}/api/v2/getWithoutBureauConsult"
    if data["data"]['status'] == '1':
        obj = {
            "id": data["data"]["curps"]["id"],
            "data": data["data"]["data"],
            "curp": data["data"]["curps"]["curp"],
            "cname": data["data"]["cname"]
        }            
        return requests.post(url, data=obj, headers=header)
    return requests.post(url, data=obj, headers=header)

if __name__ == "__main__":
    print("[¡] ———— getcurpDetails (datos de curp) ———— [¡]")
    Cdata = getcurpDetails().json()
    pprint(Cdata)
    if Cdata['data']['status'] != 'error':
        time.sleep(60)
        print("[¡] ———— getconsultwob (consulta sin buró) ———— [¡]")
        data = getconsultwob(Cdata).json()
        print(data)
    else:
        print('error en los datos/error renapo')
`

export const python3Content = `
# ------------ getcurpDetails (datos de curp) ------------
{
    'curps': {
        'cname': 'MARIO MARQUEZ MALAGON',
        'curp': 'MAMM830824HDFRLR10',
        'edad': '38',
        'estado': 'Ciudad de México',
        'fecha_nac': 'Wed, 24 Aug 1983 00:00:00 GMT'
    },
    'edad': '38',
    'status': '1',
    'powerdby': 'Searchlook'
}

# ------------ getconsultwob (consulta sin buró) ------------
{
    "status": 200,
    "data": {
        "dicok": {
            "Agresores_Sexuales": "No",
            "Aguinaldo": "No datos",
            "Alertas_Internacionales": "No",
            "CURP": "MAMM830824HDFRLR10",
            "Cedula": "No cédula",
            "Circulo_Social": "No",
            "Circulo_Social_Arreglo": [
                {"Edad": "---", "Estatus_Salario": "---", "Nombre": "---", "Parentesco": "---"}
            ],
            "Clinica": "",
            "Clinica_Arreglo": [
                {"Organización": "IMSS", "Unidad": "No clínica"}
            ],
            "Comportamiento_Personal": "No datos",
            "Comportamiento_Personal_porcentaje": "0%",
            "Credito_Dependencia": "Infonavit",
            "Credito_Maximo": "0 / $ 0",
            "Credito_Maximo_Arreglo": [
                {"Tipo": "Tarjeta de Crédito:", "Valor": "0 / $ 0"},
                {"Tipo": "Préstamo Personal:", "Valor": "0 / $ 0"},
                {"Tipo": "Automotriz:", "Valor": "0 / $ 0"},
                {"Tipo": "Hipotecario:", "Valor": "0 / $ 0"},
                {"Tipo": "Servicios / Comunicación:", "Valor": "0 / $ 0"}
            ],
            "Demandas_Administrativas": "No",
            "Demandas_Administrativas_Arreglo": [],
            "Demandas_Administrativas_Ult_Mov": "N/A",
            "Demandas_Civiles": "No",
            "Demandas_Civiles_Arreglo": [],
            "Demandas_Civiles_Ult_Mov": "N/A",
            "Demandas_Familiares": "No",
            "Demandas_Familiares_Arreglo": [],
            "Demandas_Familiares_Ult_Mov": "N/A",
            "Demandas_Laborales": "No",
            "Demandas_Laborales_Arreglo": [],
            "Demandas_Laborales_Ult_Mov": "N/A",
            "Demandas_Laborales_calf": "Excelente",
            "Demandas_Penales": "No",
            "Demandas_Penales_Arreglo": [],
            "Demandas_Penales_Ult_Mov": "N/A",
            "Dependientes": "No",
            "Dependientes_Arreglo": [
                {"Edad": "---", "Nombre": "---", "Parentesco": "---"}
            ],
            "Derecho_ServicioMedico": "No",
            "Descuento_Hipoteca": "No",
            "Dueda_SAT": "Cumplido",
            "Edad": "38",
            "Endeudamiento_Total": "Bajo / 0.0%",
            "Endeudamiento_Total_calf": "Bajo",
            "Endeudamiento_sin_Hip": "Bajo / 0.0%",
            "Endeudamiento_sin_Hip_calf": "Bajo",
            "Fecha_Consulta": "04/10/2021",
            "Finiquito": "No datos",
            "Grado_Demandas": 0,
            "Historial_Legal": 12,
            "Historial_Legal_Mensaje": "Excelente",
            "Historial_Legal_Porcentaje": "100.0%",
            "Historial_Pago": "Sin historial pago / 0%",
            "Historial_Pago_Arreglo": [
                {"Tipo": "Tarjeta de Crédito:", "Valor": "Sin historial pago / 0%"},
                {"Tipo": "Préstamo Personal:", "Valor": "Sin historial pago / 0%"},
                {"Tipo": "Automotriz:", "Valor": "Sin historial pago / 0%"},
                {"Tipo": "Hipotecario:", "Valor": "Sin historial pago / 0%"},
                {"Tipo": "Servicios / Comunicación:", "Valor": "Sin historial pago / 0%"}
            ],
            "Historial_Pago_calf": "Malo",
            "Historial_laboral_final": "No datos",
            "Historial_laboral_porcentaje": "No datos",
            "Incapacidades": "Acudir subdelegación",
            "Incapacidades_Arreglo": [
                {"Anio": "---", "Dias": "---", "Empleador": "---"}
            ],
            "Liquidacion": "No datos",
            "Max_Salary": 8312.29,
            "Mensaje_Hipoteca": "Sin Relacion Laboral Vigente Segundo Bimestre 2021",
            "Mensaje_SC": "",
            "Monto_Prestar": "$ 49,873",
            "NSS": "No NSS",
            "NSS_titulo": "NSS",
            "Nivel_Estudios": "No cédula",
            "Nivel_Socieconomico": 1.0,
            "Nivel_Socieconomico_Porcentaje": "12.5%",
            "Numero_Despidos": "Acudir subdelegación",
            "Numero_Despidos_Arreglo": [
                {"Empleador": "---", "Estado": "---", "Inactividad": "---"}
            ],
            "Numero_Despidos_calf": "Problematico",
            "Numero_Empleos": "Acudir subdelegación",
            "Numero_Empleos_Arreglo": [
                {"Alta_Baja": "---", "Duracion": "---", "Empleador": "---", "Salario": "---"}
            ],
            "Numero_Empleos_calf": "Inestable",
            "Numero_creditos_total": "0",
            "PMaximo_Hipoteca": "No apto",
            "PTE": "< 1mm",
            "PTE_calif": "Medio",
            "Pagos_Mensuales": "0 / $ 0",
            "Pagos_Mensuales_Arreglo": [
                {"Tipo": "Tarjeta de Crédito:", "Valor": "0 / $ 0"},
                {"Tipo": "Préstamo Personal:", "Valor": "0 / $ 0"},
                {"Tipo": "Automotriz:", "Valor": "0 / $ 0"},
                {"Tipo": "Hipotecario:", "Valor": "0 / $ 0"},
                {"Tipo": "Servicios / Comunicación:", "Valor": "0 / $ 0"}
            ],
            "Problemas_Empleador": "Acudir subdelegación",
            "Progresion_Salarial": "Acudir subdelegación",
            "Progresion_Salarial_Arreglo": [
                {"Anio": "---", "Empleador": "---", "Porcentaje": "---", "Salario": "---"}
            ],
            "Progresion_Salarial_calf": "Bajo",
            "Puntos_Hipoteca": "Pendiente",
            "RFC": "No RFC",
            "Recomendaria_Prestar_SL_Porcentaje": 25.0,
            "Recomendaria_Prestar_SL_Suma": 4,
            "Renta_Mensual_Estimada": "$ 10,937",
            "Residencia_Colonia": "Medio",
            "SCV_Hipoteca": "No apto",
            "SLcore": "46.67%",
            "SME": "$ 8,312",
            "SME_calif": "Social",
            "Salario_Cotizado": "Acudir subdelegación",
            "Salario_Cotizado_calf": "Social",
            "Score_Prestamo": 1.4,
            "Score_Prestamo_porcentaje": 46.67,
            "Semanas_Cotizadas": "Acudir subdelegación",
            "Semanas_Cotizadas_Arreglo": [
                {"Tipo": "IMSS", "Valor": "0 semanas / 0%"},
                {"Tipo": "ISSSTE", "Valor": "0 semanas / 0%"}
            ],
            "Situacion_Laboral_Actual": "Acudir subdelegación",
            "Tiempo_Creditos": "0 meses",
            "Tiempo_Creditos_calf": "Malo",
            "Tiempo_Laborando": "0 semanas",
            "Tiempo_Laborando_calf": "Poco Laborioso",
            "Tiempo_Paro_Acumulado": "Acudir subdelegación",
            "Tipo_Deuda": "0 / $ 0",
            "Tipo_Deuda_Arreglo": [
                {"Tipo": "Tarjeta de Crédito:", "Valor": "0 / $ 0"},
                {"Tipo": "Préstamo Personal:", "Valor": "0 / $ 0"},
                {"Tipo": "Automotriz:", "Valor": "0 / $ 0"},
                {"Tipo": "Hipotecario:", "Valor": "0 / $ 0"},
                {"Tipo": "Servicios / Comunicación:", "Valor": "0 / $ 0"}
            ],
            "Total_Homonimos": 0,
            "Ultimas_incapacidades": "Acudir subdelegación",
            "Ultimas_incapacidades_calf": "Problematico",
            "Ultimo_Empleador_Duracion": "Acudir subdelegación",
            "Universidad": "No cédula",
            "Vacaciones": "No datos",
            "Vacaciones_Pago": "No datos",
            "Vivienda_Propia": "No",
            "_id": "60d9f3d35dbbe5e3c2996c53",
            "certificados": {
                "X509Certificate": "No datos",
                "X509Certificate1": "No datos",
                "X509Certificate2": "No datos",
                "X509Certificate3": "No datos"
            },
            "consultas": {
                "Efectivas": "1",
                "Total": "1"
            },
            "creditos": {
                "Créditos_Activos": "0",
                "Créditos_Finalizados": "0",
                "Créditos_Vigentes": "0",
                "Créditos_Vigentes_Arreglo": [
                    {"Límite": "---", "Saldo": "---", "Saldo_Liquidar": "---", "Tipo": "---"}
                ],
                "Monto_Prestar": "$ 49,873",
                "Pagos_Mensuales": "0 / $ 0",
                "Tiempo_Creditos": "0 meses",
                "Tipo_Deuda": "0 / $ 0"
            },
            "otros": {
                "Historial_Empleadores_Arreglo": [
                    {"Cargo": "---", "Desde": "---", "Empresa": "---", "Hasta": "---", "Sueldo": "---"}
                ]
            }
        },
        "msg": "Consulta exitosa",
        "time": 1633377621
    }
}
`