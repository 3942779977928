export const formatDate = (date) => {
  const dateParts = date.split('/');
    var day =dateParts[0];
    var month = dateParts[1];
    // if (day < 10) {
    //   day = `0${day}`; // Add leading zero for months 1-9
    // }
    // if (month < 10) {
    //   month = `0${month}`; // Add leading zero for months 1-9
    // }
    const year = dateParts[2];
    return `${day}/${month}/${year}`;
};

export const formatDate2 = (inputDateString) => {
  try{
    const inputDate = new Date(inputDateString);
  
    const formatDateComponent = (component, options) =>
      new Intl.DateTimeFormat('en-US', options).format(inputDate);
  
    const day = formatDateComponent('day', { day: 'numeric' });
    const month = formatDateComponent('month', { month: 'short' });
    const year = formatDateComponent('year', { year: 'numeric' });
  
    return `${day} ${month} ${year}`;
  } catch (e) {
    return false
  }
}

export const formatDateSpanishMonth = (inputDateString) => {
  try{
    const inputDate = new Date(inputDateString);
  
    const formatDateComponent = (component, options, locale) =>
      new Intl.DateTimeFormat(locale, options).format(inputDate);
  
    const day = formatDateComponent('day', { day: 'numeric' }, 'en-US');
    const month = formatDateComponent('month', { month: 'short' }, 'es-ES'); // Use 'es-ES' for Spanish
    const year = formatDateComponent('year', { year: 'numeric' }, 'en-US');
  
    return `${day} ${month} ${year}`;
  } catch(e) {
    return false
  }
}

export const formatTime = (inputDateString) => {
  try{
    const inputDate = new Date(inputDateString);
  
    let hours = inputDate.getUTCHours();
    const minutes = inputDate.getUTCMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
  
    hours %= 12;
    hours = hours || 12;
  
    return `${hours}:${minutes.toString().padStart(2, '0')} ${ampm}`;
  } catch(e) {
    return false
  }
}

export function convertDateFormat(inputDate) {
  const monthsMap = {
    ene: '01',
    feb: '02',
    mar: '03',
    abr: '04',
    may: '05',
    jun: '06',
    jul: '07',
    ago: '08',
    sep: '09',
    oct: '10',
    nov: '11',
    dic: '12', // assuming dic is December
  };

  const dateArray = inputDate.split(' ');

  // Extract day, month, and year
  const day = dateArray[1];
  const month = monthsMap[dateArray[2].toLowerCase()];
  const year = dateArray[3];

  // Format the result
  const formattedDate = `${day}/${month}/${year}`;

  return formattedDate;
}