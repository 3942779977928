import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { dataTypesConfig } from "../../../utils/reportDataConfigsAndFunctions";
import { useTranslation } from "react-i18next";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgba(167, 169, 169, 0.16)",
    color: theme.palette.common.black,
  },

  backgroundColor: "transparent",
  borderBottom: "solid 1px rgba(167, 169, 169, 0.16)",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderBottom: "solid 1px rgba(167, 169, 169, 0.16)",
}));

export default function InnerTableFourth(props) {
  const { t } = useTranslation();
  const baseTransKey =
    "landingpage.leftContent.tabs.B | Employment History.InnerTableData.";
  const {
    tableData = [
      {
        Institución: baseTransKey + "IMSS",
        Semanas: baseTransKey + "7",
        Dias: baseTransKey + "5 meses",
      },
    ],
    tableHeader = [
      {
        Parentesco: "landingpage.leftContent.tabs.table_headers.Institution",
        Nombre: "landingpage.leftContent.tabs.table_headers.Weeks",
        Edad: "landingpage.leftContent.tabs.table_headers.Days",
        Estatus_Salario: "",
      },
    ],
    dataType = "imss",
  } = props;
  const { columnHeaders, createRow } = dataTypesConfig[dataType];
  const [showAllRows, setShowAllRows] = useState(false);

  const rows = tableData.map((item) => {
    const config = dataTypesConfig[dataType];
    const createParams = config.createParams.map((param) => item[param]);
    return createRow(...createParams);
  });

  const displayedRows = showAllRows ? rows : rows.slice(0, 2);

  const handleShowAllRows = () => {
    // alert(showAllRows)
    setShowAllRows(!showAllRows);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <TableContainer sx={{ borderRadius: 1 }}>
        <Table
          sx={{
            borderRadius: 2,
            border: "1px solid rgba(167, 169, 169, 0.16)",
          }}
          aria-label="customized table"
        >
          <TableHead>
            {tableHeader.map((row, index) => (
              <TableRow sx={{ backgroundColor: "transparent" }} key={index}>
                {Object.values(row).map((value, index) => (
                  <StyledTableCell
                    align={index === 0 ? "left" : "center"}
                    key={index}
                    sx={{
                      p: { xs: "5px", sm: "12px" },
                      fontSize: { xs: "2vw", sm: "0.875rem" },
                    }}
                  >
                    {t(`${value}`)}
                  </StyledTableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody sx={{ backgroundColorColor: "transparent" }}>
            {displayedRows.map((row, index) => (
              <StyledTableRow
                sx={{ backgroundColor: "transparent" }}
                key={index}
              >
                {Object.values(row).map((value, index) => (
                  <StyledTableCell
                    align={index === 0 ? "left" : "center"}
                    key={index}
                    sx={{
                      p: { xs: "5px", sm: "12px" },
                      fontSize: { xs: "1.5vw", sm: 14 },
                    }}
                  >
                    {t(`${value}`)}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: "flex", justifyContent: "center", mt: -2 }}>
        <Button
          sx={{
            backgroundColor: "#E5E5E5",
            height: "30px",
            width: "30px",
            borderRadius: 50,
            minWidth: "28px",
            p: 0,
          }}
          onClick={handleShowAllRows}
        >
          {showAllRows ? (
            <KeyboardArrowUpIcon sx={{ color: "#000000" }} />
          ) : (
            <KeyboardArrowDownIcon sx={{ color: "#000000" }} />
          )}
        </Button>
      </Box>
    </Box>
  );
}
