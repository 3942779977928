import axios from "axios";
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Fragment } from "react";
import { textAlign } from "@mui/system";
import { Link, useNavigate } from "react-router-dom";
import sucessImage from "../../../assets/images/logoutIcon.svg";
import IconButton from "@mui/material/IconButton";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import Cookies from "js-cookie";
import { encryptData } from "../../../utils/encryptionHelper";
import { getCookiesList, removeCookie } from "../../../utils/cookiesHelper";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../../../utils/msalConfig";
import { call_api_function } from "../../../utils/verifyAPICalls";
import { useTranslation } from "react-i18next";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 420,
  maxWidth: "95%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
};

const subjectList = ["The present subject is found"];

export default function LogoutModal(props) {
  const { t } = useTranslation();
  const { text = "" } = props;
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [cookieList, setCookieList] = useState([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  useEffect(() => {
    const userCookiesList = getCookiesList("userInfo");
    if (userCookiesList.length > 0) {
      setCookieList(userCookiesList);
    }
  }, []);

  const logoutUser = async () => {
    if (cookieList.length > 0 && cookieList[3] !== undefined && cookieList[6]) {
      const data_eel = encryptData(cookieList[3], 500);

      // Create the form data object
      const formData = new FormData();
      formData.append(
        "data_eel",
        JSON.stringify({
          // Convert the data_eel object to a JSON string
          data_eeli: data_eel["iv"],
          data_eels: data_eel["salt"],
          data_eelc: data_eel["ct"],
        })
      );
      formData.append("token", cookieList[4]);

      try {
        // Send the form data using fetch()
        const response = await call_api_function(
          process.env.REACT_APP_BASE_URL2 + "logout",
          formData
        );
        if (response.data == true) {
          removeCookie("userInfo");
          handleClose();
          navigate("/Login");
        } else {
          // Handle the error response, if needed
          console.error("Request failed with status:", response.status);
        }
      } catch (error) {
        // Handle any network or other errors that occurred during the request
        console.error("Error:", error);
      }
    }
  };

  return (
    <Fragment>
      <IconButton
        onClick={handleOpen}
        aria-label="logoOut"
        sx={{ backgroundColor: "#EAC9C3", borderRadius: 1 }}
      >
        <PowerSettingsNewIcon
          sx={{
            background: "#FE5A3E",
            borderRadius: 50,
            color: "#ccc",
            fontSize: 26,
          }}
        />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={sucessImage}
              width="72px"
              height="71px"
              alt="email icon"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 4,
              flexDirection: "column",
            }}
          >
            <Typography
              component={"h6"}
              sx={{
                color: "neutral.900",
                fontSize: 18,
                fontFamily: "Gilroy-SemiBold",
                textAlign: "center",
              }}
            >
              {t("Logout.logout")}
            </Typography>
            <Typography
              component={"h5"}
              sx={{
                color: "#FF191B",
                fontSize: 20,
                fontFamily: "Gilroy-SemiBold",
                textAlign: "center",
              }}
            >
              {t("Logout.out")}
            </Typography>
            <Typography
              sx={{
                fontSize: 16,
                fontFamily: "Gilroy-Medium",
                color: "rgba(27, 32, 44, 0.6)",
                textAlign: "center",
                mt: 2,
              }}
            >
              {text}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              mt: 2,
            }}
          >
            <Button
              onClick={logoutUser}
              sx={{
                backgroundColor: "primary.main",
                py: 1,
                px: { xs: 4, sm: 8 },
                fontSize: "18px",
                fontFamily: "Gilroy-Medium",
                textTransform: "capitalize",
              }}
              variant="contained"
            >
              {t("buttons.Yes")}
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              mt: 1,
            }}
          >
            <Button
              onClick={handleClose}
              style={{
                color: "rgba(27, 32, 44, 0.6)",
                fontSize: 16,
                fontFamily: "Gilroy-Medium",
                textDecoration: "underline",
                textTransform: "capitalize",
              }}
              variant="text"
            >
              {t("buttons.back")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Fragment>
  );
}
