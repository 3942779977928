import { Box, Typography } from '@mui/material'
import React from 'react'
import emptyImage from "../../assets/images/imaglogin_home.png"
import { useTranslation } from 'react-i18next';
function EmptyScreen() {
  const { t } = useTranslation();
  return (
    <Box 
    sx={{
        display:"flex", 
        flexDirection:"column", 
        justifyContent:"center",
        alignContent:"center",
        flexWrap:"wrap",
        textAlign: "center",
        alignItems: "center",
        }}
        >
        <Box sx={{width:"100%",maxWidth:"50%",mt: 4 }}>
        <img style={{width:"100%"}} src={emptyImage} alt="emty image" />
        </Box>
        <Box sx={{width:"100%",textAlign:"center",mt:6}}>
            <Typography sx={{fontSize:24,fontFamily:"Poppins-Bold",color:"neutral.900"}} variant='h4'>{t("landingpage.leftContent.mainImageText1")}</Typography>
            <Typography sx={{fontSize:24,fontFamily:"Poppins-Bold",color:"neutral.900"}} variant='h4'><span style={{color:"#4165F6"}}>{t("landingpage.leftContent.mainImageText2")}</span> {t("landingpage.leftContent.mainImageText3")}</Typography>
        </Box>
    </Box>
  )
}

export default EmptyScreen