import React,{useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Fragment } from 'react';
import { textAlign } from '@mui/system';
import {Link,useNavigate }from 'react-router-dom';
import mailImage from "../../../assets/images/mailIcon.png"
import LabelInputLeftIcon from '../../@common/Input/LabelInputLeftIcon';
import FormCheckboxes from "../../@common/Input/chexboxes"
import sucessImage from "../../../assets/images/exportIcon.svg"
import {call_api_function} from "../../../utils/verifyAPICalls"
import { useEffect} from "react";
import { getCookiesList } from "../../../utils/cookiesHelper"
import { useTranslation } from 'react-i18next';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 420,
  maxWidth:"95%",
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius:4
};

const subjectList = [
    "The present subject is found",
  ]

export default function CustomizeReportModal({savePersonalization}) {
  const TipoType = localStorage.getItem('TipoType');
  const { t } = useTranslation();
  let reportname = "";
  if (TipoType === "Without Bureau") {
    reportname = "SB";
  } else if (TipoType === "With Bureau") {
    reportname = "CB";
  } else {
    reportname = "";
  }
  
  const text = `${t("CustomizedReport.popUpConfirmationSubtittle")} ${reportname}`;

  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [cookieList, setCookieList] = useState([])
  useEffect(() => {
    const userCookiesList = getCookiesList('userInfo');
    if (userCookiesList.length > 0) {
      setCookieList(userCookiesList);
    }
  }, []);

  useEffect(() => {
  }, [cookieList]);
  const sendHandler = async () => {
    if (cookieList.length > 0 && cookieList[3] !== undefined) {
      const email = cookieList[3];
      const switchValues = JSON.parse(localStorage.getItem("switchValues"));
      const TipoType = localStorage.getItem("TipoType");
      try {
        const response = await savePersonalization(email, TipoType, switchValues);
        if(response.status==200){
          handleClose();
        }
      } catch (e) {
        console.log(e);
      }
    }
    
  };
  


  return (
    <Fragment>
      <Button
      sx={{
        backgroundColor:"primary.main",
        textAlign:"center",
        borderWidth:1,
        borderColor:"primary.main",
        borderStyle:"solid",
        borderRadius:"6px",
        py:1.5,
        px:8,
        color:"#fff",
        textDecoration:"none",
        fontSize:18,
        fontFamily:"Gilroy-Medium",
        textTransform:"capitalize"
     }}
       onClick={handleOpen} variant="contained">{t("CustomizedReport.button")}</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <Box sx={{justifyContent:"center",display:"flex", alignItems:"center"}}>
                <img src={sucessImage} width="70px" height="70px" alt="email icon" />
            </Box>
            <Box sx={{display:"flex",justifyContent:"center", mt:4,flexDirection:"column"}}>
            <Typography component={"h6"} sx={{color:"neutral.900",fontSize:20,fontFamily:"Gilroy-SemiBold",textAlign:"center"}}>{t("CustomizedReport.popUpConfirmationTittle")} <Box component={"span"} sx={{color:"primary.main"}}>{t("CustomizedReport.save")}</Box></Typography>
          
          <Typography
          sx={{
            fontSize:16,
            fontFamily: "Gilroy-Medium",
            color:"rgba(27, 32, 44, 0.6)",
            textAlign:"center",
            mt:2
          }}
          >
         {text}
          </Typography>
          </Box>
         
          <Box sx={{display:"flex",flexDirection:"row",justifyContent:"center",mt:4}}>
          <Button onClick={sendHandler} sx={{backgroundColor:"primary.main",py:1,px:{xs:4,sm:8},fontSize:"18px",  fontFamily: "Gilroy-Medium",textTransform:"capitalize" }} variant="contained">{t("CustomizedReport.button")}</Button>
          </Box>
          <Box sx={{display:"flex",flexDirection:"row",justifyContent:"center",mt:2,mb:1}}>
            <Button onClick={handleClose} style={{color:"rgba(27, 32, 44, 0.6)",fontSize:16,fontFamily:"Gilroy-Medium",textDecoration:"underline",textTransform:"capitalize"}}  variant="text">{t("buttons.back")}</Button>
          </Box>
        </Box>
      </Modal>
    </Fragment>
  );
}