import { Grid, Typography, Box } from "@mui/material";
import underLine from "../../assets/images/under-line.png";
import ReportHeader from "../@common/ReportHeader/ReportHeader";
import AccordainTable from "../@common/AccordainTable/AccordainTable";
import AssignCoupons from "./AssignCoupons";
import AssignedCoupons from "./AssignedCoupons";
import { getCookiesList } from "../../utils/cookiesHelper";
import { serverRequest } from "../../utils/requestHelper";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
function CouponsManager() {
  const { t } = useTranslation();
  const [cookieList, setCookieList] = useState([]);
  const [assignedCoupons, setassignedCoupons] = useState([]);
  useEffect(() => {
    const userCookiesList = getCookiesList("userInfo");
    if (userCookiesList.length > 0) {
      setCookieList(userCookiesList);
    }
  }, []);
  const getAssignedCoupons = async () => {
    if (cookieList.length > 0 && cookieList[6] !== undefined && cookieList[6]) {
      var dataForm = {
        user: cookieList[6],
      };
      try {
        const response = await serverRequest(
          "post",
          "perfil/cupones_asignados",
          dataForm
        );
        setassignedCoupons(response.data);
      } catch (error) {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    getAssignedCoupons();
  }, [cookieList]);

  return (
    <>
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Box sx={{ mt: 2 }}>
              <Typography
                sx={{
                  fontFamily: "Poppins-SemiBold",
                  fontSize: "24px",
                  lineHeight: "35px",
                  textAlign: "center",
                  color: "#1C212D",
                }}
              >
                {t("PageName.Coupons Manager")}
              </Typography>
              <Box sx={{ textAlign: "center", mt: -1 }}>
                <img src={underLine} alt="under line" />
              </Box>
            </Box>
          </Grid>
          {/* <Grid item xs={12} sm={12}>
            <ReportHeader/>
          </Grid> */}
        </Grid>
        <Box
          sx={{
            // backgroundColor:"red",
            width: "1195px",
            maxWidth: "100%",
            display: "flex",
            mt: 4,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <AssignCoupons getcoupan={getAssignedCoupons} />
            </Grid>
            <Grid item xs={12} md={6}>
              <AssignedCoupons data={assignedCoupons} />
            </Grid>
          </Grid>
        </Box>
      </>
    </>
  );
}

export default CouponsManager;
