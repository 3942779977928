import { useTranslation } from 'react-i18next';
import React, { useState } from 'react'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import LabelInputLeftIcon from '../@common/Input/LabelInputLeftIcon';
import personImage from "../../assets/images/person.svg"
import companyIcon from "../../assets/images/company-icon.png"
import phoneIcon from "../../assets/images/phoneIcon.png"
import LabelInput from '../@common/Input/LabelInput';
import mailIcon from "../../assets/images/mailIcon.png"
import DropDownInputSimple from '../@common/Input/DropDownInputSimple/DropDownInputSimple';
import SimpleDropDown from '../@common/Input/DropDownInputSimple/SimpleDropDown';
import EmployRefEmailSuccessSent from '../@common/Modal/EmployRefEmailSuccessSent';
import {Link,useNavigate }from 'react-router-dom';
import { encryptData } from "../../utils/encryptionHelper";
import { getCookiesList, removeCookie } from "../../utils/cookiesHelper";
import { useEffect } from 'react';
import {serverRequest} from "../../utils/requestHelper"
import {validateInput,validatePhoneNumber,validateName} from '../../utils/inputValidation';
import { emailPattern } from "../../utils/InputPatterns"
function EmployRefExternalFroms() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isEmailSuccessSent,setisEmailSuccessSent]= useState(false)
    const [cookieList, setCookieList] = useState([])
    useEffect(() => {
        const userCookiesList = getCookiesList('userInfo');
        if (userCookiesList.length > 0) {
            setCookieList(userCookiesList);
        }
    }, []);

    useEffect(() => {
    }, [cookieList]);
    const [formData, setFormData] = useState({
        number: '',
        email: '',
        referencetype: '',
        referencesrequired:'',
        document: '',
        nombre_sujeto:'',
    });
    const [formErrors, setFormErrors] = useState({})
    const handleChange = (name, value) => {
    
    // Clear the error when user starts typing
  setFormErrors((prevErrors) => ({
    ...prevErrors,
    [name]: undefined,
  }));
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
      };

    const sendHandler = async () => {
        const errors = {};
        validatePhoneNumber(formData.number, 'number', errors,t);
        validateInput(formData.email, emailPattern, 'email', errors,t);
    // Add validation for 'referencetype' and 'referencesrequired'
    if (!formData.referencetype) {
        errors.referencetype = t("placeholders.ReferenceType");
    }

    if (!formData.referencesrequired) {
        errors.referencesrequired = t("labels.labelQuantity");
    }
    if (!formData.document) {
        errors.document = t("placeholders.Document");
    }
        if (Object.keys(errors).length > 0) {
          setFormErrors(errors);
          return;
        }
        setisEmailSuccessSent(true)
        const data_erf =encryptData(formData.referencetype, 500);
        const data_eto= encryptData(formData.number, 500);
        const data_eco= encryptData(formData.email, 500);
        const data_eqr= encryptData(formData.referencesrequired, 500);
        const data_end= encryptData(formData.document, 500);
        const data_enc=encryptData(formData.nombre_sujeto, 500);
        const data_eue=encryptData(cookieList[3], 500);

        let dataPost = {
            "data_eto": {
                        "data_etoi":data_eto["iv"],
                        "data_etos":data_eto["salt"],
                        "data_etoc":data_eto["ct"]
            },
            "data_eco": {
                        "data_ecoi":data_eco["iv"],
                        "data_ecos":data_eco["salt"],
                        "data_ecoc":data_eco["ct"]
            },
            "data_erf": {
                        "data_erfi":data_erf["iv"],
                        "data_erfs":data_erf["salt"],
                        "data_erfc":data_erf["ct"]
            },
            "data_end": {
                        "data_endi":data_end["iv"],
                        "data_ends":data_end["salt"],
                        "data_endc":data_end["ct"]
            },
            "data_eqr": {
                        "data_eqri":data_eqr["iv"],
                        "data_eqrs":data_eqr["salt"],
                        "data_eqrc":data_eqr["ct"]
            },
            "data_enc": {
                        "data_enci":data_enc["iv"],
                        "data_encs":data_enc["salt"],
                        "data_encc":data_enc["ct"]
            },
            "data_eue": {
                        "data_euei":data_eue["iv"],
                        "data_eues":data_eue["salt"],
                        "data_euec":data_eue["ct"]
            }
        }
        try {
            const response = await serverRequest('post', 'rl/rl_externa', dataPost);
            if(response.data == 'ok'){
                navigate("/ReferencesPDF");
            }
            if(response.data == 'error servidor correo'){
               alert('error servidor correo')
            }
            if(response.data == 'error bd'){
                alert('error bd')
             }
          } catch (e) {
            console.log(e);
          }
        };
  return (
        <Box>
        <Grid container spacing={{xs:2,sm:2,lg:4}}>
            <Grid item xs={12} sm={12} lg={3}>
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
                <Box sx={{backgroundColor:"#ffffff",p:4,borderRadius:1}}>
                <Box sx={{ display: "flex",flexDirection: "column" }}>
                    <LabelInputLeftIcon
                 mailIcon={phoneIcon}
                 type="number"
                  label={t("labels.inputLabelNumber")}
                 name='number'
                 id="number"
                 placeholder={t("placeholders.number")}
                 value={formData.number}
                 onChange={(e) => handleChange('number', e.target.value)}
                 error={formErrors.number}
                 maxLength={10}
                 />
                 {formErrors.number && (
                   <Typography variant="body2" color="error">
                     {formErrors.number}
                   </Typography>
                 )}
                    </Box>
                    <Box sx={{display: "flex", flexDirection: "column",mt:2}}>
                    <LabelInputLeftIcon
                 mailIcon={mailIcon}
                 type='text'
                 label={t("SuperAdminSignUp.inputs.inputLabelEmail")}
                 name='email'
                 id="email"
                  placeholder={t("placeholders.email")}
                 value={formData.email}
                 onChange={(e) => handleChange('email', e.target.value)}
                 error={formErrors.email}
                 />
                 {formErrors.email && (
                   <Typography variant="body2" color="error">
                     {formErrors.email}
                   </Typography>
                 )}
                    </Box>
                    <Box sx={{display: "flex", flexDirection: "column",mt:2}}>
                    <SimpleDropDown
                 firstName={t("firstName.referencename")}
                 mailIcon={personImage}
                 type='text'
                  label={t("labels.labelReferenceType")}
                 name='referencetype'
                 id="referencetype"
                 placeholder={t("placeholders.ReferenceType")}
                 value={formData.referencetype}
                 onChange={(value) => handleChange('referencetype', value)}
                 error={formErrors.referencetype}
                 />
                 {formErrors.referencetype && (
                   <Typography variant="body2" color="error">
                     {formErrors.referencetype}
                   </Typography>
                 )}
                    </Box>
                    <Box sx={{display: "flex", flexDirection: "column",mt:2}}>
                    <LabelInput
                 type='text'
                 label={t("labels.labelDocument")}
                 name='document'
                 id="document"
                 placeholder={t("placeholders.Document")}
                 value={formData.document}
                 onChange={(e) => handleChange('document', e.target.value)}
                 error={formErrors.document}
                 />
                 {formErrors.document && (
                   <Typography variant="body2" color="error">
                     {formErrors.document}
                   </Typography>
                 )}
                    </Box>
                    <Box sx={{display: "flex", flexDirection: "column",mt:2}}>
                    <SimpleDropDown
               firstName={t("firstName.QuantityName")}
                 mailIcon={personImage}
                 type='text'
                 label={t("labels.labelQuantity")}
                 name='referencesrequired'
                 id="referencesrequired"
                 placeholder={t("labels.labelQuantity")}
                 value={formData.referencesrequired}
                 onChange={(value) => handleChange('referencesrequired', value)}
                 error={formErrors.referencesrequired}
                 />
                 {formErrors.referencesrequired && (
                   <Typography variant="body2" color="error">
                     {formErrors.referencesrequired}
                   </Typography>
                 )}
                    </Box>
                    
{/************************ Modal Email successfully **************************/}

         
                <Box sx={{display:"flex",flexDirection:"row",justifyContent:"center",mt:4}}>
                <EmployRefEmailSuccessSent
                text="Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed atturpis vitae velit euismod"
                buttonText="OK"
                onClick={sendHandler}
                isEmailSuccessSent={isEmailSuccessSent}
                />
                </Box>
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} lg={3}>
            </Grid>
    
        </Grid>
        </Box>
  )
}

export default EmployRefExternalFroms