import * as React from "react";

import Typography from "@mui/material/Typography";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import InnerTable from "./InnerTable";
import InnerTableThree from "./InnerTableThree";
import InnerTableFourth from "./InnerTableFourth";
import InnerTableSecond from "./InnerTableSecond";
import { useTranslation } from "react-i18next";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function MortgageCredit() {
  const { t } = useTranslation();
  return (
    <Box sx={{ height: "70vh", overflowY: "auto" }}>
      <TableContainer sx={{ borderRadius: 0 }} component={Paper}>
        <Table sx={{ borderRadius: 0 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: "#4165F6" }}>
            <TableRow sx={{ width: "100%" }}>
              <TableCell
                align="left"
                sx={{
                  width: "40%",
                  py: 2,
                  border: 0,
                  color: "#ffffff",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "2vw", sm: "0.875rem" },
                }}
              >
                {t("landingpage.leftContent.tabs.subtab.E")}
              </TableCell>
              <TableCell
                sx={{
                  width: "30%",
                  py: 2,
                  border: 0,
                  color: "#ffffff",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "2vw", sm: "0.875rem" },
                }}
                align="center"
              >
                {t("landingpage.leftContent.tabs.subheading.Report Format")}
              </TableCell>
              <TableCell
                sx={{
                  width: "15%",
                  py: 2,
                  border: 0,
                  color: "#ffffff",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "2vw", sm: "0.875rem" },
                }}
                align="center"
              >
                {t("landingpage.leftContent.tabs.subheading.Concept")}
              </TableCell>
              <TableCell
                sx={{
                  width: "15%",
                  py: 2,
                  border: 0,
                  color: "#ffffff",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "2vw", sm: "0.875rem" },
                }}
                align="center"
              >
                {t("landingpage.leftContent.tabs.subheading.Result")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ borderRadius: 0, mt: 2 }}>
            <TableRow
              sx={{
                borderRadius: 0,
                "&:last-child td, &:last-child th": {
                  border: 0,
                  borderRadius: 0,
                },
              }}
            >
              <TableCell
                // colSpan={2}
                sx={{
                  width: "40%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="left"
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    sx={{
                      color: "primary.main",
                      width: "100%",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    {t(
                      "landingpage.leftContent.tabs.E | Crédito Hipotecario.E. Crédito Hipotecario.I"
                    )}
                  </Typography>
                </Box>
              </TableCell>

              <TableCell
                sx={{
                  width: "30%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "1.5vw", sm: 14 },
                    fontFamily: "Gilroy-Medium",
                  }}
                >
                  {t(
                    "landingpage.leftContent.tabs.E | Crédito Hipotecario.Report Format.Available"
                  )}{" "}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                {t(
                  "landingpage.leftContent.tabs.E | Crédito Hipotecario.Concept.Estatus Crédito"
                )}
              </TableCell>

              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  borderRadius: 0,
                  border: "5px solid #ffffff !important",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <ul>
                  {t(
                    "landingpage.leftContent.tabs.E | Crédito Hipotecario.Result.Estatus Crédito"
                  )}
                </ul>
              </TableCell>
            </TableRow>
            <TableRow
              sx={{
                borderRadius: 0,
                "&:last-child td, &:last-child th": {
                  border: 0,
                  borderRadius: 0,
                },
              }}
            >
              <TableCell
                // colSpan={2}
                sx={{
                  width: "40%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  backgroundColor: "rgba(0, 127, 255, 0.1)",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="left"
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    sx={{
                      color: "primary.main",
                      width: "100%",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    {t(
                      "landingpage.leftContent.tabs.E | Crédito Hipotecario.E. Crédito Hipotecario.II"
                    )}
                  </Typography>
                </Box>
              </TableCell>

              <TableCell
                sx={{
                  width: "30%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  backgroundColor: "rgba(0, 127, 255, 0.1)",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "1.5vw", sm: 14 },
                    fontFamily: "Gilroy-Medium",
                  }}
                >
                  115{" "}
                  {t(
                    "landingpage.leftContent.tabs.E | Crédito Hipotecario.Report Format.points"
                  )}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                {t(
                  "landingpage.leftContent.tabs.E | Crédito Hipotecario.Concept.Puntos"
                )}
              </TableCell>

              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  borderRadius: 0,
                  border: "5px solid #ffffff !important",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <ul>
                  {t(
                    "landingpage.leftContent.tabs.E | Crédito Hipotecario.Result.Puntos"
                  )}
                </ul>
              </TableCell>
            </TableRow>

            <TableRow
              sx={{
                borderRadius: 0,
                "&:last-child td, &:last-child th": {
                  border: 0,
                  borderRadius: 0,
                },
              }}
            >
              <TableCell
                sx={{
                  width: "40%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="left"
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    sx={{
                      color: "primary.main",
                      width: "100%",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    {t(
                      "landingpage.leftContent.tabs.E | Crédito Hipotecario.E. Crédito Hipotecario.III"
                    )}
                  </Typography>
                </Box>
              </TableCell>

              <TableCell
                sx={{
                  width: "30%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "1.5vw", sm: 14 },
                    fontFamily: "Gilroy-Medium",
                  }}
                >
                  $120,000{" "}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                {t(
                  "landingpage.leftContent.tabs.E | Crédito Hipotecario.Concept.Subcuenta Vivienda"
                )}
              </TableCell>

              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  borderRadius: 0,
                  border: "5px solid #ffffff !important",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <ul>
                  {t(
                    "landingpage.leftContent.tabs.E | Crédito Hipotecario.Result.Subcuenta Vivienda"
                  )}
                </ul>
              </TableCell>
            </TableRow>

            <TableRow
              sx={{
                borderRadius: 0,
                "&:last-child td, &:last-child th": {
                  border: 0,
                  borderRadius: 0,
                },
              }}
            >
              <TableCell
                // colSpan={2}
                sx={{
                  width: "40%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  backgroundColor: "rgba(0, 127, 255, 0.1)",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="left"
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    sx={{
                      color: "primary.main",
                      width: "100%",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    {t(
                      "landingpage.leftContent.tabs.E | Crédito Hipotecario.E. Crédito Hipotecario.IV"
                    )}
                  </Typography>
                </Box>
              </TableCell>

              <TableCell
                sx={{
                  width: "30%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  backgroundColor: "rgba(0, 127, 255, 0.1)",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "1.5vw", sm: 14 },
                    fontFamily: "Gilroy-Medium",
                  }}
                >
                  $230,400{" "}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                {t(
                  "landingpage.leftContent.tabs.E | Crédito Hipotecario.Concept.Préstamo Máximo"
                )}
              </TableCell>

              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  borderRadius: 0,
                  border: "5px solid #ffffff !important",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <ul>
                  {t(
                    "landingpage.leftContent.tabs.E | Crédito Hipotecario.Result.Préstamo Máximo"
                  )}
                </ul>
              </TableCell>
            </TableRow>

            <TableRow
              sx={{
                borderRadius: 0,
                "&:last-child td, &:last-child th": {
                  border: 0,
                  borderRadius: 0,
                },
              }}
            >
              <TableCell
                sx={{
                  width: "40%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="left"
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    sx={{
                      color: "primary.main",
                      width: "100%",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    {t(
                      "landingpage.leftContent.tabs.E | Crédito Hipotecario.E. Crédito Hipotecario.V"
                    )}
                  </Typography>
                </Box>
              </TableCell>

              <TableCell
                sx={{
                  width: "30%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "1.5vw", sm: 14 },
                    fontFamily: "Gilroy-Medium",
                  }}
                >
                  $6,784{" "}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                {t(
                  "landingpage.leftContent.tabs.E | Crédito Hipotecario.Concept.Descuento Hipoteca"
                )}
              </TableCell>

              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  borderRadius: 0,
                  border: "5px solid #ffffff !important",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <ul>
                  {t(
                    "landingpage.leftContent.tabs.E | Crédito Hipotecario.Result.Descuento Hipoteca"
                  )}
                </ul>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
