import { Grid, Typography, Box } from "@mui/material";
import React from "react";
import underLine from "../../assets/images/under-line.png";
import ReportHeader from "../@common/ReportHeader/ReportHeader";
import AccordainTable from "../@common/AccordainTable/AccordainTable";
import UserAsignCoupons from "./UserAsignCoupons";
import RegisteredUserAction from "./RegisteredUserAction";
import { useTranslation } from "react-i18next";
function AdministratorUsers() {
  const { t } = useTranslation();
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Box sx={{ mt: 2 }}>
            <Typography
              sx={{
                fontFamily: "Poppins-SemiBold",
                fontSize: "24px",
                lineHeight: "35px",
                textAlign: "center",
                color: "#1C212D",
              }}
            >
              {t("PageName.Administrator")}
            </Typography>
            <Box sx={{ textAlign: "center", mt: -1 }}>
              <img src={underLine} alt="under line" />
            </Box>
          </Box>
        </Grid>
        {/* <Grid item xs={12} sm={12}>
            <ReportHeader/>
          </Grid> */}
      </Grid>

      <Grid>
        <Box
          sx={{
            width: "100%",
            // backgroundColor:"red",
            // width:"1195px",
            // maxWidth:"100%",
            // display:"flex",
            mt: 4,
          }}
        >
          {/* <Grid item xs={12} sm={12} lg={1}></Grid> */}
          {/*<Grid item xs={12} sm={6} lg={4}>
            <UserAsignCoupons />
          </Grid> */}

          <RegisteredUserAction />

          {/* <Grid item xs={12} sm={12} lg={1}></Grid> */}
        </Box>
      </Grid>
    </>
  );
}

export default AdministratorUsers;
