import {
  Box,
  Divider,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { React, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import calendar from "../../../assets/images/calendarimg.png";
import lineImg from "../../../assets/images/line-header-sec.png";
import couponImg from "../../../assets/images/coupon-header-icon.png";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import ellips1 from "../../../assets/images/ellipse-1.png";
import ellips2 from "../../../assets/images/ellipse-2.png";
import ellips3 from "../../../assets/images/ellipse-3.png";
import AddIcon from "@mui/icons-material/Add";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import dashImg from "../../../assets/images/dash-icon.svg";
import Container from "@mui/material/Container";
import logoImage from "../../../assets/images/logo-2.png";
import GuestNavigation from "./GuestNavigation";
import { checkGuestUserCupons } from "../../../utils/bureauHelper";
import { useTranslation } from "react-i18next";
import imgEng from "../../../assets/images/Eng.svg";
import imgspa from "../../../assets/images/Spanish.svg";

function GuestHeader() {
  const navigate = useNavigate();
  const [cupons, setCupons] = useState([]);
  const { t, i18n } = useTranslation();
  const storedLanguage = localStorage.getItem("selectedLanguage");

  const [language, setLanguage] = useState(storedLanguage);
  const lngs = {
    en: { nativeName: t("name.English"), code: "en" },
    es: { nativeName: t("name.Spanish"), code: "es" },
  };

  const handleChange = (event) => {
    const newLanguage = event.target.value;
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
    localStorage.setItem("selectedLanguage", newLanguage);
  };
  const pathHanler = () => {
    navigate("/authorizationpanel");
  };

  const pathhandlerCoupan = () => {
    navigate("/GuestCoupon");
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await checkGuestUserCupons();
        if (data) {
          setCupons(data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        backgroundColor: "#ffffff",
        width: "100%",
        boxShadow: 4,
        zIndex: 99,
        p: 2,
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap={1}
      >
        <Box
          component="img"
          src={logoImage}
          sx={{ display: { xs: "none", sm: "flex" } }}
          alt="Logo"
          onClick={() => navigate("/GuestDashboard")}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: "rgba(255, 140, 0, 0.1)",
            alignItems: "center",
            justifyContent: "center",
            px: "8px",
            py: "10px",
            borderRadius: "8px",
            cursor: "pointer",
            gap: 1,
          }}
          onClick={pathhandlerCoupan}
        >
          <Box
            component="img"
            src={couponImg}
            width={35}
            height={35}
            alt="calendar"
          />
          <Box>
            <Typography
              sx={{
                fontSize: 14,
                fontFamily: "Gilroy-Medium",
                color: "neutral.900",
              }}
            >
              {t("GuestDashboard.headerCoupons")}
            </Typography>
            <Stack direction="row" alignItems="center" gap={0.5}>
              <Typography
                sx={{
                  fontSize: 14,
                  fontFamily: "Gilroy-SemiBold",
                  color: "neutral.400",
                }}
              >
                <span
                  style={{
                    color: "#1C212D",
                  }}
                >
                  {cupons[2]}
                </span>
              </Typography>
              <Box component="img" src={lineImg} height={14} alt="line" />
              <Typography
                sx={{
                  fontSize: 14,
                  fontFamily: "Gilroy-SemiBold",
                  color: "neutral.400",
                }}
              >
                <span style={{ color: "#1C212D" }}>{cupons[1]}</span>
              </Typography>
            </Stack>
          </Box>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "center", lg: "flex-end" },
              alignItems: "center",
              // textAlign: "right",
            }}
          >
            <FormControl
              sx={{
                mt: 0,
                minWidth: 80,
                display: { xs: "none", lg: "block" },
              }}
              size="small"
            >
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={language}
                displayEmpty
                onChange={handleChange}
                sx={{
                  padding: "7px 10px",
                  display: "flex",
                  alignItems: "center",
                  "& .MuiSelect-select": {
                    display: "flex !important",
                    alignItems: "center !important",
                  },
                  "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputSizeSmall.css-17ayv1u-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                  {
                    display: "flex",
                    alignItems: "center",
                  },
                }}
              >
                {Object.keys(lngs).map((lng) => (
                  <MenuItem key={lngs[lng].code} value={lngs[lng].code}>
                    <Tooltip title={t("changeLanguage.Flag")} arrow placement="top">

                      <img
                        src={lng === "en" ? imgEng : imgspa}
                        alt={lngs[lng].nativeName}
                        width="30"
                        height="25"
                        style={{ marginRight: "10px" }}
                      />
                    </Tooltip>

                    {lng === "en" ? lngs[lng].nativeName : "Español"}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              sx={{
                mt: 0,
                minWidth: 50,
                display: { xs: "block", lg: "none" },
              }}
              size="small"
            >
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={language}
                displayEmpty
                onChange={handleChange}
                sx={{
                  padding: "4px 0px",
                  display: "flex",
                  alignItems: "center",
                  "& .MuiSelect-select": {
                    display: "flex !important",
                    alignItems: "center !important",
                  },
                  "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputSizeSmall.css-17ayv1u-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                  {
                    display: "flex",
                    alignItems: "center",
                  },
                }}
              >
                {Object.keys(lngs).map((lng) => (
                  <MenuItem
                    key={lngs[lng].code}
                    value={lngs[lng].code}
                    sx={{ justifyContent: "center" }}
                  >
                    <Tooltip title={t("changeLanguage.Flag")} arrow placement="top">

                      <img
                        src={lng === "en" ? imgEng : imgspa}
                        alt={lngs[lng].nativeName}
                        width="30"
                        height="25"
                      />
                    </Tooltip>

                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>

        <GuestNavigation />
      </Stack>
    </Box>
  );
}

export default GuestHeader;
