import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CouponsCard from "../@common/Cards/CouponsCard";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const { t } = useTranslation();
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function CouponsTabs() {
  const [value, setValue] = React.useState(0);
  const { t } = useTranslation();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ textAlign: "center" }}>
        <Tabs
          sx={{
            textAlign: "center",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            maxWidth: "fit-content",
            mx: "auto",
            "& .MuiTabs-scroller": {
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              backgroundColor: "#EFF1F8",
              p: 0.8,
              borderRadius: "4px",
            },
            "& .MuiTab-root.MuiButtonBase-root": {
              minWidth: { xs: 110, md: 145 },
            },
            "& .Mui-selected": {
              backgroundColor: "#4165F6 !important",
              color: "#ffffff !important",
              borderRadius: "4px",
            },
            "& .MuiTab-textColorPrimary": {
              py: 2,
              textTransform: "capitalize",
            },
            "& .MuiTabs-indicator": {
              display: "none",
            },
          }}
          disableRipple={true}
          value={value}
          onChange={handleChange}
        >
          <Tab
            label={t("Dashboard-main.header.coupons.CBwithBuro")}
            {...a11yProps(0)}
          />
          <Tab
            label={t("Dashboard-main.header.coupons.SBwithoutBuro")}
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} lg={4}>
            <CouponsCard couponType="bureau" cou={"100"} />
          </Grid>
          <Grid item xs={12} sm={12} lg={4}>
            <CouponsCard couponType="bureau" cou={"200"} />
          </Grid>
          <Grid item xs={12} sm={12} lg={4}>
            <CouponsCard couponType="bureau" cou={"300"} />
          </Grid>
          <Grid item xs={12} sm={12} lg={4}>
            <CouponsCard couponType="bureau" cou={"500"} />
          </Grid>
          <Grid item xs={12} sm={12} lg={4}>
            <CouponsCard couponType="bureau" cou={"1000"} />
          </Grid>
          <Grid item xs={12} sm={12} lg={4}>
            <CouponsCard couponType="bureau" cou={"2000"} />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} lg={4}>
            <CouponsCard couponType="not_bureau" cou={"100"} />
          </Grid>
          <Grid item xs={12} sm={12} lg={4}>
            <CouponsCard couponType="not_bureau" cou={"200"} />
          </Grid>
          <Grid item xs={12} sm={12} lg={4}>
            <CouponsCard couponType="not_bureau" cou={"300"} />
          </Grid>
          <Grid item xs={12} sm={12} lg={4}>
            <CouponsCard couponType="not_bureau" cou={"500"} />
          </Grid>
          <Grid item xs={12} sm={12} lg={4}>
            <CouponsCard couponType="not_bureau" cou={"1000"} />
          </Grid>
          <Grid item xs={12} sm={12} lg={4}>
            <CouponsCard couponType="not_bureau" cou={"2000"} />
          </Grid>
        </Grid>
      </TabPanel>
    </Box>
  );
}
