import { Grid, Typography, Box } from "@mui/material";
import React, { useState } from "react";
import underLine from "../../assets/images/under-line.png";
import Divider from "@mui/material/Divider";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import downloadIcon from "../../assets/images/api-downloadicon.svg";
import videoIcon from "../../assets/images/api-videoIcon.svg";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { Link, useNavigate } from "react-router-dom";
import APIeftSide from "./APIeftSide";
import axios from "axios";
import searchlook_api_doc from "../../assets/files/searchlook_api_documentation.txt";
import { useTranslation } from "react-i18next";
function APInformation() {
  const navigate = useNavigate();
  const [adminModalOpen, setAdminModalOpen] = useState(false);
  const [profileModalOpen, setProfileModalOpen] = useState(false);
  const { t } = useTranslation();
  const adminhandler = () => {
    setAdminModalOpen(true);
  };

  const profileHandler = () => {
    setProfileModalOpen(true);
  };

  const videoHelpHandler = () => {
    navigate("/aid");
  };
  // API DOCUMENTATION DOWNLOAD
  const downloadTemplate = () => {
    const link = document.createElement("a");
    link.href = searchlook_api_doc;
    link.target = "_blank";
    link.rel = "noreferrer";
    link.download = "Searchlook_Api_Documentation.txt";
    link.click();
  };

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12}>
          <Box
            sx={{
              backgroundColor: "#ffffff",
              borderRadius: 2,
            }}
          >
            <Grid container spacing={0}>
              <Grid item xs={12} sm={4}>
                <Box
                  sx={{
                    boxShadow: 4,
                    p: 2,
                    borderRadius: 2,
                  }}
                >
                  <Typography
                    sx={{
                      color: "neutral.900",
                      fontSize: "22px",
                      fontFamily: "Gilroy-SemiBold",
                      lineHeight: "29px",
                    }}
                    component={"h1"}
                  >
                    {t("api.Tittle")}
                  </Typography>
                  <Typography
                    sx={{
                      color: "text.opacity",
                      mt: 2,
                      fontSize: "16px",
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    {t("api.Subittle")}
                  </Typography>
                  <Box sx={{ py: 3 }}>
                    <Divider />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: { xs: "column", sm: "column", lg: "row" },
                    }}
                  >
                    <Button
                      onClick={downloadTemplate}
                      sx={{
                        backgroundColor: "rgba(65, 101, 246, 0.1)",
                        color: "neutral.900",
                        fontSize: 14,
                        fontFamily: "Gilroy-Medium",
                        textTransform: "capitalize",
                      }}
                      variant="contained"
                      startIcon={
                        <img
                          src={downloadIcon}
                          alt="download icon"
                          width="16px"
                          height="16px"
                        />
                      }
                    >
                      {t("api.buttons")}
                    </Button>
                    <Button
                      onClick={videoHelpHandler}
                      sx={{
                        backgroundColor: "rgba(65, 101, 246, 0.1)",
                        color: "neutral.900",
                        fontSize: 14,
                        fontFamily: "Gilroy-Medium",
                        textTransform: "capitalize",
                        mt: { xs: 2, sm: 2, lg: 0 },
                      }}
                      variant="contained"
                      startIcon={
                        <img
                          src={videoIcon}
                          alt="download icon"
                          width="20px"
                          height="20px"
                        />
                      }
                    >
                      {t("api.buttons1")}
                    </Button>
                  </Box>
                  <Box sx={{ py: 3 }}>
                    <Divider />
                  </Box>
                  <Box
                    sx={{
                      borderRadius: 1,
                      border: "solid 1px #E6E8F0",
                      p: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 14,
                        color: "text.opacity",
                        fontFamily: "Gilroy-Medium",
                      }}
                    >
                      {t("api.text")}
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                      <Box
                        sx={{
                          backgroundColor: "primary.main",
                          color: "#ffffff",
                          fontSize: 16,
                          fontFamily: "Gilroy-Medium",
                          width: 155,
                          display: "flex",
                          justifyContent: "center",
                          py: 1,
                          borderTopRightRadius: "5px",
                          borderTopLeftRadius: "5px",
                        }}
                      >
                        {t("api.lib")}
                      </Box>
                      <Box
                        sx={{
                          borderTopRightRadius: "5px",
                          borderTopLeftRadius: "0px",
                          borderBottomLeftRadius: "5px",
                          borderBottomRightRadius: "5px",
                          border: "solid 1px #E6E8F0",
                          p: 2,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            mt: 1,
                          }}
                        >
                          <Link
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              width: "100%",
                              textDecoration: "none",
                              color: "text.opacity",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: 14,
                                color: "neutral.900",
                                fontFamily: "Gilroy-Medium",
                                textTransform: "capitalize",
                              }}
                            >
                              1. pip3 install requests
                            </Typography>
                            <ChevronRightRoundedIcon
                              sx={{ fontSize: 26, color: "text.opacity" }}
                              color="text.opacity"
                            />
                          </Link>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            mt: 1,
                          }}
                        >
                          <Link
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              width: "100%",
                              textDecoration: "none",
                              color: "text.opacity",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: 14,
                                color: "neutral.900",
                                fontFamily: "Gilroy-Medium",
                                textTransform: "capitalize",
                              }}
                            >
                              2. pip3 install json
                            </Typography>
                            <ChevronRightRoundedIcon
                              sx={{ fontSize: 26, color: "text.opacity" }}
                              color="text.opacity"
                            />
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Box sx={{ m: 2 }}>
                  <APIeftSide />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default APInformation;
