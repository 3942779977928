import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import CouponsTabs from './CouponsTabs'
import { useTranslation } from 'react-i18next';
function CouponsContent() {
    const { t } = useTranslation();
  return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={1}>
            </Grid>
            <Grid item xs={12} sm={10}>
                <Box sx={{backgroundColor:"#ffffff",p:4,borderRadius:1}}>
                    <Typography sx={{
                        color:"neutral.900",
                        fontSize:22,
                        fontFamily: "Gilroy-SemiBold",
                        textAlign:"center",
                    }} component={"h2"}>
                        {t("PageName.Couponsneed")}
                    </Typography>
                    <Typography 
                    sx={{
                        color:"text.opacity",
                        fontSize:18,
                        fontFamily: "Gilroy-Medium",
                        textAlign:"center",
                        my:4,
                    }} 
                    component={"h2"}
                    >
                     {t("PageName.CoupnsType")}
                    </Typography>
                    <Box>
                        <CouponsTabs/>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} sm={1}>
            </Grid>
        </Grid>
  )
}

export default CouponsContent