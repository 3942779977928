import React from "react";

// mui imports
import { Typography } from "@mui/material";
import errorImage from "../../../assets/errorEmoji.png";
const ErrorPage = () => {
  return (
    <>
      <Typography
        component="h1"
        fontSize={{ xs: "5rem", sm: "8rem", md: "10rem", lg: "15rem" }}
        textAlign="center"
        fontWeight="bold"
        color="#f0f0f0"
      >
        4
        <img src={errorImage} alt="Error emoji" />4
      </Typography>
      <Typography
        textTransform="uppercase"
        textAlign="center"
        fontSize={{ xs: "2rem", md: "3.5rem", lg: "6rem",fontFamily: "Gilroy-ExtraBold" }}
      >
        uh oh!
      </Typography>
      <Typography textAlign="center">
        The link you’re trying to open is either broken or has been removed.
      </Typography>
    </>
  );
};

export default ErrorPage;
