import { useContext, createContext, useState, useEffect } from "react";

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openCollapseTwo, setOpenCollapseTwo] = useState(false);
  const [open, setOpen] = useState(true);
  const [mobileView, setMobileView] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setMobileView(true);
      setOpen(false);
    } else {
      setMobileView(false);
      setOpen(true);
    }
  };

  useEffect(() => {
    handleResize();

    window.addEventListener("resize", handleResize);

    return function cleanup() {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <AppContext.Provider
      value={{
        openCollapse,
        openCollapseTwo,
        setOpenCollapse,
        setOpenCollapseTwo,
        mobileView,
        setMobileView,
        mobileOpen,
        handleDrawerToggle,
        open,
        setOpen,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(AppContext);
};
