import { Grid, Typography, Box } from "@mui/material";
import React from "react";
import underLine from "../../assets/images/under-line.png";
import ReportHeader from "../@common/ReportHeader/ReportHeader";
import AccordainTable from "../@common/AccordainTable/AccordainTable";

function ReportWithBureau() {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Box sx={{ mt: 2 }}>
            <Typography
              sx={{
                fontFamily: "Poppins-SemiBold",
                fontSize: "24px",
                lineHeight: "35px",
                textAlign: "center",
                color: "#1C212D",
              }}
            >
              Report
            </Typography>
            <Box sx={{ textAlign: "center", mt: -1 }}>
              <img src={underLine} alt="under line" />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12}>
          <ReportHeader />
        </Grid>
        <Grid item xs={12} sm={12}>
          <AccordainTable />
        </Grid>
      </Grid>
    </>
  );
}

export default ReportWithBureau;
