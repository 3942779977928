import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { Fragment, useState } from "react";
import { List, ListItem, Link } from "@mui/material";
import { useLocation, Link as RouterLink } from "react-router-dom";
import Stack from "@mui/material/Stack";
import MailIcon from "@mui/icons-material/Mail";
import CallIcon from "@mui/icons-material/Call";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import NoticePrivacy from "../components/NoticePrivacy";
import TermsConditions from "../components/TermsConditions";
import { useTranslation } from "react-i18next";

function FooterTwo() {
  const { t } = useTranslation();
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [openTermsModal, setOpenTermsModal] = useState(false);

  const { pathname } = useLocation();

  const handleOpenUploadModal = () => {
    setOpenUploadModal(!openUploadModal);
  };
  const handleOpenTermsModal = () => {
    setOpenTermsModal(!openTermsModal);
  };

  return (
    <>
      {/* <Box
        sx={{
          background: "inherit",
          // display: "flex",
          // flexDirection: "row",
          // justifyContent: "flex-end",
          // position: "absolute",
          // top: "-60%",
          // bottom: 0,
          // zIndex: 9,
          // right: "10%",
        }}
      >
        <RouterLink
          to="/aidVideo"
          style={{
            backgroundColor: "#fff",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            height: 42,
            width: 42,
            borderRadius: 50,
            alignItems: "center",
            boxShadow: 10,
          }}
        >
          <QuestionMarkIcon sx={{ color: "primary.light" }} />
        </RouterLink>
      </Box> */}

      <Box
        sx={{
          background: "#2488FF",
          // position: { xs: "relative", sm: "absolute" },
          position: "relative",
          // position: "absolute",
          // zIndex: 2,
          // bottom: 0,
          // width: { xs: "100%", sm: "40%" },
          width: "100%",

          pb: 2,
          // right: 0,
          // paddingRight: { xs: "50px", lg: 0 },
          // paddingLeft: { xs: "50px", lg: 0 },
        }}
      >
        <Box sx={{ textAlign: "center", pt: 1 }}>
          <Typography
            sx={{
              color: "#fff",
              fontSize: "18px",
              fontFamily: "Poppins-SemiBold",
            }}
          >
            {t("landingpage.rightContent.footer.title")}
          </Typography>
        </Box>

        <Stack
          direction="row"
          mx={2}
          gap={1}
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
          mt={1}
        >
          <Box
            sx={{
              color: "rgba(255, 255, 255, 0.8)",
              textAlign: "center !important",
              fontSize: "12px",
              fontFamily: "Poppins-Medium",
              whiteSpace: "nowrap",
              borderRight: "2px solid rgba(255, 255, 255, 0.8)",
              pr: 1,
            }}
          >
            {t("landingpage.rightContent.footer.subtitle.Searchlook")}
          </Box>
          <Box
            sx={{
              color: "rgba(255, 255, 255, 0.8)",
              textAlign: "center !important",
              fontSize: "12px",
              fontFamily: "Poppins-Medium",
              whiteSpace: "nowrap",
              borderRight: "2px solid rgba(255, 255, 255, 0.8)",
              pr: 1,
            }}
          >
            Río Missisipi 49
          </Box>
          <Box
            onClick={() => handleOpenUploadModal()}
            sx={{
              color: "rgba(255, 255, 255, 0.8)",
              textAlign: "center",
              fontSize: "12px",
              fontFamily: "Poppins-Medium",
              whiteSpace: "nowrap",
              cursor: "pointer",
            }}
          >
            {t("landingpage.rightContent.footer.subtitle.Privacy Notice")}
          </Box>
          <Box
            onClick={() => handleOpenTermsModal()}
            sx={{
              color: "rgba(255, 255, 255, 0.8)",
              textAlign: "center",
              fontSize: "12px",
              fontFamily: "Poppins-Medium",
              whiteSpace: "nowrap",
              borderLeft: "2px solid rgba(255, 255, 255, 0.8)",
              pl: 1,
              cursor: "pointer",
            }}
          >
            {t("landingpage.rightContent.footer.subtitle.Terms and Conditions")}
          </Box>
        </Stack>

        {/* <Box
          sx={{
            display: "flex",
            // justifyContent: "center",
            pt: 1,
          }}
        >
          <List
            sx={{
              display: "flex",
              // justifyContent: "center",
              flexDirection:
                pathname === "/"
                  ? { xs: "column", xxl: "row" }
                  : { xs: "column", md: "row" },
              p: 0,
            }}
          >
            <Box
              sx={{
                display: "flex",
                // justifyContent: "center",
                p: 0,
                flexDirection: { xs: "column", lg: "row" },
              }}
            >
              <ListItem
                sx={{
                  px: 1,
                  borderRight: {
                    xs: "solid 0px rgba(255, 255, 255, 0.8)",
                    sm: "solid 2px rgba(255, 255, 255, 0.8)",
                  },
                  py: 0,
                  ".MuiListItem-root": {
                    textAlign: "center !important",
                  },
                }}
              >
                <Box
                  sx={{
                    color: "rgba(255, 255, 255, 0.8)",
                    textAlign: "center !important",
                    fontSize: "12px",
                    fontFamily: "Poppins-Medium",
                    textDecoration: "none",
                    width: { xs: "100%", sm: "120px" },
                  }}
                >
                  {t("landingpage.rightContent.footer.subtitle.Searchlook")}
                </Box>
              </ListItem>
              <ListItem
                sx={{
                  px: 1,
                  borderRight: {
                    xs: "solid 0px rgba(255, 255, 255, 0.8)",
                    sm: "solid 2px rgba(255, 255, 255, 0.8)",
                  },
                  py: 0,
                  ".MuiListItem-root": {
                    textAlign: "center !important",
                  },
                }}
              >
                <Box
                  sx={{
                    color: "rgba(255, 255, 255, 0.8)",
                    textAlign: "center !important",
                    fontSize: "12px",
                    fontFamily: "Poppins-Medium",
                    textDecoration: "none",
                    width: { xs: "100%", sm: "120px" },
                  }}
                >
                  Río Missisipi 49
                </Box>
              </ListItem>
            </Box>
            <Box
              sx={{
                display: "flex",
                // justifyContent: "center",
                p: 0,
                flexDirection: { xs: "column", lg: "row" },
              }}
            >
              <ListItem
                sx={{
                  px: 1,
                  borderRight: {
                    xs: "solid 0px rgba(255, 255, 255, 0.8)",
                    sm: "solid 2px rgba(255, 255, 255, 0.8)",
                  },
                  py: 0,
                  ".MuiListItem-root": {
                    textAlign: "center !important",
                  },
                }}
              >
                <Link

                  sx={{
                    color: "rgba(255, 255, 255, 0.8)",
                    fontSize: "12px",
                    fontFamily: "Poppins-Medium",
                    textDecoration: "none",
                    width: { xs: "100%", sm: "104px" },
                    cursor: "pointer",
                    textAlign: "center !important",
                  }}
                >
                  {t("landingpage.rightContent.footer.subtitle.Privacy Notice")}
                </Link>
              </ListItem>
              <ListItem
                sx={{
                  px: 1,
                  py: 0,
                  textAlign: "center",
                  ".MuiListItem-root": {
                    textAlign: "center !important",
                  },
                }}
              >
                <Link
                  onClick={() => handleOpenTermsModal()}
                  sx={{
                    color: "rgba(255, 255, 255, 0.8)",
                    fontSize: "12px",
                    fontFamily: "Poppins-Medium",
                    textDecoration: "none",
                    width: { xs: "100%", sm: "140px" },
                    cursor: "pointer",
                    textAlign: "center !important",
                  }}
                >
                  {t(
                    "landingpage.rightContent.footer.subtitle.Terms and Conditions"
                  )}
                </Link>
              </ListItem>
            </Box>
          </List>
        </Box>
        */}
        <Box sx={{ display: "flex", justifyContent: "center", pt: 2 }}>
          <Stack
            // direction={{ xs: "column", sm: "row" }}
            direction="row"
            flexWrap="wrap"
            gap={2}
            justifyContent="center"
            alignItems="center"
          >
            <Link
              sx={{
                color: "rgba(255, 255, 255, 0.8)",
                fontSize: "12px",
                fontFamily: "Poppins-Medium",
                textDecoration: "none",
                background: "rgba(255, 255, 255, 0.1)",
                borderRadius: 50,
                px: 1,
                py: 0.5,
                display: "flex",
                justifyContent: "center",
                whiteSpace: "nowrap",
                "&:hover": {
                  color: "rgba(255, 255, 255, 0.8)",
                },
              }}
              href="tel:55 3332 6533"
            >
              <CallIcon sx={{ mt: 0.5, mr: 1 }} fontSize="20px" />
              {t("landingpage.rightContent.footer.contactData.tel")}
            </Link>
            <Link
              sx={{
                color: "rgba(255, 255, 255, 0.8)",
                fontSize: "12px",
                fontFamily: "Poppins-Medium",
                textDecoration: "none",
                background: "rgba(255, 255, 255, 0.1)",
                borderRadius: 50,
                px: 1,
                py: 0.5,
                display: "flex",
                justifyContent: "center",
                whiteSpace: "nowrap",
                "&:hover": {
                  color: "rgba(255, 255, 255, 0.8)",
                },
              }}
              href="mailto:contact@searchlook.mx"
            >
              <MailIcon sx={{ mt: 0.5, mr: 1 }} fontSize="20px" />
              {t("landingpage.rightContent.footer.contactData.email")}
            </Link>
          </Stack>
        </Box>
      </Box>
      <NoticePrivacy
        open={openUploadModal}
        handleClose={handleOpenUploadModal}
      />
      <TermsConditions
        openTerms={openTermsModal}
        handleTermsClose={handleOpenTermsModal}
      />
    </>
  );
}

export default FooterTwo;
