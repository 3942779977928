import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { muiTheme } from "./muiTheme/index";
import { CssBaseline, ThemeProvider } from "@mui/material";
import "./il8n";

import { AppProvider } from "./context";

// Bringing in the GoogleOAuthProvider from the package
import { GoogleOAuthProvider } from "@react-oauth/google";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <GoogleOAuthProvider clientId={
    window.location.origin.endsWith("ai")? process.env.REACT_APP_GOOGLE_CLIENT_ID_AI : process.env.REACT_APP_GOOGLE_CLIENT_ID_MX
  }>
    <BrowserRouter>
      <ThemeProvider theme={muiTheme}>
        <CssBaseline />
        <AppProvider>
          <App />
        </AppProvider>
      </ThemeProvider>
    </BrowserRouter>
  </GoogleOAuthProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
