// without counter

import React,{useState, useEffect, useRef} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
// import logoImg from "../../../../assets/images/sologo.png"
import logoImg from "../../../../assets/images/Loader.gif"

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 160,
  // maxWidth:"95%",
  bgcolor: 'background.paper',
  border: '0px solid #000',
  boxShadow: 0,
  // p: 4,
  backgroundColor:"transparent",
  border:0,
  "&:focus": {
    outline: "none",
  }
};

export default function LogoModalLoader({isOpenLogoModal,visible}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Modal
        open={isOpenLogoModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{backgroundColor:"rgba(27, 32, 44, 0.6)"}}
      >
        <Box sx={style}>
          <Box sx={{display:"flex",flexDirection:"row", justifyContent:"center"}}>
            <img src={logoImg} height={260} alt='logo image' />
          </Box>
        </Box>
      </Modal>
    </div>
  );
}