import { useTranslation } from "react-i18next";
import { Grid, Typography, Box, Stack } from "@mui/material";
import React, { useState, useCallback, useEffect } from "react";
import InputRightIcon from "../../@common/Input/InputRightIcon/InputRightIcon";
import personIcon from "../../../assets/images/person.svg";
import DatePickerInput from "../../@common/Input/DatePicker/DatePickerInput";
import DropDownInputSimple from "../../@common/Input/DropDownInputSimple/DropDownInputSimple";
import DropDown from "../../@common/Input/DropDownInputSimple/DropDown";
import LabelInput from "../../@common/Input/LabelInput";
import RadioButtonsGroup from "../../@common/Input/radios";
import Button from "@mui/material/Button";
import GenerateQueryModal from "../../@common/Modal/GenerateQueryModal";
import GenerateQueryModalConfirmation from "../../@common/Modal/GenerateQueryModalConfirmation";
import WithBureauQueryConfirmationModal from "../../@common/Modal/WithBureauModal/WithBureauQueryConfirmationModal";
import EmailSuccessfullySent from "../../@common/Modal/EmailSuccessfullySent";
import ThankYouMessage from "../../@common/Modal/ThankYouMessage";
import LogoModalLoader from "../../@common/Modal/WithBureauModal/LogoModalLoader";
import WithBureauQueryModal from "../../@common/Modal/WithBureauModal/WithBureauQueryModal";
import sjcl from "sjcl";
import axios from "axios";
import CryptoJS from "crypto-js";
import {
  AesEncrypt,
  getKeysSaltAndIv,
  setKeysDict,
  encryptCurpZeroPayload,
  prepareCurpPayload,
} from "../../../utils/encryptionHelper";
import { options_edo } from "../../../utils/optionsEdo";
import { getCookiesList } from "../../../utils/cookiesHelper";
import {
  timeSleep,
  checkCurpZero,
  checkCupons,
} from "../../../utils/bureauHelper";
import { formatDate } from "../../../utils/dateFormatHelper";
import GuestTermsAndConditionModal from "../../@common/Modal/WithBureauModal/GuestTermsAndConditionModal";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { call_api_function } from "../../../utils/verifyAPICalls";
import FormHelperText from "@mui/material/FormHelperText";
import AlertModal from "../../@common/Modal/AlertModal";
import AutoCompleteModal from "../../@common/Modal/AutoCompleteModal";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import "./ToggleButton.css";
import AutocompleteBlue from "../../../assets/images/Autocomplete Arrow Blue.svg";
import Autocompleteorange from "../../../assets/images/Autocomplete Arrow Orange.svg";
import { serverRequest, serverRequest2 } from "../../../utils/requestHelper";
import { encryptData } from "../../../utils/encryptionHelper";
import SwitchesCustomized from "./SwitchButton";


function WithBureauQueryForm() {
  const { t } = useTranslation();
  const canidateType = [
    { label: t("thanks.Executive Staff"), value: "Executive_Staff" },
    { label: t("thanks.Operational Staff"), value: "Operational_Staff" },
  ];

  const sexTypeList = [
    { label: t("labels.radioLabelFemale"), value: "Feminine" },
    { label: t("labels.radioLabelMale"), value: "Male" },
    { label: t("labels.radioLabelNonBinary"), value: "Non-Binary" },
  ];

  const activateLegalFilterList = [
    { label: t("labels.inputLabelYes"), value: "Yes" },
    { label: t("labels.inputLabelNo"), value: "No" },
  ];

  const multiReportTypeList = [
    { label: t("labels.radioLabelWithBureau"), value: "With Bureau" },
    { label: t("labels.radioLabelWithoutBureau"), value: "Without Bureau" },
  ];
  options_edo[0].name = t("placeholders.ddEdonto");

  var subsections_dict = {
    A: true,
    B: true,
    C: true,
    D: true,
    E: true,
    F: true,
    G: true,
    sub_a1: true,
    sub_a2: true,
    sub_a3: true,
    sub_a4: true,
    sub_a5: true,
    sub_a6: true,
    sub_a7: true,
    sub_a8: true,
    sub_a9: true,
    sub_b1: true,
    sub_b2: true,
    sub_b3: true,
    sub_b4: true,
    sub_b5: true,
    sub_b6: true,
    sub_b7: true,
    sub_b8: true,
    sub_b9: true,
    sub_b10: true,
    sub_c1: true,
    sub_c2: true,
    sub_c3: true,
    sub_c4: true,
    sub_c5: true,
    sub_c6: true,
    sub_c7: true,
    sub_c8: true,
    sub_d1: true,
    sub_d2: true,
    sub_d3: true,
    sub_d4: true,
    sub_d5: true,
    sub_d6: true,
    sub_d7: true,
    sub_d8: true,
    sub_d9: true,
    sub_e1: true,
    sub_e2: true,
    sub_e3: true,
    sub_e4: true,
    sub_e5: true,
    sub_f1: true,
    sub_f2: true,
    sub_f3: true,
    sub_f4: true,
    sub_f5: true,
    sub_f6: true,
    sub_g1: true,
    sub_g2: true,
    sub_g3: true,
    sub_g4: true,
  };
  // cookie state
  const [cookieList, setCookieList] = useState([]);
  // Modals states
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLogoModalOpen, setLogoModalOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false); // Add a state to control the visibility of the alert
  const [DataSend, setDataSend] = useState({});
  const [showAlertModal, setShowAlertModal] = useState(false); // Add a state to control the visibility of the alert
  const [loadFunction, setLoadFunction] = useState(true);
  const [showtext, setShowtext] = useState("");
  const [appendData, setapeendData] = useState(false);
  const handleCloseAlertModal = () => {
    setShowAlertModal(false);
    setLoadFunction(true);
  };
  const handleAcceptAlert = () => {
    setLoadFunction(false);
    setShowAlertModal(false);
    const gender = sexTypedata === "M" ? "Feminine" : sexTypedata === "X" ? "Non-Binary" : "Male";
    setCurp(curpACdata);
    setName(namedata);
    setLastName(last_namedata);
    setMotherLastName(mother_last_namedata);
    setSexType(gender);
    setDateOfBrith(date_of_brithdata);
    setBirthState(birthStatedata);
    updateDisabledFields();
  };

  const updateDisabledFields = (ac_type = '') => {
    console.log(['ACType', ac_type || ACType, (ac_type || ACType) == "curp"]);
    if ((ac_type || ACType) == "curp") {
      setDisableCURP(false);
      setDisableName(true);
      setDisableLastName(true);
      setDisableMotherLastName(true);
      setDisableSexType(true);
      setDisableDateOfBirth(true);
      setDisableBirthState(true);
    } else {
      console.log('updateDisabledFields.disableCURP', disableCURP);
      setDisableCURP(true);
      setDisableName(false);
      setDisableLastName(false);
      setDisableMotherLastName(false);
      setDisableSexType(false);
      setDisableDateOfBirth(false);
      setDisableBirthState(false);
    }
  }

  // Form data
  const [name, setName] = useState("");
  const [curp, setCurp] = useState("");
  const [last_name, setLastName] = useState("");
  const [mother_last_name, setMotherLastName] = useState("");
  const [sexType, setSexType] = useState("Feminine");
  const [date_of_brith, setDateOfBrith] = useState(""); // fecha de nacimiento
  const [birthState, setBirthState] = useState("State");
  const [legalFiter, setLegalFiter] = useState("Yes");
  const [folio1, setFolio1] = useState("");
  const [folio2, setFolio2] = useState("");
  const [curpACdata, setCurpACdata] = useState("");
  const [namedata, setNamedata] = useState("");
  const [last_namedata, setLastNamedata] = useState("");
  const [mother_last_namedata, setMotherLastNamedata] = useState("");
  const [sexTypedata, setSexTypedata] = useState("");
  const [CandidateTypedata, setCandidateTypedata] = useState("");
  const [date_of_brithdata, setDateOfBrithdata] = useState(""); // fecha de nacimiento
  const [birthStatedata, setBirthStatedata] = useState("");

  const [ACType, setACType] = useState("");
  const [disableCURP, setDisableCURP] = useState(true);
  const [disableName, setDisableName] = useState(true);
  const [disableLastName, setDisableLastName] = useState(true);
  const [disableMotherLastName, setDisableMotherLastName] = useState(true);
  const [disableSexType, setDisableSexType] = useState(true);
  const [disableDateOfBirth, setDisableDateOfBirth] = useState(true);
  const [disableBirthState, setDisableBirthState] = useState(true);

  const [isNSSValid, setIsNSSValid] = useState(false);
  const [isCedulaValid, setIsCedulaValid] = useState(false);
  const [isFolio1Valid, setIsFolio1Valid] = useState(false);

  const [curpData, setcurpData] = useState("");
  const [estadoData, setestadoData] = useState("");
  const [nssData, setnssData] = useState("");
  const [nss1Data, setnss1Data] = useState("");
  const [cname, setcname] = useState("");
  const [edad, setedad] = useState("");
  const [showHomonyms, setHomonyms] = useState(false);

  const [selectClientdata, setselectClient] = useState("");
  const [Clientdata, setClientdata] = useState("");
  const [zonedata, setzonedata] = useState("");
  const [selectzonedata, setselectzonedata] = useState("");
  const [branchesdata, setbranchesdata] = useState("");
  const [selectbranchesdata, setselectbranchesdata] = useState("");
  // const [multiReportType, setMultiReportType] = useState('With Bureau');
  const [nbdata, setNBData] = useState({});
  const [isAutoComplete, setIsAutoComplete] = useState(false); // auto complete state
  //Optional Form data
  const [sns, setSnS] = useState(""); //nss
  const [id, setId] = useState(""); //cedula

  const [curpFocus, setCurpFocus] = useState(false);
  const [nameFocus, setNameFocus] = useState(false);
  const [lastNameFocus, setLastNameFocus] = useState(false);
  const [motherLastNameFocus, setMotherLastNameFocus] = useState(false);
  const [curpChanged, setCurpChanged] = useState(false);
  const [nameChanged, setNameChanged] = useState(false);
  const [lastNameChanged, setLastNameChanged] = useState(false);
  const [motherLastNameChanged, setMotherLastNameChanged] = useState(false);
  const [dateOfBrithChanged, setDateOfBrithChanged] = useState(false);
  const [birthStateChanged, setBirthStateChanged] = useState(false);

  //when with bureau data
  const [street, setStreet] = useState(""); //calle
  const [number, setNumber] = useState(""); //number
  const [cologne, setCologne] = useState("");
  const [municipality, setMunicipality] = useState("");
  const [postal_code, setPostalCode] = useState("");
  const [state, setState] = useState("State"); //estada

  const [emailSentModalOpen, setEmailSentModalOpen] = useState(false);
  const [customeReport, setCustomReport] = useState(false);
  const [subsectionDicts, setSubSectionDicts] = useState(subsections_dict);

  const [snsFlag, setSnsFlag] = useState(false);
  const [snsError, setSnsError] = useState("Field Required");
  const [idFlag, setIdFlag] = useState(false);
  const [idError, setIdError] = useState("Field Required");

  const [showNSSField, setShowNSSField] = useState(false);
  const [showCedulaField, setShowCedulaField] = useState(false);
  const [showSEPTextField, setShowSEPTextField] = useState(false);
  const isNameEmpty = name.trim() === "";
  const isLastNameEmpty = last_name.trim() === "";
  const isFolioFirstEmpty =  folio1.trim() === "";
  const isFolioSecondEmpty = folio2.trim() === "";
  const isMotherNameEmpty = mother_last_name.trim() === "";
  const isbirthState =
    birthState.trim() === "State" || birthState.trim() === "Estado";
  const dateOfBirthPattern =
    /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(19\d{2}|20[0-9]{2})$/;
  const isDateOfBirthValid =
    date_of_brith.trim() !== "" && dateOfBirthPattern.test(date_of_brith);
  const isDateBirthEmpty = !isDateOfBirthValid;
  const isStreetEmpty = street.trim() === "";
  const isPostal_code = postal_code.trim() === "";
  const isCologneEmpty = cologne.trim() === "";
  const isStateEmpty = state.trim() === "State";
  const isMunicipalityEmpty = municipality.trim() === "";
  const handleCheckboxChange = (event) => {
    setShowNSSField(event.target.checked);
  };
  const handleCheckboxTwoChange = (event) => {
    setShowCedulaField(event.target.checked);
  };
  const handleCheckboxThreeChange  =(event) => {
    setShowSEPTextField(event.target.checked);
  }

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const handleFocus = (inputId) => {
    console.log("handleFocus", inputId);
    if (inputId === "curp") {
      setCurpFocus(true);
      setCurpChanged(false);
    }
    if (inputId === "name") {
      setNameFocus(true);
      setNameChanged(false);
    } else if (inputId === "last_name") {
      setLastNameFocus(true);
      setLastNameChanged(false);
    } else if (inputId === "mother_last_name") {
      setMotherLastNameFocus(true);
      setMotherLastNameChanged(false);
    }
  };
  const handleBlur = (inputId) => {
    console.log("handleBlur", inputId);
    if (inputId === "curp") {
      setCurpFocus(false);
      console.log("curpChanged", curpChanged);
      if (curpChanged) {
        // fetchAutoCompleteModal();
      }
    } else if (inputId === "name") {
      setNameFocus(false);
      console.log("nameChanged", nameChanged);
      if (nameChanged) {
        // fetchAutoCompleteModal();
      }
    } else if (inputId === "last_name") {
      setLastNameFocus(false);
      console.log("lastNameChanged", lastNameChanged);
      if (lastNameChanged) {
        // fetchAutoCompleteModal();
      }
    } else if (inputId === "mother_last_name") {
      setMotherLastNameFocus(false);
      console.log("motherLastNameChanged", motherLastNameChanged);
      if (motherLastNameChanged) {
        // fetchAutoCompleteModal();
      }
    }
  };
  const handleFolio1Change = (e) => {
    let value = e.target.value.replace(/[^a-zA-Z0-9,-]/g, '');
    let new_value = '';
    for (let folio of value.split(',')) {
      let new_folio = folio.replace(/-/g, "");
      if (new_folio.length > 8) new_folio = new_folio.slice(0, 8) + '-' + new_folio.slice(8);
      if (new_folio.length > 12) new_folio = new_folio.slice(0, 13) + '-' + new_folio.slice(13);
      if (new_folio.length > 16) new_folio = new_folio.slice(0, 18) + '-' + new_folio.slice(18);
      if (new_folio.length > 20) new_folio = new_folio.slice(0, 23) + '-' + new_folio.slice(23);
      new_folio = new_folio.replace(/-+$/, "");
      new_value += (new_value? ',':'') + (folio.includes(new_folio)? folio:new_folio);
    }
    for (let i = 36; i < new_value.length; i += 37) {
      if (new_value[i] !== ',') {
        new_value = new_value.slice(0, i) + ',' + new_value.slice(i);
      }
    }
    setFolio1(new_value);
    if (new_value.replace(/,/g, "").length > 0 && new_value.replace(/,/g, "").length % 36 === 0) {
      setIsFolio1Valid(true);
    } else {
      setIsFolio1Valid(false);
    }
  };
  const handleFolio2Change = (event) => {
    let value = event.target.value.replace(/\s/g, '');
    setFolio2(value);
  };

  useEffect(() => {
    if (cookieList.length != 0) {
      loadAutoComplete();
    }
  }, [cookieList]);
  // Effects
  useEffect(() => {
    const userCookiesList = getCookiesList("userInfo");
    if (userCookiesList.length > 0) {
      setCookieList(userCookiesList);
    }
  }, []);
  useEffect(() => {
    if (Object.keys(DataSend).length !== 0) {
      getCurpZero(DataSend);
    }
  }, [DataSend]);
  useEffect(() => {
    if (subsectionDicts !== subsections_dict) {
      //setWithoutBureauModalOpen(true);
    }
  }, [subsectionDicts]);

  // First Modal handler
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  // Logo modal Handlers
  const handleOpenLogoModal = () => {
    setLogoModalOpen(true);
  };

  const handleCloseLogoModal = () => {
    setLogoModalOpen(false);
  };

  const email = "";
  const phone = "";
  const scl = "1"; // FIXME Sujeto confirmación legal

  //user
  const user_tjt = "NA";
  const user_master = "1";
  const user_customer = "qwert";
  const [multiReportType, setMultiReportType] = useState("Without Bureau");

  const [withoutBureauModalOpen, setWithoutBureauModalOpen] = useState(false);
  const [withBureauOpen, setWithBureauOpen] = useState(false);

  const [isThankYouModalOpen, setIsThankYouModalOpen] = useState(false);
  const [reportData, setReportData] = useState({});

  // modal open and close

  const withoutBureauHandler = () => {
    handleOpenLogoModal();
    encryptData1();
  };

  const openThankYouModal = (data) => {
    setReportData(data);
    setIsThankYouModalOpen(true);
  };

  const withBureauHandler = () => {
    handleOpenLogoModal();
    encryptData1();
    // setWithBureauOpen(true)
  };
  var transformedArray = [];
  const clientdata = async () => {
    const endpoint = process.env.REACT_APP_BASE_URL2 + "perfil/clientes";
    // Check if cookieList[6] is defined and not empty
    if (cookieList[6]) {
      const data_eid = encryptData(cookieList[6], 500);
      var enc_id = {
        data_eid: {
          data_eidi: data_eid["iv"],
          data_eids: data_eid["salt"],
          data_eidc: data_eid["ct"],
        },
      };
      const res = await serverRequest2("post", endpoint, enc_id);
      // Check if res.data is an array before using .map()
      if (Array.isArray(res.data)) {
        // Handle the API response here
        transformedArray = res.data.map((option) => {
          return { name: option };
        });
        setClientdata(transformedArray);
      } else {
      }
    } else {
    }
  };
  var zoneArray = [];
  const zoneapidata = async () => {
    // Check if cookieList[6] is defined and not empty
    if (cookieList[6]) {
      const data_eid = encryptData(cookieList[6], 500);
      var enc_id = {
        data_eid: {
          data_eidi: data_eid["iv"],
          data_eids: data_eid["salt"],
          data_eidc: data_eid["ct"],
        },
      };

      const res = await serverRequest("post", "perfil/di_zonas", enc_id);
      // Check if res.data is an array before using .map()
      if (Array.isArray(res.data)) {
        // Handle the API response here
        zoneArray = res.data.map((option) => {
          return { name: option };
        });
        setzonedata(zoneArray);
      } else {
      }
    } else {
    }
  };
  var branchArray = [];
  const branchdata = async () => {
    // Check if cookieList[6] is defined and not empty
    if (cookieList[6]) {
      const data_eid = encryptData(cookieList[6], 500);
      var enc_id = {
        data_eid: {
          data_eidi: data_eid["iv"],
          data_eids: data_eid["salt"],
          data_eidc: data_eid["ct"],
        },
      };
      const res = await serverRequest("post", "perfil/sucursales", enc_id);
      // Check if res.data is an array before using .map()
      if (Array.isArray(res.data)) {
        // Handle the API response here
        branchArray = res.data.map((option) => {
          return { name: option };
        });
        setbranchesdata(branchArray);
      } else {
      }
    } else {
    }
  };

  useEffect(() => {
    if (cookieList.length != 0) {
      clientdata();
      zoneapidata();
      branchdata();
    }
  }, [cookieList]);
  //fine
  const handleCanidateType = useCallback((e) => {
    setCandidateTypedata(
      CandidateTypedata == e.target.value ? "" : e.target.value
    );
  });
  const legalFilterHandler = useCallback((e) => {
    setLegalFiter(legalFiter == e.target.value ? "" : e.target.value);
  });

  const multiReportTypeHandler = useCallback((e) => {
    setMultiReportType(multiReportType == e.target.value ? "" : e.target.value);
  });

  // Encrypt data for curp_zero, when querying from Panel
  const encryptData1 = () => {
    // const consult_data_array =
    var filterLegalNo = "";
    if (legalFiter === "Yes") {
      filterLegalNo = "Si";
    } else {
      filterLegalNo = "";
    }
    var genderNo = "";
    if (sexType === "Feminine") {
      genderNo = "M";
    } else if (sexType === "Non-Binary") {
      genderNo = "X";
    } else {
      genderNo = "H";
    }
    var staffValue = "";
    if (CandidateTypedata === "Executive_Staff") {
      staffValue = "ejecutivo";
    } else if (CandidateTypedata === "Operational_Staff") {
      staffValue = "operativo";
    }
    var arr_date_of_brith = [];
    const formateDateOfBirth = formatDate(date_of_brith);
    arr_date_of_brith = formateDateOfBirth.split("/");
    const day_birth = arr_date_of_brith[0];
    const month_birth = arr_date_of_brith[1] || "";
    const year_birth = arr_date_of_brith[2] || "";
    var userAgent = navigator.userAgent; //The user agent string contains information about the browser and operating system being used by the user to access the web page. It includes details such as the name and version of the browser, as well as the device and operating system being used.

    var keys = getKeysSaltAndIv();
    var subject_name = name + " " + last_name + " " + mother_last_name;
    var keys = getKeysSaltAndIv();
    const formData = {
      name: name,
      curp_zero: curp,
      last_name: last_name,
      mother_last_name: mother_last_name,
      day_birth: day_birth,
      month_birth: month_birth,
      year_birth: year_birth,
      genderNo: genderNo,
      sns: sns,
      id: id,
      scl: scl,
      email: email,
      phone: phone,
      birthState: birthState,
      street: street + " " + number,
      cologne: cologne,
      municipality: municipality,
      postal_code: postal_code,
      state: multiReportType === "Without Bureau" ? "NA" : state,
      filterLegalNo: filterLegalNo,
      user_tjt: user_tjt,
      user_master: user_master,
      user_customer: user_customer,
      userAgent: navigator.userAgent,
      multiRepo: multiReportType === "Without Bureau" ? "SB" : "CB",
      cliente: selectClientdata,
      selectzonedata: selectzonedata,
      selectbranchesdata: selectbranchesdata,
      staff: staffValue,
      folio1: folio1.replace(/,+$/, ""),
      folio2: folio2.replace(/,+$/, ""),
    };
    const dS = prepareCurpPayload(formData, cookieList, subsections_dict);
    setDataSend(dS);
  };
  const getCurpZero = async (dataCurpZero) => {
    const cupon = multiReportType == "With Bureau" ? "CB" : "SB";
    const checkCuponsResponse = await checkCupons(cupon, cookieList[3]);
    if (checkCuponsResponse === "error") {
      handleCloseLogoModal();
      setShowAlert(true);
      let messageData;
      if (localStorage.getItem("selectedLanguage") == "en") {
        messageData =
          "We apologize, but you have run out of query coupons. Please contact your administrator or purchase more coupons to continue using our services. If you need assistance, call us at +52 55 3332 6533 or email us at contacto@searchlook.mx.";
      } else {
        messageData =
          "Lo sentimos, has agotado tus cupones de consulta. Por favor, contacta a tu administrador o adquiere más cupones para continuar utilizando nuestros servicios. Si necesitas ayuda, llámanos al 55 3332 6533 o escríbenos a contacto@searchlook.mx.";
      }
      setShowtext(messageData);
      return;
    }
    const endpoint = process.env.REACT_APP_BASE_URL2 + "curp_zero";
    var continuarCurpZero = false;
    const response = await axios.post(endpoint, dataCurpZero, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.data.mensaje === "Guardado correctamente") {
      continuarCurpZero = true;
      nextStep(response.data._id, continuarCurpZero);
    }
  };

  const nextStep = async (insertedId, continuarCurpZero) => {
    if (continuarCurpZero === true) {
      try {
        await timeSleep();
      } catch (e) {
        setShowAlert(true);
        setShowtext("Desconexión de red. Continúa");
      }
      var i_curpzero = 0;
      var i_flag = false;
      while (i_flag === false) {
        var returnCurpZero = await checkCurpZero(insertedId);
        if (returnCurpZero.pending === false) {
          if (returnCurpZero.final_response.status === "1") {
            // Limpiar inputs
            /* Persona bloqueada */
            if (returnCurpZero.final_response.curps._id == "Bloqueado") {
              setShowAlert(true);
              setShowtext("Bloqueado");
            } else {
              var data = {
                _id: returnCurpZero.final_response.curps._id,
                nombre: returnCurpZero.final_response.cname,
                email: cookieList[3],
                sucursal: returnCurpZero.petition.sujeto.sucursal,
                zona: returnCurpZero.petition.sujeto.zona,
                staff: returnCurpZero.petition.sujeto.staff,
                filtro_legal: returnCurpZero.petition.sujeto.filtro_legal,
                cliente: returnCurpZero.petition.sujeto.cliente,
                estado: returnCurpZero.petition.sujeto.buro.estado,
                colonia: returnCurpZero.petition.sujeto.buro.colonia,
                municipio: returnCurpZero.petition.sujeto.buro.municipio,
              };
              setNBData(data);
              handleCloseLogoModal();
              validarTipoConsulta();
            }
          } else if (returnCurpZero.final_response.status === "varios") {
            setcurpData(returnCurpZero["final_response"]["curps"][0]["curp"]);
            setestadoData(
              returnCurpZero["final_response"]["curps"][0]["estado"]
            );
            setnssData(returnCurpZero["final_response"]["curps"][0]["nss"]);
            setnss1Data(returnCurpZero["final_response"]["curps"][1]["nss"]);
            setcname(returnCurpZero["final_response"]["curps"][0]["cname"]);
            setedad(returnCurpZero["final_response"]["curps"][0]["edad"]);
            setHomonyms(true);
            var data = {
              _id: returnCurpZero.final_response.curps[0]._id,
              nombre: returnCurpZero.final_response.cname,
              email: cookieList[3],
            };
            setNBData(data);
            handleCloseLogoModal();
            const sub_dict = resetSubsections("SB");
            setSubSectionDicts(sub_dict);
            validarTipoConsulta();
          } else if (
            returnCurpZero.final_response.error === "error_en_los_datos"
          ) {
            // Borde naranja en inputs
            setShowAlert(true);
            setShowtext(t("GuestDashboard.consultPopUpMessageDataError"));
            handleCloseLogoModal();
          } else {
            // Borde naranja en inputs
            setShowAlert(true);
            setShowtext("else");
            handleCloseLogoModal();
          }
          i_flag = true;
          insertedId = false;
          returnCurpZero = false;
        } else if (returnCurpZero.pending === true) {
          try {
            await timeSleep();
          } catch (e) {
            setShowAlert(true);
            setShowtext("Desconexión de red. Continúa");
          }
        }
        i_curpzero++;
        if (i_curpzero === 20 && i_flag === false) {
          i_flag = true;
          insertedId = false;
          returnCurpZero = false;
          handleCloseLogoModal();
          setShowAlert(true);
          setShowtext(t("Dashboard.Validation"));
        }
      }
    }
  };

  const validarTipoConsulta = async () => {
    // decide which modal box to open
    const cupon = multiReportType == "With Bureau" ? "CB" : "SB";
    const checkCuponsResponse = await checkCupons(cupon, cookieList[3]);
    if (checkCuponsResponse === "error") {
      handleCloseLogoModal();
      setShowAlert(true);
      let messageData;
      if (localStorage.getItem("selectedLanguage") == "en") {
        messageData =
          "We apologize, but you have run out of query coupons. Please contact your administrator or purchase more coupons to continue using our services. If you need assistance, call us at +52 55 3332 6533 or email us at contacto@searchlook.mx.";
      } else {
        messageData =
          "Lo sentimos, has agotado tus cupones de consulta. Por favor, contacta a tu administrador o adquiere más cupones para continuar utilizando nuestros servicios. Si necesitas ayuda, llámanos al 55 3332 6533 o escríbenos a contacto@searchlook.mx.";
      }
      setShowtext(messageData);
    }

    if (cupon == "CB") {
      if (!customeReport) {
        resetSubsections("CB");
      }
      setWithBureauOpen(true);
    } else if (cupon == "SB") {
      if (!customeReport) {
        const sub_dict = resetSubsections("SB");
        setSubSectionDicts(sub_dict);
      }
      setWithoutBureauModalOpen(true);
    }
  };

  const snsHandler = (e) => {
    const reg = /^[0-9]*$/;
    const input = e.target.value; // Remove leading and trailing spaces
    const validReg = reg.test(input);
    if (validReg) {
      if (input.length === 11) {
        setIsNSSValid(true);
      } else {
        setIsNSSValid(false);
      }
      setSnsFlag(false);
    } else {
      setSnsFlag(true);
      setSnsError(t("thanks.enternumber"));
    }
    setSnS(input);
  };

  const idHandler = (e) => {
    const reg = /^[0-9]*$/;
    const input = e.target.value; // Remove leading and trailing spaces
    const validReg = reg.test(input);
    if (validReg) {
      if (input.length === 7 || input.length === 8) {
        setIsCedulaValid(true);
      } else {
        setIsCedulaValid(false);
      }
      setIdFlag(false);
    } else {
      setIdFlag(true);
      setIdError(t("thanks.enternumber"));
    }
    setId(input);
  };

  const resetSubsections = (tipoCupon) => {
    var sub_dict = subsections_dict;

    if (tipoCupon == "CB") {
      Object.keys(sub_dict).forEach(function (key) {
        sub_dict[key] = true;
      });
    } else if (tipoCupon == "SB") {
      Object.keys(sub_dict).forEach(function (key) {
        sub_dict[key] = true;
      });
      sub_dict["D"] =
        sub_dict["sub_d1"] =
        sub_dict["sub_d2"] =
        sub_dict["sub_d3"] =
        sub_dict["sub_d4"] =
        sub_dict["sub_d5"] =
        sub_dict["sub_d6"] =
        sub_dict["sub_d7"] =
        sub_dict["sub_d8"] =
        sub_dict["sub_d9"] =
        sub_dict["sub_g3"] =
        false;
    }

    return sub_dict;
  };
  const loadAutoComplete = async () => {
    let formData = new FormData();
    formData.append("email", cookieList[3]);
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL2 + "api/autocomplete_load",
      formData,
      {
        header: {
          "Content-Type": "multipart/form-data",
        },
      },
      { timeout: 10000 }
    );
    if (response.data) {
      setIsAutoComplete(true);
    }
  };
  const saveAutoComplete = async () => {
    let formData = new FormData();
    formData.append("email", cookieList[3]);
    formData.append("active", isAutoComplete);
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL2 + "api/autocomplete_save",
      formData,
      {
        header: {
          "Content-Type": "multipart/form-data",
        },
      },
      { timeout: 10000 }
    );
    if (response.data === "Done") {
      loadAutoComplete();
    }
  };
  const fetchAutoCompleteModal = async () => {
    try {
      if (loadFunction) {
        if (
          (curp && curp.length === 18) || (
            name !== "" &&
            last_name !== "" &&
            sexType !== "" &&
            birthState !== "State" &&
            dateOfBirthPattern.test(date_of_brith) &&
            birthState !== "" &&
            date_of_brith !== ""
          )
        ) {
          let formData = new FormData();
          formData.append("curp", curp);
          formData.append("name", name);
          formData.append("lname", last_name);
          formData.append("slname", mother_last_name);
          formData.append("birth_state", birthState);
          formData.append("date_of_birth", date_of_brith);
          formData.append("gender", sexType === "Feminine" ? "M" : sexType === "Non-Binary" ? "X" : "H");
          formData.append("empresa", cookieList[13]);
          formData.append('folio1', folio1);
          formData.append('folio2', folio2);
          const response = await axios.post(
            process.env.REACT_APP_BASE_URL2 + "api/autocomplete",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              timeout: 10000,
            }
          );
          let valid_data;
          if (
            response.data.curp_based &&
            response.data.curp_based != 'No encontrado'
          ) {
            setACType("curp");
            if (response.data.curp_based.match < 8) {
              valid_data = response.data.curp_based;
            }
          } else {
            if (isAutoComplete) {
              if (response.data.count_based.name) {
                setACType("count");
                if (response.data.count_based.match < 8) {
                  valid_data = response.data.count_based;
                } else {
                  setLoadFunction(false);
                  setCurp(response.data.count_based.curp);
                  updateDisabledFields('count');
                }
              }
            } else {
              if (response.data.match_based.name) {
                setACType("match");
                if (response.data.match_based.match == 7) {
                  valid_data = response.data.match_based;
                }
              }
            }
          }
          if (valid_data) {
            setCurpACdata(valid_data.curp);
            setNamedata(valid_data.name);
            setLastNamedata(valid_data.lname);
            setMotherLastNamedata(valid_data.slname);
            setSexTypedata(valid_data.gender);
            setDateOfBrithdata(valid_data.date_of_birth);
            setBirthStatedata(valid_data.birth_state);
            setShowAlertModal(true);
          }
        }
      }
    } catch (error) { }
  };

  useEffect(() => {
    if (
      (curp && curp.length === 18) || (
        !nameFocus &&
        !lastNameFocus &&
        !motherLastNameFocus &&
        name !== "" &&
        last_name !== "" &&
        sexType !== "" &&
        birthState !== "State" &&
        dateOfBirthPattern.test(date_of_brith) &&
        birthState !== "" &&
        date_of_brith !== ""
      )
    ) {
      console.log([curp, nameFocus, lastNameFocus, motherLastNameFocus, sexType, date_of_brith, birthState, isAutoComplete,]);
      fetchAutoCompleteModal();
    }
  }, [
    curp,
    nameFocus,
    lastNameFocus,
    motherLastNameFocus,
    sexType,
    date_of_brith,
    birthState,
    isAutoComplete,
  ]);

  useEffect(() => {
    if (isAutoComplete) {
      setLoadFunction(true);
    }
  }, [isAutoComplete]);
  const [error, setError] = useState('');

  const [optionsEdo, setOptionsEdo] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [changeColor, setChangeColor] = useState(false);
  const [changeColor2, setChangeColor2] = useState(false);


  const autoFill = async () => {
    const endpoint = `${process.env.REACT_APP_BASE_URL2}get_address_auto?d_codigo=${postal_code}`;
    try {
      const response = await axios.get(endpoint);
      const data = response.data;
      if (Array.isArray(data) && data.length === 1) {
        const item = data[0];
        autofillFields(`${item.d_codigo}, ${item.d_asenta}, ${item.D_mnpio}, ${item.d_estado}`);
      } else if (Array.isArray(data) && data.length > 1) {
        setOptionsEdo(data); // Include a default option
        setShowDropdown(true);
      }
    } catch (error) {
      console.error("Error fetching address:", error);
    }
  };

  const handleDropdownChange = (event) => {
    const selectedIndex = event.target.value;
    const selectedData = optionsEdo[selectedIndex];
    console.log(selectedData, selectedIndex, optionsEdo, "**********")
    setSelectedOption(selectedData);
    autofillFields(selectedIndex);
    setShowDropdown(false);
  };

  const autofillFields = (addressData) => {
    // Split the addressData string into individual parts
    const [d_codigo, d_asenta, D_mnpio, d_estado] = addressData.split(", ");

    // Set each part into separate states
    setCologne(d_asenta);
    setMunicipality(D_mnpio);
    setState(d_estado);
    setChangeColor2(true)
  };

  return (
    <Box sx={{ backgroundColor: "#ffffff", p: 4 }}>
      {showAlert && <AlertModal text={showtext} onClose={handleCloseAlert} />}
      {showAlertModal && (
        <AutoCompleteModal
          curp={curpACdata}
          name={namedata}
          last_name={last_namedata}
          mother_last_name={mother_last_namedata}
          date_of_brith={date_of_brithdata}
          sexType={sexTypedata}
          birthState={birthStatedata}
          onClose={handleCloseAlertModal}
          onAccept={handleAcceptAlert}
        />
      )}


      {/* <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" justifyContent="space-between" mb={2}> */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Box>
            <SwitchesCustomized
              isAutoComplete={isAutoComplete}
              toggleButton={(switchState) => {
                setLoadFunction(true);
                setIsAutoComplete(switchState);
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box pb={2} mt={-4}>
            <InputRightIcon
              disabled={
                (name || last_name || mother_last_name || date_of_brith || birthState != 'State') && disableCURP
              }
              mailIcon={personIcon} // Assuming personIcon is defined somewhere
              type="text"
              label={t("labels.inputLabelCurp")}
              name="curp"
              id="Curp"
              inputValue={curp}
              placeholder={t("labels.inputLabelCurp")}
              onChange={(event) => {
                setLoadFunction(true);

                setCurp(event.target.value.toUpperCase());
                setCurpChanged(true);
                setName('');
                setLastName('');
                setMotherLastName('');
                setSexType('Feminine');
                setDateOfBrith('');
                setBirthState('State');

                setDisableName(true);
                setDisableLastName(true);
                setDisableMotherLastName(true);
                setDisableSexType(true);
                setDisableDateOfBirth(true);
                setDisableBirthState(true);
              }}
              onBlur={() => { handleBlur('curp') }}
              onFocus={() => { handleFocus('curp') }}
              maxLength={18} // Add maxLength attribute to restrict input to 18 characters
              style={{ width: '100%' }} // Set a fixed width or percentage width for the input field
            />
          </Box>
        </Grid>


        {error && (
          <Grid item xs={12}>
            <Box mt={{ xs: 2, sm: 0 }} style={{ color: 'red' }}>
              {error}
            </Box>
          </Grid>
        )}
      </Grid>

      {/* </Stack> */}





      <Grid container spacing={2}>
        {zonedata.length > 0 ? (
          <Grid item xs={12} sm={6}>
            <Box sx={{ mt: 0 }}>
              {zonedata.length > 0 ? (
                <DropDown
                  options_data={zonedata}
                  type="text"
                  name="zone"
                  subLabel=""
                  id="zone"
                  label={t("thanks.Select zone")}
                  placeholder={t("thanks.Select zone")}
                  inputValue={selectzonedata}
                  value="" // Set to an empty string initially
                  onChange={(event) => setselectzonedata(event.target.value)}
                />
              ) : null}
            </Box>
          </Grid>
        ) : null}

        {branchesdata.length > 0 ? (
          <Grid item xs={12} sm={6}>
            <Box sx={{ mt: 0 }}>
              {branchesdata.length > 0 ? (
                <DropDown
                  options_data={branchesdata}
                  type="text"
                  name="branch"
                  subLabel=""
                  id="branch"
                  label={t("thanks.Select branch")}
                  placeholder={t("thanks.Select branch")}
                  inputValue={selectbranchesdata}
                  value="" // Set to an empty string initially
                  onChange={(event) =>
                    setselectbranchesdata(event.target.value)
                  }
                />
              ) : null}
            </Box>
          </Grid>
        ) : null}

        {Clientdata.length > 0 ? (
          <Grid item xs={12} sm={6}>
            <Box sx={{ mt: 0.8 }}>
              {Clientdata.length > 0 ? (
                <DropDown
                  options_data={Clientdata}
                  type="text"
                  name="Client"
                  subLabel=""
                  id="Client"
                  label={t("thanks.Select Client")}
                  placeholder={t("thanks.Select Client")}
                  inputValue={selectClientdata}
                  value="" // Set to an empty string initially
                  onChange={(event) => setselectClient(event.target.value)}
                />
              ) : null}
            </Box>
          </Grid>
        ) : null}

        {cookieList[13] === "ICONN" ? (
          <Grid item xs={12} sm={6}>
            <Box sx={{ mt: { xs: 0, sm: 2 } }}>
              {cookieList[13] === "ICONN" ? (
                <RadioButtonsGroup
                  topLabelName={t("thanks.Candidate Type")}
                  topLabel={true}
                  GroupingList={canidateType}
                  value={CandidateTypedata}
                  onChange={handleCanidateType}
                  smGrid={6}
                  xs={6}
                  sx_group={{
                    mt: "8px",
                  }}
                />
              ) : null}
            </Box>
          </Grid>
        ) : null}

        <Grid item xs={12} sm={6} style={{
          paddingTop: 0,
        }}>
          <Box>
            <InputRightIcon
              disabled={curp && disableName}
              mailIcon={personIcon}
              type="text"
              label={t("labels.inputLabelName")}
              name="name"
              id="Name"
              inputValue={name}
              placeholder={t("placeholders.name")}
              onChange={(event) => {
                setLoadFunction(true);

                setName(event.target.value);
                setNameChanged(true);
                setCurp('');

                console.log('disableCURP', disableCURP);
                setDisableCURP(true);
              }}
              onBlur={() => {
                handleBlur("name");
              }}
              onFocus={() => {
                handleFocus("name");
              }}
              mandatory={true}
              FilledOut={curp && curp.length === 18}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} style={{
          paddingTop: 0,
        }}>
          <Box>
            <InputRightIcon
              disabled={curp && disableLastName}
              mailIcon={personIcon}
              type="text"
              label={t("labels.inputLabelLastName")}
              name="lname"
              id="lname"
              placeholder={t("placeholders.lastname")}
              inputValue={last_name}
              onChange={(event) => {
                setLoadFunction(true);

                setLastName(event.target.value);
                setLastNameChanged(true);
                setCurp('');

                console.log('disableCURP', disableCURP);
                setDisableCURP(true);
              }}
              onBlur={() => {
                handleBlur("last_name");
              }}
              onFocus={() => {
                handleFocus("last_name");
              }}
              mandatory={true}
              FilledOut={curp && curp.length === 18}

            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} style={{
          paddingTop: 0,
        }}>
          <Box >
            <InputRightIcon
              disabled={curp && disableMotherLastName}
              mailIcon={personIcon}
              type="text"
              label={t("labels.inputLabelMotherName")}
              name="motherlastname"
              id="motherlastname"
              placeholder={t("placeholders.mothername")}
              inputValue={mother_last_name}
              onChange={(event) => {
                setLoadFunction(true);

                setMotherLastName(event.target.value);
                setMotherLastNameChanged(true);
                setCurp('');

                console.log('disableCURP', disableCURP);
                setDisableCURP(true);
              }}
              onBlur={() => {
                handleBlur("mother_last_name");
              }}
              onFocus={() => {
                handleFocus("mother_last_name");
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box >
            <RadioButtonsGroup
              disabled={curp && disableSexType}
              topLabelName={t("labels.topLabelSex")}
              topLabel={true}
              GroupingList={sexTypeList}
              value={sexType}
              onChange={(event) => {
                setLoadFunction(true);

                setSexType(sexType == event.target.value ? "" : event.target.value);
                setCurp('');

                console.log('disableCURP', disableCURP);
                setDisableCURP(true);
              }}
              smGrid={4}
              xs={6}
              sx_group={{
                mt: "8px",
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} style={{
          paddingTop: 0,
        }}>
          <Box sx={{ mt: 0 }}>
            <DatePickerInput
              disabled={curp && disableDateOfBirth}
              selectedDate={date_of_brith}
              onDateChange={(value) => {
                setLoadFunction(true);

                setDateOfBrith(value);
                setDateOfBrithChanged(true);
                setCurp('');

                console.log('disableCURP', disableCURP);
                setDisableCURP(true);
              }}
              mandatory={true}
              FilledOut={curp && curp.length === 18}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} style={{
          paddingTop: 0,
        }}>
          <Box sx={{ mt: 0 }}>
            <DropDownInputSimple
              disabled={curp && disableBirthState}
              options_data={options_edo}
              type="text"
              name="companyname"
              subLabel={t("sublabels.mandatory")}
              id="companyname"
              label={t("labels.inputLabelEdoNto")}
              inputValue={birthState}
              onChange={(event) => {
                setLoadFunction(true);

                setBirthState(event.target.value);
                setBirthStateChanged(true);
                setChangeColor(true);
                setCurp('');

                console.log('disableCURP', disableCURP);
                setDisableCURP(true);
              }}
              // style={{ backgroundColor: 'rgba(255, 140, 0, 0.1)' }} // Apply color for mandatory field
              mandatory={true}
              FilledOut={curp && curp.length === 18}
              selectedColor={changeColor}

            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box >
            <FormControlLabel
              control={
                <Checkbox
                  checked={showNSSField}
                  onChange={handleCheckboxChange}
                />
              }
              label={t("labels.inputLabelSnsOptional")}
            // labelPlacement="start" // Adjust the label placement as needed
            />
            {showNSSField && (
              <LabelInput
                type="number"
                subLabel=""
                label=""
                name="SNS"
                id="SNS"
                placeholder={t("placeholders.sns")}
                minLength={11}
                maxLength={11}
                onChange={snsHandler}
                isValid={isNSSValid}
              />
            )}
          </Box>
          {snsFlag && (
            <FormHelperText sx={{ color: "red", fontSize: 12 }}>
              {snsError}
            </FormHelperText>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box >
            <FormControlLabel
              control={
                <Checkbox
                  checked={showCedulaField}
                  onChange={handleCheckboxTwoChange}
                />
              }
              label={t("labels.inputLabelIdOptional")}
            // labelPlacement="start" // Adjust the label placement as needed
            />
            {showCedulaField && (
              <Box sx={{ mt: 0 }}>
                <LabelInput
                  type="number"
                  subLabel=""
                  label=""
                  name="ID"
                  id="ID"
                  placeholder={t("placeholders.id")}
                  maxLength={8}
                  onChange={idHandler}
                  isValid={isCedulaValid}
                />
              </Box>
            )}
          </Box>
          {idFlag && (
            <FormHelperText sx={{ color: "red", fontSize: 12 }}>
              {idError}
            </FormHelperText>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showSEPTextField}
                  onChange={handleCheckboxThreeChange}
                />
              }
              label={t("labels.inputLabelSEPOptional")}
            // labelPlacement="start" // Adjust the label placement as needed
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box></Box>
        </Grid>
        {
          showSEPTextField && <>
            <Grid item xs={12} sm={6} className="folio-fields-section">
              <Box>
                  <InputRightIcon
                  mailIcon={null}
                  type="text"
                  label={t("labels.inputLabelFolioOne")}
                  name="folio1"
                  id="Folio1"
                  inputValue={folio1}
                  placeholder={t("placeholders.FolioOne")}
                  onChange={handleFolio1Change}
                  onBlur={() => {}}
                  onFocus={() => {}}
                  minLength={36}
                  style={{ width: '100%' }}
                  isValid={isFolio1Valid}
                  />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} className="folio-fields-section">
              <Box>
                <InputRightIcon
                  mailIcon={null}
                  type="text"
                  label={t("labels.inputLabelFolioSecond")}
                  name="folio2"
                  id="Folio2"
                  inputValue={folio2}
                  placeholder={t("placeholders.FolioSecond")}
                  onChange={handleFolio2Change}
                  onBlur={() => {}}
                  onFocus={() => {}}
                  style={{ width: '100%' }}
                />
              </Box>
            </Grid>
          </>
        }
        <Grid item xs={12} sm={multiReportType == "With Bureau" ? 6 : 6} style={{
          paddingTop: 0,
        }}>
          <Box sx={{ mt: { xs: 0, sm: 2 } }}>
            <RadioButtonsGroup
              topLabelName={t("labels.inputActiveLegalFilter")}
              topLabel={true}
              GroupingList={activateLegalFilterList}
              value={legalFiter}
              onChange={legalFilterHandler}
              smGrid={5}
              xs={6}
              sx_group={{
                mt: "8px",
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={multiReportType == "With Bureau" ? 6 : 6} style={{
          paddingTop: 0,
        }}>
          <Box sx={{ mt: { xs: 0, sm: 3 } }}>
            <RadioButtonsGroup
              topLabelName={t("labels.inputMultiReportType")}
              topLabel={true}
              GroupingList={multiReportTypeList}
              value={multiReportType}
              onChange={multiReportTypeHandler}
              smGrid={6}
              xs={6}
              sx_group={{
                mt: "8px",
              }}
            />
          </Box>
        </Grid>
        {multiReportType == "With Bureau" && (
          <>
            <Grid item xs={12} sm={6}>
              <Box >
                <div>
                  {showDropdown ? (
                    <DropDownInputSimple
                      options_data={optionsEdo}
                      label={t("labels.inputLabelPostalCode")}
                      inputValue={selectedOption ? selectedOption.value : ""}
                      onChange={(event) => handleDropdownChange(event)}
                      useCustomFormat={true}
                      mandatory={true}
                      showDefaultOption={true}

                    />
                  ) : (
                    <LabelInput
                      type="text"
                      subLabel=""
                      label={t("labels.inputLabelPostalCode")}
                      name="code"
                      id="code"
                      placeholder={t("placeholders.postalcode")}
                      inputValue={postal_code}
                      onChange={(event) => setPostalCode(event.target.value)}
                      onKeyUp={autoFill}
                      maxLength={6}
                      Mandatory={true}
                    />
                  )}
                </div>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ mt: 0 }}>
                <LabelInput
                  type="text"
                  subLabel=""
                  label={t("labels.inputLabelStreet")}
                  name="street"
                  id="street"
                  placeholder={t("placeholders.street")}
                  inputValue={street}
                  onChange={(event) => setStreet(event.target.value)}
                  Mandatory={true}

                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ mt: 0 }}>
                <LabelInput
                  type="text"
                  subLabel=""
                  label={t("labels.inputLabelNumber")}
                  name="number"
                  id="number"
                  placeholder={t("placeholders.number")}
                  // inputValue={number}
                  onChange={(event) => setNumber(event.target.value)}
                  maxLength={10}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box >
                <LabelInput
                  type="text"
                  subLabel=""
                  label={t("labels.inputLabelCologne")}
                  name="cologne"
                  id="cologne"
                  placeholder={t("placeholders.cologne")}
                  inputValue={cologne}
                  onChange={(event) => setCologne(event.target.value)}
                  Mandatory={true}


                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ mt: { xs: 0, sm: 2 } }}>
                <LabelInput
                  type="text"
                  subLabel=""
                  label={t("labels.inputLabelMavorsOffice")}
                  name="office"
                  id="office"
                  placeholder={t("placeholders.mavorsoffice")}
                  inputValue={municipality}
                  onChange={(event) => setMunicipality(event.target.value)}
                  Mandatory={true}

                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box sx={{ mt: { xs: 0, sm: 2 } }}>
                <DropDownInputSimple
                  options_data={options_edo}
                  type="text"
                  name="condition"
                  subLabel=""
                  id="condition"
                  label={t("labels.inputLabelCondition")}
                  onChange={(event) => {
                    setState(event.target.value);
                    setChangeColor2(true)
                  }}
                  inputValue={state}
                  mandatory={true}
                  selectedColor={changeColor2}

                />
              </Box>
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "primary.main",
                textAlign: "center",
                borderRadius: "6px",
                py: 1.5,
                px: 8,
                color: "#fff",
                textDecoration: "none",
                fontSize: 18,
                fontFamily: "Gilroy-Medium",
                textTransform: "capitalize",
              }}
              disabled={!(
                (((curp && curp.length === 18) || !(isNameEmpty || isLastNameEmpty || isDateBirthEmpty || isbirthState)))
                &&
                ((showNSSField ? isNSSValid : true) && (showCedulaField ? isCedulaValid : true) && (showSEPTextField ? isFolio1Valid : true))
                &&
                (multiReportType === "With Bureau" ? !(isStreetEmpty || isCologneEmpty || isMunicipalityEmpty || isStateEmpty || isPostal_code) : true)
              )}
              onClick={() => {
                if (curp && curp.length < 18) {
                  setError(t("labels.inputLabelCurpError"));
                } else {
                  setError(false); // Clear error state if CURP length is not less than 18
                  if (multiReportType === "With Bureau") {
                    withBureauHandler();
                  } else {
                    withoutBureauHandler();
                  }
                  saveAutoComplete();
                }
              }}

            >
              {t("buttons.consult")}
            </Button>

            {/************************** With Bureau All Modal imported *************************/}
            <GuestTermsAndConditionModal
              withoutBureauModalOpen={withoutBureauModalOpen}
              setWithoutBureauModalOpen={setWithoutBureauModalOpen}
              tagLineShow={true}
              subjectCheckboxShow={true}
              emailBoxButtonShow={true}
              emailLabel={t("guestTermsCondition.emailLabel")}
              dataCurpZero={DataSend}
              nbData={nbdata}
              onConfirmation={openThankYouModal}
              setEmailSentModalOpen={setEmailSentModalOpen}
              subsections_dicts={subsectionDicts}
              showHomonyms={showHomonyms}
              nss1Data={nss1Data}
              nssData={nssData}
              estadoData={estadoData}
              curpData={curpData}
              cname={cname}
              edad={edad}
            />

            {/************************** Without Bureau All Modal imported *************************/}
            <LogoModalLoader isOpenLogoModal={isLogoModalOpen} />
            {/* <WithBureauQueryModal/> */}
            <WithBureauQueryConfirmationModal
              withBureauOpen={withBureauOpen}
              setWithBureauOpen={setWithBureauOpen}
              onConfirmation={openThankYouModal}
              tagLineShow={true}
              subjectCheckboxShow={true}
              emailBoxButtonShow={true}
              emailLabel={t("guestTermsCondition.emailLabelpin")}
              mobileLabel={t("guestTermsCondition.mobileLabel")}
              dataCurpZero={DataSend}
              nbData={nbdata}
              subsections_dicts={subsectionDicts}
              setEmailSentModalOpen={setEmailSentModalOpen}
            />
            {/* <WithBureauQueryConfirmationModal
                 tagLineShow={false}
                 subjectCheckboxShow={true}
                 emailBoxButtonShow={true}
                 emailLabel=t("thanks.Cellnumberreceiving")
                 mobileLabel="Mail receipt"
                 /> */}
            <EmailSuccessfullySent
              isOpen={emailSentModalOpen}
              setEmailSentModalOpen={setEmailSentModalOpen}
              setWithoutBureauModalOpen={setWithoutBureauModalOpen}
            />
            <ThankYouMessage
              isOpen={isThankYouModalOpen}
              reportInfo={reportData}
              subsetions_dicts={subsectionDicts}
            />
            {/* <GenerateQueryModalConfirmation
                 dataCurpZero={DataSend} nbData={nbdata} isOpen={isModalOpen} onClose={handleCloseModal}
                 tagLineShow={true}
                 subjectCheckboxShow={true}
                 emailBoxButtonShow={true}
                 emailLabel="Mail for sending Terms and Conditions / Privacy Notice"
                 /> */}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default WithBureauQueryForm;
