import { IconClearAll, IconSettings } from "@tabler/icons-react";
import {
  createContext,
  useState,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from "react";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";

import { useTranslation } from "react-i18next";

import axios from "axios";
import Spinner from "../Spinner/Spinner";
import { ChatContext } from "./ChatContext";
import { ChatInput } from "./ChatInput";
import { ChatLoader } from "./ChatLoader";
import { MemoizedChatMessage } from "./MemoizedChatMessage";
import { Grid, Typography, Box } from "@mui/material";
import underLine from "../../assets/images/under-line.png";
import { timeSleep } from "../../utils/bureauHelper";
import AlertModal from "../@common/Modal/AlertModal";
import LogoModalLoader from "../@common/Modal/WithBureauModal/LogoModalLoader.js";

function Chat() {
  const { t } = useTranslation("chat");

  const { data, curp, item_id } = useLocation().state;

  useEffect(() => {
    let formdata = new FormData();
    formdata.append("vlid", item_id);
    formdata.append("type", "vistos_chat");
    axios.post(process.env.REACT_APP_BASE_URL2 + "ver_revision", formdata, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }, []);

  const { chatLog, setChatLog } = useContext(ChatContext);
  const [showAlert, setShowAlert] = useState(false);
  const [showText, setShowText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentMessage, setCurrentMessage] = useState();
  const [autoScrollEnabled, setAutoScrollEnabled] = useState(true);
  const [showSettings, setShowSettings] = useState(false);
  const [showScrollDownButton, setShowScrollDownButton] = useState(false);
  const initialized = useRef(false);

  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);
  const textareaRef = useRef(null);
  const stopConversationRef = useRef(null);

  const openModal = (flag, errorMsg) => {
    setShowAlert(flag);
    setShowText(errorMsg);
  };

  useEffect(() => {
    if (!initialized.current) {
      setChatLog({
        loading: false,
        messageIsStreaming: false,
        conversations: [data],
        selectedConversation: data,
        currentMessage: data.messages[data.messages.length - 2],
      });
      setCurrentMessage(chatLog.currentMessage);
      initialized.current = true;
    }
  }, [setChatLog, setCurrentMessage]);

  const saveConversation = async (conversation) => {};

  const saveConversations = async (conversations) => {};

  const updateConversation = (updatedConversation, allConversations) => {
    const updatedConversations = allConversations.map((c) => {
      if (c.id === updatedConversation.id) {
        return updatedConversation;
      }

      return c;
    });

    saveConversation(updatedConversation);
    saveConversations(updatedConversations);

    return {
      single: updatedConversation,
      all: updatedConversations,
    };
  };

  const handleUpdateConversation = (conversation, data) => {
    const updatedConversation = {
      ...conversation,
      [data.key]: data.value,
    };

    const { single, all } = updateConversation(
      updatedConversation,
      chatLog.conversations
    );

    setChatLog({ selectedConversation: single, conversations: all });
  };

  const handleSend = useCallback(
    async (message, deleteCount = 0) => {
      if (chatLog.selectedConversation) {
        let updatedConversation;

        if (deleteCount) {
          const updatedMessages = [...chatLog.selectedConversation.messages];
          for (let i = 0; i < deleteCount; i++) {
            updatedMessages.pop();
          }
          updatedConversation = {
            ...chatLog.selectedConversation,
            messages: [...updatedMessages, message],
          };
        } else {
          updatedConversation = {
            ...chatLog.selectedConversation,
            messages: [...chatLog.selectedConversation.messages, message],
          };
        }
        setChatLog({
          selectedConversation: updatedConversation,
          loading: true,
          messageIsStreaming: true,
        });
        const chatBody = {
          item_id: item_id,
          chat_id: updatedConversation.chat_id,
          curp: curp,
          messages: updatedConversation.messages,
          currentMessage: message,
        };
        const response = await axios.post(
          process.env.REACT_APP_BASE_URL2 + `gpt/chat/reply`,
          chatBody,
          { headers: { "Content-Type": "application/json" } }
        );
        // const response = {
        //   status: 200,
        //   statusText: 'OK',
        //   data: {
        //     content: 'Hola, soy el asistente virtual de Bureau Veritas. ¿En qué puedo ayudarte?',
        //   },
        // }
        if (response.status !== 200) {
          setChatLog({ loading: false, messageIsStreaming: false });
          toast.error(response.statusText);
          return;
        }
        if (!response.data) {
          setChatLog({ loading: false, messageIsStreaming: false });
          return;
        }
        if (updatedConversation.messages.length === 1) {
          const { content } = message;
          const customName =
            content.length > 30 ? content.substring(0, 30) + "..." : content;
          updatedConversation = {
            ...updatedConversation,
            name: customName,
          };
        }
        setChatLog({ loading: false });
        let done = false;
        let isFirst = true;
        let text = "";

        for (let word of response.data.content.split(" ")) {
          console.log("word", word);
          text += word + " ";
          console.log("text", text);

          if (isFirst) {
            isFirst = false;
            const updatedMessages = [
              ...updatedConversation.messages,
              {
                role: "assistant",
                content: word,
                thread_x: response.data.thread_x,
                thread_y: response.data.thread_y,
              },
            ];
            updatedConversation = {
              ...updatedConversation,
              messages: updatedMessages,
            };
            setChatLog({ selectedConversation: updatedConversation });
          } else {
            const updatedMessages = updatedConversation.messages.map(
              (message, index) => {
                if (index === updatedConversation.messages.length - 1) {
                  return {
                    ...message,
                    content: text,
                  };
                }
                return message;
              }
            );
            updatedConversation = {
              ...updatedConversation,
              messages: updatedMessages,
            };
            setChatLog({ selectedConversation: updatedConversation });
          }
          await timeSleep(10);
        }

        saveConversation(updatedConversation);
        const updatedConversations = chatLog.conversations.map(
          (conversation) => {
            if (conversation.id === chatLog.selectedConversation.id) {
              return updatedConversation;
            }
            return conversation;
          }
        );
        if (updatedConversations.length === 0) {
          updatedConversations.push(updatedConversation);
        }
        setChatLog({ conversations: updatedConversations });
        saveConversations(updatedConversations);
        setChatLog({ messageIsStreaming: false });
      }
    },
    [chatLog.conversations, chatLog.selectedConversation]
  );

  const scrollToBottom = useCallback(() => {
    if (autoScrollEnabled) {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      textareaRef.current?.focus();
    }
  }, [autoScrollEnabled]);

  const handleScroll = () => {
    if (chatContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        chatContainerRef.current;
      const bottomTolerance = 30;

      if (scrollTop + clientHeight < scrollHeight - bottomTolerance) {
        setAutoScrollEnabled(false);
        setShowScrollDownButton(true);
      } else {
        setAutoScrollEnabled(true);
        setShowScrollDownButton(false);
      }
    }
  };

  const handleScrollDown = () => {
    chatContainerRef.current?.scrollTo({
      top: chatContainerRef.current.scrollHeight,
      behavior: "smooth",
    });
  };

  const handleSettings = () => {
    setShowSettings(!showSettings);
  };

  const onClearAll = () => {
    if (
      window.confirm(t("Are you sure you want to clear all messages?")) &&
      chatLog.selectedConversation
    ) {
      handleUpdateConversation(chatLog.selectedConversation, {
        key: "messages",
        value: [],
      });
    }
  };

  function throttle(func, limit) {
    let lastFunc;
    let lastRan;

    return function () {
      const context = this;
      const args = arguments;

      if (!lastRan) {
        func.apply(context, args);
        lastRan = Date.now();
      } else {
        clearTimeout(lastFunc);
        lastFunc = setTimeout(function () {
          if (Date.now() - lastRan >= limit) {
            func.apply(context, args);
            lastRan = Date.now();
          }
        }, limit - (Date.now() - lastRan));
      }
    };
  }

  const scrollDown = () => {
    if (autoScrollEnabled) {
      messagesEndRef.current?.scrollIntoView(true);
    }
  };
  const throttledScrollDown = throttle(scrollDown, 250);

  // useEffect(() => {
  //   console.log('currentMessage', currentMessage);
  //   if (currentMessage) {
  //     handleSend(currentMessage);
  //     setChatLog({
  //       ...chatLog,
  //       ...{currentMessage: undefined},
  //     });
  //   }
  // }, [currentMessage]);

  useEffect(() => {
    throttledScrollDown();
    chatLog.selectedConversation &&
      setCurrentMessage(
        chatLog.selectedConversation.messages[
          chatLog.selectedConversation.messages.length - 2
        ]
      );
  }, [chatLog.selectedConversation, throttledScrollDown]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setAutoScrollEnabled(entry.isIntersecting);
        if (entry.isIntersecting) {
          textareaRef.current?.focus();
        }
      },
      {
        root: null,
        threshold: 0.5,
      }
    );
    const messagesEndElement = messagesEndRef.current;
    if (messagesEndElement) {
      observer.observe(messagesEndElement);
    }
    return () => {
      if (messagesEndElement) {
        observer.unobserve(messagesEndElement);
      }
    };
  }, [messagesEndRef]);

  return (
    <>
      <LogoModalLoader isOpenLogoModal={isLoading} />
      {showAlert && (
        <AlertModal text={showText} onClose={() => setShowAlert(false)} />
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Box sx={{ mt: 2 }}>
            <Typography
              sx={{
                fontFamily: "Poppins-SemiBold",
                fontSize: "24px",
                lineHeight: "35px",
                textAlign: "center",
                color: "#1C212D",
              }}
            >
              Chat
            </Typography>
            <Box sx={{ textAlign: "center", mt: -1 }}>
              <img src={underLine} alt="under line" />
            </Box>
          </Box>
        </Grid>
        <Box
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
            padding: "0 0 24px",
          }}
        >
          <Box
            sx={{
              overflowY: "scroll",
              overflowX: "hidden",
              boxShadow:
                "inset 0em 25px 5px 0 rgba(0, 0, 0, 0.1), -4px -4px 6px 0px rgba(0, 0, 0, 0.3)",
              backgroundColor: "rgb(255, 230, 205)",
              "&::-webkit-scrollbar": {
                width: "10px",
                borderRadius: "5px",
              },
              "&::-webkit-scrollbar-track": {
                borderTopRightRadius: "10px",
                borderBottomRightRadius: "10px",
                backgroundColor: "rgb(255, 230, 205)",
              },
              "&::-webkit-scrollbar-track:hover": {
                borderRadius: "10px",
                backgroundColor: "rgba(255, 140, 0, 0.4)",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(255, 140, 0, 1.0)",
                borderRadius: "5px",
                border: "2px solid transparent",
                backgroundClip: "content-box",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                border: "1px solid transparent",
                backgroundColor: "rgba(255, 100, 0, 1.0)",
              },
              borderRadius: "10px",
              mb: 7,
              height: "calc(100vh - 320px)",
            }}
            ref={chatContainerRef}
            onScroll={handleScroll}
          >
            {chatLog.selectedConversation?.messages.length === 0 ? (
              <div
                style={{
                  margin: "auto",
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px", // 5px for normal, 10px for md
                  padding: "12px", // 5px for normal, 12px for md
                  maxWidth: "600px", // for sm
                }}
              ></div>
            ) : (
              <>
                {chatLog.selectedConversation?.messages.map(
                  (message, index) => (
                    <MemoizedChatMessage
                      key={index}
                      message={message}
                      messageIndex={index}
                      onEdit={(editedMessage) => {
                        setCurrentMessage(editedMessage);
                        // discard edited message and the ones that come after then resend
                        handleSend(
                          editedMessage,
                          chatLog.selectedConversation?.messages.length - index
                        );
                      }}
                    />
                  )
                )}

                {chatLog.loading && <ChatLoader />}

                <div
                  style={{
                    height: "162px",
                  }}
                  ref={messagesEndRef}
                />
              </>
            )}
          </Box>
          <ChatInput
            stopConversationRef={stopConversationRef}
            textareaRef={textareaRef}
            onSend={(message, plugin) => {
              setCurrentMessage(message);
              handleSend(message, 0);
            }}
            onScrollDownClick={handleScrollDown}
            onRegenerate={() => {
              if (currentMessage) {
                handleSend(currentMessage, 2);
              }
            }}
            showScrollDownButton={showScrollDownButton}
            // Add styles for ChatInput here if needed
          />
        </Box>
      </Grid>
    </>
  );
}
export default Chat;
