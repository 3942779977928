// ** React Imports
import { useState } from "react";
import { useTranslation } from "react-i18next";
// ** MUI Imports
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";

// ** Icon Imports
import Checkbox from "@mui/material/Checkbox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/material";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const ITEM_HEIGHT = 48;

const AuthorizationCheckbox = (props) => {
  // ** State
  const { t } = useTranslation();
  const options = [
    t("options.All"),
    t("options.Nothing"),
    t("options.Read"),
    t("options.Unauthorized"),
    t("options.Starred"),
    t("options.Unstarred"),
    t("options.Red"),
    t("options.Yellow"),
    t("options.Green"),
    t("options.Unseen"),
  ];
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState("All"); // Initialize with a default option
  const {
    updateFavoriteItems,
    updateUnstarFavoriteItems,
    showAllItems,
    updateReadItems,
    updateUnReadItems,
    updateYellowItems,
    updateRedItems,
    updateGreenItems,
    updateUnseenItems,
    selectAllChecked,
    handleCheckboxChange,
    handleUnselectAllClick,
  } = props;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuItemClick = (option) => {
    setSelectedOption(option);
    if (option === "Starred" || option === "Favoritos") {
      // Call the updateFavoriteItems function when "Starred" is clicked
      updateFavoriteItems();
      handleUnselectAllClick();
    }
    if (option === "Unstarred" || option === "No favoritos") {
      // Call the updateFavoriteItems function when "Unstarred" is clicked
      updateUnstarFavoriteItems();
      handleUnselectAllClick();
    }
    if (option === "All" || option === "Todos") {
      // Call the  showAllItems function when "All" is clicked

      showAllItems();
    }
    if (option === "Unauthorized" || option === "No autorizados") {
      // Call the  updateUnReadItems function when "Unread" is clicked
      updateUnReadItems();
      handleUnselectAllClick();
    }
    if (option === "Read" || option === "Autorizados") {
      // Call the  updateReadItems function when "Read" is clicked
      updateReadItems();
      handleUnselectAllClick();
    }
    if (option === "Red" || option === "Rojo") {
      // Call the  updateRedItems function when "Red" is clicked
      updateRedItems();
      handleUnselectAllClick();
    }
    if (option === "Yellow" || option === "Amarillo") {
      // Call the  updateYellowItems function when "Yellow" is clicked
      updateYellowItems();
      handleUnselectAllClick();
    }
    if (option === "Green" || option === "Verde") {
      // Call the  updateYellowItems function when "Green" is clicked
      updateGreenItems();
      handleUnselectAllClick();
    }
    if (option === "Unseen" || option === "No vistos") {
      // Call the  updateYellowItems function when "Green" is clicked
      updateUnseenItems();
      handleUnselectAllClick();
    }

    handleClose();
  };
  return (
    <div>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Checkbox
          {...label}
          checked={selectAllChecked}
          onChange={handleCheckboxChange}
        />
        <IconButton
          sx={{ p: 0 }}
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <ExpandMoreIcon
            sx={{ transform: anchorEl ? "rotate(180deg)" : null }}
          />
        </IconButton>
      </Box>

      <Menu
        keepMounted
        id="long-menu"
        anchorEl={anchorEl}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: 180,
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            sx={{ borderBottom: "solid 1px rgba(27, 32, 44, 0.11)", mx: 0.5 }}
            key={option}
            selected={option === selectedOption}
            onClick={() => handleMenuItemClick(option)}
            disabled={option === "Nothing"}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default AuthorizationCheckbox;
