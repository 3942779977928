import Cookies from "js-cookie";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { Fragment, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import Button from "@mui/material/Button";

import { Link } from "react-router-dom";

import Footer from "../../Footer/Footer";
import bgImage from "../../assets/images/coverphoto.png";
import AnimationLoginScreen from "../@common/AnimationLoginScreen/AnimationLoginScreen";
import AlertModal from "../@common/Modal/AlertModal";
import OtpSuccessfullySendModal from "../@common/Modal/OtpSuccessfullySendModal";
import verifyImage from "../../assets/images/verify-icon.svg";

import { useNavigate } from "react-router-dom";
import SixDigitCode from "../@common/Input/SixDigitCode/SixDigitCode";
import VerifyAccountModal from "./VerifyAccountModal";
import { serverRequest2 } from "../../utils/requestHelper";
import { useTranslation } from "react-i18next";
import AuthLeftScreen from "../@common/AuthLeftScreen";

function VerifyAccount() {
  const { t } = useTranslation();
  const { dataForm } = useLocation().state;

  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showtext, setShowtext] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showSuccesstext, setShowSuccesstext] = useState("");
  const [showVerifyModalAlert, setShowVerifyModalAlert] = useState(false);
  const [verifyModalText, setVerifyModalText] = useState("");
  const [isVerifyByEmail, setIsVerifyByEmail] = useState(false);
  // validation
  const [otpError, setOTPError] = useState(false);

  useEffect(() => {
    if (showtext != "") {
      setShowAlert(true);
    }
  }, [showtext]);

  useEffect(() => {
    if (showSuccesstext != "") {
      setShowSuccessModal(true);
    }
  }, [showSuccesstext]);

  const handleCloseAlert = () => {
    setShowAlert(false);
    setShowtext("");
  };

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    setShowSuccesstext("");
  };

  const handleCloseVerifyModalAlert = () => {
    setShowVerifyModalAlert(false);
    setVerifyModalText("");
  };

  const loginWithOTP = async () => {
    if (isValidOtpCode(otp)) {
      setOTPError(false);
      dataForm["otp"] = otp;
      dataForm["via_email"] = isVerifyByEmail;
      const response = await serverRequest2("post", "2fa", dataForm);
      if (
        response.status === 200 &&
        typeof response.data === "object" &&
        response.data["error"] == "invalid otp"
      ) {
        setShowtext("Otp is expired or invalid, Please try again");
      } else if (response.data == "no existe") {
        setShowtext("User does not exist");
      } else if (response.data == "error bd") {
        setShowtext(t("thanks.check"));
      } else if (
        response.status === 200 &&
        typeof response.data === "object" &&
        response.data.length != 0
      ) {
        Cookies.set("userInfo", response.data);
        navigate("/Dashboard");
      }
    } else {
      setOTPError(true);
    }
  };

  const resendOtpViaEmail = async () => {
    dataForm["via_email"] = true;
    const response = await serverRequest2("post", "ingresar", dataForm);
    if (
      response.status == 200 &&
      typeof response.data === "object" &&
      response.data["2fa_required"] &&
      response.data["message"] == "OTP SENT Successfully to your email"
    ) {
      setIsVerifyByEmail(true);
      // setShowtext("OTP sent to your registered email")
      setShowSuccesstext(t("thanks.OTPsent"));
    } else if (
      response.status == 200 &&
      response.data == "User is already logged in"
    ) {
      setShowtext("User already logged in");
    } else if (response.data == "no existe") {
      setShowtext("User does not exist");
    } else if (response.data == "error bd") {
      setShowtext(t("thanks.check"));
    } else {
      setShowtext(t("thanks.check"));
    }
  };

  const resendOtpViaPhone = async () => {
    dataForm["via_email"] = false;
    const response = await serverRequest2("post", "ingresar", dataForm);
    if (
      response.status == 200 &&
      typeof response.data === "object" &&
      response.data["2fa_required"] &&
      response.data["message"] == "OTP SENT Successfully to your phone number"
    ) {
      setIsVerifyByEmail(false);
      setShowSuccesstext(t("2FAConfiguration.OTPsent"));
    } else if (
      response.status == 200 &&
      response.data == "User is already logged in"
    ) {
      setShowtext("User already logged in");
    } else if (response.data == "no existe") {
      setShowtext("User does not exist");
    } else if (response.data == "error bd") {
      setShowtext(t("thanks.check"));
    } else {
      setShowtext(t("thanks.check"));
    }
  };

  const isValidOtpCode = (value) => {
    // validate otp
    return value.length === 6 && /^\d+$/.test(value);
  };

  return (
    <>
      {showAlert && <AlertModal text={showtext} onClose={handleCloseAlert} />}
      {showSuccessModal && (
        <OtpSuccessfullySendModal
          text={showSuccesstext}
          onClose={handleCloseSuccessModal}
        />
      )}

      <Box
        sx={{
          background: "#fff",
        }}
        id="particles-js"
      >
        <Grid container>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
                minHeight: "100vh",
              }}
            >
              <Box
                sx={{
                  background: "#ffffff",
                  position: "relative",
                  zIndex: 1,
                  borderRadius: 1,
                  p: { xs: 2, sm: 4 },
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontFamily: "Poppins-SemiBold",
                      fontSize: "18px",
                      lineHeight: "25px",
                      textAlign: "center",
                      color: "#1C212D",
                    }}
                  >
                    {t("thanks.verify")}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", my: 4, justifyContent: "center" }}>
                  <img
                    src={verifyImage}
                    width="100px"
                    height="100px"
                    alt="Verify Your Account"
                  />
                </Box>
                <Box sx={{ display: "flex", mb: 1, justifyContent: "center" }}>
                  <SixDigitCode onCodeChange={(value) => setOtp(value)} />
                </Box>
                {otpError && (
                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flex: "row",
                      mt: 2,
                      mb: 2,
                    }}
                    variant="body2"
                    color="error"
                  >
                    {t("verifyAccount.otp")}
                  </Typography>
                )}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flex: "row",
                    mt: 2,
                    mb: 2,
                  }}
                >
                  {/* <Link style={{ color: "#4165F6", fontFamily: "Gilroy-Medium", fontSize: "16px", display: "flex", justifyContent: "center", textDecoration: "none" }} to="/GuestUser">Resend OTP</Link> */}
                  <Button
                    onClick={resendOtpViaPhone}
                    sx={{
                      color: "#4165F6",
                      display: "flex",
                      justifyContent: "center",
                      textDecoration: "none",
                      fontSize: "16px",
                      fontFamily: "Gilroy-Medium",
                    }}
                    variant="text"
                  >
                    {t("thanks.otp")}
                  </Button>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    mt: 3,
                  }}
                >
                  <Button
                    onClick={loginWithOTP}
                    sx={{
                      backgroundColor: "primary.main",
                      py: 1,
                      width: 270,
                      fontSize: "18px",
                      fontFamily: "Gilroy-Medium",
                    }}
                    variant="contained"
                  >
                    {t("thanks.login")}
                  </Button>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    mt: 3,
                  }}
                >
                  {/* <Button  onClick={handleOpen}  variant="outlined" sx={{ py: 1, fontSize: "18px", fontFamily: "Gilroy-Medium",color:"primary.main",borderColor:"primary.main"}}>Receive OTP Via Email</Button> */}
                  <VerifyAccountModal
                    text={verifyModalText}
                    showAlert={showVerifyModalAlert}
                    onClose={handleCloseVerifyModalAlert}
                    resendOtpViaEmail={resendOtpViaEmail}
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <AuthLeftScreen
              text={t("Login.buttons.signUpLink")}
              url="/Signup"
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
export default VerifyAccount;
