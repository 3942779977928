import { Grid,Typography,Box } from '@mui/material'
import React from 'react'
import Menubar from '../@common/Menubar/Menubar'
import underLine from "../../assets/images/under-line.png"
import CouponsContent from './CouponsContent'
import GuestHeader from '../@common/Header/GuestHeader'
import { useTranslation } from 'react-i18next';
function GuestCoupon() {
  const { t } = useTranslation();
  return (
    <>
         <Grid container spacing={0}>
            <GuestHeader></GuestHeader>
         <Grid item xs={12} sm={12}>
          <Box sx={{mt:2}}>
              <Typography 
                sx={{
                fontFamily:"Poppins-SemiBold",
                fontSize:"24px",
                lineHeight:"35px",
                textAlign:"center",
                color:"#1C212D",
                marginTop:'120px'
                }}>
                {t("GuestUser.header.subtitle")}
                </Typography>
                <Box sx={{textAlign:"center",mt:-1}}>
                <img src={underLine} alt="under line" />
                </Box>
            </Box>  
          </Grid>
        </Grid>
        <Box
          sx={{
            // backgroundColor:"red",
            // width:{sx:"100%",sm:"1145px"},
            // maxWidth:"100%",
            display:"flex",
            flexDirection:"column",
            mt:4
        }}
        >
             <CouponsContent/>
          </Box>
</>
  )
}

export default GuestCoupon