import { Grid, Typography, Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import Menubar from "../@common/Menubar/Menubar";
import { useTranslation } from "react-i18next";
import underLine from "../../assets/images/under-line.png"
import ReportHeader from '../@common/ReportHeader/ReportHeader'
import AccordainTable from '../@common/AccordainTable/AccordainTable'
import Container from '@mui/material/Container';
import GuestHeader from '../@common/Header/GuestHeader'
import GuestFooter from '../../Footer/GuestFooter'
import { translationHelper, translateNestedDict, verReport, checkCupons } from "../../utils/bureauHelper";
import FloatingBar from '../@common/FloatingBar/FloatingBar';
import ReportEmailSendModal from "../@common/Modal/ReportEmailSendModal.js";
import AlertModal from "../@common/Modal/AlertModal";
import LogoModalLoader from "../@common/Modal/WithBureauModal/LogoModalLoader.js";
import axios from 'axios';

function GuestUserReport() {
  const { t, i18n } = useTranslation();
  const [cookieList, setCookieList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailModal, setIsEmailModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showText, setShowText] = useState("");

  const { personalization, espData, sub_sections_data, vlid, curp, usuarioCupon } = useLocation().state;
  const [currentReport, setCurrentReport] = useState(i18n.language === "es" ? espData : translateNestedDict(espData));

  useEffect(() => async () => {
    if (i18n.language === "es") {
      setCurrentReport(espData);
    }
    if (i18n.language === "en") {
      setCurrentReport(translateNestedDict(espData))
    }
  }, [i18n.language]);
  
  const openModal = (flag, errorMsg) => {
    setShowAlert(flag);
    setShowText(errorMsg);
  };

  const resendReport = async () => {
    setIsLoading(true);
    let queryParams = `vlid=${vlid}&curp=${curp}&email=${encodeURIComponent(cookieList[3])}&type=${usuarioCupon}&function=resend&lang=${localStorage.getItem('selectedLanguage') || 'es'}&update=false`;
    try {
      const response = await axios.get(process.env.REACT_APP_BASE_URL2 + 'consult?' + queryParams);
      if (response.data == 'not found') {
        setIsLoading(false);
        openModal(true, t("thanks.NoUpdatedDocument"));
      } else if (response.data == 'error bd') {
        setIsLoading(false);
        openModal(true, "Error Occured");
      } else if (response.data == 'ok') {
        verReport(vlid);
        checkCupons(usuarioCupon, cookieList[3]);
        setIsLoading(false);
        setIsEmailModal(true);
      }
    } catch (error) {
      setIsLoading(false);
      openModal(true, t("thanks.check"));
    }
  };

  const downloadReport = async () => {
    setIsLoading(true);
    let queryParams = `vlid=${vlid}&curp=${curp}&email=${encodeURIComponent(cookieList[3])}&type=${usuarioCupon}&function=download&lang=${localStorage.getItem('selectedLanguage') || 'es'}&update=false`;
    try {
      const response = await axios.get(process.env.REACT_APP_BASE_URL2 + 'consult?' + queryParams, { responseType: 'arraybuffer', });
      if (response.data == 'not found') {
        setIsLoading(false)
        openModal(true, t("thanks.NoUpdatedDocument"));
      } else {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        const timeZone = "America/Mexico_City"; // Replace with the correct time zone identifier
        const currentDate = new Date();
        const formatter = new Intl.DateTimeFormat("en", {
          timeZone,
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        });
        const formattedDate = formatter.format(currentDate).replace(/:/g, "-");

        const filename = `${curp}_${formattedDate}.pdf`;
        a.download = filename;

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        window.URL.revokeObjectURL(url);

        verReport(vlid);
        checkCupons(usuarioCupon, cookieList[3]);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      openModal(true, "Some error occured");
    }
  };

  return (
    <>
    {showAlert && <AlertModal text={showText} onClose={() => setShowAlert(false)} />}
    {isEmailModal && (
      <ReportEmailSendModal
        open={isEmailModal}
        onClose={() => setIsEmailModal(false)}
      />
    )}
    <LogoModalLoader isOpenLogoModal={isLoading} />
    <GuestHeader/>
    <Box sx={{mt:14,mb:10}}>
        <Container>
        <FloatingBar
          resendReport={resendReport}
          downloadReport={downloadReport}
        />
         <Grid container spacing={2}>
         <Grid item xs={12} sm={12}>
          <Box sx={{mt:2}}>
              <Typography 
                sx={{
                fontFamily:"Poppins-SemiBold",
                fontSize:"24px",
                lineHeight:"35px",
                textAlign:"center",
                color:"#1C212D"
                }}>
                  Report
                </Typography>
                <Box sx={{ textAlign: "center", mt: -1 }}>
                  <img src={underLine} alt="under line" />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12}>
              <ReportHeader
                Age={currentReport["Edad"]}
                Curp={currentReport["CURP"]}
                RFC={currentReport["RFC"]}
                Name={currentReport["nombre"]}
                Date={currentReport["Fecha_Consulta"]}
                NSS={currentReport["NSS"]}
                Total_Homonimos={currentReport["Total_Homonimos"]}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Box
                sx={{
                  backgroundColor: "#ffffff",
                  px: 0,
                  py: 3,
                  borderRadius: 1,
                }}
              >
                <AccordainTable
                  personalization={personalization}
                  reportData={currentReport}
                  sub_dicts={sub_sections_data}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <GuestFooter />
    </>
  );
}

export default GuestUserReport;
