import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Fragment } from 'react';
import { textAlign } from '@mui/system';
import { Link, useNavigate } from 'react-router-dom';
import mailImage from "../../../assets/images/mailIcon.png"
import LabelInputLeftIcon from '../../@common/Input/LabelInputLeftIcon';
import videoIcon from "../../../assets/images/profile_Icon.svg"
import { styled } from '@mui/material/styles'
import cameraIcons from "../../../assets/images/p_cameraIcon.svg"
import mailIcon from "../../../assets/images/mailIcon.png"
import phoneIcon from "../../../assets/images/phoneIcon.png"
import companyIcon from "../../../assets/images/company-icon.png"
import Cookies from 'js-cookie';
import sjcl from "sjcl";
import axios from 'axios';
import { serverRequest, serverRequest2 } from "../../../utils/requestHelper";
import { encryptData } from '../../../utils/encryptionHelper';
import { useEffect } from 'react'
// ** Third Party Imports
import { useDropzone } from 'react-dropzone'
import AlertModal from "../../@common/Modal/AlertModal"
import { call_api_function } from "../../../utils/verifyAPICalls"
import { useTranslation } from 'react-i18next';
import defaultimg from "../../../assets/images/avatar.svg"
// Styled component for the upload image inside the dropzone area
const Img = styled('img')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    marginRight: theme.spacing(15.75)
  },
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(4)
  },
  [theme.breakpoints.down('sm')]: {
    width: 160
  }
}))

// Styled component for the heading inside the dropzone area
const HeadingTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(5),
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(4)
  }
}))

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 580,
  maxWidth: "95%",
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 4
};

const subjectList = [
  "The present subject is found",
]

export default function ProfileModal(props) {
  const { t } = useTranslation();
  const { adminModalOpen, setAdminModalOpen, email, phone, titleMain, subTitle, emailEmpresa, phoneEmpresa, handleImagelogoUpdate } = props;
  const navigate = useNavigate();
  const cookies = Cookies.get('userInfo').split(',');
  const cookie_str = Cookies.get('compDetails');
  //const cookiesComp = JSON.parse(cookie_str); 
  // const cookie_str = Cookies.get('compDetails');
  // const cookiesComp = JSON.parse(cookie_str); 
  const [open, setOpen] = React.useState(false);
  const [userEmail, setEmail] = useState(emailEmpresa);
  const [userPhone, setPhone] = useState(phoneEmpresa);
  const [address, setAddress] = useState(props.subTitle);
  // const [email, setEmail] = useState(cookiesComp.correo_contacto ? cookiesComp.correo_contacto : "");
  // const [phone, setPhone] = useState(cookiesComp.telefono_contacto ? cookiesComp.telefono_contacto : "");
  // const [address, setAddress] = useState(cookiesComp.direccion ? cookiesComp.direccion : "");
  // ** State
  const [files, setFiles] = useState([])
  const [showAlert, setShowAlert] = useState(false); // Add a state to control the visibility of the alert
  const [showtext, setShowtext] = useState("");
  const [logoimage, setlogoimage] = useState('')
  // ** Hook
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: 'image/png, image/jpeg, image/jpg', // Accept specific image formats
    onDrop: acceptedFiles => {
      // Validate file type here
      const validExtensions = ['image/png', 'image/jpeg', 'image/jpg'];
      const invalidFiles = acceptedFiles.filter(file => !validExtensions.includes(file.type));

      if (invalidFiles.length > 0) {
        setShowAlert(true);
        setShowtext(t("BulkUpload.imageextension"))
      } else {
        setFiles(acceptedFiles.map(file => Object.assign(file)));
      }
    }
  });


  const img = files.map(file => (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: "center", }}>
      <img style={{ borderRadius: "8px" }} width={"143px"} height={"143px"} key={file.name} alt={file.name} className='single-file-image' src={URL.createObjectURL(file)} />
      <Box sx={{
        position: "absolute",
        mb: "-140px",
        mr: "-140px"
      }}>
        <img src={cameraIcons} width={"43px"} height={"43px"} alt="email icon" />
      </Box>
    </Box>
  ))

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    // setOpen(false);
    props.setAdminModalOpen(false)
    setFiles([]);
  }

  const sendHandler = () => {
    //  alert("Update")
    updateCompanyDetails();
    if (files.length > 0)
      updatelogoPicture();
  };
  const updatelogoPicture = async () => {
    try {
      const formdata = new FormData();
      formdata.append("user_mail", email);

      if (files.length > 0) {
        const file = files[0]; // Get the first (and only) file from the array
        formdata.append("image", file);
        formdata.append("imageName", file.name);
      }
      const res = await call_api_function(process.env.REACT_APP_BASE_URL2 + 'upload-image', formdata);
      if (res.status == 200 && res.data == 'upload_ok') {
        getlogo();
      } else {
        if (res.data == 'invalid_name') {
          setShowtext('Nombre inválido')
        } else if (res.data == 'invalid_extension') {
          setShowtext('El logo no es una imagen.')
        } else if (res.data == 'file_size_error') {
          setShowtext('Tamaño inválido.')
        } else if (res.data == 'upload_error') {
          setShowtext('Erros al subir el logo.')
        } else if (res.data == 'resize_error') {
          setShowtext('Error al ajustar el tamaño del logo')
        } else {
          setShowtext('Error en la petición')
        }
        setShowAlert(true);
      }

    } catch (e) {
      setShowAlert(true);
      setShowtext('error', e)
    }
  };
  const updateCompanyDetails = async () => {
    const data_eel = encryptData(cookies[3], 500)
    const data_ede = encryptData(address, 500)
    const data_ece = encryptData(userEmail, 500)
    const data_ete = encryptData(userPhone, 500);

    let dataForm_ = {
      "data_eel": {
        "data_eeli": data_eel["iv"],
        "data_eels": data_eel["salt"],
        "data_eelc": data_eel["ct"]
      },
      "data_ede": {
        "data_edei": data_ede["iv"],
        "data_edes": data_ede["salt"],
        "data_edec": data_ede["ct"]
      },
      "data_ece": {
        "data_ecei": data_ece["iv"],
        "data_eces": data_ece["salt"],
        "data_ecec": data_ece["ct"]
      },
      "data_ete": {
        "data_etei": data_ete["iv"],
        "data_etes": data_ete["salt"],
        "data_etec": data_ete["ct"]
      }
    }
    try {
      const response = await serverRequest('post', 'perfil/update_data_empresa', dataForm_)
      if (response.data == 'actualizado') {
        // window.location.reload();
        cookies[15] = address;
        cookies[16] = userEmail;
        cookies[17] = userPhone;
        Cookies.set('userInfo', cookies.join(','));
        handleClose()

      } else {
        setShowAlert(true);
        setShowtext('An unexpected error occurred. Please report it')
        handleClose()
      }
    } catch (e) {
      setShowAlert(true);
      setShowtext('error', e)
    }
  }
  const getlogo = async () => {
    try {
      const data_eel = encryptData(email, 500)
      var dataForm = {
        "data_eel": {
          "data_eeli": data_eel["iv"],
          "data_eels": data_eel["salt"],
          "data_eelc": data_eel["ct"]
        }
      }
      const res = await serverRequest2('post', 'perfil/get_logo', dataForm)
      setlogoimage(res.data.url_logo);
      props.handleImagelogoUpdate(res.data.url_logo);
    }
    catch (e) {
      setShowAlert(true);
      setShowtext('error', e)
    }
  }
  useEffect(() => {
    getlogo();
  }, [email])
  return (
    <Fragment>
      <Modal
        open={props.adminModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >  
        <Box sx={style}>
          <Box {...getRootProps({ className: 'dropzone' })} sx={files.length ? { height: 140 } : {}}>
            <input {...getInputProps()} />
            {files.length ? (
              img
            ) : (
              <>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: "center", }}>
                  {logoimage ? (
                    <img width={"143px"} height={"143px"} src={logoimage} alt='Main Image' />
                  ) : (
                    <img width={"143px"} height={"143px"} src={defaultimg} alt='Placeholder Image' />
                  )}
                  <Box sx={{
                    position: "absolute",
                    mb: "-140px",
                    mr: "-140px"
                  }}>
                    <img src={cameraIcons} width={"43px"} height={"43px"} alt="email icon" />
                  </Box>
                </Box>
              </>
            )}
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2, flexDirection: "column" }}>
            <Box>
              <LabelInputLeftIcon
                mailIcon={companyIcon}
                type='text'
                label={t("SuperAdminSignUp.inputs.inputLabelCompanyAddress")}
                name='caddress'
                inputValue={address}
                id="caddress"
                placeholder={t("SuperAdminSignUp.inputs.inputTextCompanyAddress")}
                onChange={e => setAddress(e.target.value)}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <LabelInputLeftIcon
                mailIcon={mailIcon}
                type='text'
                label={t("SuperAdminSignUp.inputs.inputLabelEmail")}
                name='email'
                inputValue={userEmail}
                id="email"
                placeholder={t("SuperAdminSignUp.inputs.inputTextCompanyEmail")}
                onChange={e => setEmail(e.target.value)}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <LabelInputLeftIcon
                mailIcon={phoneIcon}
                type='text'
                label={t("SuperAdminSignUp.inputs.inputTextCompanyTel")}
                name='number'
                inputValue={userPhone}
                id="number"
                placeholder={t("SuperAdminSignUp.inputs.inputLabelCompanyTel")}
                onChange={e => setPhone(e.target.value)}
              />
            </Box>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", mt: 4 }}>
            <Button onClick={sendHandler} sx={{ backgroundColor: "primary.main", py: 1, px: { xs: 4, sm: 8 }, fontSize: "18px", fontFamily: "Gilroy-Medium", textTransform: "capitalize" }} variant="contained">{t("buttons.update")}</Button>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", mt: 1 }}>
            <Button onClick={handleClose} style={{ color: "rgba(27, 32, 44, 0.6)", fontSize: 16, fontFamily: "Gilroy-Medium", textDecoration: "underline", textTransform: "capitalize" }} variant="text">{t("buttons.back")}</Button>
          </Box>
        </Box>
      </Modal>
      {showAlert && (
        <AlertModal
          text={showtext}
          onClose={() => setShowAlert(false)}
        />
      )}
    </Fragment>
  );
}