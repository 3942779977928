import axios from 'axios';
// import { AxiosRequestConfig, AxiosResponse, AxiosError, Method } from 'axios';

/* eslint-disable */
export const isAxiosResponse = (response) => {
  return (response).status !== undefined;
}

export const isAxiosError = (response) => {
  return (response).isAxiosError !== undefined;
}
// Sending payload as FormData (parseJson = true, isFormData = true)
// Sending payload as JSON (parseJson = true, isFormData = false)
export const serverRequest = async (method, url, payload={}, params={}, timeout=30000, parseJson=true, isFormData=true) => {
  let data;

  if (isFormData) {
    data = new FormData();
    for (const [key, value] of Object.entries(payload)) {
      parseJson ? data.append(key, JSON.stringify(value)) : data.append(key, value);
    }
  } else {
    data = parseJson ? JSON.stringify(payload) : payload;
  }

  const config = {
    method,
    url,
    params,
    data,
    baseURL: process.env.REACT_APP_BASE_URL2,
    timeout: timeout,
  };

  try {
    const response = await axios(config);
    return response;
  } catch (error) {
    return error;
  }
};

export const serverRequest2 = async (method, url, payload={}, params={}, timeout=30000, customBaseURL=null) => {
  const data = new FormData();
  
  for (const [key, value] of Object.entries(payload)) {
    if (typeof value === 'object' && value !== null) {
      data.append(key, JSON.stringify(value));
    } else {
      data.append(key, value);
    }
  }
  
  const baseURL = customBaseURL || process.env.REACT_APP_BASE_URL2; // Use customBaseURL if provided, otherwise use REACT_APP_BASE_URL2
  
  const config = {
    method,
    url,
    params,
    data,
    baseURL: baseURL,
    timeout: timeout,
  };
  
  try {
    const response = await axios(config);
    return response;
  } catch (error) {
    return error;
  }
};

