import sjcl from 'sjcl';
import CryptoJS from 'crypto-js';

export const encryptData = (data, iter) => {
  const encryptedData = sjcl.encrypt(process.env.REACT_APP_MIX_COOK_KEY, data, { "iter" : iter });
  const parsedData = JSON.parse(encryptedData);
  const encryptedObject = {
    iv: parsedData.iv,
    salt: parsedData.salt,
    ct: parsedData.ct
  };
  return encryptedObject;
};

export const AesEncrypt = (data, salt, iv) => {
    var CryptoJS = require("crypto-js");
    var key = CryptoJS.PBKDF2(process.env.REACT_APP_MIX_COOK_KEY, salt, { hasher: CryptoJS.algo.SHA512, keySize: 64 / 8, iterations: 100 });
    var encrypted = CryptoJS.AES.encrypt(data, key, { iv: iv });
    var ciphertext = CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
    return ciphertext;
};

export const setKeysDict = (salt_, iv_) => {
    var CryptoJS = require("crypto-js");
    var data = {
        data_est: CryptoJS.enc.Hex.stringify(salt_),
        data_eib: CryptoJS.enc.Hex.stringify(iv_)
    };
    return data;
};

export const getKeysSaltAndIv = () =>{
    var salt = CryptoJS.lib.WordArray.random(256);
    var iv = CryptoJS.lib.WordArray.random(16);
    var keys = { iv: iv, salt: salt };
    return keys;
}

export const encryptCurpZeroPayload = (formData, cookieList, subsections_dict, user_type='') => {
  var keys = getKeysSaltAndIv()
  var dS = {
    data_sne:AesEncrypt(formData.name, keys.salt, keys.iv),
    data_sap:AesEncrypt(formData.last_name, keys.salt, keys.iv),
    data_sam:AesEncrypt(formData.mother_last_name, keys.salt, keys.iv), 
    data_sdo:AesEncrypt(formData.day_birth, keys.salt, keys.iv), 
    data_smo:AesEncrypt(formData.month_birth, keys.salt, keys.iv), 
    data_syo:AesEncrypt(formData.year_birth, keys.salt, keys.iv),
    data_sso:AesEncrypt(formData.genderNo, keys.salt, keys.iv),
    data_sns:AesEncrypt(formData.sns, keys.salt, keys.iv), //optional
    data_scm:AesEncrypt(formData.id, keys.salt, keys.iv), //optional


    // data_scl:AesEncrypt(confirmation_legal, keys.salt, keys.iv), //fix me
    // data_scm:AesEncrypt(identification_card, keys.salt, keys.iv),
    data_scl:AesEncrypt(formData.scl, keys.salt, keys.iv), //fix me
    data_sel:AesEncrypt(formData.email, keys.salt, keys.iv),
    data_sto:AesEncrypt(formData.phone, keys.salt, keys.iv),
    data_sen:AesEncrypt(formData.birthState, keys.salt, keys.iv), //subject_edo_birth
    data_sce:AesEncrypt(formData.street, keys.salt, keys.iv),
    data_sca:AesEncrypt(formData.cologne, keys.salt, keys.iv),
    data_smu:AesEncrypt(formData.municipality, keys.salt, keys.iv),
    data_scp:AesEncrypt(formData.postal_code, keys.salt, keys.iv),
    data_seo:AesEncrypt(formData.state, keys.salt, keys.iv),
    data_sfl:AesEncrypt(formData.filterLegalNo, keys.salt, keys.iv),

    // user data cookies
    data_une:AesEncrypt(user_type != 'guest' ? cookieList[0] : 'nombre Invitado', keys.salt, keys.iv), //c[0]
    data_uap:AesEncrypt(user_type != 'guest' ? cookieList[1] : 'a_paterno Invitado', keys.salt, keys.iv), //c[1]
    data_uam:AesEncrypt(user_type != 'guest' ? cookieList[2] : 'a_materno Invitado', keys.salt, keys.iv), //c[2]
    data_uel:AesEncrypt(cookieList[3], keys.salt, keys.iv), //c[3]
    data_uto:AesEncrypt(user_type != 'guest' ? cookieList[5] : 'tel Invitado', keys.salt, keys.iv), //c[5]
    data_uid:AesEncrypt(user_type != 'guest' ? cookieList[6] : '_id Invitado', keys.salt, keys.iv), //c[6]
    data_ucn:AesEncrypt(cookieList[4], keys.salt, keys.iv), //c[4] //cupon
    data_uta:AesEncrypt(formData.user_tjt, keys.salt, keys.iv), // FIXME Change if coupon is used
    data_umr:AesEncrypt(formData.user_master, keys.salt, keys.iv), // NOTE Registered User ('1') / Guest ('0')
    data_umd:AesEncrypt(user_type != 'guest' ? cookieList[6] : '_id Invitado', keys.salt, keys.iv), //c[6] master_id // NOTE ObjectId in case of registered user
    data_ucl:AesEncrypt(formData.user_customer, keys.salt, keys.iv), //customer default NA
    data_userAgent:AesEncrypt(formData.userAgent, keys.salt, keys.iv),
    data_esv:setKeysDict(keys.salt, keys.iv),
    secciones_consulta:subsections_dict
  };
  return dS
}

export const prepareCurpPayload = (formData, cookieList, subsections_dict, user_type='') => {
  var keys = getKeysSaltAndIv()
  var dS = {
    sujeto_curp: formData.curp_zero,
    sujeto_nombre: formData.name,
    sujeto_apellido_p: formData.last_name,
    sujeto_apellido_m: formData.mother_last_name,
    sujeto_dia_nacimiento: formData.day_birth,
    sujeto_mes_nacimiento: formData.month_birth, 
    sujeto_year_nacimiento: formData.year_birth,
    sujeto_sexo: formData.genderNo,
    sujeto_nss: formData.sns, //optional
    sujeto_cedula: formData.id, //optional
    sujeto_folio_first: formData.folio1,
    sujeto_folio_second: formData.folio2,
    
    // data_scl:AesEncrypt(confirmation_legal, keys.salt, keys.iv), //fix me
    // data_scm:AesEncrypt(identification_card, keys.salt, keys.iv),
    sujeto_confirmacion_legal:formData.scl, //fix me
    sujeto_email:formData.email,
    sujeto_tel:formData.phone,
    sujeto_edo_nacimiento:formData.birthState, //subject_edo_birth
    sujeto_calle:formData.street,
    sujeto_colonia:formData.cologne,
    sujeto_municipio:formData.municipality,
    sujeto_codigo_postal:formData.postal_code,
    sujeto_estado:formData.state,
    sujeto_filtro_legal:formData.filterLegalNo,

    // user data cookies
    usuario_nombre:user_type != 'guest' ? cookieList[0] : 'nombre Invitado', //c[0]
    usuario_apellido_p:user_type != 'guest' ? cookieList[1] : 'a_paterno Invitado', //c[1]
    usuario_apellido_m:user_type != 'guest' ? cookieList[2] : 'a_materno Invitado', //c[2]
    usuario_email:cookieList[3], //c[3]
    usuario_tel:user_type != 'guest' ? cookieList[5] : 'tel Invitado', //c[5]
    usuario_id:user_type != 'guest' ? cookieList[6] : '_id Invitado', //c[6]
    usuario_cupon:user_type != 'guest' ? formData.multiRepo : "SB", //c[4] //cupon
    usuario_tjt:formData.user_tjt, // FIXME Change if coupon is used
    usuario_master:formData.user_master, // NOTE Registered User ('1') / Guest ('0')
    usuario_master_id:user_type != 'guest' ? cookieList[6] : '_id Invitado', //c[6] master_id // NOTE ObjectId in case of registered user
    data_ucl:formData.user_customer, //customer default NA
    sujeto_cliente:formData.cliente,//optional
    sujeto_zone:formData.selectzonedata,//optional
    sujeto_sucursal:formData.selectbranchesdata,//optional
    sujeto_staff:formData.staff,//optional
    data_userAgent: formData.userAgent,
    // data_esv:setKeysDict(keys.salt, keys.iv),
    // secciones_consulta:subsections_dict
  };
  return dS
}