
import React, { useRef, useEffect , useState} from "react";
import "./DropDownInput.css"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import mailImage from "../../../../assets/images/company-icon.png"

function DropDownInput(props) {
  const {
    data =[],
    onChange,
    containerStyle,
    inputValue,
    label = "label",
    errorMsg = "errorMsg",
    flag = false,
    name,
    value = "",
    firstName = value,
    id = "",
    star,
    disabled,
    hideFirst = false,
    disableFirst = false,
    icon = "expand_more",
    onBlur,
    mailIcon={mailImage},
    placeholder
  } = props;
  const selectRef = useRef(null);
  return (
    <div className="clddi_input_container">
      <label htmlFor="" className="clddi_label">
        {label}
        <span style={{ color: "red" }}>{star}</span>
      </label>
      <div className="clddi_div1">
        <div className="clddi_container">
          <div className='icon_box'><img src={mailIcon} alt="Lock icon" width={17} height={20} /></div>
          <select
            className="clddi_input form-select"
            value={inputValue}
            onChange={onChange}
            ref={selectRef}
            aria-describedby={flag ? "ErrorMessage" : ""}
            aria-required="true"
            aria-invalid={flag ? "true" : "false"}
            data-rule="select"
            placeholder={placeholder}
          >
            {!hideFirst ? (
              <option value={value} disabled={disableFirst}>
                {firstName}
              </option>
            ) : null}
            {!disabled ? (
              data.map((item, index) => {
                return (
                  <option value={item.value} key={index}>
                    {item.name}
                  </option>
                );
              })
            ) : (
              <option value={inputValue} key={1}>
                {inputValue}
              </option>
            )}
          </select>
          <ExpandMoreIcon className="clddi_icon" />
        </div>
        <div
          id="ErrorMessage"
          aria-hidden={flag ? "false" : "true"}
          role="alert"
          style={{ display: flag ? "block" : "none" }}
        >
          <p className="clddi_error_text">{errorMsg}</p>
        </div>
      </div>
    </div>
  );
}

export default DropDownInput;
