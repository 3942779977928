import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import React, { useEffect, useLayoutEffect, useState, Fragment } from "react";
import Authorizeimage from "../../../assets/images/Authorization Icon.svg";
import reportimg from "../../../assets/images/reporticonblue.svg";
import legalIconOne from "../../../assets/images/legal-icon-one.svg";
import legalIconOneGrey from "../../../assets/images/legal-icon-one-grey.svg";
import legalIconTwo from "../../../assets/images/legal-icon-two.svg";
import legalIconTwoGrey from "../../../assets/images/legal-icon-two-grey.svg";
import legalIconThree from "../../../assets/images/legal-icon-three.svg";
import legalIconThreeGrey from "../../../assets/images/legal-icon-three-grey.svg";
import refreshiconblue from "../../../assets/images/refresh-icon.svg";
import refreshicongrey from "../../../assets/images/refresh-icon-grey.svg";
import refreshiconwhite from "../../../assets/images/refresh-icon-white.svg";
import { useTranslation } from "react-i18next";

import CloseIcon from "@mui/icons-material/Close";

const ButtonDiv = styled.div`
  width: ${props => (props.isModelSummarizeReport ? '55px' : '50px')};
  height: ${props => (props.isModelSummarizeReport ? '55px' : '50px')};
  background-color: ${props => (props.isModelSummarizeReport ? '#FF8D28' : '#4165f6')};
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: ${props => props.clickLocation.y}px;
  left: ${props => props.clickLocation.x}px;
  transform: translate(-50%, -50%);
  margin-top: ${props => props.marginTop};
  margin-left: ${props => props.marginLeft};
  transition: background-color 0.3s, width 0.3s, height 0.3s;

  &:hover {
    background-color: ${props => (props.disabled ? '#4165f6' : '#FF8D28')};
  }
`;

function RevisionLegalComponent({
  vistosReporte,
  updateReport,
  clickLocation,
  handleRevisionLegalClosed,
  summarizeReport,
  analyzeHomonimo,
  startChat,
  refreshLegalReview,
  showAllRevisionIcon,
  curp,
  itemId,
  itemIdNBC,
  itemIndex,
  vistosRevisionSummary,
  vistosRevisionHomonym,
  vistosRevisionChat,
}) {
  const { t } = useTranslation();
  const [isModelSummarizeReport, setisModelSummarizeReport] = useState(false);
  const [isModelAnalyzeHomonimo, setisModelAnalyzeHomonimo] = useState(false);
  const [isModelStartChat, setisModelStartChat] = useState(false);
  const [isModelRefresh, setisModelRefresh] = useState(false);

  const handleSummarizeReport = async () => {
    handleClosed();
    setisModelSummarizeReport(true);
    if (vistosRevisionSummary) {
      await summarizeReport(curp, itemId, false);
    } else {
      await summarizeReport(curp, itemId, true);
    }
  };
  const handleAnalyzeHomonimo = async () => {
    handleClosed();
    setisModelAnalyzeHomonimo(true);
    if (vistosRevisionHomonym) {
      await analyzeHomonimo(curp, itemId, false);
    } else {
      await analyzeHomonimo(curp, itemId, true);
    }
  };
  const handleStartChat = async () => {
    handleClosed();
    setisModelStartChat(true);
    if (vistosRevisionChat) {
      await startChat(curp, itemId, itemIdNBC, false);
    } else {
      await startChat(curp, itemId, itemIdNBC, true);
    }
  };
  const handleRefresh = async () => {
    handleClosed();
    setisModelRefresh(true);
    refreshLegalReview(curp, itemId, itemIdNBC);
  }
  const handleClosed = () => {
    setisModelSummarizeReport(false);
    setisModelAnalyzeHomonimo(false);
    setisModelStartChat(false);
    setisModelRefresh(false);
  };

  return (
    <div>
      <Modal
        open={true}
        onClose={handleRevisionLegalClosed}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {showAllRevisionIcon ? (
          <div>
            <div>
              <div
                style={{
                  position: "absolute",
                  top: clickLocation.y,
                  left: clickLocation.x,
                  transform: "translate(-50%, -50%)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    display: "block",
                    backgroundColor: "white",
                    fontSize: 14,
                    fontFamily: "Gilroy-Medium",
                    width: "45px",
                    height: "45px",
                    borderRadius: "50%",
                  }}
                >
                  {" "}
                  <img
                    src={reportimg}
                    width={"24px"}
                    alt="icon email send"
                    style={{
                      paddingTop: "10px",
                      marginLeft: "14px",
                      display: "block",
                    }}
                  />
                </Typography>
              </div>

              <ButtonDiv 
                disabled={vistosReporte === 0}
                isModelAnalyzeHomonimo={isModelAnalyzeHomonimo} 
                clickLocation={clickLocation}
                marginTop={'-80px'}
                marginLeft={'0'}
              >
                <IconButton
                  disabled={vistosReporte === 0}
                  onClick={() => handleAnalyzeHomonimo()}
                >
                  <Box
                    sx={{
                      p: "7px",
                      height: "40px",
                      width: "40px",
                      mx: "auto",
                      display: "block",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={vistosReporte === 0 ? legalIconOneGrey : legalIconOne}
                      width={"18px"}
                      alt="icon email send"
                    />
                  </Box>
                </IconButton>
              </ButtonDiv>
              <ButtonDiv 
                disabled={vistosReporte === 0}
                isModelSummarizeReport={isModelSummarizeReport} 
                clickLocation={clickLocation}
                marginTop={'-30px'}
                marginLeft={'-80px'}
                >
                <IconButton
                  disabled={vistosReporte === 0}
                  onClick={() => handleSummarizeReport()}
                >
                  <Box
                    sx={{
                      p: "7px",
                      height: "40px",
                      width: "40px",
                      mx: "auto",
                      display: "block",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={vistosReporte === 0 ? legalIconTwoGrey : legalIconTwo}
                      width={"18px"}
                      alt="icon email send"
                    />
                  </Box>
                </IconButton>
              </ButtonDiv>
              <ButtonDiv 
                disabled={vistosReporte === 0}
                isModelStartChat={isModelStartChat} 
                clickLocation={clickLocation}
                marginTop={'-30px'}
                marginLeft={'80px'}
              >
                <IconButton
                  disabled={vistosReporte === 0}
                  onClick={() => handleStartChat()}
                >
                  <Box
                    sx={{
                      p: "7px",
                      height: "40px",
                      width: "40px",
                      mx: "auto",
                      display: "block",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={vistosReporte === 0 ? legalIconThreeGrey : legalIconThree}
                      width={"18px"}
                      alt="icon email send"
                    />
                  </Box>
                </IconButton>
              </ButtonDiv>
              <ButtonDiv
                disabled={vistosRevisionSummary === 0 && vistosRevisionHomonym === 0 && vistosRevisionChat === 0}
                isModelRefresh={isModelRefresh}
                clickLocation={clickLocation}
                marginTop={'80px'}
                marginLeft={'0'}
              >
                <IconButton
                  disabled={vistosRevisionSummary === 0 && vistosRevisionHomonym === 0 && vistosRevisionChat === 0}
                  onClick={() => handleRefresh()}
                >
                  <Box
                    sx={{
                      p: "7px",
                      height: "40px",
                      width: "40px",
                      mx: "auto",
                      display: "block",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={vistosRevisionSummary === 0 && vistosRevisionHomonym === 0 && vistosRevisionChat === 0 ? refreshicongrey : refreshiconwhite}
                      width={"18px"}
                      alt="icon email send"
                    />
                  </Box>
                </IconButton>
              </ButtonDiv>
              <Typography
                sx={{
                  position: "absolute",
                  top: clickLocation.y,
                  left: clickLocation.x,
                  transform: "translate(-50%, -50%)",
                  marginTop: "-120px",
                  color: "#ffffff",
                  fontSize: 14,
                  fontFamily: "Gilroy-Medium",
                }}
              >
                {t("authorizationpanel.rightContent.buttons.Homonyms")}
              </Typography>
              <Typography
                sx={{
                  position: "absolute",
                  top: clickLocation.y,
                  left: clickLocation.x,
                  transform: "translate(-50%, -50%)",
                  marginTop: "-70px",
                  marginLeft: "-80px",
                  color: "#ffffff",
                  fontSize: 14,
                  fontFamily: "Gilroy-Medium",
                }}
              >
                {t("authorizationpanel.rightContent.buttons.Summary")}
              </Typography>
              <Typography
                sx={{
                  position: "absolute",
                  top: clickLocation.y,
                  left: clickLocation.x,
                  transform: "translate(-50%, -50%)",
                  marginTop: "-70px",
                  marginLeft: "80px",
                  color: "#ffffff",
                  fontSize: 14,
                  fontFamily: "Gilroy-Medium",
                }}
              >
                {t("authorizationpanel.rightContent.buttons.QnA")}
              </Typography>
              <Typography
                sx={{
                  position: "absolute",
                  top: clickLocation.y,
                  left: clickLocation.x,
                  transform: "translate(-50%, -50%)",
                  marginTop: "120px",
                  color: "#ffffff",
                  fontSize: 14,
                  fontFamily: "Gilroy-Medium",
                }}
              >
                {t("authorizationpanel.rightContent.buttons.Update")}
              </Typography>
            </div>
          </div>
        ) : (
          <div>
            <div
              style={{
                position: "absolute",
                top: clickLocation.y,
                left: clickLocation.x,
                transform: "translate(-50%, -50%)",
                marginLeft: "5px",
                marginTop: "5px",
              }}
            >
              <Typography
                sx={{
                  display: "block",
                  backgroundColor: "white",
                  fontSize: 14,
                  fontFamily: "Gilroy-Medium",
                  width: "45px",
                  height: "45px",
                  borderRadius: "50%",
                }}
              >
                {" "}
                <img
                  src={Authorizeimage}
                  width={"29px"}
                  alt="icon email send"
                  style={{
                    filter: "grayscale(100%)",
                    marginTop: "5px",
                    marginLeft: "7px",
                  }}
                />
              </Typography>
            </div>

            <div
              style={{
                backgroundColor: "#4165f6",
                width: "50px",
                height: "50px",
                borderRadius: "50%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                top: clickLocation.y,
                left: clickLocation.x,
                transform: "translate(-50%, -50%)",
                marginTop: "-80px",
              }}
            >
              <IconButton
              // onClick={() => forwardEmail(itemId, itemIndex)}
              >
                <Box
                  sx={{
                    p: "7px",
                    height: "40px",
                    width: "40px",
                    mx: "auto",
                    display: "block",
                    marginLeft: "3px",
                  }}
                >
                  <SendIcon
                    sx={{ color: "#ffffff", transform: "rotate(-24deg)" }}
                  />
                </Box>
              </IconButton>
            </div>
            <Typography
              sx={{
                position: "absolute",
                top: clickLocation.y,
                left: clickLocation.x,
                transform: "translate(-50%, -50%)",
                marginTop: "-120px",
                color: "#ffffff",
                fontSize: 14,
                fontFamily: "Gilroy-Medium",
              }}
            >
              {t("authorizationpanel.rightContent.buttons.Authorization")}
            </Typography>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default RevisionLegalComponent;
