// src/msalConfig.js
export const msalConfigLala = {
    auth: {
        clientId: 'f44eec0f-3ec9-4be1-a703-af533b2acc44',
        authority:
            'https://login.microsoftonline.com/6bc21fa9-e5b8-4501-93db-ac165a1c1e6c',
        redirectUri: "/login_lala",
        postLogoutRedirectUri: "/login_lala",
        navigateToLoginRequestUrl: true,
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
    },
    windowHashTimeout: 60000,
    iframeHashTimeout: 6000,
    loadFrameTimeout: 0,
};
