import { Grid, Typography, Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import {useState, useEffect} from "react";
import Paper from "@mui/material/Paper";
import Accordion from "@mui/material/Accordion";
import imgReport from "../../assets/images/avatar.svg";
import underLine from "../../assets/images/under-line.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { useTranslation } from 'react-i18next';
import axios from "axios";

function Homonym() {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState([
    "Labor",
    "Civil",
    "Familiar",
    "Penal",
    "Administrativa",
  ]);
  const handleChange = (panel) => (event, isExpanded) => {
    if (isExpanded) {
      setExpanded([...expanded, panel]);
    } else {
      setExpanded(expanded.filter((item) => item !== panel));
    }
  };
  const { data, item_id } = useLocation().state;
  useEffect(() => {
      let formdata = new FormData();
      formdata.append("vlid", item_id);
      formdata.append("type", "vistos_homonym");
      axios.post(process.env.REACT_APP_BASE_URL2 + 'ver_revision', formdata, {"headers": {"Content-Type": "multipart/form-data"}});
  }, []);
  // const language = localStorage.getItem('selectedLanguage');
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Box sx={{ mt: 2 }}>
            <Typography
              sx={{
                fontFamily: "Poppins-SemiBold",
                fontSize: "24px",
                lineHeight: "35px",
                textAlign: "center",
                color: "#1C212D",
              }}
            >
              {t("thanks.Homonyms")}
            </Typography>
            <Box sx={{ textAlign: "center", mt: -1 }}>
              <img src={underLine} alt="under line" />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Box
            sx={{
              backgroundColor: "#ffffff",
              px: 2,
              py: 2,
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <img src={imgReport} width={70} height={70} alt="report" />
              <Box sx={{ ml: 2 }}>
                <Typography
                  sx={{
                    fontSize: 18,
                    fontFamily: "Gilroy-SemiBold",
                    color: "neutral.900",
                  }}
                >
                  {data["userdata"]["name"]}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12}>
          <div>
            <Accordion
              key="Labor"
              expanded={expanded.includes("Labor")}
              onChange={handleChange("Labor")}
              sx={{
                mb: 2,
                "& .MuiAccordionSummary-root.Mui-expanded": {
                  minHeight: "45px !important",
                },
              }}
            >
              <TableContainer
                sx={{ backgroundColor: "primary.main", m: 0 }}
                component={Paper}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        color: "primary.main",
                        backgroundColor: "#ffffff",
                        borderRadius: 50,
                      }}
                    />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    my: "0px !important",
                    "& .MuiAccordionSummary-content": {
                      m: "0px !important",
                      minHeight: "45px !important",
                    },
                  }}
                >
                  <Table sx={{  }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            width: "40px",
                            py: 0,
                            border: 0,
                            color: "#ffffff",
                          }}
                        >
                          A.
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            width: null,
                            py: 0,
                            border: 0,
                            color: "#ffffff",
                          }}
                        >
                          Demandas Laborales
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                </AccordionSummary>
              </TableContainer>
              <AccordionDetails sx={{ display: "flex", flexDirection: "row" }}>
                <Box sx={{ width: "40px" }}></Box>
                <TableContainer sx={{ borderRadius: 0 }} component={Paper}>
                  <Table
                    sx={{  borderRadius: 0 }}
                    aria-label="simple table"
                  >
                    <TableBody sx={{ borderRadius: 0 }}>
                      <p>
                        <span style={{ whiteSpace: "pre-wrap" }}>
                          {data["laborales"] || ""}
                        </span>
                      </p>
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          </div>
          <div>
            <Accordion
              key="Civil"
              expanded={expanded.includes("Civil")}
              onChange={handleChange("Civil")}
              sx={{
                mb: 2,
                "& .MuiAccordionSummary-root.Mui-expanded": {
                  minHeight: "45px !important",
                },
              }}
            >
              <TableContainer
                sx={{ backgroundColor: "primary.main", m: 0 }}
                component={Paper}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        color: "primary.main",
                        backgroundColor: "#ffffff",
                        borderRadius: 50,
                      }}
                    />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    my: "0px !important",
                    "& .MuiAccordionSummary-content": {
                      m: "0px !important",
                      minHeight: "45px !important",
                    },
                  }}
                >
                  <Table sx={{  }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            width: "40px",
                            py: 0,
                            border: 0,
                            color: "#ffffff",
                          }}
                        >
                          B.
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            width: null,
                            py: 0,
                            border: 0,
                            color: "#ffffff",
                          }}
                        >
                          Demandas Civiles
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                </AccordionSummary>
              </TableContainer>
              <AccordionDetails sx={{ display: "flex", flexDirection: "row" }}>
                <Box sx={{ width: "40px" }}></Box>
                <TableContainer sx={{ borderRadius: 0 }} component={Paper}>
                  <Table
                    sx={{  borderRadius: 0 }}
                    aria-label="simple table"
                  >
                    <TableBody sx={{ borderRadius: 0 }}>
                      <p>
                        <span style={{ whiteSpace: "pre-wrap" }}>
                          {data["civiles"] || ""}
                        </span>
                      </p>
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          </div>
          <div>
            <Accordion
              key="Familiar"
              expanded={expanded.includes("Familiar")}
              onChange={handleChange("Familiar")}
              sx={{
                mb: 2,
                "& .MuiAccordionSummary-root.Mui-expanded": {
                  minHeight: "45px !important",
                },
              }}
            >
              <TableContainer
                sx={{ backgroundColor: "primary.main", m: 0 }}
                component={Paper}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        color: "primary.main",
                        backgroundColor: "#ffffff",
                        borderRadius: 50,
                      }}
                    />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    my: "0px !important",
                    "& .MuiAccordionSummary-content": {
                      m: "0px !important",
                      minHeight: "45px !important",
                    },
                  }}
                >
                  <Table sx={{  }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            width: "40px",
                            py: 0,
                            border: 0,
                            color: "#ffffff",
                          }}
                        >
                          C.
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            width: null,
                            py: 0,
                            border: 0,
                            color: "#ffffff",
                          }}
                        >
                          Demandas Familiares
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                </AccordionSummary>
              </TableContainer>
              <AccordionDetails sx={{ display: "flex", flexDirection: "row" }}>
                <Box sx={{ width: "40px" }}></Box>
                <TableContainer sx={{ borderRadius: 0 }} component={Paper}>
                  <Table
                    sx={{  borderRadius: 0 }}
                    aria-label="simple table"
                  >
                    <TableBody sx={{ borderRadius: 0 }}>
                      <p>
                        <span style={{ whiteSpace: "pre-wrap" }}>
                          {data["familiares"] || ""}
                        </span>
                      </p>
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          </div>
          <div>
            <Accordion
              key="Penal"
              expanded={expanded.includes("Penal")}
              onChange={handleChange("Penal")}
              sx={{
                mb: 2,
                "& .MuiAccordionSummary-root.Mui-expanded": {
                  minHeight: "45px !important",
                },
              }}
            >
              <TableContainer
                sx={{ backgroundColor: "primary.main", m: 0 }}
                component={Paper}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        color: "primary.main",
                        backgroundColor: "#ffffff",
                        borderRadius: 50,
                      }}
                    />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    my: "0px !important",
                    "& .MuiAccordionSummary-content": {
                      m: "0px !important",
                      minHeight: "45px !important",
                    },
                  }}
                >
                  <Table sx={{  }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            width: "40px",
                            py: 0,
                            border: 0,
                            color: "#ffffff",
                          }}
                        >
                          D.
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            width: null,
                            py: 0,
                            border: 0,
                            color: "#ffffff",
                          }}
                        >
                          Asuntos Penales
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                </AccordionSummary>
              </TableContainer>
              <AccordionDetails sx={{ display: "flex", flexDirection: "row" }}>
                <Box sx={{ width: "40px" }}></Box>
                <TableContainer sx={{ borderRadius: 0 }} component={Paper}>
                  <Table
                    sx={{  borderRadius: 0 }}
                    aria-label="simple table"
                  >
                    <TableBody sx={{ borderRadius: 0 }}>
                      <p>
                        <span style={{ whiteSpace: "pre-wrap" }}>
                          {data["penales"] || ""}
                        </span>
                      </p>
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          </div>
          <div>
            <Accordion
              key="Administrativa"
              expanded={expanded.includes("Administrativa")}
              onChange={handleChange("Administrativa")}
              sx={{
                mb: 2,
                "& .MuiAccordionSummary-root.Mui-expanded": {
                  minHeight: "45px !important",
                },
              }}
            >
              <TableContainer
                sx={{ backgroundColor: "primary.main", m: 0 }}
                component={Paper}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        color: "primary.main",
                        backgroundColor: "#ffffff",
                        borderRadius: 50,
                      }}
                    />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    my: "0px !important",
                    "& .MuiAccordionSummary-content": {
                      m: "0px !important",
                      minHeight: "45px !important",
                    },
                  }}
                >
                  <Table sx={{  }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            width: "40px",
                            py: 0,
                            border: 0,
                            color: "#ffffff",
                          }}
                        >
                          E.
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            width: null,
                            py: 0,
                            border: 0,
                            color: "#ffffff",
                          }}
                        >
                          Demandas Administrativas
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                </AccordionSummary>
              </TableContainer>
              <AccordionDetails sx={{ display: "flex", flexDirection: "row" }}>
                <Box sx={{ width: "40px" }}></Box>
                <TableContainer sx={{ borderRadius: 0 }} component={Paper}>
                  <Table
                    sx={{  borderRadius: 0 }}
                    aria-label="simple table"
                  >
                    <TableBody sx={{ borderRadius: 0 }}>
                      <p>
                        <span style={{ whiteSpace: "pre-wrap" }}>
                          {data["administrativas"] || ""}
                        </span>
                      </p>
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default Homonym;
