import { Grid, Typography, Box } from "@mui/material";
import React from "react";

import underLine from "../../assets/images/under-line.png";
import EmployReferenceContent from "./EmployReferenceContent";
import { useTranslation } from "react-i18next";

function EmployReferences() {
  const { t } = useTranslation();
  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12}>
          <Box sx={{ mt: 2 }}>
            <Typography
              sx={{
                fontFamily: "Poppins-SemiBold",
                fontSize: "24px",
                lineHeight: "35px",
                textAlign: "center",
                color: "#1C212D",
              }}
            >
              {t("PageName.References")}
            </Typography>
            <Box sx={{ textAlign: "center", mt: -1 }}>
              <img src={underLine} alt="under line" />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: 4,
        }}
      >
        <EmployReferenceContent />
      </Box>
    </>
  );
}

export default EmployReferences;
