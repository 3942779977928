import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { Fragment, useState } from "react";
import logo from "../../assets/images/logo.png";
import underLine from "../../assets/images/under-line.png";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../Footer/Footer";
import bgImage from "../../assets/images/coverphoto.png";
import AnimationLoginScreen from "../@common/AnimationLoginScreen/AnimationLoginScreen";
import { useTranslation } from "react-i18next";
import AuthLeftScreen from "../@common/AuthLeftScreen";
function Signup() {
  const { t } = useTranslation();
  const [adminButton, setAdminButton] = useState(false);
  const [userButton, setUserButton] = useState(false);
  const [disableContinue, setDisableContinue] = useState(true);
  const navigate = useNavigate();

  const supperHanler = () => {
    setAdminButton(true);
    setUserButton(false);
    setDisableContinue(false);
  };
  const userHanler = () => {
    setAdminButton(false);
    setUserButton(true);
    setDisableContinue(false);
  };
  const handleContinueBtn = () => {
    if (adminButton) {
      navigate("/SuperAdminSignUp");
    } else {
      navigate("/UserSignup");
    }
  };
  return (
    <>
      <Box
        sx={{
          background: "#fff",
        }}
        id="particles-js"
      >
        <Grid container>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
                minHeight: "100vh",
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",

                  margin: "auto",
                }}
              >
                <Box
                  sx={{
                    background: "#ffffff",
                    position: "relative",
                    zIndex: 1,
                    borderRadius: 1,
                    p: { xs: 2, sm: 4 },
                    // mt: 3,
                    // mb: 3,
                    width: { xs: "90%", sm: "500px" },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <img src={logo} alt="logo" />
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Typography
                      sx={{
                        fontFamily: "Poppins-SemiBold",
                        fontSize: "24px",
                        lineHeight: "35px",
                        textAlign: "center",
                        color: "#1C212D",
                      }}
                    >
                      {t("Signup.header.title")}
                    </Typography>
                    <Box sx={{ textAlign: "center", mt: -1 }}>
                      <img src={underLine} alt="under line" />
                    </Box>
                  </Box>
                  <Box
                    flex={"column"}
                    sx={{ width: { xs: "90%", sm: "70%" }, mx: "auto", mt: 2 }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                      }}
                    >
                      <Button
                        sx={{
                          backgroundColor: !adminButton
                            ? "#ffffff"
                            : "primary.opacity",
                          textAlign: "center",
                          borderWidth: 1,
                          borderColor: !adminButton
                            ? "neutral.400"
                            : "primary.main",
                          borderStyle: "solid",
                          borderRadius: "6px",
                          width: "100% !important",
                          p: 1.5,
                          color: !adminButton ? "neutral.400" : "primary.main",
                          textDecoration: "none",
                          fontSize: 18,
                          fontFamily: "Gilroy-Medium",
                          textTransform: "capitalize",
                        }}
                        onClick={supperHanler}
                      >
                        {t("Signup.buttons.buttonTextSuperAdmin")}
                      </Button>
                    </Box>
                    <Box
                      sx={{
                        mt: 2,
                      }}
                    >
                      <Button
                        sx={{
                          backgroundColor: !userButton
                            ? "#ffffff"
                            : "primary.opacity",
                          textAlign: "center",
                          borderWidth: 1,
                          borderColor: !userButton
                            ? "neutral.400"
                            : "primary.main",
                          borderStyle: "solid",
                          borderRadius: "6px",
                          width: "100% !important",
                          p: 1.5,
                          color: !userButton ? "neutral.400" : "primary.main",
                          textDecoration: "none",
                          fontSize: 18,
                          fontFamily: "Gilroy-Medium",
                          textTransform: "capitalize",
                        }}
                        onClick={userHanler}
                      >
                        {t("Signup.buttons.buttonTextUser")}
                      </Button>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      mt: 6,
                    }}
                  >
                    <Button
                      onClick={handleContinueBtn}
                      disabled={disableContinue}
                      sx={{
                        backgroundColor: "primary.main",
                        py: 1,
                        px: { xs: 4, sm: 6 },
                        fontSize: "18px",
                        fontFamily: "Gilroy-Medium",
                      }}
                      variant="contained"
                    >
                      {t("Signup.buttons.buttonTextContinue")}
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flex: "row",
                      mt: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        color: "#000000",
                        fontFamily: "Gilroy-Medium",
                        fontSize: "16px",
                      }}
                    >
                      {t("Signup.buttons.returnToLoginLink")}{" "}
                      <Link
                        to="/Login"
                        style={{
                          color: "#4165F6",
                          textDecoration: "none",
                          marginLeft: "5px",
                        }}
                      >
                        {t("Signup.buttons.Login")}
                      </Link>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <AuthLeftScreen text={t("Signup.buttons.Login")} url="/Login" />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Signup;
