import React from "react";
import LoginImage from "../../assets/images/loginRounded.png";
import { Box, Button, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

const AuthLeftScreen = ({ text, url }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          backgroundImage: `url(${LoginImage})`,
          width: "100%",
          // height: "100%",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: 10,
          position: "relative",
        }}
      >
        <Box>
          <Typography
            sx={{
              color: "#fff",
              fontSize: 30,
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {t("authLeftScreen.header")}
          </Typography>
          <Typography
            sx={{ color: "#fff", textAlign: "center", mt: 1, opacity: 0.7 }}
          >
            {t("authLeftScreen.description")}
          </Typography>
          <Box sx={{ textAlign: "center", mt: 3 }}>
            <Button
              variant="outlined"
              sx={{
                color: "#fff",
                boxShadow: "0px 10px 50px #0836650F",
                border: "2px solid #FFFFFF !important",
                borderRadius: "6px",
                textTransform: "capitalize",
                width: 200,
              }}
              onClick={() => navigate(url)}
            >
              {text}
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            position: "absolute",
            bottom: "10%",
            zIndex: 9,
            right: "10%",
          }}
        >
          <Link
            to="/aidVideo"
            style={{
              backgroundColor: "#fff",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              height: 42,
              width: 42,
              borderRadius: 50,
              alignItems: "center",
              boxShadow: 10,
            }}
          >
            <QuestionMarkIcon sx={{ color: "primary.light" }} />
          </Link>
        </Box>
      </Box>
    </>
  );
};

export default AuthLeftScreen;
