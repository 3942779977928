import React, { useState, createContext } from "react";

// Create the context
export const ChatContext = createContext(null);

// Provider Component
export const ChatProvider = ({ children }) => {
  const [chatLog, setChatLogState] = useState({});

  // Custom setter function that merges updates
  const setChatLog = (newData) => {
    setChatLogState((prevChatLog) => ({
      ...prevChatLog,
      ...newData,
    }));
  };

  return (
    <ChatContext.Provider value={{ chatLog, setChatLog }}>
      {children}
    </ChatContext.Provider>
  );
};
