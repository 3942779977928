// FloatingBar.js
import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import IconButton from "@mui/material/IconButton";
import { Box, Typography } from "@mui/material";

import legalIconOne from "../../../assets/images/legal-icon-one.svg";
import legalIconOneGrey from "../../../assets/images/legal-icon-one-grey.svg";

import legalIconTwo from "../../../assets/images/legal-icon-two.svg";
import legalIconTwoGrey from "../../../assets/images/legal-icon-two-grey.svg";

import legalIconThree from "../../../assets/images/legal-icon-three.svg";
import legalIconThreeGrey from "../../../assets/images/legal-icon-three-grey.svg";

import privacyImg from "../../../assets/images/resend-privacy.svg";
import privacyImggrey from "../../../assets/images/svgviewer-output.svg";

import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";


const FloatingBarContainer = styled.div`
  cursor: pointer;
  position: fixed;
  top: 15%;
  right: 5%;
  z-index: 9999;
  transition: all 0.3s ease;
  filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.4));
  
  &.expanded {
    background-color: transparent;
    border-radius: 4px;
    animation: expand 0.3s ease;
  }

  @keyframes expand {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  @media (min-width: 768px) {
    top: 300px;
  }
`;

const ButtonGroup1 = styled.div`
  margin-bottom: 10px;
  border-radius: 4px;
  padding: 5px;
  background-color: #4165F6;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const ButtonGroup2 = styled.div`
  border-radius: 4px;
  padding: 5px;
  background-color: #4165F6;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const SquareButton = styled.button`
  width: 50px;
  height: 50px;
  background-color: #FF8D28;
  border: none;
  border-radius: 50%;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.25));

  &.expanded {
    transform: rotate(45deg);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
  }
`;

const FloatingBar = (props) => {
  const { resendReport, downloadReport } = props;
  const [isOpen, setIsOpen] = useState(false);
  const floatingBarRef = useRef(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (
      floatingBarRef.current &&
      !floatingBarRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  return (
    <FloatingBarContainer
      ref={floatingBarRef}
      className={isOpen ? 'expanded' : ''}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      {isOpen ? (
        <>
          <ButtonGroup1>
            <IconButton
              onClick={() => resendReport()}
              sx={{
                backgroundColor: 'transparent',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.7)',
                },
              }}
            >
              <Box
                sx={{
                  p: "7px",
                  height: "40px",
                  width: "40px",
                  mx: "auto",
                  display: "block",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={privacyImg}
                  width={"26px"}
                  alt="icon email send"
                />
              </Box>
            </IconButton>
            <IconButton
              onClick={() => downloadReport()}
              sx={{
                backgroundColor: 'transparent',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.7)',
                },
              }}
            >
              <Box
                sx={{
                  p: "7px",
                  height: "40px",
                  width: "40px",
                  mx: "auto",
                  display: "block",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <SimCardDownloadIcon
                  sx={{ color: "#ffffff" }}
                />
              </Box>
            </IconButton>
          </ButtonGroup1>
        </>
      ) : (
        <SquareButton
          onClick={handleToggle}
          className={isOpen ? 'expanded' : ''}
        >
          +
        </SquareButton>
      )}
    </FloatingBarContainer>
  );
};

export default FloatingBar; 